import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

interface OwnProps { }

type Props = OwnProps;

const StorePromotionsPage: FunctionComponent<Props> = (props) => {

  return (
    <div>
      <Helmet>
        <title>FH - Kortingen en Coupons</title>
        <meta name="description" content="Kortingen en coupons." />
        <meta name="keywords" content="kortingen, coupons, korting, coupon" />
      </Helmet>
      <h1>Kortingen en Coupons</h1>
    </div>
  );
};

export default StorePromotionsPage;
