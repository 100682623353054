import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow_down_links.svg';
import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import FilterCategory from '../../../../models/FilterCategory';
import ClothingCategory from '../../../../models/storemodels/ClothingCategory';
import { FiltersMap } from '../../../utils/Filters';
import FilterMenuCategoryItems from './filter-menu-category-components/FilterMenuCategoryItems';
import FilterMenuSearch from './filter-menu-category-components/FilterMenuSearch';

interface IProps {
    category: FilterCategory;
    filters: FiltersMap;
    searchable: boolean;
    listByChar: boolean;
    orderByChar: boolean;
    clearFilter: (key: string) => void;
    toggleFilter: (key: string, value: string) => void;
}

/**
 * A child component of FilterMenu which presents one filtercategory.
 * @component
 * @author Sem van Koutrik
 * @param {FilterCategory} category
 * @param {FiltersMap} filters
 * @param {boolean} searchable
 * @param {boolean} listByChar 
 */
const FilterMenuCategory: React.FC<IProps> = (
    {
        category,
        filters,
        searchable,
        listByChar,
        orderByChar,
        clearFilter,
        toggleFilter
    }
) => {
    const [searchResults, setSearchResults] = useState<ClothingCategory[]>();

    const categoryRef = useRef<HTMLDivElement>(null);
    const mqDesktop = window.matchMedia('(min-width: 960px)');

    const items = filters.get(category.category);

    const fold = () => !mqDesktop.matches && categoryRef.current && categoryRef.current.classList.toggle("filter-menu-category--close");

    return (
        <div className={mqDesktop.matches ? "filter-menu-category" : "filter-menu-category filter-menu-category--close"} id={category.name} ref={categoryRef}>
            {
                mqDesktop.matches &&
                searchable &&
                <FilterMenuSearch
                    arrayToSearch={category.clothing_categories}
                    setArray={setSearchResults}
                />
            }

            <div className="filter-menu-category__top" onClick={fold}>
                <p className="filter-menu-category__title">{category.name}</p>

                <div className="right-side">
                    {
                        items &&
                        items.length > 0 &&
                        <button
                            onClick={() => clearFilter(category.category)}
                            className="filter-menu-category__restore-button"
                        >Herstel <Close /></button>
                    }

                    {!mqDesktop.matches && <ArrowDown className="filter-menu-category__arrow" />}
                </div>
            </div>

            {
                !mqDesktop.matches &&
                searchable &&
                <FilterMenuSearch
                    arrayToSearch={category.clothing_categories}
                    setArray={setSearchResults}
                />
            }

            <FilterMenuCategoryItems
                key={category.name}
                listByChar={listByChar}
                category={searchResults === undefined ? category : { ...category, clothing_categories: searchResults }}
                items={items}
                toggleFilter={toggleFilter}
                orderByChar={orderByChar}
            />
        </div>
    )
}

export default FilterMenuCategory;