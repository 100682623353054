import React, {FormEvent, FunctionComponent, useContext, useEffect, useState} from 'react';
import ImageUpload from "../../../../utils/ImageUpload";
import RequestHelper from "../../../../../requests/RequestHelper";
import DummyStoreEndpoint from "../../../../../requests/DummyStoreEndpoint";
import {ReactComponent as Close} from "../../../../../assets/icons/close.svg";
import {ToastContext} from "../../../../../contexts/ToastContext";
import HorecaEndpoint from "../../../../../requests/HorecaEndpoint";

interface OwnProps {
    show: boolean;
    setShow: (show: boolean) => void
}

type Props = OwnProps;

const HorecaImageModal: FunctionComponent<Props> = (props) => {
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const {notify} = useContext(ToastContext)

    const horecaEndpoint = new HorecaEndpoint();

    useEffect(() => {
        const getImageURL = async () => {
            const imageUrlArray = await horecaEndpoint.getImage();
            // @ts-ignore
            setCurrentImageUrl(imageUrlArray.image_url);
        }

        getImageURL().then();
    }, []);

    const handleImageUpload = (image: HTMLImageElement) => {
        setImage(image);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const imageFormData = RequestHelper.convertToFormData({image: image});
        await horecaEndpoint.setImage(imageFormData);

        notify('Horeca afbeelding is gewijzigd');
        props.setShow(false);
    }

    return (
        <div className={`popup-container new-subcategory ${props.show ? "open" : ""}`}>
            <div className="popup">
                <Close onClick={() => props.setShow(false)} className="close"/>
                <form onSubmit={handleSubmit} className="dummy-image-form">
                    <h2>Horeca afbeelding</h2>
                    <ImageUpload
                        setImage={handleImageUpload}
                        currentImageUrl={currentImageUrl}
                    />
                    <p>Min: 204 width, 204 height.</p>
                    <p>Max: 635 width, 358 height </p>
                    <button type="submit">Wijzigen</button>
                </form>
            </div>
        </div>
    );
};

export default HorecaImageModal;
