import RestEndpoint from "./RestEndpoint";

class StoreReviewShopperEndpoint extends RestEndpoint {
    constructor(store_id: number) {
        super(`stores/${store_id}/review-shopper`);
    }

    getReviewShopper() {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}`);
    }
}

export default StoreReviewShopperEndpoint;
