import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import Category from "../../models/Category";
import RestEndpoint from "../../requests/RestEndpoint";
import CategorySection from "./category-manage-page/CategorySection";
import { Helmet } from "react-helmet";

const ManageCategoriesPage: FunctionComponent = (props) => {
    const [categories, setCategories] = useState<Category[]>([]);

    const { user } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (!user || user.role_id !== 1) {
            history.push("/");
        }
    }, [user, history]);

    useEffect(() => {
        const fetchAllCategories = async () => {
            const categoryEndpoint = new RestEndpoint("categories");

            //@ts-ignore
            const allCategories: Category[] = await categoryEndpoint.all();
            setCategories(allCategories);
        };
        fetchAllCategories();
    }, [setCategories]);

    return (
        <div className="categories-management">
            <Helmet>
                <title>FH - Categoriebeheer</title>
            </Helmet>
            <div className="header">
                <h1>Categoriebeheer</h1>
                <p>
                    Hier kun je per hoofd-categorie de categorieën wijzigen, toevoegen en
                    verwijderen waaruit een winkel kan kiezen. Deze categorieën
                    verschijnen ook als filter op de zoekpagina.
        </p>
            </div>
            <div className="categories-management-body">
                {categories.map((category) => (
                    <CategorySection parent={category} key={category.name} />
                ))}
            </div>
        </div>
    );
};

export default ManageCategoriesPage;
