import React from 'react';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

interface OwnProps {
    title: string,
    buttonText: string,
    buttonFunction: () => void,
    setShow: Function,
    show: boolean
}

type Props = OwnProps;

const Popup: React.FC<Props> = (props) => {
    return (
        <div className={`popup-container ${props.show ? "open" : ""}`}>
            <div className="popup">
                <Close onClick={() => props.setShow(false)} className="close" />

                <h2>{props.title}</h2>

                <button className="button" onClick={props.buttonFunction}>{props.buttonText}</button>
            </div>
        </div>
    )
}

export default Popup