import RestEndpoint from "./RestEndpoint";

class ReviewPaginatedEndpoint extends RestEndpoint {
    constructor(storeId: number) {
        super(`stores/${storeId}/reviews-paginated`);
    }

    getPaginated(pageNumber: number) {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}?page=${pageNumber}`);
    }
}

export default ReviewPaginatedEndpoint;
