import React, { ChangeEvent, FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from "react-router";
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow_right_links.svg";
import { ReactComponent as CheckRegister } from "../../assets/icons/check_register.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { PageContentContext } from '../../contexts/PageContentContext';
import { ToastContext } from '../../contexts/ToastContext';
import RequestError from "../../models/RequestError";
import User from "../../models/User";
import CustomCheck from "../utils/CustomCheck";
import ValidationError from "../utils/ValidationError";


interface OwnProps {
}

type Props = OwnProps & RouteComponentProps;

class RegisterDate {
    day: string = '1';
    month: string = '1';
    year: string = '2000';
}

const Register: FunctionComponent<Props> = (props) => {
    const [user, setUser] = useState(new User());
    const [birthday, setBirthday] = useState(new RegisterDate());
    const [agreedTermasAndConditions, setAgreedTermasAndConditions] = useState<boolean>(false);
    const [requestError, setRequestError] = useState<RequestError>();

    const { register } = useContext(AuthContext);
    const { notify } = useContext(ToastContext);
    const {pageContents} = useContext(PageContentContext);

    const benefitOne = pageContents.find(pageContent => pageContent.location === 'login-account-benefits-1')?.content;
    const benefitTwo = pageContents.find(pageContent => pageContent.location === 'login-account-benefits-2')?.content;
    const benefitThree = pageContents.find(pageContent => pageContent.location === 'login-account-benefits-3')?.content;

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setUser({ ...user, [e.target.id]: e.target.value })
    };

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.id === 'day') {
            if (parseInt(e.target.value) < 1) {
                setBirthday({ ...birthday, day: '1' })
            } else {
                if (birthday.month === '2') {
                    if (((parseInt(birthday.year) % 4 === 0) && (parseInt(birthday.year) % 100 !== 0)) || (parseInt(birthday.year) % 400 === 0)) {
                        if (parseInt(e.target.value) > 29) {
                            setBirthday({ ...birthday, day: '29' })
                        } else {
                            setBirthday({ ...birthday, [e.target.id]: e.target.value })
                        }
                    } else {
                        if (parseInt(e.target.value) > 28) {
                            setBirthday({ ...birthday, day: '28' })
                        } else {
                            setBirthday({ ...birthday, [e.target.id]: e.target.value })
                        }
                    }
                } else {
                    if (parseInt(e.target.value) > 31) {
                        setBirthday({ ...birthday, day: '31' })
                    } else {
                        setBirthday({ ...birthday, [e.target.id]: e.target.value })
                    }
                }
            }
        }

        if (e.target.id === 'month') {
            if (parseInt(e.target.value) < 1) {
                setBirthday({ ...birthday, month: '1' })
            } else {
                if (parseInt(e.target.value) > 12) {
                    setBirthday({ ...birthday, month: '12' })
                } else {
                    setBirthday({ ...birthday, month: e.target.value })
                }
            }

            if (parseInt(e.target.value) === 2) {
                if (((parseInt(birthday.year) % 4 === 0) && (parseInt(birthday.year) % 100 !== 0)) || (parseInt(birthday.year) % 400 === 0)) {
                    if (parseInt(birthday.day) > 29) {
                        setBirthday({ ...birthday, day: '29' })
                    }
                } else {
                    if (parseInt(birthday.day) > 28) {
                        setBirthday({ ...birthday, day: '28' })
                    }
                }
            }
        }

        if (e.target.id === 'year') {
            setBirthday({ ...birthday, year: e.target.value })
        }
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const requestError = await register(user);

        if (requestError) {
            window.scrollTo(0, 0);
            setRequestError(requestError);
        } else {
            notify("Bevestig je aanmelding in de mail.")
            props.history.push('/');
        }
    };

    useEffect(() => {
        setUser({ ...user, date_of_birth: `${birthday.year}/${birthday.month}/${birthday.day}` })
    }, [birthday]);

    return (
        <div id="register">
            <Helmet>
                <title>Registreren bij Fashion Hotspots - Fashion Hotspots</title>
                <meta name="description" content="Registreren bij FashionHotspots is gratis. Na het registreren krijg je toegang tot exclusieve kortingsacties bij de Fashion Hotspots winkels" />
                <meta name="keywords" content="register, fashion hotspots, shopper" />
            </Helmet>

            <form onSubmit={handleSubmit}>
                <h1>REGISTREREN</h1>

                {requestError && <ValidationError requestError={requestError} />}

                <h2>PERSOONLIJKE GEGEVENS</h2>

                <input
                    id="first_name"
                    type="text"
                    value={user.first_name}
                    onChange={handleChange}
                    placeholder="Voornaam *"
                    required
                />

                <input
                    id="last_name"
                    type="text"
                    value={user.last_name}
                    onChange={handleChange}
                    placeholder="Achternaam *"
                    required
                />

                <h2>GEBOORTEDATUM</h2>

                <div className="date">
                    <input id="day" type="number"
                        value={birthday.day}
                        onChange={handleDateChange}
                        placeholder="dd *"
                        required />

                    <input id="month" type="number"
                        value={birthday.month}
                        onChange={handleDateChange}
                        placeholder="mm *"
                        required />

                    <input id="year" type="number"
                        value={birthday.year}
                        onChange={handleDateChange}
                        placeholder="yy *"
                        required />
                </div>

                <h2>INLOGGEGEVENS</h2>

                <input id="email" type="text" value={user.email}
                    onChange={handleChange}
                    placeholder="E-mailadres *"
                    required />

                <input id="password" type="password" value={user.password}
                    onChange={handleChange}
                    placeholder="Wachtwoord *"
                    required />

                <input id="password_confirmation" type="password" value={user.password_confirmation}
                    onChange={handleChange}
                    placeholder="Wachtwoord herhalen *"
                    required />

                <div className="terms-and-conditions">
                    <CustomCheck
                        checked={agreedTermasAndConditions}
                        onClick={() => setAgreedTermasAndConditions(!agreedTermasAndConditions)}
                        required={true}
                    />

                    <p>Als je verdergaat, ga je akkoord met onze <Link to="/algemene-voorwaarden">gebruikersvoorwaarden</Link> en bevestig je dat je ons privacybeleid hebt gelezen.</p>
                </div>

                <button type="submit">Registreren <ArrowRight /></button>
            </form>

            <div className="register-div">
                <h1>GRATIS ACCOUNT AANMAKEN</h1>

                <p>De voordelen van een gratis account zijn:</p>

                <ul className="pros">
                    <li>
                        <CheckRegister/>
                        <div dangerouslySetInnerHTML={{__html: benefitOne!}}></div>
                    </li>
                    <li>
                        <CheckRegister/>
                        <div dangerouslySetInnerHTML={{__html: benefitTwo!}}></div>
                    </li>
                    <li>
                        <CheckRegister/>
                        <div dangerouslySetInnerHTML={{__html: benefitThree!}}></div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Register;
