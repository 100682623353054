import React, {createRef, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ReactComponent as BrandIcon} from '../../../assets/icons/brand_search_result.svg';
import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import {ReactComponent as SearchIcon} from '../../../assets/icons/magnifier_searchfield.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/map_search_result.svg';
import {ReactComponent as StoreIcon} from '../../../assets/icons/store_search_result.svg';
import Brand from '../../../models/Brand';
import City from '../../../models/City';
import Store from '../../../models/Store';
import {storeToUrl} from "../../utils/StoreHelper";

interface Props {
    stores: Store[],
    cities: City[],
    brands: Brand[],
    routeProps: RouteComponentProps,
    locationAvailable: boolean
}

const Search: React.FC<Props> = ({stores, cities, brands, routeProps, locationAvailable}) => {
    const [storeSug, setStoreSug] = useState<Store[]>([]);
    const [citySug, setCitySug] = useState<City[]>([]);
    const [brandSug, setBrandSug] = useState<Brand[]>([]);
    const [searchValue, setSearch] = useState<string>();

    const mq = window.matchMedia('(max-width: 960px)');

    const searchInputRef = createRef<HTMLInputElement>();
    const searchInputMobile = createRef<HTMLInputElement>();
    const searchResultsRef = createRef<HTMLUListElement>();
    const searchDiv = createRef<HTMLDivElement>();
    const noResultsRef = createRef<HTMLLIElement>();

    const highlightText = (text: string) => {
        if (!searchValue) return;

        const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));

        return (
            <p>
                {
                    parts.map((part, i) =>
                        <span key={i}
                              style={part.toLowerCase() === searchValue.toLowerCase() ? {fontWeight: 'bold'} : {}}>
                            {part}
                        </span>
                    )
                }
            </p>
        )
    }

    const filter = (list: any[], regExp: RegExp) => {
        return list.length ? list.filter(item => regExp.test(item.name)) : []
    }

    const search = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const noResults = noResultsRef.current;
        noResults?.classList.add("hidden");

        const searchInput = e.target.value.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, "\\$&");
        setSearch(searchInput);

        if (searchInput.length <= 0) {
            setStoreSug([]);
            setCitySug([]);
            setBrandSug([]);
            return;
        }

        const regExp = new RegExp(`${searchInput}`, "i");

        const newStoreSug: Store[] = filter(stores, regExp);
        const newCitySug: City[] = filter(cities, regExp);
        const newBrandSug: Brand[] = filter(brands, regExp);

        const arraysFull = [newStoreSug, newCitySug, newBrandSug].filter(array => array.length > 0).length;
        const slice = Math.ceil(15 / arraysFull);

        setStoreSug(newStoreSug.slice(0, slice === Infinity ? 0 : slice));
        setCitySug(newCitySug.slice(0, slice === Infinity ? 0 : slice));
        setBrandSug(newBrandSug.slice(0, slice === Infinity ? 0 : slice));

        if (arraysFull <= 0) {
            noResults?.classList.remove("hidden")
        } else {
            noResults?.classList.add("hidden")
        }
    }

    const clickSearch = () => {
        const searchResults = searchResultsRef.current;

        if (!searchResults) return;

        if (mq.matches) {
            const search = searchDiv.current;
            const searchInput = searchInputMobile.current;

            if (!search) return;
            if (!searchInput) return;

            search.classList.remove("hidden");

            //Unfocus the main search bar
            searchInputRef.current?.blur();

            //Focus the mobile search bar
            searchInput.focus();
            searchInput.setSelectionRange(0, 0); //iOS-thingy
        } else {
            const searchInput = searchInputRef.current;
            if (!searchInput) return;

            searchInput.value.length !== 0 && searchResults.classList.remove("hidden");
        }
    }

    const closeSearch = () => {
        if (!mq.matches) return;
        if (!searchDiv.current) return;

        searchDiv.current.classList.add("hidden");
    }

    const redirectFilter = (catName: string, itemName: string) => {
        routeProps.history.push(
            `/stad-overzicht/?sorteer_op=${locationAvailable ? "afstand" : "naam"}&sorteer_richting=oplopend&${catName}=${encodeURIComponent(itemName)}`
        );
    }

    const redirectStore = (name: string) => {
        routeProps.history.push(`/winkels/${name}`);
    }

    const focusSearch = () => searchInputRef.current?.focus();

    return (
        <div id="search">
            <div className={`search-input ${mq.matches ? "" : "desktop"}`}>
                <SearchIcon id="search-icon" onClick={mq.matches ? clickSearch : focusSearch}/>
                <input type="text" placeholder="Zoek op stad, merk of winkel..." onClick={clickSearch} onChange={search}
                       ref={searchInputRef}/>
            </div>

            <div className={mq.matches ? "hidden" : ""} id={`search-${mq.matches ? "mobile" : "desktop"}`}
                 ref={searchDiv}>
                {
                    mq.matches &&
                    <div id="search-top">
                        <div id="search-title">
                            <p>Zoeken</p>
                            <Close id="close-icon" onClick={closeSearch}/>
                        </div>

                        <SearchIcon id="search-icon"/>

                        <input type="text" placeholder="Zoek op stad, merk of winkel..." onChange={search}
                               id="search-input" ref={searchInputMobile}/>
                    </div>
                }

                <ul className={`search-results ${!searchValue ? "hidden" : ""}`} ref={searchResultsRef}>

                    {<li className="no-results hidden" ref={noResultsRef}>Geen resultaten.</li>}

                    {
                        citySug.map((city) => {
                            return (
                                <li key={city.id} onClick={() => redirectFilter("stad", city.name)} className="result">
                                    <LocationIcon className="search-result-icon"/>
                                    <div className="result-text">
                                        {highlightText(city.name)}
                                    </div>
                                </li>
                            )
                        })
                    }

                    {
                        brandSug.map((brand) => {
                            return (
                                <li key={brand.id} onClick={() => redirectFilter("merken", brand.name)}
                                    className="result">
                                    <BrandIcon className="search-result-icon"/>
                                    <div className="result-text">
                                        {highlightText(brand.name)}
                                    </div>
                                </li>
                            )
                        })
                    }

                    {
                        mq.matches ?
                            storeSug.map((store) => {
                                return (
                                    <li key={`${store.id}-address`} className="result"
                                        onClick={() => redirectStore(storeToUrl(store))}>
                                        <StoreIcon className="search-result-icon"/>
                                        <div className="result-text">
                                            {highlightText(store.name)}

                                            <p className="address">{store.street} {store.house_number}, {store.city}</p>
                                        </div>
                                    </li>
                                )
                            })
                            :
                            storeSug.map((store) => {
                                return (
                                    <li key={store.id} className="result"
                                        onClick={() => redirectStore(storeToUrl(store))}>
                                        <StoreIcon className="search-result-icon"/>
                                        <div className="result-text">
                                            {highlightText(store.name)}
                                            <p>, {store.street} {store.house_number}, {store.city}</p>
                                        </div>
                                    </li>
                                )
                            })
                    }
                </ul>
            </div>
        </div>
    )
}

export default Search;
