import React, {FunctionComponent, useContext} from 'react';
import {PageContentContext} from "../../contexts/PageContentContext";
import { Helmet } from 'react-helmet';

interface OwnProps {
}

type Props = OwnProps;

const PrivacyPolicyPage: FunctionComponent<Props> = (props) => {

    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'privacy-policy')?.content;

    return (
        <div>
            <Helmet>
                <title>Privacy - FashionHotspots</title>
                <meta name="description" content="Het privacybeleid van Fashion Hotspots"/>
                <meta name="keywords" content="privacy, policy"/>

            </Helmet>
            <div dangerouslySetInnerHTML={{ __html: content! }} className="text-content" />
        </div>
    );
};

export default PrivacyPolicyPage;
