import React, { useState } from 'react'
import PageContentEditor from './PageContentEditor';
import PageContent from '../../../../models/PageContent';
import RestEndpoint from '../../../../requests/RestEndpoint';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow_down_links.svg';

interface IProps {
    content: PageContent[],
    group: string | null,
    pageTitle: string,
    endpoint: RestEndpoint
}

type Props = IProps;

const PageContentList: React.FC<Props> = props => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <div className="page-content-list">
            <h1>{props.pageTitle} <Arrow onClick={() => setShow(!show)} className={show ? "open" : ""} /></h1>

            <div className={show ? "page-content-list-items" : "page-content-list-items close"}>
                {
                    props.content.map(c => {
                        if (c.group === props.group) {
                            return (
                                <>
                                    <PageContentEditor content={c} key={c.id} endpoint={props.endpoint} />
                                </>
                            )
                        } else {
                            return <></>;
                        }
                    })
                }
            </div>
        </div>
    )
}

export default PageContentList;
