import React, {ChangeEvent, FunctionComponent, useContext, useEffect, useState,} from "react";
import RestEndpoint, {RequestDataType} from "../../../requests/RestEndpoint";
import PersonalInfo from "../../../models/PersonalInfo";
import {AuthContext} from "../../../contexts/AuthContext";
import {PersonalDetails} from "./PersonalDetails";
import {ContactDetailsMobile} from "./ContactDetailsMobile";
import {ContactDetailsWeb} from "./ContactDetailsWeb";
import {ChangePassword} from "./ChangePassword";
import {SubmitDetails} from "./SubmitDetails";
import {Link, useParams} from "react-router-dom";
import {ToastContext} from "../../../contexts/ToastContext";
import {Helmet} from "react-helmet";
import {PageContentContext} from "../../../contexts/PageContentContext";

const ShopperDetails: FunctionComponent<{}> = (props) => {
    const [details, setDetails] = useState<PersonalInfo>();
    const [checks, setChecks] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {user, fetchUser} = useContext(AuthContext);
    const {id} = useParams<{ id: string }>();
    const {notify, errorNotification} = useContext(ToastContext);
    const {pageContents} = useContext(PageContentContext);
    const mqDesktop = window.matchMedia('(min-width: 960px)');

    const content = pageContents.find(pageContent => pageContent.location === 'shopper-details')?.content;

    const handleDetailsFormSubmit = async (e: any) => {
        console.log('submit triggered');

        e.preventDefault();

        const updateEndpoint = new RestEndpoint(`shoppers/${user?.id}`);

        let response;

        try {
            response = await updateEndpoint.put(details as RequestDataType);

            notify("Wijzigingen succesvol opgeslagen.");

            window.scrollTo(0, 0);

            fetchUser();
        } catch (e) {
            console.log(e)
            if (e.errors) {
                const errorFields = Object.keys(e.errors);
                console.log(errorFields)

                errorFields.forEach((error, index) => {
                    setErrorMessage(
                        (errorMessage === "" ? "" : ", ") + e.errors[error][0]
                    );
                    setFieldRejected(error);
                });
            } else if (e.message) {
                setErrorMessage(e.message);
            }
        }

        if (response) {
            setChecks(true);
            const divs = document.querySelectorAll("input");
            divs.forEach((div) => {
                div.style.border = "";
            });
            setErrorMessage("");
        }

        window.scrollTo(0, 0);
    };

    const setFieldRejected = (name: string) => {
        const field = document.getElementById(name);

        if (field) {
            field.classList.remove("validated");
            field.style.border = "1px solid red";
        }
    };

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (details) {
            const newDetails: PersonalInfo = details;
            if (!e.target.value) {
                //@ts-ignore
                delete newDetails[e.target.name];
            } else {
                //@ts-ignore
                newDetails[e.target.name] = e.target.value;
            }

            setDetails(newDetails);
        }
    };

    const handleDOBChange = () => {
        if (details) {
            const day = parseInt(
                (document.getElementById("dob_day") as HTMLInputElement).value
            );
            const month = parseInt(
                (document.getElementById("dob_month") as HTMLInputElement).value
            );
            const year = parseInt(
                (document.getElementById("dob_year") as HTMLInputElement).value
            );

            const newDOB = new Date(year, month, day);

            if (details) {
                const newDetails: PersonalInfo = details;

                newDetails.date_of_birth =
                    newDOB.getFullYear() +
                    "-" +
                    ("0" + newDOB.getMonth()).slice(-2) +
                    "-" +
                    ("0" + newDOB.getDate()).slice(-2);

                setDetails(newDetails);
            }
        }
    };

    useEffect(() => {
        if (id) {
        } else {
            if (user) {
                const parsedUser = {
                    redirect_url:
                    user.redirect_url,
                    address: user.shopper_details?.address || "",
                    city: user.shopper_details?.city || "",
                    date_of_birth: user.shopper_details?.date_of_birth || new Date(),
                    email: user.email || "",
                    gender: user.shopper_details?.gender || "",
                    first_name: user.first_name || "",
                    last_name: user.last_name || "",
                    phone_number: user.shopper_details?.phone_number || "",
                    postcode: user.shopper_details?.postcode || "",
                    is_sso: user.is_sso
                };
                setDetails(parsedUser);
            }
        }
    }, [user, id]);

    console.log(mqDesktop.matches);

    if (details) {
        const dateOfBirth = new Date(details.date_of_birth);

        return (
            <div className="personal-details">
                <Helmet>
                    <title>FH - Persoonlijke details</title>
                    <meta name="description" content="Persoonlijke details."/>
                </Helmet>

                <div className="details-header">
                    <div className="subtitle" dangerouslySetInnerHTML={{__html: content!}}></div>

                    <p className="errors">{errorMessage}</p>
                </div>

                <form
                    className="personal-details-form"
                    onSubmit={handleDetailsFormSubmit}
                >
                    <div className="details-body">
                        <div className="details-section">
                            <div className="section-header">
                                <h3>
                                    Persoonlijke Gegevens
                                    <div className="section-icon">
                                        <p>|</p>
                                    </div>
                                </h3>
                            </div>

                            <PersonalDetails
                                details={details}
                                dateOfBirth={dateOfBirth}
                                handleFieldChange={handleFieldChange}
                                handleDOBChange={handleDOBChange}
                                checks={checks}
                            />
                        </div>
                        <div className="details-section">
                            <div className="section-header">
                                <h3>
                                    Contactgegevens
                                    <div className="section-icon">
                                        <p>|</p>
                                    </div>
                                </h3>
                            </div>



                            {
                                mqDesktop.matches ?
                                    <ContactDetailsWeb
                                        details={details}
                                        handleFieldChange={handleFieldChange}
                                        checks={checks}
                                    />
                                    :
                                    <ContactDetailsMobile
                                        details={details}
                                        handleFieldChange={handleFieldChange}
                                        checks={checks}
                                    />
                            }

                        </div>

                        <div className="details-section">
                            <ChangePassword
                                handleFieldChange={handleFieldChange}
                                checks={checks}
                            />
                        </div>

                        <div className="details-section">
                            <SubmitDetails/>
                        </div>

                        <p className="disclaimer">
                            Als je verdergaat, ga je akkoord met onze{" "}
                            <u>
                                <Link to="/algemene-voorwaarden">gebruikersvoorwaarden</Link>
                            </u>{" "}
                            en bevestig je dat je ons{" "}
                            <u>
                                <Link to="/privacy-verklaring">privacybeleid</Link>
                            </u>{" "}
                            hebt gelezen.
                        </p>
                    </div>
                </form>
            </div>
        );
    } else {
        return <p>Loading...</p>;
    }
};

export default ShopperDetails;
