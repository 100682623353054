import React, {FunctionComponent, useContext, useState} from "react";
import Banner from "../../../models/Banner";
import ImageUpload from "../../utils/ImageUpload";
import RestEndpoint from "../../../requests/RestEndpoint";
import RequestHelper from "../../../requests/RequestHelper";
import {ToastContext} from "../../../contexts/ToastContext";
import {BannerContext} from "../../../contexts/BannerContext";

const BannerItem: FunctionComponent<{ banner: Banner }> = (props) => {
    const [bannerImage, setBannerImage] = useState<HTMLImageElement>();
    const {notify} = useContext(ToastContext);
    const {getAllBanners} = useContext(BannerContext);

    const bannerResolutions: { name: string, resolution: string }[] = [
        {
            "name": "home-header-desktop",
            "resolution": "2600 x 750px"
        },
        {
            "name": "home-header-mobile",
            "resolution": "1920 x 1440px"
        }
    ]

    const handleChange = async (image: HTMLImageElement) => {
        setBannerImage(image);

        const endpoint = new RestEndpoint(`banners`);

        const data = RequestHelper.convertToFormData({
            id: props.banner.id,
            image,
        });

        await endpoint.update(data);
        notify("De wijzigingen zijn opgeslagen.");
        await getAllBanners();
    };

    return (
        <div className="banner-item">
            <ImageUpload
                setImage={handleChange}
                currentImageUrl={props.banner.image_url}
            />
            <p className="location">{props.banner.location} <b>({bannerResolutions.find(
                bannerResolution => bannerResolution.name === props.banner.location)?.resolution ?? '1924 x 256px'
            })</b></p>
        </div>
    );
};

export default BannerItem;
