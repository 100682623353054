import React, { useEffect, useState, useContext } from "react";
import RestEndpoint from "../../../requests/RestEndpoint";
import { ToastContext } from "../../../contexts/ToastContext";
import ReactPaginate from "react-paginate";
import User from "./UserManagement/User";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

interface OwnProps {
  type: string;
}

type Props = OwnProps;

export interface IUser {
  id: number;
  address: string;
  city: string;
  gender: string;
  date_of_birth: string;
  email: string;
  first_name: string;
  last_name: string;
  number_of_reviews: number;
  phone_number: string;
  postcode: string;
  rating: number;
  created_at: string;
  email_verified_at?: string;
}

interface IUserResponse {
  data?: IUser[];
  links?: {
    first: string;
    last: string;
    next: string;
    prev: string;
  };
  meta?: {
    currentpage: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

const endpoint = new RestEndpoint("users");

const UserManagement: React.FC<Props> = (props) => {
  const [users, setUsers] = useState<IUser[]>();
  const [response, setResponse] = useState<IUserResponse>();
  const [page, setPage] = useState<number>(0);
  const { notify, errorNotification } = useContext(ToastContext);

  useEffect(() => {
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleUserDelete = (userId: number) => {
    setUsers(users?.filter(user => user.id !== userId));
  };

  const getUsers = async () => {
    try {
      let response: IUserResponse = await endpoint.search([
        { type: "type", values: [props.type] },
        { type: "page", values: [page] },
      ]);

      setResponse(response);

      if (response.data) {
        setUsers(response.data);
      } else {
        notify("Er gaat iets fout bij het laden van de gebruikers", "error");
      }
    } catch (e) {
      errorNotification(e);
    }
  };

  const mqDesktop = window.matchMedia("(min-width: 960px)");

  const changePage = (data: any) => setPage(data.selected + 1);

  const getCurrentPage = () => page;

  return (
    <div id="user-management">
      <Helmet>
        <title>Superuser - Gebruikers</title>
      </Helmet>

      <h1>{props.type === "shopper" ? "Shoppers" : "Administrators"}</h1>
      <p className="subtitle">
        Op deze pagina kun je{" "}
        {props.type === "shopper" ? "shoppers" : "administrators"} beheren.
      </p>
      {props.type === "admin" ? (
        <Link to="/admin-details/nieuw">
          <button className="button">Nieuwe administrator</button>
        </Link>
      ) : null}

      {users?.map((user) => (
        <User
          user={user}
          endpoint={endpoint}
          key={user.id}
          handleUserDelete={handleUserDelete}
          errorNotification={errorNotification}
          type={props.type}
        />
      ))}

      {
        //@ts-ignore
        response?.last_page !== 1 && (
          <ReactPaginate
            previousLabel={mqDesktop.matches ? "vorige" : "<"}
            nextLabel={mqDesktop.matches ? "volgende" : ">"}
            breakLabel={"..."}
            disableInitialCallback={true}
            initialPage={getCurrentPage()}
            breakClassName={"break-me"}
            pageCount={
              response ? (response.meta ? response.meta.last_page : 0) : 0
            }
            pageRangeDisplayed={2}
            marginPagesDisplayed={3}
            onPageChange={changePage}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )
      }
    </div>
  );
};

export default UserManagement;
