import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../contexts/AuthContext";
import RestEndpoint from "../../requests/RestEndpoint";
import {ReactComponent as CallLogo} from "../../assets/icons/contact/phone.svg";
import {ReactComponent as WhatsAppLogo} from "../../assets/icons/contact/whatsapp.svg";
import {ReactComponent as EmailLogo} from "../../assets/icons/contact/mail.svg";
import {ReactComponent as ContactFormLogo} from "../../assets/icons/contact/contactform.svg";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow-right2.svg";

import FHDetail from "../../models/FHDetail";
import {Link} from "react-router-dom";

interface OwnProps {
}

type Props = OwnProps;

const FAQContact: FunctionComponent<Props> = (props) => {

    const [fhDetail, setFhDetail] = useState<FHDetail>();
    const {user} = useContext(AuthContext);
    const fhDetailEndpoint = new RestEndpoint('fashion-hotspots');

    useEffect(() => {

        const fetchFHDetails = async () => {
            // @ts-ignore
            setFhDetail(await fhDetailEndpoint.find(1));
        };

        fetchFHDetails();
    }, []);

    if (!fhDetail) return <div></div>;

    return (
        <div className="faq-contact">
            {(user?.role_id === 1 || user?.role_id === 2) &&
            <a href={"tel:" + fhDetail?.whatsapp} className="contact-item no-border">
                <div className="contact-item-content">
                    <div className="mb-2">
                        <b>Bel ons</b> <span>(lokaal tarief)</span>
                    </div>
                    <div className="show-on-tablet">
                        <b>{fhDetail?.phone_pretty}</b>
                    </div>
                </div>
                <div className="contact-item-logo"><CallLogo/></div>
            </a>
            }

            {(user?.role_id === 1 || user?.role_id === 2) &&
            <a href={"https://wa.me/" + fhDetail?.whatsapp} target="_blank" className="contact-item" rel="noopener noreferrer">
                <div className="contact-item-content">
                    <div className="mb-2">
                        <b>WhatsApp ons</b>
                    </div>
                    <div className="show-on-tablet">
                        <b>{fhDetail?.whatsapp_pretty}</b>
                    </div>
                </div>
                <div className="contact-item-logo"><WhatsAppLogo/></div>
            </a>
            }

            <div className="show-on-tablet no-border">
                <div className="contact-form-mail">
                    {user?.role_id &&
                    <a href={"mailto:" + fhDetail?.email} className="contact-item">
                        <div className="contact-item-content">
                            <div><b>Mail ons</b><span>Binnen 24 uur reactie</span></div>
                        </div>
                        <div className="contact-item-arrow show-on-tablet">
                            <ArrowRight/>
                        </div>
                    </a>
                    }
                    {user?.role_id &&
                    <div className="border-bottom"></div>
                    }

                    <Link className="contact-item" to="/contact">
                        <div className="contact-item-content">
                            <div><b>Contactformulier</b><span>Binnen 24 uur reactie</span></div>
                        </div>

                        <div className="contact-item-arrow">
                            <ArrowRight/>
                        </div>
                    </Link>
                </div>
            </div>
            {user?.role_id &&
            <div className="hide-on-tablet">
                <a href={"mailto:" + fhDetail?.email} className="contact-item">
                    <div className="contact-item-content">
                        <div><b>Mail ons</b></div>
                    </div>
                    <div className="contact-item-logo"><EmailLogo/></div>
                </a>
            </div>
            }
            <div className="hide-on-tablet">
                <Link className="contact-item no-border" to="/contact">
                    <div className="contact-item-content">
                        <div><b>Contactformulier</b></div>
                    </div>
                    <div className="contact-item-logo"><ContactFormLogo/></div>
                </Link>
            </div>
        </div>
    );
};

export default FAQContact;
