import React, {FunctionComponent, useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {StoreDetails} from "../../../../../models/StoreDetails";
import RestEndpoint from "../../../../../requests/RestEndpoint";
import StoreItem from "./StoreItem";

interface IProps {
    filter: number | null,
    updateDummy?: (store: number) => void,
    storesReset: boolean,
    setStoresReset: React.Dispatch<React.SetStateAction<boolean>>
}

const StoreList: FunctionComponent<IProps> = ({filter, updateDummy, storesReset, setStoresReset}) => {
    const [stores, setStores] = useState<StoreDetails[]>([]);
    const [page, setPage] = useState<number>(0);
    const [amountOfPages, setAmountOfPages] = useState<number>();
    const mqDesktop = window.matchMedia("(min-width: 960px)");

    const getAllStores = async () => {
        const userEndpoint = new RestEndpoint("stores");

        let response;

        if (filter === null) {
            response = await userEndpoint.search([
                {type: "type", values: ["store-owner"]},
                {type: "page", values: [page]},
                {type: "paginate", values: ["true"]}
            ]);
        } else {
            response = await userEndpoint.search([
                {type: "type", values: ["store-owner"]},
                {type: "filter", values: [filter]},
                {type: "page", values: [page]},
                {type: "paginate", values: ["true"]}
            ]);
        }

        //@ts-ignore
        const allStores: StoreDetails[] = response.data;

        //@ts-ignore
        setAmountOfPages(response.meta.last_page);
        setStores(allStores);
    };

    useEffect(() => {
        getAllStores();
        storesReset && setStoresReset(false);

    }, [page, filter, storesReset]);

    return (
        <>
            {
                stores.map((store) => {
                    return <StoreItem store={store} key={store.id} updateDummy={updateDummy}
                                      setStoresReset={setStoresReset} filter={filter}/>;
                })
            }

            <ReactPaginate
                previousLabel={mqDesktop.matches ? "vorige" : "<"}
                nextLabel={mqDesktop.matches ? "volgende" : ">"}
                breakLabel={"..."}
                disableInitialCallback={true}
                initialPage={page}
                breakClassName={"break-me"}
                pageCount={amountOfPages || 0}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                onPageChange={(data) => {
                    setPage(data.selected + 1);
                }}
                containerClassName={"pagination"}
                activeClassName={"active"}
            />
        </>
    );
};

export default StoreList;
