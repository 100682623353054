import React, { ChangeEvent, FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ReactComponent as Check } from "../../../../assets/icons/check_white_checkbox.svg";
import { FilterCategoriesContext } from "../../../../contexts/FilterCategoriesContext";
import { PageContentContext } from "../../../../contexts/PageContentContext";
import { ToastContext } from '../../../../contexts/ToastContext';
import FilterCategory from '../../../../models/FilterCategory';
import ClothingCategory from "../../../../models/storemodels/ClothingCategory";
import RestEndpoint from "../../../../requests/RestEndpoint";
import SearchComponent from '../../../utils/SearchComponent';
import {useParams} from "react-router-dom";

interface OwnProps {
}

type Props = OwnProps;

const ManageStoreClothingCategories: FunctionComponent<Props> = (props) => {
    const { filterCategories } = useContext(FilterCategoriesContext);
    const {id} = useParams<{ id: string }>();
    const { getPageContentForLocation } = useContext(PageContentContext);
    const [categories, setCategories] = useState<FilterCategory[]>();
    const [filteredCategories, setFilteredCategories] = useState<FilterCategory[]>();
    const storeClothingCategoriesEndpoint = new RestEndpoint(`stores/${id}/clothing-categories`);
    const [clothingCategoryIds, setClothingCategoryIds] = useState<number[]>([]);
    const { notify } = useContext(ToastContext);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setClothingCategoryIds([...clothingCategoryIds, +e.target.name]);
            return;
        }
        const tempClothingCategoryIds = clothingCategoryIds.filter(clothingCategoryId => clothingCategoryId !== +e.target.name);
        setClothingCategoryIds(tempClothingCategoryIds);
    };

    useEffect(() => {
        getClothingCategoriesOfStore().then();
    }, []);

    useEffect(() => {
        setCategories(filterCategories);
        setFilteredCategories(filterCategories);
    }, [filterCategories])

    const getClothingCategoriesOfStore = async () => {
        // @ts-ignore
        const clothingCategoriesOfStore: ClothingCategory[] = await storeClothingCategoriesEndpoint.all();
        const tempClothingCategoryIds = clothingCategoriesOfStore.map(clothingCategory => clothingCategory.id);
        // @ts-ignore
        setClothingCategoryIds(tempClothingCategoryIds);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await storeClothingCategoriesEndpoint.store({ clothing_category_ids: clothingCategoryIds });
            notify("Wijzigingen opgeslagen.");
        } catch (e) {
            notify(e.message, "error")
        }
    };

    const changeBrand = (array: any[]) => {
        setFilteredCategories(categories?.map(category => {
            if(category.category === "merken") {
                return {...category, clothing_categories: array};
            }

            return category;
        }));
    }

    // @ts-ignore
    const categoriesHTML = filteredCategories?.reduce((categoriesHTML, filterCategory) => {
        if (filterCategory.category === "merken") {
            filterCategory.clothing_categories.sort((a, b) => a.name.localeCompare(b.name));

            const clothingCategoryHTML = filterCategory.clothing_categories.map(clothingCategory => {
                return (
                    <div className="clothing-category" key={clothingCategory.id}>
                        <p>{clothingCategory.name}</p>
                        <div className="checkbox-container">
                            <input
                                id={'' + clothingCategory.id}
                                checked={!!clothingCategoryIds.find(clothingCategoryId => clothingCategoryId === clothingCategory.id)}
                                type="checkbox"
                                name={'' + clothingCategory.id} onChange={handleChange}
                            />
                            <span className="checkmark"><Check /></span>
                        </div>
                    </div>
                );
            });

            const unfilteredCategory = categories?.find(cat => cat.id === filterCategory.id)

            if(unfilteredCategory === undefined) return;

            const filterCategoryHTML = (
                <div key={filterCategory.id}>
                    <h2>{filterCategory.name}</h2>

                    <SearchComponent
                        placeholder="Welk merk zoek je?"
                        objAttr="name"
                        array={unfilteredCategory?.clothing_categories}
                        setArray={changeBrand}
                    />

                    <div className="category-items">
                        {clothingCategoryHTML}
                    </div>
                    <button>Opslaan</button>
                </div>
            );

            return [...categoriesHTML, filterCategoryHTML];
        } else {
            const clothingCategoryHTML = filterCategory.clothing_categories.map(clothingCategory => {
                return (
                    <div className="clothing-category" key={clothingCategory.id}>
                        <p>{clothingCategory.name}</p>
                        <div className="checkbox-container" >

                            <input
                                id={'' + clothingCategory.id}
                                checked={!!clothingCategoryIds.find(clothingCategoryId => clothingCategoryId === clothingCategory.id)}
                                type="checkbox"
                                name={'' + clothingCategory.id} onChange={handleChange}
                            />
                            <span className="checkmark"><Check /></span>
                        </div>
                    </div>
                );
            });

            const filterCategoryHTML = (
                <div key={filterCategory.id}>
                    <h2>{filterCategory.name}</h2>
                    <div className="category-items">
                        {clothingCategoryHTML}
                    </div>
                    <button>Opslaan</button>
                </div>
            );

            return [...categoriesHTML, filterCategoryHTML];
        }
    }, []);

    return (
        <div className="store-clothing-categories store-admin">
            <Helmet>
                <title>FH - Assortiment</title>
                <meta name="description" content="Hier kun je jouw assortiment toevoegen."/>
            </Helmet>
            <div dangerouslySetInnerHTML={{__html: getPageContentForLocation('store-categories')?.content}}>

            </div>
            <form onSubmit={handleSubmit}>
                {categoriesHTML}
            </form>
        </div>
    );
};

export default ManageStoreClothingCategories;
