import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from 'react'
import PageContent from '../../../../models/PageContent';
import RequestError from '../../../../models/RequestError';
import HTMLEditor from '../../../utils/HTMLEditor';
import RestEndpoint from '../../../../requests/RestEndpoint';
import ValidationError from '../../../utils/ValidationError';
import {ToastContext} from '../../../../contexts/ToastContext';
import {PageContentContext} from '../../../../contexts/PageContentContext';

interface OwnProps {
    content: PageContent,
    endpoint: RestEndpoint
}

type Props = OwnProps;

const PageContentEditor: React.FC<Props> = (props) => {
    const {notify} = useContext(ToastContext);
    const {getAllPageContents} = useContext(PageContentContext);

    const [text, setText] = useState<string>("");
    const [requestError, setRequestError] = useState<RequestError>();
    const [content, setContent] = useState<PageContent>(new PageContent);

    const handleChange = (data: string) => {
        setText(data)
    }

    const handleChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!content) return;

        try {
            await props.endpoint.update(content)
            await getAllPageContents();
            notify("Wijzigingen opgeslagen.")
        } catch (e) {
            setRequestError(requestError)
        }
    }

    useEffect(() => {
        setContent({...content, content: text})
    }, [text])

    useEffect(() => {
        setContent(props.content)
    }, [])

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {requestError && <ValidationError requestError={requestError}/>}

                <h2 className="title">{props.content.location}</h2>

                <div className="editor">
                    {
                        props.content.is_html ?
                            <HTMLEditor content={props.content.content} onChange={handleChange}/>
                            :
                            <textarea cols={30} rows={5} onChange={handleChangeTextArea}>{props.content.content}</textarea>
                    }
                </div>


                <button type="submit"> Opslaan</button>
            </form>
        </div>
    )
}

export default PageContentEditor;
