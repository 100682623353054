import React, { FunctionComponent } from "react";

const CheckboxItem: FunctionComponent<{
  image: string;
  checked: boolean;
  name: string;
  id: number;
  handleFieldChange: any;
}> = (props) => {
  return (
    <div className="checkbox-item" key={"extra_service_" + props.name}>
      <img src={props.image} alt={props.name} />
      <p>{props.name}</p>
      <div className="store-details-checkbox">
        <label htmlFor={props.name + props.id.toString()} className="checkbox">
          <input
            type="checkbox"
            id={props.name + props.id.toString()}
            defaultChecked={props.checked}
            onChange={(e) => {
              props.handleFieldChange(e, props.id);
            }}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    </div>
  );
};

export default CheckboxItem;
