import React, { createRef, FunctionComponent, useContext, useEffect, useState, useRef } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";
import { ReactComponent as Account } from "../../assets/icons/account_teal.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as DashboardMenu } from "../../assets/icons/dashboard_menu.svg";
import { ReactComponent as FAQs } from "../../assets/icons/faqs.svg";
import { ReactComponent as LogoDark } from "../../assets/icons/FashionHotspots-logo_beeldmerk.svg";
import { ReactComponent as Logo } from "../../assets/icons/FashionHotspots-logo_teal.svg";
import { ReactComponent as Hamburger } from "../../assets/icons/hamburger_menu.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as StoreGreenMenu } from "../../assets/icons/store_green_menu.svg";
import { AuthContext } from "../../contexts/AuthContext";
import FirstVisitBanner from './navbar-components/FirstVisitBanner';
import HelpMenu from './navbar-components/HelpMenu';
import ShopperLinks from './navbar-components/user-menu-components/ShopperLinks';
import StoreLinks from './navbar-components/user-menu-components/StoreLinks';
import UserMenu from './navbar-components/UserMenu';

interface OwnProps {

}

type Props = OwnProps;

const NavBar: FunctionComponent<Props> = (props) => {
    const { logout, user } = useContext(AuthContext);
    const [firstVisit, setFirstVisit] = useState<boolean>();
    const history = useHistory();
    let menuRef = useRef(document.createElement("div"));

    const mqDekstop = window.matchMedia('(min-width: 960px)');

    const navMenu = createRef<HTMLDivElement>();

    const toggleMenu = () => {
        const menu = navMenu.current;

        if (!menu) return;

        menu.classList.toggle("open");
    }

    useEffect(() => {
        localStorage["visited"] ? setFirstVisit(false) : setFirstVisit(true);

        localStorage.setItem("visited", "1")
    }, [])

    useEffect(() => {
        const menu = navMenu.current;

        if (!menu) return;
        menu.classList.remove("open");
    }, [history.location])

    const closeMenu = () => {
        const menu = navMenu.current;
        menu?.classList.remove("open");
    }

    useEffect(() =>  {
        let handler = (event: any) => {
            if (!menuRef.current.contains(event.target as Node)) {
                closeMenu();
            }
        };

        document.addEventListener("click", handler);

        return () => {
            document.removeEventListener("click", handler);
        };
    });

    return (
        <nav>
            {firstVisit && <FirstVisitBanner setFirstVisit={setFirstVisit} />}
            <div className={!localStorage["visited"] ? "nav-cont" : "nav-cont fixed"}>
                {firstVisit && <FirstVisitBanner setFirstVisit={setFirstVisit} />}

                <div ref={menuRef} className="nav-content">
                    <NavLink to={'/'}><Logo id="logo" /></NavLink>

                    <div className="nav-main-menu">
                        <NavLink to={'/login'}><Account id="account" /></NavLink>


                        {user && <UserMenu user={user} logout={logout}></UserMenu>}

                        {!user && mqDekstop.matches && <NavLink to={'/login'} className="login-title">INLOGGEN</NavLink>}

                        {
                            mqDekstop.matches ?
                                <HelpMenu user={user} />
                                :
                                <Hamburger id="hamburger" onClick={toggleMenu} />
                        }
                    </div>
                </div>
            </div>

            {
                !mqDekstop.matches ?
                    user ?
                        <div className="nav-menu" ref={navMenu}>
                            <div className="nav-menu-top">
                                <p>Hallo <b>{user.first_name}</b></p>

                                <Close id="close" onClick={() => toggleMenu()} />
                            </div>
                            {user.role_id === 3 && <ShopperLinks />}
                            {user.role_id === 2 && <StoreLinks user={user} />}

                            <NavLink to={'/dashboard'}><DashboardMenu /> Dashboard</NavLink>
                            <Link to={'/'} onClick={() => logout()}><Logout /> Uitloggen</Link>

                            <p className="sub-menu-title">HELP</p>
                            <NavLink to={'/veelgestelde-vragen'}><FAQs /> Veelgestelde vragen</NavLink>
                            <NavLink to={'/hoe-werkt-het'}><LogoDark /> Hoe werkt het?</NavLink>
                            {user.role_id === 2 || user.role_id === 3 ? <NavLink to={'/contact'}>Contact</NavLink> : <></>}
                        </div>
                        :
                        <div className="nav-menu" ref={navMenu}>
                            <div className="nav-menu-top">
                                <p>HELP</p>
                                <Close id="close" onClick={() => closeMenu()} />
                            </div>
                            <NavLink to={'/veelgestelde-vragen'}><FAQs /> Veelgestelde vragen</NavLink>
                            <NavLink to={'/hoe-werkt-het'}><LogoDark /> Hoe werkt het?</NavLink>
                            <NavLink to={'/hoe-werkt-het-winkel'}><StoreGreenMenu />Aanmelden winkels</NavLink>
                        </div>
                    :
                    <></>
            }
        </nav>
    );
};

export default NavBar;
