import React, { FunctionComponent } from 'react';
import Store from "../../../../models/Store";

interface OwnProps {
    store: Store
}

type Props = OwnProps;

const About: FunctionComponent<Props> = (props) => {
    return (
        <div className="detail-section-component">
            <h2 className="hide-on-tablet">Over deze winkel</h2>
            <h2 className="show-on-tablet">Over {props.store.name}</h2>
            <div dangerouslySetInnerHTML={{__html: props.store.information}}></div>
        </div>
    );
}

export default About;
