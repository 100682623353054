import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right2.svg";
import { ReactComponent as ArrowRightLong } from "../../assets/icons/arrow_right_links.svg";
import { AuthContext } from "../../contexts/AuthContext";
import FAQSubCategory from "../../models/FAQSubCategory";
import RestEndpoint from "../../requests/RestEndpoint";
import Breadcrumb from "../utils/Breadcrumb";
import FAQHelper from "../utils/FAQHelper";
import FAQContact from "./FAQContact";

interface OwnProps {
}

type Props = OwnProps;


const FAQSubcategoryPage: FunctionComponent<Props> = () => {

    const [faqSubCategory, setFaqSubCategory] = useState<FAQSubCategory>();
    const { sub_category_id } = useParams<{ sub_category_id: string }>();
    const { user } = useContext(AuthContext);

    useEffect(() => {

        const fetchFaqSubCategory = async () => {
            let faqCategoryId = 1;
            if (user) {
                faqCategoryId = FAQHelper.getFAQCategoryId(user.role_id);
            }

            const faqSubCategoryEndpoint = new RestEndpoint(`faq-categories/${faqCategoryId}/faq-subcategories`);

            // @ts-ignore
            setFaqSubCategory(await faqSubCategoryEndpoint.find(sub_category_id));
        };

        fetchFaqSubCategory();
    }, []);

    // @ts-ignore
    const questions = faqSubCategory?.questions.map(question => {
        return (
            <Link key={question.id} className='item' to={`/veelgestelde-vragen/${sub_category_id}/${question.id}`}>
                <span className="question">
                    {question.question}
                </span>

                <span className='subcategory-arrow-right'>
                    <ArrowRight />
                </span>
            </Link>
        )
    });


    return (
        <div>
            <Helmet>
                <title>{ `Veel gestelde vragen over ${faqSubCategory?.name} - Fashion Hotspots FAQ` }</title>
                <meta name="description" content={`Hier vind je de veelgestelde vragen en antwoorden over ${faqSubCategory?.name}. Jouw vraag en antwoord niet kunnen vinden? Log in en stel je vraag via het contactformulier.`} />
            </Helmet>

            <Breadcrumb tiers={[{ url: '/', name: 'Home' }, { url: '/veelgestelde-vragen', name: 'Veelgestelde vragen' },
            { url: '/veelgestelde-vragen/' + faqSubCategory?.id, name: faqSubCategory?.name! }]} />
            <div className="faq-all faq-subcategory">
                <div className='faq-header' style={{ backgroundImage: 'url(' + faqSubCategory?.header_url + ')' }}>
                    <div className='faq-category-title'>
                        {faqSubCategory?.name}
                    </div>
                </div>

                <div className="faq-content">
                    <h3>Snel antwoord op je vraag</h3>
                    <div>
                        {questions}
                    </div>
                    <Link className="back-to-faq-categories" to={'/veelgestelde-vragen'}>
                        <div className="back-image-container">
                            <ArrowRightLong />
                        </div>
                        <span>Naar veelgestelde vragen</span>
                    </Link>
                    <h3>Contact</h3>
                    <FAQContact />
                </div>
            </div>
        </div>
    );
};

export default FAQSubcategoryPage;
