import React, { useEffect, useState } from 'react';
import FilterCategory from '../../../../../models/FilterCategory';
import ClothingCategory from '../../../../../models/storemodels/ClothingCategory';
import FilterMenuItem from './FilterMenuItem';

interface IProps {
    listByChar: boolean,
    category: FilterCategory,
    items?: string[],
    toggleFilter: (key: string, value: string) => void
    orderByChar: boolean
}

const FilterMenuCategoryItems: React.FC<IProps> = ({ listByChar, category, items, toggleFilter, orderByChar }) => {
    const alphabet: string[] = 'abcdefghijklmnopqrstuvwxyz'.split("");

    let [cats, setCats] = useState<ClothingCategory[]>();

    const isChecked = (value: string): boolean => items?.some(i => i == value) ?? false

    const toggleFilterOfCategory = (value: string) => toggleFilter(category.category, value)

    useEffect(() => {
        setCats(
            category.clothing_categories.filter(item => !isChecked(item.name) ? item : false)
        )
    }, [category.clothing_categories])

    return (
        <div
            className={
                category.clothing_categories.length > 5
                    ? "filter-menu-category-items filter-menu-category-items--scroll"
                    : "filter-menu-category-items"}
        >
            <div>
                {
                    items?.map((item, i) =>
                        isChecked(item) ?
                            <FilterMenuItem
                                item={item}
                                toggleFilter={toggleFilterOfCategory}
                                isChecked={isChecked}
                                key={i}
                            /> : <></>
                    )
                }
            </div>

            {
                listByChar ?
                    alphabet.map(char => {
                        if (!cats) return;

                        let itemsFilteredByChar = cats.filter(item => item.name.toLowerCase().startsWith(char));

                        if (!itemsFilteredByChar || itemsFilteredByChar.length === 0) return <div key={char}></div>;

                        return (
                            <>
                                {listByChar && <p className="filter-menu-category-items__char" key={`${char}-char`}>{char.toUpperCase()}</p>}

                                <div className={listByChar ? "filter-menu-category-items__items" : ""} key={`${char}-div`}>
                                    {
                                        itemsFilteredByChar.map((item, i) =>
                                            isChecked(item.name) ?
                                                <FilterMenuItem
                                                    item={item.name}
                                                    toggleFilter={toggleFilterOfCategory}
                                                    isChecked={isChecked}
                                                    key={i}
                                                /> : <div key={i}></div>
                                        )
                                    }
                                </div>
                            </>
                        )
                    })
                    :
                    <>
                        <div className={listByChar ? "filter-menu-category-items__items" : ""}>
                            {
                                orderByChar ?
                                    cats?.sort((a, b) => (a.name < b.name) ? -1 : 1)
                                        .map((item, i) =>
                                            !isChecked(item.name) ?
                                                <FilterMenuItem
                                                    item={item.name}
                                                    toggleFilter={toggleFilterOfCategory}
                                                    isChecked={isChecked}
                                                    key={i}
                                                /> : <div key={i}></div>
                                        )
                                    :
                                    cats?.map((item, i) =>
                                        !isChecked(item.name) ?
                                            <FilterMenuItem
                                                item={item.name}
                                                toggleFilter={toggleFilterOfCategory}
                                                isChecked={isChecked}
                                                key={i}
                                            /> : <div key={i}></div>
                                    )
                            }
                        </div>
                    </>
            }
        </div>
    )
}

export default FilterMenuCategoryItems