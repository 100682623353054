import React, {FunctionComponent} from "react";
import OpeningHoursForDay from "../../../models/storemodels/OpeningHoursForDay";
import OpeningHoursForHoliday from "../../../models/storemodels/OpeningHoursForHoliday";

const OpeningTimeRow: FunctionComponent<{
    day: string | null;
    data: OpeningHoursForDay | OpeningHoursForHoliday;
    handleOpeningTimesChange: any;
    isSpecialOpeningTime: boolean;
}> = (props) => {

    const addIsSpecialIdTag = () => {
        if (props.isSpecialOpeningTime) {
            return '_special';
        }
        return '';
    }

    return (
        <tr key={"opening_time_" + props.data.id}>
            <td>{props.day || ""}</td>
            <td>
                <input
                    type="text"
                    id={props.data.id + addIsSpecialIdTag() + "_start_time"}
                    defaultValue={props.data.start_time || ""}
                    placeholder="00:00"
                    onChange={(e) => {
                        props.handleOpeningTimesChange(e, props.data.id, props.isSpecialOpeningTime);
                    }}
                />
            </td>
            <td>
                <input
                    type="text"
                    id={props.data.id + addIsSpecialIdTag() +  "_end_time"}
                    defaultValue={props.data.end_time || ""}
                    placeholder="00:00"
                    onChange={(e) => {
                        props.handleOpeningTimesChange(e, props.data.id, props.isSpecialOpeningTime);
                    }}
                />
            </td>
            <td className="spacer"></td>
            <td>
                <input
                    type="text"
                    id={props.data.id + addIsSpecialIdTag() + "_closed_start_time"}
                    defaultValue={props.data.closed_start_time || ""}
                    placeholder="00:00"
                    onChange={(e) => {
                        props.handleOpeningTimesChange(e, props.data.id, props.isSpecialOpeningTime);
                    }}
                />
            </td>
            <td>
                <input
                    type="text"
                    id={props.data.id + addIsSpecialIdTag() + "_closed_end_time"}
                    defaultValue={props.data.closed_end_time || ""}
                    placeholder="00:00"
                    onChange={(e) => {
                        props.handleOpeningTimesChange(e, props.data.id, props.isSpecialOpeningTime);
                    }}
                />
            </td>
            <td className="spacer"></td>
            <td className="store-details-checkbox">
                <label htmlFor={props.data.id + addIsSpecialIdTag() + "_closed"} className="checkbox">
                    <input
                        type="checkbox"
                        id={props.data.id + addIsSpecialIdTag() + "_closed"}
                        defaultChecked={props.data.closed === 1}
                        onChange={(e) => {
                            props.handleOpeningTimesChange(e, props.data.id, props.isSpecialOpeningTime);
                        }}
                    />
                    <span className="checkmark"></span>
                </label>
            </td>
        </tr>
    );
};

export default OpeningTimeRow;
