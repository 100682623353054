import React, {FunctionComponent, useState} from "react";
import Model from "../../models/Model";
import Store from "../../models/Store";
import ExpandedCoupon from "../pages/my-coupons-page/ExpandedCoupon";

export class CouponModel extends Model {
    store_id: number = -1;
    title: string = "";
    description: string = "";
    conditions: string = "";
    code: string = "";
    expiry_date: string = "";
    count: number = 0;
    created_at: string = "";
    updated_at: string = "";
    image_url: string = "";

    constructor(Coupon?: CouponModel) {
        super();
        Object.assign(this, Coupon);
    }
}

const Coupon: FunctionComponent<{
    disabled: boolean;
    title: string;
    subtitle: string;
    onClick?: any;
    type?: string;
    blueBackground?: boolean;
    store?: Store;
    coupon?: CouponModel;
    expandCoupon?: boolean;
    isFavorite?: boolean;
}> = (props) => {
    const [modalOpen, setModalOpen] = useState(false);


    const clickCoupon = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        props.onClick &&
        props.onClick(e);

        !props.disabled &&
        props.expandCoupon &&
        setModalOpen(true);
    }

    return (
        <>
            <svg
                width="300"
                height="180"
                className={props.disabled ? `coupon disabled` : `coupon`}
                onClick={clickCoupon}
            >
                {
                    !props.disabled &&
                    <path className="favorite-store"
                          fill={props.isFavorite ? "#00A09B" : "#6c6c6c"}
                          d="M128.85,46.47a15.28,15.28,0,0,0-14.48-10.55H88.21a.84.84,0,0,1-.82-.58L79.31,10.46a15.26,15.26,0,0,0-29,0L42.22,35.34a.79.79,0,0,1-.8.58H15.29a15.25,15.25,0,0,0-9,27.61L27.47,78.9a.84.84,0,0,1,.29.94L19.7,104.7a15.21,15.21,0,0,0,23.46,17L64.3,106.39a.8.8,0,0,1,1,0l21.14,15.35a15.25,15.25,0,0,0,23.48-17l-8.08-24.86a.85.85,0,0,1,.31-1l21.17-15.37A15.26,15.26,0,0,0,128.85,46.47Z"
                          transform="translate(255 40) scale(0.25)"/>
                }

                <g fill="#FFFFFF">
                    <polyline
                        className="coupon-border"
                        points="300,0 300,180"
                        strokeWidth="4"
                    />
                    <polyline
                        className="coupon-border"
                        points="300,180 0,180"
                        strokeWidth="4"
                    />
                    <polyline
                        className="coupon-border"
                        points="0,70 0,0"
                        strokeWidth="4"
                    />
                    <polyline
                        className="coupon-border"
                        points="0,180 0,110"
                        strokeWidth="4"
                    />
                    <circle
                        className="coupon-border half-circle"
                        cx="0"
                        cy="90"
                        r="20"
                        strokeWidth="2"
                        fill={props.blueBackground ? "#cceceb" : "none"}
                    />
                </g>
                <g>
                    <rect
                        className="coupon-header-background"
                        x="0"
                        y="0"
                        width="300"
                        height="30"
                    />
                    <text
                        className="coupon-header-text"
                        x="50%"
                        y="15"
                        dominantBaseline="middle"
                        textAnchor="middle"
                    >
                        {props.type ? props.type : 'COUPON'}
                    </text>
                </g>
                <g>
                    <rect
                        className="coupon-body"
                        x="0"
                        y="0"
                        width="300"
                        height="180"
                        fill="transparent"
                    />

                    <text textAnchor="middle" x="50%" y="50%" className="coupon-title">
                        {props.title}
                    </text>
                    <foreignObject
                        x="30"
                        y="115"
                        width="240"
                        height="45"
                        textAnchor="middle"
                    >
                        <p className="coupon-subtitle">{props.subtitle}</p>
                    </foreignObject>
                </g>
            </svg>


            {
                props.coupon &&
                !props.disabled &&
                <ExpandedCoupon
                    isOpen={modalOpen}
                    closeModal={() => {
                        setModalOpen(false);
                    }}
                    storeImage={props.store?.logo_url}
                    coupon={props.coupon}
                    expiryDate={props.coupon.expiry_date}
                    refreshCoupons={() => Promise.resolve()}
                />
            }

        </>
    );
};

export default Coupon;
