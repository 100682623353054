import React, { useContext } from 'react'
import {ReactComponent as LogoDark} from "../../../assets/icons/FashionHotspots-logo_beeldmerk.svg";
import { ReactComponent as Close } from  "../../../assets/icons/close.svg";
import { PageContentContext } from '../../../contexts/PageContentContext';

interface OwnProps {
    setFirstVisit: Function
}

type Props = OwnProps;

const FirstVisitBanner: React.FC<Props> = ({ setFirstVisit }) => {
    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'first-visit')?.content;

    return (
        <div id="first-visit-banner">
            <Close className="close" onClick={() => setFirstVisit(false)} />

            <div id="left" dangerouslySetInnerHTML={{ __html: content! }} />

            <div id="right">
                <LogoDark />
                <div>
                    <p id="logo-fashion">FASHION</p>
                    <p>HOTSPOTS</p>
                </div>
            </div>
        </div>
    )
}

export default FirstVisitBanner
