import React, {useContext, useEffect, useState} from "react";
import {PageContentContext} from "../../contexts/PageContentContext";

interface IProps {
}

const Cookie: React.FC<IProps> = (props) => {
    const [cookieAccepted, setCookieAccepted] = useState<boolean | undefined>(undefined);
    const {pageContents} = useContext(PageContentContext);
    const cookieBannerContent = pageContents.find(pageContent => pageContent.location === 'cookie-banner')?.content;

    useEffect(() => {
        const cookie = localStorage.getItem("cookie");
        if (cookie === null) {
            return;
        }
        const cookieAccepted = !!+cookie;

        if (cookieAccepted) {
            const tag = document.createElement('script');
            tag.async = true;
            tag.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZMHW92HBM1';
            const body = document.getElementsByTagName('body')[0];
            body.appendChild(tag);
        }

        setCookieAccepted(cookieAccepted);
    }, [])

    const acceptCookiePolicy = () => {
        localStorage.setItem("cookie", "1");

        setCookieAccepted(true);
    }

    const refuseCookiePolicy = () => {
        localStorage.setItem("cookie", "0");

        setCookieAccepted(false);
    }

    return (
        <>
            {
                (cookieAccepted === undefined && cookieBannerContent !== undefined) &&
                <div className="cookie">
                    <div className="wrapper">
                        <div dangerouslySetInnerHTML={{__html: cookieBannerContent!}}></div>
                        <div className="button-container">
                            <button className="accept-button" onClick={acceptCookiePolicy}>Accepteren</button>
                            <button className="decline-button" onClick={refuseCookiePolicy}>Weigeren</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Cookie;
