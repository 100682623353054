import React, {ChangeEvent, FormEvent, FunctionComponent, useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import {ReactComponent as Close} from '../../../../assets/icons/close.svg';
import {CityContext} from "../../../../contexts/CityContext";
import {StoreContext} from "../../../../contexts/StoreContext";
import {ToastContext} from "../../../../contexts/ToastContext";
import {StoreDetails} from "../../../../models/StoreDetails";
import RestEndpoint from "../../../../requests/RestEndpoint";
import DummyImageModal from "./manage-store-components/DummyImageModal";
import ManageStoresDropdown from "./manage-store-components/ManageStoresDropdown";
import StoreList from "./manage-store-components/StoreList";
import Store from "../../../../models/Store";

class Dummy {
    id?: number = 0;
    name: string = "";
    city_id: number = 0;
    street: string = "";
    house_number: string = "";
    latitude: number = 0;
    longitude: number = 0;
}

interface ICityOption {
    value: number;
    label: string;
}

const ManageStores: FunctionComponent = (props) => {
    const history = useHistory();
    const [showDummyImageModal, setShowDummyImageModal] = useState(false);

    const {cities} = useContext(CityContext);
    const {notify, errorNotification} = useContext(ToastContext);
    const {stores} = useContext(StoreContext);
    const storeEndpoint = new RestEndpoint('stores');

    const [showDummy, setShowDummy] = useState<boolean>();
    const [updatingDummy, setUpdatingDummy] = useState<boolean>();
    const [dummyCreds, setDummyCreds] = useState<Dummy>(new Dummy());
    const [filter, setFilter] = useState<number | null>(null);
    const [storesReset, setStoresReset] = useState<boolean>(false);


    const [cityOptions, setCityOptions] = useState<ICityOption[]>([]);

    const newStore = () => {
        localStorage.setItem("newStore", "true");
        history.push("/winkel-eigenaar-details/nieuw");
    };


    const handleDummySubmit = async (e: FormEvent) => {
        e.preventDefault();

        let geo;

        try {
            geo = await getGeolocation();
        } catch (e) {
            errorNotification(e)
            return;
        }

        const dummy: Dummy | StoreDetails = {...dummyCreds, latitude: geo?.lat, longitude: geo?.lng}

        const endpoint = new RestEndpoint("dummy-stores");

        try {
            updatingDummy ?
                await endpoint.update(dummy)
                :
                await endpoint.store(dummy);

            setDummyCreds(new Dummy())
            setShowDummy(false)

            updatingDummy ?
                notify("Dummy geüpdate.")
                :
                notify("Dummy toegevoegd.")

            setStoresReset(true);

            setUpdatingDummy(false)
        } catch (e) {
            errorNotification(e)
        }
    }

    const handleDummyChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDummyCreds({...dummyCreds, [e.target.id]: e.target.value})
    }


    useEffect(() => {
        setCityOptions(cities.map((city) => ({
            value: city.id,
            label: city.name,
        })));
    }, [cities]);


    const handleCitySelect = (e: any) => {
        setDummyCreds({...dummyCreds, city_id: e.value});
    }

    const getGeolocation = async (): Promise<any> => {

        if (cities.length > 0 && dummyCreds.city_id > 0) {
            const city = cities.find((city) => city.id === dummyCreds.city_id);

            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json` +
                `?address=${dummyCreds.street}+${dummyCreds.house_number},+${city?.name}&key=AIzaSyCH8Cm7J5Mviekf5AgqgaA6wOmFhL3lYvE`,
                {
                    mode: "cors",
                    credentials: "same-origin",
                }
            );

            const json = await response.json();

            if (!json.results[0]) throw new Error("No results.");

            const {location} = json.results[0].geometry;

            return location;
        }

        throw new Error("Kan de geo-locatie niet vaststellen.");
    };

    const updateDummy = async (storeId: number) => {
        setUpdatingDummy(true)

        // @ts-ignore
        const dummyToUpdate: Store = await storeEndpoint.find(storeId);

        let dummy: Dummy = {
            id: dummyToUpdate.id,
            name: dummyToUpdate.name,
            city_id: dummyToUpdate.city_id,
            house_number: dummyToUpdate.house_number,
            street: dummyToUpdate.street,
            longitude: dummyToUpdate.longitude,
            latitude: dummyToUpdate.latitude
        }

        await setDummyCreds(dummy);

        setShowDummy(true);
    }

    const closePopup = () => {
        setDummyCreds(new Dummy());
        setUpdatingDummy(false);
        setShowDummy(false);
    }

    return (
        <div className="manage-stores user-management" id="store-management">
            <Helmet>
                <title>Superuser - Winkelbeheer</title>
            </Helmet>

            <h1>Winkelbeheer</h1>

            <p className="subtitle">Op deze pagina kun je winkels bekijken, toevoegen en wijzigen.</p>

            <div className={"buttons-dropdown"}>
                <div>
                    <button className="button" onClick={newStore}>Nieuwe Winkel</button>
                    <button className="button button-dummy" onClick={() => setShowDummy(true)}>Nieuwe Dummy</button>
                    <button className="button ml-1" onClick={() => setShowDummyImageModal(true)}>Dummy afbeelding
                        wijzigen
                    </button>

                </div>

                <ManageStoresDropdown
                    setFilter={setFilter}
                />
            </div>

            <StoreList filter={filter} updateDummy={updateDummy} storesReset={storesReset}
                       setStoresReset={setStoresReset}/>

            <DummyImageModal show={showDummyImageModal} setShow={setShowDummyImageModal}/>

            <div className={`popup-container new-subcategory ${showDummy && "open"}`}>
                <div className="popup">
                    <Close onClick={closePopup} className="close"/>

                    <form onSubmit={e => handleDummySubmit(e)}>
                        <h2>{updatingDummy ? "Update" : "Nieuwe"} dummy</h2>

                        <input placeholder="Naam" id="name" onChange={e => handleDummyChange(e)}
                               value={dummyCreds.name}></input>

                        <div className="select">
                            <Select
                                options={cityOptions}
                                onChange={handleCitySelect}
                                noOptionsMessage={() => "Geen steden gevonden"}
                                value={cityOptions.find((option) => option.value === dummyCreds.city_id) === undefined
                                    ? null
                                    : cityOptions.find((option) => option.value === dummyCreds.city_id)}
                                placeholder="Selecteer een stad"
                            />
                        </div>

                        <br></br>

                        <input placeholder="Straat" id="street" onChange={e => handleDummyChange(e)}
                               value={dummyCreds.street}></input>

                        <input placeholder="Huisnummer" id="house_number" onChange={e => handleDummyChange(e)}
                               value={dummyCreds.house_number}></input>

                        <button type="submit">{updatingDummy ? "Wijzigen" : "Toevoegen"}</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ManageStores;
