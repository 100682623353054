import React, { FunctionComponent } from "react";
import RequestError from "../../../models/RequestError";

const StoreError: FunctionComponent<{ error: RequestError }> = (props) => {
  return (
    <div className="errors">
      {props.error.errors && Object.keys(props.error.errors).length > 1 ? (
        <ul>
          {Object.keys(props.error.errors).map((error, index) => (
            //@ts-ignore
            <li key={"error_index"}>{props.error.errors[error]}</li>
          ))}
        </ul>
      ) : (
        Object.keys(props.error.errors || []).map((error) => (
          //@ts-ignore
          <p key="error">{props.error.errors[error]}</p>
        ))
      )}
    </div>
  );
};

export default StoreError;
