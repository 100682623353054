import React, { FunctionComponent } from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import CityList from './CityList';
import { Helmet } from 'react-helmet';

interface OwnProps extends RouteComponentProps { }

type Props = OwnProps;

const NotFoundPage: FunctionComponent<Props> = (props) => {

  return (
    <div className="text-content" id="not-found-page">
      <Helmet>
        <title>FH - 404</title>
      </Helmet>
      <h1>Sorry, deze pagina is geen hotspot</h1>

      <p>In deze steden zijn wel veel hotspots te vinden...</p>

      <CityList props={props} />

      <p>of ga terug naar onze <Link to="/">homepage</Link></p>
    </div>
  );
};

export default NotFoundPage;
