import React, { ChangeEvent, FormEvent, FunctionComponent, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { AuthContext } from "../../contexts/AuthContext";
import Credentials from "../../models/Credentials";
import RequestError from "../../models/RequestError";
import ValidationError from "../utils/ValidationError";

interface OwnProps {
}

type Props = OwnProps & RouteComponentProps;

const AdminLogin: FunctionComponent<Props> = (props) => {

    const [credentials, setCredentials] = useState(new Credentials());
    const [requestError, setRequestError] = useState<RequestError>();
    const {login} = useContext(AuthContext);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCredentials({...credentials, [e.target.id]: e.target.value})
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const requestError = await login(credentials);
        if (requestError) {
            setRequestError(requestError);
        }
    };

    return (
        <div id="login">
            <Helmet>
                <title>FH - Admin Login</title>
                <meta name="description" content="Login-pagina voor beheerders." />
            </Helmet>

            <form onSubmit={handleSubmit}>
                <h1>INLOGGEN</h1>
                <a href="/password-reset">Ben je, je wachtwoord vergeten?</a>

                { requestError && <ValidationError requestError={requestError}/> }

                <div className="form-group">
                    <input id="email" type="text" value={credentials.email}
                           onChange={handleChange}
                           placeholder="E-mailadres"
                           className="required"
                           required/>
                </div>

                <div className="form-group">
                    <input id="password" type="password" value={credentials.password}
                           onChange={handleChange}
                           className="required"
                           placeholder="Wachtwoord"
                           required/>
                </div>

                <div className="form-group">
                    <button type="submit">Inloggen</button>
                </div>
            </form>
        </div>
    );
};

export default AdminLogin;
