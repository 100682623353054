import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import AgeGender from "../models/AgeGender";
import Category from '../models/Category';

interface OwnProps {

}

interface IAgeGenderContext {
    ageGenders: AgeGender[],
    getAllAgeGenders: () => Promise<void>
}

const ageGenderEndPoint = new RestEndpoint('categories/1');

export const AgeGenderContext = createContext({} as IAgeGenderContext);

type Props = OwnProps;

const AgeGenderContextProvider: FunctionComponent<Props> = (props) => {
    const [ageGenders, setAgeGenders] = useState<AgeGender[]>([]);

    useEffect(() => {
        getAllAgeGenders().then()
    }, [])

    const getAllAgeGenders = async (): Promise<void> => {
        // @ts-ignore
        const category: Category = await ageGenderEndPoint.all();
        // @ts-ignore
        setAgeGenders(category.clothing_categories);
    }

    return (
        <AgeGenderContext.Provider
            value={{ageGenders: ageGenders, getAllAgeGenders: getAllAgeGenders}}>
                {props.children}
        </AgeGenderContext.Provider>
    )
}

export default AgeGenderContextProvider;
