import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import City from "../../models/City";
import RestEndpoint from "../../requests/RestEndpoint";
import ManageCityList from "./manage-cities-page/ManageCityList";
import { StoreContext } from "../../contexts/StoreContext";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CityContext } from "../../contexts/CityContext";
import {ToastContext} from "../../contexts/ToastContext";

const ManageCities: FunctionComponent = (props) => {
  const [cities, setCities] = useState<City[]>([]);
  const [error, setError] = useState("");
  const endpoint = new RestEndpoint('cities');
  const { stores } = useContext(StoreContext);
  const { getAllCities } = useContext(CityContext);
  const history = useHistory();
  const { notify } = useContext(ToastContext)

  useEffect(() => {
    const getAllCities = async () => {
      //@ts-ignore
      const allCities: City[] = await endpoint.all();

      setCities(allCities);
    };
    getAllCities();
  }, [setCities]);

  const deleteItem = async (id: number) => {
    setError("");
    if (stores.filter((store) => store.city_id === id).length > 0) {
      setError("Je kunt geen stad verwijderen waar winkels in gevestigd zijn.");
      return;
    }
    await endpoint.destroy({ id });
    await getAllCities();
    const citiesWithoutDeleted = cities.filter((city) => city.id !== id);
    setCities(citiesWithoutDeleted);
    notify("De stad is succesvol verwijderd.");
  };
  return (
    <div className="manage-cities">
      <Helmet>
        <title>Stadsbeheer</title>
      </Helmet>
      <div className="header">
        <h1>Stadsbeheer</h1>
        <p className="subtitle">
          Op deze pagina kun je all steden aanpassen, verwijderen en nieuwe
          steden toevoegen. Klik op het potloodje om een stad te wijzigen of
          klik op het kruisje om een stad te verwijderen.
        </p>
      </div>
      <div className="body">
        <p className="error">{error}</p>
        <ManageCityList cities={cities} deleteItem={deleteItem} />
        <button
          className="button"
          onClick={() => {
            history.push("/admin/beheer-steden/nieuw");
          }}
        >
          Nieuwe Stad
        </button>
      </div>
    </div>
  );
};

export default ManageCities;
