import React from 'react';
import City from '../../../models/City';
import {ReactComponent as ArrowRight} from '../../../assets/icons/arrow_right_links.svg';

interface IProps {
    city: City | undefined
}

const CityImage: React.FC<IProps> = ({city}) => {
    const mqDesktop = window.matchMedia('(min-width: 960px)');
    const bottomText = 'of kies een van onze routes ';

    return city
        ?
        (
            <>
                <div className="filter-city">
                    {
                        !mqDesktop.matches &&
                        <p className="city-name">{city.name.charAt(0).toUpperCase() + city.name.slice(1)}</p>
                    }

                    <div style={{backgroundImage: !mqDesktop.matches ? `url('${city.image_url}')` : ""}}
                         className="city-image-desktop">
                        {
                            mqDesktop.matches &&
                            <a href={'https://fashionhotspotsroutes.nl/'} target="_blank" className="city-description" >
                                <p className="city-description-text" dangerouslySetInnerHTML={{ __html: city.description }}>
                                </p>
                                <p className="city-description-bottom-text">
                                    {bottomText}
                                    <ArrowRight id="arrow-right-links" className="arrow-right-links"/>
                                </p>
                            </a>
                        }
                    </div>

                    {
                        !mqDesktop.matches &&
                        <p className="city-description">
                            <p className="city-description-text" dangerouslySetInnerHTML={{ __html: city.description }}>
                            </p>
                            <p className="city-description-bottom-text">
                                {bottomText}
                                <ArrowRight id="arrow-right-links" className="arrow-right-links"/></p>
                        </p>
                    }

                </div>
                <div className="filter-city__desktop-image">
                    {
                        mqDesktop.matches &&
                        <div style={{backgroundImage: `url('${city.image_url}')`}}
                             className="filter-city__desktop-img"></div>
                    }
                    {
                        mqDesktop.matches &&
                        <div className="filter-city__desktop-fade"></div>
                    }
                </div>
            </>
        )
        :
        <div></div>
}

export default CityImage;