import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as FAQs } from "../../../assets/icons/faqs.svg";
import { ReactComponent as LogoDark } from "../../../assets/icons/FashionHotspots-logo_beeldmerk.svg";
import { ReactComponent as StoreGreenMenu } from "../../../assets/icons/store_green_menu.svg";
import User from '../../../models/User';

interface OwnProps {
    user: User | undefined;
}

type Props = OwnProps;

const HelpMenu: React.FC<Props> = (props) => {
    return (
        <div className="help-menu">
            <p className="help-title">HELP</p>

            <ul className="help-menu-list">
                <div className="menu-arrow"><span></span></div>
                <NavLink to={'/veelgestelde-vragen'}><FAQs /> Veelgestelde vragen</NavLink>
                <NavLink to={'/hoe-werkt-het'}><LogoDark /> Hoe werkt het?</NavLink>
                <NavLink to={'/contact'}><LogoDark /> Contact</NavLink>
                {!props.user && <NavLink to={'/hoe-werkt-het-winkel'}><StoreGreenMenu /> Aanmelden winkels</NavLink>}
            </ul>
        </div>
    )
}

export default HelpMenu;
