import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrow_right_links.svg";
import { AuthContext } from "../../../../contexts/AuthContext";
import Store from "../../../../models/Store";
import Review from "../../../../models/storemodels/Review";
import ReviewPaginatedEndpoint from "../../../../requests/ReviewPaginatedEndpoint";
import StoreReview from "./StoreReview";

interface OwnProps {
    store: Store
}

type Props = OwnProps;

interface FilterMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number
}

const Reviews: FunctionComponent<Props> = (props) => {
    const {store} = props;
    const reviewPaginatedEndpoint = new ReviewPaginatedEndpoint(store.id);
    const {isAuthenticated, user} = useContext(AuthContext);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [pagination, setPagination] = useState<FilterMeta>();

    useEffect(() => {
        getReviews(1);
    }, [store]);

    const getReviews = async (pageNumber: number) => {
        const paginatedReviews = await reviewPaginatedEndpoint.getPaginated(pageNumber);
        // @ts-ignore
        const reviewsTemp: Review[] = paginatedReviews.data;

        setReviews(reviewsTemp);
        // @ts-ignore
        setPagination(paginatedReviews.meta);
    };

    const reviewsHTML = reviews.map(review => {
        return <StoreReview key={review.id} review={review} store={props.store}/>
    })

    const changePage = (data: any) => {
        let selected = data.selected + 1;

        getReviews(selected).then();
    }


    return (
        <div className="detail-section-component store-reviews-container">
            <div className="overall-ratings">
                <div>
                    <div className="store-rating"><span>{
                        store.number_of_reviews > 0 ?
                            (Math.round(store?.rating * 10) / 10).toString().replace(".", ",")
                            :
                            '-'
                    }</span>/10</div>
                    <p>Gebaseerd op {store.number_of_reviews} recensies</p>
                </div>
            </div>
            {reviewsHTML}
            <div className="hide-on-tablet">
                <div className="store-reviews-footer">
                    <ReactPaginate
                        previousLabel='<'
                        nextLabel='>'
                        breakLabel='...'
                        breakClassName={'break-me'}
                        pageCount={pagination ? pagination.last_page : 0}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        onPageChange={changePage}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                    {
                        (isAuthenticated() && user?.role_id === 3) &&
                        <Link to={`/shopper/maak-review/${store.id}`}>
                            <button className="button">Schrijf een recensie <ArrowRight/></button>
                        </Link>
                    }

                </div>
            </div>

            <div className="show-on-tablet">
                <div className="store-reviews-footer">
                    <ReactPaginate
                        previousLabel={'vorige'}
                        nextLabel={'volgende'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pagination ? pagination.last_page : 0}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={3}
                        onPageChange={changePage}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />

                    {
                        (isAuthenticated() && user?.role_id === 3) &&
                        <Link to={`/shopper/maak-review/${store.id}`}>
                            <button className="button">Schrijf een recensie <ArrowRight/></button>
                        </Link>
                    }

                </div>
            </div>

        </div>
    );
}

export default Reviews;
