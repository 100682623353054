export const getDistance = async (latitude: number, longitude: number) => {
    return new Promise<number>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
            position => {
                const { coords } = position;
                const degreeLatitudeToKm = 111;
                const degreeLongitudeToKm = 66;

                const difLatitudeKm = (coords.latitude - latitude) * degreeLatitudeToKm;
                const difLongitudeKm = (coords.longitude - longitude) * degreeLongitudeToKm;

                resolve(Math.pow(Math.pow(difLatitudeKm, 2) + Math.pow(difLongitudeKm, 2), 0.5));
            },
            error => reject(error)
        )
    )
}