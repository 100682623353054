import React, {useContext, useEffect, useState} from 'react';
import City from '../../../models/City';
import {stringToUrl} from "../../utils/UrlHelper";
import {CityContext} from "../../../contexts/CityContext";

interface OwnProps {
    redirectFilter: Function,
    slice?: number
}

type Props = OwnProps;

const CityPictures: React.FC<Props> = (props) => {
    const { sortedCities } = useContext(CityContext);
    const [cities, setCities]= useState<City[]>([]);

    useEffect(() => {
        setCities(props.slice ? sortedCities.slice(0, props.slice) : sortedCities);
    }, [sortedCities]);

    return (
        <div id="city-pictures">
            {
                cities.map(city =>
                    <div className="city-container" key={city.id}
                         onClick={() => props.redirectFilter("stad", encodeURIComponent(city.name).replace(/ /g, "-"))}>
                        <div className="city" style={{backgroundImage: `url('${city.image_url}')`}} key={city.id}>
                            <span className="gradient"></span>
                            <p>{city.name}</p>
                            <div className="circle"></div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default CityPictures;
