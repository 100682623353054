import React, {
    FunctionComponent,
    useEffect,
    useContext,
    useState,
} from "react";
import ArrowWhite from "../../../assets/icons/arrow_right_white_buttons.svg";
import ReviewItem from "./ReviewItem";
import RestEndpoint from "../../../requests/RestEndpoint";
import { AuthContext } from "../../../contexts/AuthContext";
import Review from "../../../models/storemodels/Review";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PageContentContext } from "../../../contexts/PageContentContext";

const MyReviews: FunctionComponent = () => {
    const { user } = useContext(AuthContext);
    const { pageContents } = useContext(PageContentContext);

    const [reviews, setReviews] = useState<Review[]>([]);

    const content = pageContents.find(pageContent => pageContent.location === 'my-reviews')?.content;

    const [loading, setLoading] = useState<boolean>(true);

    const deleteReview = async (id: number) => {
        setReviews(reviews.filter((review) => review.id !== id));

        const deleteReviewEndpoint = new RestEndpoint(
            `shoppers/${user?.id}/reviews`
        );
        await deleteReviewEndpoint.destroy({ id });
    };

    useEffect(() => {
        const getAllReviews = async (): Promise<void> => {
            setLoading(true);

            try {
                const reviewsEndpoint = new RestEndpoint(
                    `shoppers/${user?.id}/reviews`
                );
                const allReviews = await reviewsEndpoint.all();
                //@ts-ignore
                setReviews(allReviews);
            } catch (e) {
                console.log(e);
            }

            setLoading(false);
        };
        getAllReviews();
    }, [user]);

    const reviewItems = (data: any) => {
        return data.map((review: any) => {
            const store = {
                id: review.store_id,
                name: review.store,
                city: review.city,
            };

            return (
                <ReviewItem
                    store={store}
                    review={review}
                    deleteReview={deleteReview}
                    key={review.id}
                />
            );
        });
    };

    if (reviews) {
        return (
            <div className="my-reviews">
                <Helmet>
                    <title>FH - Mijn reviews</title>
                    <meta name="description" content="Vind hier jouw reviews." />
                </Helmet>

                <div className="reviews-header">
                    <div className="subtitle">
                        <div dangerouslySetInnerHTML={{ __html: content! }} />
                    </div>

                    <div className="button-container">
                        <Link to={`/shopper/maak-review`}>
                            <button className="new-review button">
                                Schrijf een recensie
                <img className="add-icon" src={ArrowWhite} alt="" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="reviews-body">{reviewItems(reviews)}</div>
            </div>
        );
    } else {
        return <p> {loading ? "Loading..." : "Geen reviews."} </p>;
    }
};

export default MyReviews;
