import React, {FunctionComponent, useState} from "react";
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom";
import HorecaList from "./manage-horeca-components/HorecaList";
import HorecaImageModal from "./manage-horeca-components/HorecaImageModal";

const ManageHorecas: FunctionComponent = (props) => {
    const history = useHistory();
    const [showHorecaImageModal, setShowHorecaImageModal] = useState(false);
    const [horecaReset, setHorecaReset] = useState<boolean>(false);

    const newHoreca = () => {
        localStorage.setItem("newStore", "true");
        history.push("/horeca-eigenaar-details/nieuw");
    };

    return (
        <div className="manage-stores user-management" id="store-management">
            <Helmet>
                <title>Superuser - Horecabeheer</title>
            </Helmet>

            <h1>Horecabeheer</h1>

            <p className="subtitle">Op deze pagina kun je horeca bekijken, toevoegen en wijzigen.</p>

            <div className={"buttons-dropdown"}>
                <div>
                    <button className="button" onClick={newHoreca}>Nieuwe Horeca</button>
                    <button className="button ml-1" onClick={() => setShowHorecaImageModal(true)}>Horeca afbeelding
                        wijzigen
                    </button>
                </div>
            </div>

            <HorecaList horecaReset={horecaReset} setHorecaReset={setHorecaReset}/>

            <HorecaImageModal show={showHorecaImageModal} setShow={setShowHorecaImageModal}/>
        </div>
    );
};

export default ManageHorecas;
