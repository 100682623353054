import RestEndpoint from "./RestEndpoint";

class HorecaEndpoint extends RestEndpoint {

    constructor() {
        super('horecas');
    }

    getImage(): string {
        // @ts-ignore
        return this.requestHelper.get(`${this.endpoint}/${this.resource}/image`);

    }

    setImage(formData: FormData): string {
        // @ts-ignore
        return this.requestHelper.post(`${this.endpoint}/${this.resource}/image`, formData);
    }
}

export default HorecaEndpoint;
