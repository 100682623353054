import React, {FunctionComponent, useEffect, useState} from 'react';
import Store from "../../../../models/Store";

interface OwnProps {
    store: Store
}

type Props = OwnProps;

const ExtraInfo: FunctionComponent<Props> = (props) => {
    const {store} = props;

    const [distance, setDistance] = useState(0);

    const getDistance = (latitude: number, longitude: number) => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const {coords} = position;
                const degreeLatitudeToKm = 111;
                const degreeLongitudeToKm = 66;

                const difLatitudeKm = (coords.latitude - latitude) * degreeLatitudeToKm;
                const difLongitudeKm = (coords.longitude - longitude) * degreeLongitudeToKm;

                const distance = Math.pow(Math.pow(difLatitudeKm, 2) + Math.pow(difLongitudeKm, 2), 0.5);

                setDistance(distance)
            });
        }
    }

    const paymentMethods = store.payment_methods.map(paymentMethod => {
        return <div key={paymentMethod.id}><img src={paymentMethod.image_url} alt={paymentMethod.name}/></div>
    });

    const socialMediaAccounts = store.social_media_accounts.map(socialMediaAccount => {
        return <div key={socialMediaAccount.id}>
            <a href={socialMediaAccount.pivot.url} target="_blank" rel="noopener noreferrer">
                <img src={socialMediaAccount.image_url} alt={socialMediaAccount.name}/>
            </a>
        </div>
    });

    const travelOptions = store.travel_options.map(travelOption => {
        return (
            <div key={travelOption.id}>
                <div className="img-container">
                    <img src={travelOption.image_url} alt={travelOption.name}/>
                </div>
                <span>{travelOption.content}</span>
            </div>
        )
    });

    const extraServices = store.extra_services.map((extraService, index, array) => {
        if (index + 1 === array.length) {
            return <span key={extraService.id}>{extraService.name}</span>
        }

        return (
            <span key={extraService.id}>{extraService.name}, </span>
        );
    });

    useEffect(() => {
        getDistance(store.latitude, store.longitude);
    }, [store])

    return (
        <div className="detail-section-component">
            <h2>Extra informatie</h2>
            <div className="extra-info-content">

                <div className="google-map">
                    <iframe
                        width="443"
                        height="341"
                        frameBorder="0" style={{border: 0}}
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC3P_S4bDe0-0OeBKxqivvHBR4WzwxksXU
    &q=${store.street}+${store.house_number},${store.city}`}
                        allowFullScreen
                        title="Google Maps">
                    </iframe>
                </div>
                <div>
                    {
                        store.payment_methods.length > 0 &&
                        <div className="extra-info-component">
                            <h4>Betaalwijze</h4>
                            <div className="payment-methods">
                                {paymentMethods}
                            </div>
                        </div>
                    }
                    {
                        store.social_media_accounts.length > 0 &&
                        <div className="extra-info-component">
                            <h4>Je vindt deze winkel ook op</h4>
                            <div className="social-media-accounts">
                                {socialMediaAccounts}
                            </div>
                        </div>
                    }
                    {
                        store.travel_options.length > 0 &&
                        <div className="extra-info-component">
                            <h4>Hoe kom je er?</h4>
                            <div className="travel-options">
                                {travelOptions}
                            </div>
                        </div>
                    }
                    {
                        store.extra_services.length > 0 &&
                        <div className="extra-info-component">
                            <h4>Extra services</h4>
                            {extraServices}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ExtraInfo;
