import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import Store from "../../../models/Store";
import SocialMediaRow from "./SocialMediaRow";
import SubmitChanges from "./SubmitChanges";
import SocialMediaAccount from "../../../models/storemodels/SocialMediaAccount";
import RestEndpoint from "../../../requests/RestEndpoint";
import StoreError from "./StoreError";
import RequestError from "../../../models/RequestError";
import { ToastContext } from "../../../contexts/ToastContext";

const SocialMedia: FunctionComponent<{
    formData: Store;
}> = (props) => {
    const [socialMedia, setSocialMedia] = useState<{ id: number; url: string | null }[]>([]);
    const [error, setError] = useState<RequestError | null>(null);
    const [socialMediaAccounts, setSocialMediaAccounts] = useState<SocialMediaAccount[]>([]);
    const { notify } = useContext(ToastContext);

    const handleSubmit = async () => {
        const updatedAccounts = {
            social_media_accounts: socialMedia.filter(
                (account) => account.url !== null
            ),
        };

        const updateAccountsEndpoint = new RestEndpoint(
            `stores/${props.formData.id}/social-media-accounts`
        );
        try {
            await updateAccountsEndpoint.store(updatedAccounts);
            notify("Wijzigingen opgeslagen.");
        } catch (e) {
            setError(e);
            notify(e.message, "error");
        }
    };

    const findDataForSocialMeda = (
        data: SocialMediaAccount[],
        account: string
    ) => {
        const targetAccount = data.find(
            (socialMediaAccount) => socialMediaAccount.name === account
        );

        return {
            url: targetAccount?.pivot.url || null,
            id: targetAccount?.id || null,
        };
    };

    const handleFormChange = (id: number, value: string | null) => {
        const socialMediaCopy = [...socialMedia];

        const index = socialMediaCopy.findIndex(
            (socialMediaItem) => socialMediaItem.id === id
        );

        if (index !== -1) {
            if (value) {
                socialMediaCopy[index].url = value;
            } else {
                socialMediaCopy.splice(index, 1);
            }
        } else {
            socialMediaCopy.push({
                id: id,
                url: value,
            });
        }
        setSocialMedia(socialMediaCopy);
    };

    useEffect(() => {
        const socialMediaEndpoint = new RestEndpoint("social-media-accounts");
        const getAllSocialMedia = async (): Promise<void> => {
            //@ts-ignore
            const socialMedia: SocialMediaAccount[] = await socialMediaEndpoint.all();
            setSocialMediaAccounts(socialMedia);
        };

        getAllSocialMedia().then();

        setSocialMedia(
            props.formData.social_media_accounts.map((socialMediaAccount) => ({
                id: socialMediaAccount.id || -1,
                url: socialMediaAccount.pivot.url,
            }))
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const socialMediaList = socialMediaAccounts.map((account) => (
        <SocialMediaRow
            name={account.name}
            icon={account.image_url}
            handleFormChange={handleFormChange}
            url={
                findDataForSocialMeda(
                    props.formData.social_media_accounts,
                    account.name
                ).url
            }
            id={account.id || -1}
            key={"social_media_table_row_" + account.id}
        />
    ));

    return (
        <div className="section-body">
            <div className="section-container">
                {error ? <StoreError error={error} /> : ""}
                <table className="social-media-table">
                    <tbody>{socialMediaList}</tbody>
                </table>
                <SubmitChanges submitChanges={handleSubmit} />
            </div>
        </div>
    );
};

export default SocialMedia;
