import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import Specialist from "../models/Specialist";
import Category from '../models/Category';

interface OwnProps {

}

interface ISpecialistContext {
    specialists: Specialist[],
    getAllSpecialists: () => Promise<void>
}

const specialistEndPoint = new RestEndpoint('categories/4');

export const SpecialistContext = createContext({} as ISpecialistContext);

type Props = OwnProps;

const SpecialistContextProvider: FunctionComponent<Props> = (props) => {
    const [specialists, setSpecialists] = useState<Specialist[]>([]);

    useEffect(() => {
        getAllSpecialists().then()
    }, [])

    const getAllSpecialists = async (): Promise<void> => {
        // @ts-ignore
        const category: Category = await specialistEndPoint.all();
        setSpecialists(category.clothing_categories);
    }

    return (
        <SpecialistContext.Provider
            value={{specialists: specialists, getAllSpecialists: getAllSpecialists}}>
                {props.children}
        </SpecialistContext.Provider>
    )
}

export default SpecialistContextProvider;