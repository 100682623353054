import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const DashboardCard: FunctionComponent<{
  title: string;
  icon: string;
  path: string;
}> = (props) => {
  return (
    <Link to={props.path} className="dashboard-card" key={props.title}>
      <div className="card-header" lang="nl">
        <img src={props.icon} alt={props.title} className="card-image" />
        <h4 className="card-title">{props.title}</h4>
      </div>
      <div className="card-text">{props.children}</div>
    </Link>
  );
};

export default DashboardCard;
