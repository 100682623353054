import React, { useState, ChangeEvent, useEffect, useRef, useContext } from 'react'
import FAQSubCategory from '../../../../models/FAQSubCategory';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow_down_links.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Check } from '../../../../assets/icons/check_register.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { ReactComponent as Add } from '../../../../assets/icons/add.svg';
import FAQ from '../../../../models/FAQ';
import Model from '../../../../models/Model';
import { AdminFAQContext } from '../../../../contexts/AdminFAQContext';
import QandA from './QandA';
import Popup from '../../../utils/Popup';
import { ToastContext } from '../../../../contexts/ToastContext';
import ImageUpload from '../../../utils/ImageUpload';
import NewQandA from './NewQandA';

class SubCategory extends Model {
    id: number = 0;
    f_a_q_category_id: number = 0;
    name: string = "";
    type: string = "";
    header_url: string = "";
    logo_url: string = "";
    questions?: FAQ[]
}

interface OwnProps {
    subCat: FAQSubCategory,
    deleteSelf: (id: number) => void,
    updateSelf: (subCat: SubCategory) => void
}

class NewQuestion {
    faq_category: number = 0;
    faq_subcategory: number = 0;
    question: string = "";
    answer: string = "";
}

type Props = OwnProps;

const SubCat: React.FC<Props> = (props) => {
    const { updateSubCat, deleteSubCat, getSubCatQuestions, getSubCat, addQuestion } = useContext(AdminFAQContext);
    const { notify, errorNotification } = useContext(ToastContext);
    const [edit, setEdit] = useState<boolean>(false);
    const [showFAQs, setShowFAQs] = useState<boolean>(false);
    const [subCat, setSubCat] = useState(new SubCategory());
    const [questions, setQuestions] = useState<FAQ[]>();
    const [newQuestion, setNewQuestion] = useState<NewQuestion | undefined>(new NewQuestion());
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [showNew, setShowNew] = useState<boolean>(false);
    const [showImage, setShowImage] = useState<boolean>();
    const [showLogo, setShowLogo] = useState<boolean>();

    useEffect(() => {
        setSubCat(props.subCat)

        getQuestions();

        setNewQuestion(undefined);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSubCat({ ...subCat, name: e.target.value })
        props.updateSelf(subCat)
    };

    const cancelEdit = async () => {
        setEdit(false);

        try {
            let originalSubCat = await getSubCat(props.subCat);

            setSubCat(originalSubCat);

            notify("Wijzigingen ongedaan gemaakt.");
        } catch (e) {
            errorNotification(e);
        }
    }

    const updateSelf = async () => {
        const requestError = await updateSubCat(subCat);

        if (requestError) {
            errorNotification(requestError);
        } else {
            setEdit(false);
            notify("Wijzigingen opgeslagen.");
        }
    }

    const deleteSelf = async () => {
        const requestError = await deleteSubCat(props.subCat);

        if (requestError) {
            errorNotification(requestError)
        } else {
            notify(`${subCat.name} verwijderd.`)

            props.deleteSelf(props.subCat.id)
        }
    }

    const getQuestions = async () => {
        try {
            let questions = await getSubCatQuestions(props.subCat);
            setQuestions(questions);
        } catch (e) {
            setQuestions([e]);
        }
    }

    const updateQuestion = (q: FAQ) => setQuestions(questions?.map(question => question.id === q.id ? q : question))

    const deleteQuestion = (id: number) => setQuestions(questions?.filter(question => question.id !== id));

    const handleSubmitQandA = async (question: NewQuestion) => {
        try {
            const response = await addQuestion({ ...question, faq_category: subCat.f_a_q_category_id, faq_subcategory: subCat.id });

            setShowNew(false);
            
            toggleNewQuestion();
            setQuestions(questions?.concat(response))
        } catch (e) {
            errorNotification(e)
            throw new Error();
        }
    }

    const toggleNewQuestion = () => {
        if (!newQuestion) {
            setNewQuestion(new NewQuestion())
        } else {
            setNewQuestion(undefined)
        }
    }

    const uploadImage = async (image: HTMLImageElement) => {
        if (subCat?.id === undefined || subCat?.name === undefined) {
            return;
        }

        try {
            await updateSubCat({
                ...subCat,
                header: image
            })
            notify("Wijzigingen opgeslagen")
        } catch (e) {
            errorNotification(e)
        }
    }

    const uploadLogo = async (image: HTMLImageElement) => {
        if (subCat?.id === undefined || subCat?.name === undefined) {
            return;
        }

        try {
            await updateSubCat({
                ...subCat,
                logo: image
            })
            notify("Wijzigingen opgeslagen")
        } catch (e) {
            errorNotification(e)
        }
    }

    return (
        <div className="sub-cat">
            <div className={`image-container ${showImage ? "open" : ""}`}>
                <div className="image-buttons">
                    <form>
                        <div className="faq-admin__image-size">Aanbevolen resolutie: 1920 x 850px</div>
                        <ImageUpload setImage={uploadImage} currentImageUrl={subCat?.header_url} />
                    </form>

                    <Close onClick={() => setShowImage(false)} className="close" />
                </div>
            </div>

            <div className={`image-container ${showLogo ? "open" : ""}`}>
                <div className="image-buttons">
                    <form className="faq-admin__logo">
                        <div className="faq-admin__image-size">Aanbevolen resolutie: 56 x 56px</div>
                        <ImageUpload setImage={uploadLogo} currentImageUrl={subCat?.logo_url} />
                    </form>

                    <Close onClick={() => setShowLogo(false)} className="close" />
                </div>
            </div>

            <div className="sub-cat-header">
                <div className="sub-cat-title">
                    {
                        edit ?
                            <input
                                placeholder={subCat.name}
                                value={subCat.name}
                                onChange={handleChange}
                            />
                            :
                            <h2>{subCat.name}</h2>
                    }

                    {
                        !edit && <p onClick={() => setShowImage(true)} className="open-image">header</p>
                    }

                    {
                        !edit && <p onClick={() => setShowLogo(true)} className="open-image">logo</p>
                    }
                </div>

                <div className="sub-cat-edit-btn">
                    {
                        edit ?
                            <div>
                                <Check onClick={updateSelf} />
                                <Close onClick={cancelEdit} />
                            </div>
                            :
                            <Edit onClick={() => setEdit(true)} />
                    }


                    {
                        !edit && <Add onClick={() => setShowNew(true)} />
                    }

                    <Delete onClick={() => setConfirmation(true)} />
                    <Arrow onClick={() => setShowFAQs(!showFAQs)} className={`show-question ${showFAQs ? "open" : ""}`} />
                </div>
            </div>

            <Popup
                title={`"${subCat.name}" in zijn geheel verwijderen?`}
                buttonText="Verwijderen"
                buttonFunction={deleteSelf}
                setShow={setConfirmation}
                show={confirmation}
            />

            <div className={`popup-container new-question ${showNew && "open"}`} >
                <div className="popup">
                    <Close onClick={() => setShowNew(false)} className="close" />

                    <NewQandA submit={handleSubmitQandA} />
                </div>
            </div>

            <div className={`questions ${showFAQs ? "" : "close"}`}>
                {
                    questions?.map(q => {
                        return (
                            <QandA
                                question={q}
                                subCat={subCat}
                                updateSelf={updateQuestion}
                                deleteSelf={deleteQuestion}
                                key={q.id}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SubCat
