import React, {
    ChangeEvent,
    FormEvent,
    FunctionComponent,
    useContext, useEffect,
    useState,
} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import Credentials from "../../models/Credentials";
import {RouteComponentProps, Link} from "react-router-dom";
import ValidationError from "../utils/ValidationError";
import RequestError from "../../models/RequestError";

import {ReactComponent as StoreIcon} from "../../assets/icons/store_green_menu.svg";
import {ReactComponent as Arrow} from "../../assets/icons/arrow_right_links.svg";
import {ReactComponent as CheckRegister} from "../../assets/icons/check_register.svg";
import Facebook from "./../../assets/icons/login-facebook.svg";
import Google from "./../../assets/icons/login-google.svg";
import ResetPassword from "./ResetPasswordEmail";
import {Helmet} from "react-helmet";
import {PageContentContext} from "../../contexts/PageContentContext";
import {ToastContext} from "../../contexts/ToastContext";

interface OwnProps {
}

type Props = OwnProps & RouteComponentProps;

const Login: FunctionComponent<Props> = (props) => {
    const [credentials, setCredentials] = useState(new Credentials());
    const [requestError, setRequestError] = useState<RequestError>();
    const [showResetPassword, setResetPassword] = useState<boolean>();
    const {notify} = useContext(ToastContext);

    const {login} = useContext(AuthContext);
    const {pageContents} = useContext(PageContentContext);

    const benefitOne = pageContents.find(pageContent => pageContent.location === 'login-account-benefits-1')?.content;
    const benefitTwo = pageContents.find(pageContent => pageContent.location === 'login-account-benefits-2')?.content;
    const benefitThree = pageContents.find(pageContent => pageContent.location === 'login-account-benefits-3')?.content;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCredentials({...credentials, [e.target.id]: e.target.value});
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const requestError = await login(credentials);

        if (requestError) {
            setRequestError(requestError);
            window.scrollTo(0, 0);
        }
    };

    const loginThroughSSO = async (provider: string) => {
        sessionStorage.setItem("provider", provider);
        window.location.href = process.env.REACT_APP_API_ENDPOINT + "/auth/login/" + provider;
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('verified')) {
            notify("Je account is succesvol geactiveerd. Je kunt nu inloggen.");
        }
    }, [])

    const toggleResetPassword = () => setResetPassword(!showResetPassword);

    return (
        <div id="login">
            <Helmet>
                <title>Inloggen bij Fashion Hotspots - Fashion Hotspots</title>
                <meta name="description" content="Log in met je wachtwoord en emailadres of via je Facebook- of Google Account"/>
                <meta name="keywords" content="login, fashion hotspots, account" />
            </Helmet>

            <ResetPassword showResetPassword={showResetPassword} toggleResetPassword={toggleResetPassword}
                           email={credentials.email} handleChange={handleChange}/>

            <form onSubmit={handleSubmit} className="login-div">
                <h1>INLOGGEN</h1>
                <p onClick={toggleResetPassword} className="reset-password-link">Ben je je wachtwoord vergeten?</p>

                {requestError && <ValidationError requestError={requestError}/>}

                <div className="form-group">
                    <input
                        id="email"
                        type="text"
                        value={credentials.email}
                        onChange={handleChange}
                        placeholder="E-mailadres *"
                        className="required"
                        required
                    />

                </div>

                <div className="form-group">
                    <input
                        id="password"
                        type="password"
                        value={credentials.password}
                        onChange={handleChange}
                        className="required"
                        placeholder="Wachtwoord *"
                        required
                    />
                </div>

                <div className="form-group login-form-group">
                    <button type="submit" className="login">
                        Inloggen
                    </button>
                    <span className="login">of</span>
                    <button
                        className="login facebook"
                        onClick={() => {
                            loginThroughSSO("facebook");
                        }}
                        type="button"
                    >
                        <img src={Facebook} alt="Facebook"/> <p>Log in via Facebook</p>
                    </button>
                    <button
                        className="login google"
                        onClick={() => {
                            loginThroughSSO("google");
                        }}
                        type="button"
                    >
                        <img src={Google} alt="Google"/> <p>Log in via Google</p>
                    </button>
                </div>
            </form>

            <div className="register-div">
                <h1>GRATIS ACCOUNT AANMAKEN</h1>

                <p>De voordelen van een gratis account zijn:</p>

                <ul className="pros">
                    <li>
                        <CheckRegister/>
                        <div dangerouslySetInnerHTML={{__html: benefitOne!}}></div>
                    </li>
                    <li>
                        <CheckRegister/>
                        <div dangerouslySetInnerHTML={{__html: benefitTwo!}}></div>
                    </li>
                    <li>
                        <CheckRegister/>
                        <div dangerouslySetInnerHTML={{__html: benefitThree!}}></div>
                    </li>
                </ul>

                <Link to="/registreer" className="register">
                    Registreren <Arrow/>
                </Link>

                <Link to="/hoe-werkt-het-winkel" className="store-information">
                    <StoreIcon/> Informatie voor winkels
                </Link>
            </div>
        </div>
    );
};

export default Login;
