import React, {FunctionComponent} from 'react';
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow-right2.svg";
import {Link} from "react-router-dom";


interface tier {
    url: string,
    name: string
}

interface OwnProps {
    tiers: tier[]
}

type Props = OwnProps;

const Breadcrumb: FunctionComponent<Props> = (props) => {

    const tiersHtml = props.tiers.map((tier, i, arr) => {
        return (
            <span key={i} className="test">
                <Link to={tier.url}>{tier.name}</Link>
                {arr.length -1 !== i &&
                    <span className='breadcrumb-arrow-right'>
                        <ArrowRight/>
                    </span>
                }
            </span>
        )
    });

    return (
        <div className="breadcrumb">
            <div className="breadcrumb-content">
                <div className="breadcrumb-text">
                    {tiersHtml}
                </div>
            </div>
        </div>
    );
};

export default Breadcrumb;
