import RestEndpoint from "./RestEndpoint";

class SearchEndpoint extends RestEndpoint {
    constructor() {
        super('search');
    }

    getSearch(searchURL: string) {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}${searchURL}`);
    }
}

export default SearchEndpoint;
