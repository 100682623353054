import React, { FunctionComponent } from "react";
import ArrowWhite from "../../../assets/icons/arrow_right_white_buttons.svg";

export const SubmitDetails: FunctionComponent<{}> = (props) => {
  return (
    <div className="section-body">
      <div className="input-group">
        <div className="input-group-body">
          <div className="input-field">
            <input
              type="submit"
              value="Opslaan"
              className="submit-personal-changes"
            />
            <img className="submit-icon" src={ArrowWhite} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
