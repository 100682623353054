import React, {FunctionComponent, useContext} from "react";
import NewsItem from "../../../models/NewsItem";
import Edit from "../../../assets/icons/edit.svg";
import Close from "../../../assets/icons/close.svg";
import {Link} from "react-router-dom";
import RestEndpoint from "../../../requests/RestEndpoint";
import {ToastContext} from "../../../contexts/ToastContext";
import {removeSpecialChars, stringToUrl} from "../../utils/UrlHelper";

const ManageNewsListItem: FunctionComponent<{
    newsItem: NewsItem;
    refreshItems: () => Promise<void>;
}> = (props) => {
    const {notify} = useContext(ToastContext)

    const removeItem = async () => {
        const endpoint = new RestEndpoint(
            `${props.newsItem.type === "news" ? "news_items" : "press_releases"}`
        );
        await endpoint.destroy({id: props.newsItem.id});
        props.refreshItems().then();
        notify(`Bericht ${props.newsItem.title} verwijderd.`);
    };
    return (
        <tr>
            <td>
                {props.newsItem.type === "press" ? "Persbericht" : "Nieuwsbericht"}
            </td>
            <td>
                <Link to={`/nieuws/${stringToUrl(removeSpecialChars(props.newsItem.title))}`}>{props.newsItem.title}</Link>
            </td>
            <td>{props.newsItem.created_at || "Geen datum"}</td>
            <td>
                <Link to={`/admin/beheer-nieuws/${props.newsItem.type}/${props.newsItem.id}`}>

                    <img src={Edit} alt=""/>
                </Link>
                <img src={Close} className="pointer" alt="" onClick={removeItem}/>
            </td>
        </tr>
    );
};

export default ManageNewsListItem;
