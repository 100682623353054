import React, {FormEvent, FunctionComponent, useContext, useEffect, useState,} from "react";
import City from "../../../models/City";
import {useHistory, useParams} from "react-router-dom";
import {CityContext} from "../../../contexts/CityContext";
import ImageUpload from "../../utils/ImageUpload";
import RestEndpoint from "../../../requests/RestEndpoint";
import RequestHelper from "../../../requests/RequestHelper";
import {ToastContext} from "../../../contexts/ToastContext";
import {Helmet} from "react-helmet";

const EditCity: FunctionComponent = (props) => {
    const [city, setCity] = useState<City>();
    const {cities, getAllCities} = useContext(CityContext);
    const [errors, setErrors] = useState<string[]>();
    const [image, setImage] = useState<HTMLImageElement>();
    const {notify} = useContext(ToastContext);

    const {id} = useParams<{ id: string }>();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            setCity(cities.find((city) => city.id === +id));
        }
    }, [cities, id]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const endpoint = new RestEndpoint('cities');
        const name = (document.getElementById("city-name") as HTMLInputElement)?.value;
        const description = (document.getElementById("city-description") as HTMLTextAreaElement)?.value;

        if (city) {
            let data: any = {
                id: city.id,
                name: name,
                description: description,
                image: image,
            };

            if (!data.image) {
                delete data.image;
            } else {
                data = RequestHelper.convertToFormData(data);
            }

            await endpoint.update(data);

            notify("De wijzigen zijn succesvol opgeslagen.");
            setTimeout(() => {
                history.push("/admin/beheer-steden");
            }, 1500);
        } else {
            if (!image) {
                setErrors(["Een afbeelding voor een stad is verplicht."]);
            } else {
                const data = {
                    name,
                    description,
                    image,
                };
                try {
                    await endpoint.store(RequestHelper.convertToFormData(data));
                    
                    notify("De stad is succesvol aangemaakt.");
                    setTimeout(() => {
                        history.push("/admin/beheer-steden");
                    }, 1500);
                } catch (e) {
                    window.scrollTo(0, 0);
                    setErrors(e.errors.name);
                }
            }
        }

        await getAllCities();
    };

    return (
        <div className="edit-city">
            <Helmet>
                <title>FH - Stad aanpassen/toevoegen</title>
            </Helmet>
            <div className="header">
                <h1>Stad {city ? "aanpassen" : "toevoegen"}</h1>
                <p className="subtitle">
                    Op deze pagina kun je{" "}
                    {city
                        ? "de geselecteerde stad aanpassen"
                        : "een nieuwe stad toevoegen"}
                </p>
            </div>
            <div className="body">
                {errors?.map((error) => (
                    <p className="error">{error}</p>
                ))}
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        id="city-name"
                        placeholder="Stadsnaam"
                        defaultValue={city?.name}
                        required
                    />
                    <textarea
                        id="city-description"
                        cols={20}
                        rows={10}
                        defaultValue={city?.description.replace(/<br\s*[\/]?>/gi, "\n")}
                        placeholder="Beschrijving"
                        required
                    ></textarea>
                    <p>800 x 533px</p>
                    <ImageUpload setImage={setImage} currentImageUrl={city?.image_url}/>
                    <button className="button">Opslaan</button>
                </form>
            </div>
        </div>
    );
};

export default EditCity;
