import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
  FormEvent,
  useContext,
} from "react";
import StandardInput from "../../../utils/StandardInput";
import RestEndpoint from "../../../../requests/RestEndpoint";
import { ToastContext } from "../../../../contexts/ToastContext";

interface IContactInfo {
  id?: number;
  email: string;
  phone: string;
  phone_pretty: string;
  whatsapp: string;
  whatsapp_pretty: string;
}

const ContactInfo: FunctionComponent = (props) => {
  const [contactInfo, setContactInfo] = useState<IContactInfo>();
  const endpoint = new RestEndpoint("fashion-hotspots");
  const { notify, errorNotification } = useContext(ToastContext);

  useEffect(() => {
    const getAllContactInfo = async () => {
      //@ts-ignore
      const allContactInfo: IContactInfo = await endpoint.find(1);

      setContactInfo(allContactInfo);
    };
    getAllContactInfo();
  }, [setContactInfo]);

  const changeContactInfo = (
    e: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    //@ts-ignore
    const infoCopy: IContactInfo = { ...contactInfo };
    //@ts-ignore
    infoCopy[field] = e.target.value;
    setContactInfo(infoCopy);
  };

  const makeNumberPretty = (number: string) => {
    let prettyNumber = "";
    number = number.replace(" ", "");
    if (number.includes("+")) {
      let prefix = number.slice(0, 3);
      number = number.slice(3);
      prettyNumber += prefix;
    }
    if (number.charAt(0) === "3") {
      prettyNumber += `+${number.slice(0, 2)}`;
      number = number.slice(2);
    }
    if (number.charAt(0) === "6") {
      prettyNumber += ` 0${number.charAt(0)}`;
      number = number.slice(1);
    } else {
      prettyNumber += ` ${number.slice(0, 2)}`;
      number = number.slice(2);
    }
    const endParts = number.match(/.{1,3}/g);
    endParts?.forEach((part) => {
      prettyNumber += ` ${part}`;
    });

    return prettyNumber;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    //@ts-ignore
    const detailCopy: IContactInfo = { ...contactInfo };
    detailCopy.phone_pretty = makeNumberPretty(detailCopy.phone);
    detailCopy.whatsapp_pretty = makeNumberPretty(detailCopy.whatsapp);
    detailCopy.id = 1;

    try {
      await endpoint.update(detailCopy);
      notify("Wijzigingen succesvol opgeslagen.");
    } catch (e) {
      errorNotification(e);
    }
  };

  return (
    <div className="section">
      <h2>Contactinformatie</h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <label htmlFor="email">Email</label>
          <StandardInput
            placeholder="Email"
            id="email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              changeContactInfo(e, "email");
            }}
            value={contactInfo?.email || ""}
            type="email"
            required
          />
        </div>
        <div className="row">
          <label htmlFor="phone">Telefoonnummer</label>
          <StandardInput
            id="phone"
            placeholder="Telefoonnummer"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              changeContactInfo(e, "phone");
            }}
            value={contactInfo?.phone || ""}
            type="tel"
            required
          />
        </div>
        <div className="row">
          <label htmlFor="whatsapp">Whatsapp-nummer</label>
          <StandardInput
            id="whatsapp"
            placeholder="Whatsapp"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              changeContactInfo(e, "whatsapp");
            }}
            value={contactInfo?.whatsapp || ""}
            type="tel"
            required
          />
        </div>
        <button className="button">Opslaan</button>
      </form>
    </div>
  );
};

export default ContactInfo;
