import Model from "../Model";

class OpeningHoursForHoliday extends Model {
    store_id: number = -1;
    date: string = "";
    start_time: string = "";
    end_time: string = "";
    closed_start_time: string|null = "";
    closed_end_time: string|null = "";
    closed: number = -1;

    constructor(OpeningHoursForHoliday?: OpeningHoursForHoliday) {
        super();
        Object.assign(this, OpeningHoursForHoliday);
    }
}

export default OpeningHoursForHoliday;
