import React, { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { SellingPointContext } from '../../../contexts/SellingPointsProvider';

import Slider from "react-slick";

interface OwnProps {
    props: RouteComponentProps
}

type Props = OwnProps;

const SellingPoints: React.FC<Props> = (_props) => {
    const { sellingPoints } = useContext(SellingPointContext);

    const mqMobile = window.matchMedia('(max-width: 959px)');

    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: false
    };

    const generatePoint = (id: number, image_url: string, title: string, content: string) => {
        return (
            <div className="selling-point" key={id}>
                <div className="image-container">
                    <img src={image_url} alt="" />
                </div>
                <div className="selling-point-text">
                    <p className="point-title">{title}</p>
                    <p className="point-content">{content}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="selling-points">
            {mqMobile.matches && <p className="selling-points-title">In het kort</p>}

            {
                (mqMobile.matches) ? (
                    <div className="selling-points-container-mobile">
                        <Slider {...sliderSettings}>
                            {
                                sellingPoints.map(point => {
                                    return (
                                        generatePoint(point.id, point.image_url, point.title, point.content)
                                    )
                                })
                            }
                        </Slider>
                    </div>
                ) : (
                        <div className="selling-points-container">
                            {
                                sellingPoints.map(point => {
                                    return (
                                        generatePoint(point.id, point.image_url, point.title, point.content)
                                    )
                                })
                            }
                        </div>
                    )
            }
        </div>
    )
}

export default SellingPoints;
