import React, { FunctionComponent, useContext } from "react";
import { CityContext } from "../../../contexts/CityContext";
import CityListItem from "./CityListItem";

const CityList: FunctionComponent = (props) => {
  const { cities } = useContext(CityContext);

  return (
    <div className="city-list">
      {cities.map((city) => (
        <CityListItem city={city} />
      ))}
    </div>
  );
};

export default CityList;
