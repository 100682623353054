import React, {
    FunctionComponent,
    useState,
    useContext,
    useEffect,
} from "react";
import Review from "../../../models/storemodels/Review";
import { AuthContext } from "../../../contexts/AuthContext";
import RestEndpoint from "../../../requests/RestEndpoint";
import { StoreContext } from "../../../contexts/StoreContext";
import StoreReviewItem from "./StoreReviewItem";
import Store from "../../../models/Store";
import { PageContentContext } from "../../../contexts/PageContentContext";
import { Helmet } from "react-helmet";

const StoreReviews: FunctionComponent = (props) => {
    const [store, setStore] = useState<Store | null>(null);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const { stores } = useContext(StoreContext);
    const { user } = useContext(AuthContext);
    const { pageContents } = useContext(PageContentContext);

    const updateReviews = () => {
        getStoreReviews(store?.id);
    };

    const subTitle = pageContents.find(content => content.location === "store-reviews")?.content;

    const getStoreReviews = async (storeIdForUser?: number) => {
        const storeReviewEndpoint = new RestEndpoint(
            `stores/${storeIdForUser}/reviews`
        );

        const reviewResponse = await storeReviewEndpoint.all();

        console.log(reviewResponse)

        //@ts-ignore
        setReviews(reviewResponse);

        setLoading(false);
    };

    const reviewItems = reviews.map((review) => (
        <StoreReviewItem
            review={review}
            deleteReview={{}}
            store={stores.find((store) => store.id === review.child_review?.id)}
            key={"review_" + review.id}
            ownStore={store}
            updateReviews={updateReviews}
        />
    ));

    useEffect(() => {
        if (stores.length > 0) {
            const storeForUser =
                stores.find((store) => store.user_id === user?.id) || null;

            setStore(storeForUser);
            const storeIdForUser = storeForUser?.id;

            if (storeIdForUser) {
                getStoreReviews(storeIdForUser);
            }
        }
    }, [stores, user]);

    if (reviews.length > 0) {
        return (
            <div className="my-reviews my-reviews--store">
                <Helmet>
                    <title>Store - Reviews</title>
                </Helmet>
                <div className="reviews-header subtitle" dangerouslySetInnerHTML={{ __html: subTitle! }}>

                </div>
                <div className="reviews-body">{reviewItems}</div>
            </div>
        );
    }
    return (
        <div className="my-reviews">
            <div className="reviews-header">
                <h2>Mijn recensies</h2>
                <p className="subtitle">{loading ? "Laden..." : "Geen reviews gevonden."}</p>
            </div>
            <div className="reviews-body"></div>
        </div>
    );
};

export default StoreReviews;
