import React from 'react'
import {NavLink} from 'react-router-dom';

// Icons
import {ReactComponent as Tags} from "../../../../assets/icons/tags.svg";
import {ReactComponent as Ratings} from "../../../../assets/icons/ratings.svg";
import {ReactComponent as Photos} from "../../../../assets/icons/photos.svg";
import {ReactComponent as MyHomepage, ReactComponent as Actions} from "../../../../assets/icons/my_homepage.svg";
import {ReactComponent as IDCard} from "../../../../assets/icons/id_card.svg";
import User from "../../../../models/User";
import {storeToUrl} from "../../../utils/StoreHelper";

interface OwnProps {
    user: User;
}

type Props = OwnProps;

const StoreLinks: React.FC<Props> = (props) => {

    const isHoreca = props.user.store?.is_horeca || false;

    return (
        <div>
            <NavLink to={'/winkel-eigenaar-details'}><IDCard/> Mijn accountgegevens</NavLink>
            <NavLink to={`/winkel-details/${props.user?.store?.name ? '' : 'nieuw'}`}><IDCard/> Mijn gegevens</NavLink>
            {
                !isHoreca &&
                <NavLink to={'/winkel-admin/afbeeldingen'}><Photos/> Mijn foto's</NavLink>
            }
            <NavLink to={'/winkel-admin/mijn-acties'}><Actions/> Mijn acties</NavLink>
            <NavLink to={'/winkel-admin/kleding-categorieen'}><Tags/> Mijn tags</NavLink>
            {
                !isHoreca &&
                <NavLink to={'/winkel-admin/mijn-recensies'}><Ratings/> Mijn recensies</NavLink>
            }
            {
                !isHoreca &&
                <NavLink to={`/winkels/${storeToUrl(props.user.store)}`}><MyHomepage/> Mijn winkelpagina</NavLink>
            }
        </div>
    )
}

export default StoreLinks;
