import React, { FunctionComponent } from 'react';
import Review from "../../../../models/storemodels/Review";
import { ReactComponent as Account } from "../../../../assets/icons/account.svg";
import Store from "../../../../models/Store";


interface OwnProps {
    review: Review
    store: Store
}

type Props = OwnProps;

const StoreReview: FunctionComponent<Props> = (props) => {

    const { review, store } = props;
    const { child_review } = review;

    const mqDesktop = window.matchMedia('(min-width: 960px)');

    const reviewDate = review?.created_at ? new Date(review.created_at.replace(/\s/, 'T')) : undefined;
    const responseDate = child_review?.created_at ? new Date(child_review.created_at.replace(/\s/, 'T')) : undefined;

    return (
        <div className="store-review">

            <div className="review-review">
                <div className="review-header">
                    <div>
                        <div className="review-title">{review.title}</div>
                        <div className="review-date">
                            {reviewDate && `${reviewDate.getDate()}-${reviewDate.getMonth() + 1}-${reviewDate.getFullYear()}`}
                        </div>

                    </div>
                    <div>
                        <div className="review-rating">
                            <span>{review.rating}</span>
                            /10
                        </div>
                    </div>
                </div>
                <div className="review-content">{review.content}</div>
                {
                    child_review &&
                    <div className="review-store-reply">
                        <div className="review-review">
                            {
                                mqDesktop.matches &&
                                <div className="review-date">
                                    {responseDate && `${responseDate.getDate()}-${responseDate.getMonth() + 1}-${responseDate.getFullYear()}`}
                                </div>
                            }
                            {child_review.title && <h3 className="review-title">{child_review.title}</h3>}
                            <p className="review-content">{child_review?.content}</p>
                        </div>
                        <div className="review-user">
                            <div className="user-avatar">
                                {
                                    !store.logo_url ?
                                        <img src={store.logo_url} />
                                        :
                                        <Account id="account" />
                                }
                            </div>
                            <div className="user-name">
                                <p>{store.name}</p>

                                <p>{!mqDesktop.matches && <div className="review-date">{responseDate && `${responseDate.getDate()}-${responseDate.getMonth() + 1}-${responseDate.getFullYear()}`}</div>}</p>
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="review-user">
                <div className="user-avatar">
                    <Account id="account" />
                </div>
                <div className="review-user-detail">
                    <div className="user-name">{review.user_name}</div>
                    <div className="user-number-of-reviews">{review.user_number_of_reviews}
                        {review.user_number_of_reviews < 2 ? ' recensie' : ' recensies'}
                    </div>
                </div>

            </div>


        </div>
    );
};

export default StoreReview;
