import React, {FunctionComponent} from 'react';
import Store from "../../../../models/Store";

interface OwnProps {
    store: Store
}

type Props = OwnProps;

const Hours: FunctionComponent<Props> = (props) => {

    const dayOfTheWeek = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"];

    return (
        <div className="detail-section-component hours-container">
            <div className="standard">
                <h2>Openingstijden</h2>
                <ul>
                    {
                        props.store.opening_hours_for_days.map(day => {
                            return (
                                <li key={day.id} className='hours'>
                                    <div className='hours__line'>
                                        <span className='hours__day'>{dayOfTheWeek[day.day_of_the_week - 1]}
                                        </span>
                                        {day.closed ?
                                            <span className='hours__times'>Gesloten</span>
                                            :
                                            <span className='hours__times'>
                                                <span>{day.start_time}</span><span>-</span><span>{day.end_time}</span>
                                            </span>
                                        }
                                    </div>
                                    {
                                        ((day.closed_start_time && day.closed_end_time) && !day.closed) &&
                                        <div className='hours__line hours__line--closed'>
                                            <span className='hours__day hours__day--closed'>
                                                gesloten tussen
                                            </span>
                                            <span className='hours__times hours__times--closed'>
                                                <span>{day.closed_start_time}</span><span>-</span><span>{day.closed_end_time}</span>
                                            </span>
                                        </div>
                                    }

                                </li>
                            );
                        })
                    }

                </ul>
            </div>

            <div className="custom">
                <h2>Aangepaste openingstijden</h2>
                <ul>
                    {
                        props.store.opening_hours_for_holidays.map(day => {
                            return (
                                <li className='hours' key={day.id}>
                                    <div className='hours__line'>
                                        <span className='hours__day'>{day.date}</span>
                                        {day.closed ?
                                            <span className='hours__times'>Gesloten</span>
                                            :
                                            <span className='hours__times'>
                                                <>{day.start_time} - {day.end_time}</> <br/>

                                            </span>
                                        }
                                    </div>
                                    {
                                        ((day.closed_start_time && day.closed_end_time) && !day.closed) &&
                                        <div className='hours__line hours__line--closed'>
                                            <span className='hours__day hours__day--closed'>
                                                gesloten tussen
                                            </span>
                                            <span className='hours__times'>
                                               {day.closed_start_time} - {day.closed_end_time}
                                            </span>
                                        </div>
                                    }
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
}

export default Hours;
