import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, useLocation } from "react-router-dom";
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down_links.svg';
import NewsItem from '../../models/NewsItem';
import RestEndpoint from "../../requests/RestEndpoint";
import Breadcrumb from "../utils/Breadcrumb";
import { removeSpecialChars, stringToUrl } from "../utils/UrlHelper";

interface OwnProps extends RouteComponentProps {

}

type Props = OwnProps;

const newsEndPoint = new RestEndpoint('news_items');
const pressEndPoint = new RestEndpoint('press_releases');


const News: React.FunctionComponent<Props> = (props) => {
    const [itemCount, setCount] = useState(6);
    const location = useLocation().pathname;
    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);

    useEffect(() => {
        getItems();
    }, []);

    const getItems = async () => {
        const items = isNews() ? await newsEndPoint.all() : await pressEndPoint.all();
        // @ts-ignore
        setNewsItems(items.reverse());
    };

    const isNews = () => {
        return location.includes('nieuws');
    };

    const redirectToNews = (id: number) => {
        const newsTitle = stringToUrl(removeSpecialChars(newsItems.find(newsItem => newsItem.id === id)!.title));

        if (isNews()) {
            props.history.push(`/nieuws/${newsTitle}`);
            return;
        }

        props.history.push(`/pers/${newsTitle}`);
    }

    const showMore = () => {
        setCount(itemCount + 6);
    }

    const showLess = () => {
        if (itemCount - 6 < 6) {
            setCount(6)
        } else {
            setCount(itemCount - 6);
        }
    }

    return (
        <div>
            <Helmet>
                <title>FH - Nieuws/Pers</title>
                <meta name="description" content="Vind hier alle Fashion Hotspots nieuwsberichten." />
                <meta name="keywords" content="nieuws, fashion hotspots" />
            </Helmet>

            <Breadcrumb tiers={[{ url: '/', name: 'Home' },
            isNews() ? { url: '/nieuws', name: 'Nieuws' } : { url: '/pers', name: 'Pers' }]} />

            <div className="news">
                <div className="last-item"
                    style={newsItems[0] ? { backgroundImage: `url(${newsItems[0].image_url})` } : {}}>
                    <p className="last-item-title">{newsItems[0] && newsItems[0].title}</p>
                </div>

                <p className="page-title">{isNews() ? 'NIEUWS' : 'PERS'}</p>

                <div className="news-cards">
                    {
                        newsItems.map((item, i) => {
                            const date = new Date(item.created_at?.replace(/\s/, 'T'));

                            let months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "July", "Augustus", "September", "October", "November", "December"];
                            if (i < itemCount) {
                                return (
                                    <div className="news-card-container" onClick={() => redirectToNews(item.id)} key={item.id}>
                                        <div className="news-card">
                                            <div className="image" style={{ backgroundImage: `url(${item.image_url})` }}></div>
                                            <div className="text">
                                                <p className="date">{`${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`}</p>
                                                <p className="label">{item.label}</p>

                                                <p className="item-title">{item.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return <div key={item.id}></div>
                            }
                        })
                    }
                </div>

                {
                    newsItems.length > 6 ?
                        newsItems.length > itemCount ?
                            <p className="show-more" onClick={showMore}>Laat meer zien <ArrowDown /></p>
                            :
                            <p className="show-more show-more--less" onClick={showLess}>Laat minder zien <ArrowDown /></p>
                        :
                        ""
                }
            </div>
        </div>
    )
}

export default News;
