import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import FAQHelper from "../utils/FAQHelper";
import RestEndpoint from "../../requests/RestEndpoint";
import FAQSubCategory from "../../models/FAQSubCategory";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import FAQ from "../../models/FAQ";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right2.svg";
import { ReactComponent as ArrowRightLong } from "../../assets/icons/arrow_right_links.svg";
import FAQContact from "./FAQContact";
import Breadcrumb from "../utils/Breadcrumb";
import { Helmet } from 'react-helmet';

interface OwnProps {
}

type Props = OwnProps;

const FAQPage: FunctionComponent<Props> = () => {
    const [faqSubCategory, setFaqSubCategory] = useState<FAQSubCategory>();
    const [faq, setFaq] = useState<FAQ>();
    const { sub_category_id, faq_id } = useParams<{ sub_category_id: string, faq_id: string }>();
    const { user } = useContext(AuthContext);

    useEffect(() => {

        const fetchFaq = async () => {
            let faqCategoryId = 1;
            if (user) {
                faqCategoryId = FAQHelper.getFAQCategoryId(user.role_id);
            }

            const faqSubCategoryEndpoint = new RestEndpoint(`faq-categories/${faqCategoryId}/faq-subcategories`);

            // @ts-ignore
            const faqSubCategoryTemp = await faqSubCategoryEndpoint.find(sub_category_id);
            // @ts-ignore
            setFaqSubCategory(faqSubCategoryTemp);
            // @ts-ignore
            setFaq(faqSubCategoryTemp?.questions?.find(question => question.id === +faq_id));
        };

        fetchFaq();
    }, [faq_id]);


    // @ts-ignore
    const otherQuestions = faqSubCategory?.questions?.filter(question => question.id !== +faq_id)
        .map(question => {
            return (
                <Link key={question.id} className='item' to={`/veelgestelde-vragen/${sub_category_id}/${question.id}`}>
                    <span className="question">
                        {question.question}
                    </span>

                    <span className='subcategory-arrow-right'>
                        <ArrowRight />
                    </span>
                </Link>
            )
        });

    return (
        <div>
            <Helmet>
                <title>{ `Veel gestelde vragen over ${faq?.question} - Fashion Hotspots FAQ` }</title>
                <meta name="description" content={`Hier vind je de veelgestelde vragen en antwoorden over ${faq?.question}. Jouw vraag en antwoord niet kunnen vinden? Log in en stel je vraag via het contactformulier.`} />
            </Helmet>

            <Breadcrumb tiers={[{ url: '/', name: 'Home' }, { url: '/veelgestelde-vragen', name: 'Veelgestelde vragen' },
            { url: '/veelgestelde-vragen/' + faqSubCategory?.id, name: faqSubCategory?.name! }]} />

            <div className="faq-all faq">
                <h2>
                    {faq?.question}
                </h2>
                <div className="faq-content">
                    <div className="faq-answer" dangerouslySetInnerHTML={{ __html: faq?.answer || "" }}>

                    </div>

                    <h2>Meer over {faqSubCategory?.name}</h2>
                    <div className="other-questions">
                        {otherQuestions}
                    </div>
                    <Link className="back-to-faq-categories" to={'/veelgestelde-vragen'}>
                        <div className="back-image-container">
                            <ArrowRightLong />
                        </div>
                        <span>Naar veelgestelde vragen</span>
                    </Link>
                    <h3>Contact</h3>
                    <FAQContact />
                </div>
            </div>
        </div>
    );
};

export default FAQPage;
