import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow_right_links.svg';
import { ToastContext } from '../../../../contexts/ToastContext';
import User from '../../../../models/User';
import RestEndpoint from '../../../../requests/RestEndpoint';
import ShopperEndpoint from '../../../../requests/ShopperEndpoint';
import ReviewComponent from './ReviewComponent';

interface OwnProps extends RouteComponentProps {

}

type Props = OwnProps;

interface Review {
    id: number,
    rating: number,
    store_id: number,
    store: string,
    city: string,
    title: string,
    content: string,
    user_name: string,
    user_id: number,
    user_number_of_reviews: number,
    created_at: string,
    updated_at: string
}

const shopperEndpoint = new ShopperEndpoint();
const userEndpoint = new RestEndpoint('users');

const ShopperReview: React.FC<Props> = (props) => {
    const { notify, errorNotification } = useContext(ToastContext);

    const [reviews, setReviews] = useState<Review[]>();
    const [user, setUser] = useState<User>();

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        getReviews();
        getUser();
    }, [id])

    const getReviews = async () => {
        if (!id) return;

        try {
            const response = await shopperEndpoint.getShopperReviews(parseInt(id));

            // @ts-ignore
            setReviews(response)
        } catch (e) {
            errorNotification(e);
        }
    }

    const getUser = async () => {
        if (!id) return;

        try {
            const response = await userEndpoint.find(parseInt(id));

            // @ts-ignore
            setUser(response)
        } catch (e) {
            errorNotification(e);
        }
    }

    const deleteReview = async (reviewId: number) => {
        if(!user?.id) return;

        try {
            await shopperEndpoint.deleteShopperReview(user?.id, reviewId);
            setReviews(reviews?.filter(review => review.id !== reviewId));
        } catch (e) {
            errorNotification(e);
        }
    }

    const updateReview = async (review: Review) => {
        try {
            if(!user?.id) return;

            await shopperEndpoint.updateShopperReview(user?.id, review.id, review)

            notify("Wijzigingen opgeslagen.")
        } catch (e) {
            throw new Error(e);
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setReviews(reviews?.map(review => review.id === parseInt(e.target.id) ? {...review, content: e.target.value}: review))
    }

    const cancelUpdate = async (id: number) => {
        try {
            if(!user?.id) return;

            const response = await shopperEndpoint.getShopperReview(user.id, id) 

            // @ts-ignore
            setReviews(reviews?.map(review => review.id === id ? {...review, content: response.content}: review))

            notify("Wijzigingen geannuleerd.", "warning")
        } catch (e) {
            errorNotification(e);

            throw new Error(e);
        }
    }

    return (
        <div className="shopper-reviews-page">
            <Helmet>
                <title>FH - Reviews</title>
            </Helmet>

            <Link to={'/admin/shoppers'} className="back-button"><ArrowRight /> Terug naar shoppers</Link>
            <h2>Reviews van: {user?.first_name} {user?.last_name} ({reviews?.length})</h2>

            {
                reviews?.map(review => {
                    return (
                        <ReviewComponent key={review.id} review={review} handleChange={handleChange} deleteReview={deleteReview} updateReview={updateReview} cancelUpdate={cancelUpdate} />
                    )
                })
            }
        </div>
    )
}

export default ShopperReview
