import React, { ChangeEvent, SetStateAction } from 'react'

// Icons
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/magnifier_searchfield.svg';

interface IProps {
    arrayToSearch: any[]
    setArray: React.Dispatch<SetStateAction<any[] | undefined>>
    obj?: string
}

const FilterMenuSearch: React.FC<IProps> = ({ arrayToSearch, setArray, obj }) => {
    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchInput = e.target.value.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, "\\$&");

        let patt = new RegExp(`${searchInput}`, "i");

        let newArray = arrayToSearch.filter(item => patt.test(item.name))

        let array = newArray.filter(item => item.name.toLowerCase().startsWith(searchInput));

        newArray = newArray.filter(item => !item.name.toLowerCase().startsWith(searchInput));

        setArray(array.concat(newArray));
    };

    return (
        <div className="filter-menu-search">
            <SearchIcon className="filter-menu-search__icon" />
            <input className="filter-menu-search__brands" onChange={handleSearch} placeholder="Welk merk zoek je?" />
        </div>
    )
}

export default FilterMenuSearch;