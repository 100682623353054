import React, { useContext } from 'react'
import CityPictures from './home-page-components/CityPictures';
import { CityContext } from '../../contexts/CityContext';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface OwnProps extends RouteComponentProps {

}

type Props = OwnProps;

const AllCities: React.FC<Props> = (props) => {
    const redirectToFilter = (catName: string, itemName: string) => props.history.push(`/stad-overzicht?sorteer_op=afstand&sorteer_richting=oplopend&${catName}=${itemName}&page=1`);

    return (
        <div id="all-cities">
            <Helmet>
                <title>FH - Alle steden</title>
                <meta name="description" content="Vind hier alle steden met Hotspots." />
                <meta name="keywords"  content="steden, fashion hotspots" />
            </Helmet>

            <h1>Alle Steden</h1>

            <CityPictures  redirectFilter={redirectToFilter} />
        </div>
    )
}

export default AllCities;
