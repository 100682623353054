import React from 'react'

import { ReactComponent as Check } from "../../../../../assets/icons/check_white_checkbox.svg";

interface IProps {
    item: string
    toggleFilter: (value: string) => void
    isChecked: (value: string) => boolean
}

const FilterMenuItem: React.FC<IProps> = ({ item, toggleFilter, isChecked }) => {
    return (
        <div className="filter-menu-category-items__item">
            <div className="checkbox-container">
                <input type="checkbox" onClick={() => toggleFilter(item)}
                    checked={isChecked(item)}
                    onChange={() => ""}></input>
                <div className="checkmark" >
                    <Check />
                </div>
            </div>
            <p>{item}</p>
        </div>
    )
}

export default FilterMenuItem;