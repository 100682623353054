import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { ReactComponent as Add } from '../../../../assets/icons/add.svg';
import { ReactComponent as Arrow } from '../../../../assets/icons/arrow_down_links.svg';
import { ReactComponent as Check } from '../../../../assets/icons/check_register.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { AdminFAQContext } from '../../../../contexts/AdminFAQContext';
import { ToastContext } from '../../../../contexts/ToastContext';
import FAQ from '../../../../models/FAQ';
import FAQSubCategory from '../../../../models/FAQSubCategory';
import Model from '../../../../models/Model';
import ImageUpload from '../../../utils/ImageUpload';
import SubCat from './SubCat';

interface OwnProps {
    mainCat: FAQCategory,
    updateSelf: Function
}

type Props = OwnProps;

class SubCategory extends Model {
    f_a_q_category_id: number = 0;
    name: string = "";
    type: string = "";
    header_url: string = "";
    logo_url: string = "";
    questions?: FAQ[]
}

class NewSubCategory {
    faq_category?: number = 0;
    name?: string = "";
    header?: HTMLImageElement = undefined;
    logo?: HTMLImageElement = undefined;
}

class FAQCategory {
    id: number = 0;
    created_at: string = "";
    updated_at: string = "";
    name: string = "";
    type: string = "";
    header_url: string = "";
    sub_categories: FAQSubCategory[] = [];
}

const MainCat: React.FC<Props> = (props) => {
    const { updateMainCat, storeSubCat, getMainCat } = useContext(AdminFAQContext);
    const { notify, errorNotification } = useContext(ToastContext);

    const [mainCat, setMainCat] = useState(new FAQCategory());
    const [showNew, setNew] = useState<boolean>(false);
    const [newSubCat, setNewSubCat] = useState<NewSubCategory>();
    const [showSubCat, setShowSubCat] = useState<boolean>();
    const [showImage, setShowImage] = useState<boolean>();
    const [edit, setEdit] = useState<boolean>();

    useEffect(() => {
        setMainCat(props.mainCat)
    }, [])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMainCat({ ...mainCat, [e.target.id]: e.target.value })
    }

    const updateSelf = async (e: FormEvent) => {
        e.preventDefault();

        try {
            await updateMainCat(mainCat);

            setEdit(false);
            notify("Wijzigingen opgeslagen.");
        } catch (e) {
            errorNotification(e);
        }
    }

    const cancelUpdate = async () => {
        try {
            let originalMainCat = await getMainCat(props.mainCat);

            setMainCat(originalMainCat);

            notify("Wijzigingen ongedaan gemaakt.");

            setEdit(false);
        } catch (e) {
            errorNotification(e);
        }
    }

    const togglePopup = () => {
        if (showNew) {
            setNewSubCat(undefined);
        } else {
            setNewSubCat({ name: "" })
        }

        setNew(!showNew);
    }

    const addSubCategory = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let requestError = await storeSubCat({ ...newSubCat, faq_category: mainCat?.id })

        if (requestError) {
            errorNotification(requestError)
        } else {
            notify("Wijzigingen opgeslagen.")
            setNewSubCat(new NewSubCategory);
            setNew(false);
            window.location.reload();
        }
    }

    const addSubCatImage = (image: HTMLImageElement) => {
        setNewSubCat({ ...newSubCat, header: image })
    }

    const addSubCatLogo = (image: HTMLImageElement) => {
        setNewSubCat({ ...newSubCat, logo: image })
    }

    const handleChangeNew = (e: ChangeEvent<HTMLInputElement>) => {
        setNewSubCat({ ...SubCat, name: e.target.value })
    }

    const deleteSubCategory = (id: number) => {
        const sub_categories = mainCat?.sub_categories.filter(subCat => subCat.id !== id)

        if (!sub_categories) return;

        // @ts-ignore
        setMainCat({ ...mainCat, sub_categories })
    }

    const updateSubCat = (cat: SubCategory) => {
        const sub_categories = mainCat?.sub_categories.map(subCat => subCat.id === cat.id ? cat : subCat)

        if (!sub_categories) return;

        // @ts-ignore
        setMainCat({ ...mainCat, sub_categories })
    }

    const uploadImage = async (image: HTMLImageElement) => {
        if (mainCat?.id === undefined || mainCat?.name === undefined) {
            return;
        }

        try {
            await updateMainCat({ id: mainCat.id, name: mainCat.name, header: image })
            notify("Wijzigingen opgeslagen")
        } catch (e) {
            errorNotification(e)
        }
    }

    return (
        <div className="main-cat">
            <div className={`image-container ${showImage ? "open" : ""}`}>
                <div className="image-buttons">
                    <form>
                        <div className="faq-admin__image-size">Aanbevolen resolutie: 1920 x 850px</div>
                        <ImageUpload setImage={uploadImage} currentImageUrl={mainCat?.header_url} />
                    </form>

                    <Close onClick={() => setShowImage(false)} className="close" />
                </div>
            </div>

            <div className="main-cat-header">
                <div className="header-content">
                    <div className="header-title">
                        {
                            edit ?
                                <input value={mainCat?.name} onChange={handleChange} id="name" />
                                :
                                <h1 className="main-cat-name">{mainCat?.name}</h1>
                        }

                        {!edit && <p onClick={() => setShowImage(true)} className="open-image">Open image</p>}
                    </div>

                    <div className="buttons">
                        {
                            edit ?
                                <div>
                                    <Check onClick={updateSelf} />
                                    <Close onClick={cancelUpdate} />
                                </div>
                                :
                                <Edit onClick={() => setEdit(true)} />


                        }
                        <Add onClick={togglePopup} />
                        <Arrow onClick={() => setShowSubCat(!showSubCat)} className={`arrow ${showSubCat ? "open" : ""}`} />
                    </div>
                </div>
            </div>

            {
                <div className={`popup-container new-subcategory ${showNew && "open"}`}>
                    <div className="popup">
                        <Close onClick={() => setNew(false)} className="close" />

                        <form onSubmit={e => addSubCategory(e)}>
                            <h2>Nieuwe sub-categorie</h2>

                            <input placeholder="Naam" id="name" onChange={e => handleChangeNew(e)}></input>


                            <ImageUpload setImage={addSubCatImage} title="Upload Header"/>
                            <div className="image-size image-size--faq">Aanbevolen resolutie: 1920 x 850px</div>
                            <ImageUpload setImage={addSubCatLogo} title="Upload Logo"/>
                            <div className="image-size image-size--faq">Aanbevolen resolutie: 56 x 56px</div>


                            <button type="submit">Toevoegen</button>
                        </form>
                    </div>
                </div>
            }

            <div className="questions close">
                {
                    showSubCat &&
                    mainCat?.sub_categories?.map(subCat => {
                        return (
                            <SubCat
                                subCat={subCat}
                                deleteSelf={deleteSubCategory}
                                updateSelf={updateSubCat}
                                key={subCat.id}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MainCat
