class FAQHelper {
    static getFAQCategoryId = (userId: number): number => {
        if (userId === 3) {
            return 2;
        }
        if (userId === 1 || userId === 2) {
            return 3;
        }

        return 1;
    };
};

export default FAQHelper;
