export class FiltersMap extends Map<string, string[]> {
    // Constructor
    static fromQueryString(queryString: string): FiltersMap {
        let queryParameters: FiltersMap = new this()

        new URLSearchParams(queryString).forEach((value, key) => {
            queryParameters.set(key, value.split(","))
        })

        return queryParameters
    }

    toQueryString(): string {
        let queryString = ""

        this.forEach((items, key) => {
            queryString += encodeURIComponent(key)
            queryString += "="

            let valueString = items.map(i => i.replace(" ", "-")).map(i => encodeURIComponent(i)).join(",")

            queryString += valueString
            queryString += "&"
        })
        
        return "?" + queryString.slice(0, -1)
    }

    toggle(key: string, value: string) {
        let values = this.get(key)

        if (values == undefined) {
            this.set(key, value);
        } else {
            if (values.some(v => v == value)) {
                if (this.get(key)?.length == 1) {
                    this.delete(key)
                } else {
                    this.set(key, values.filter(v => v == value))
                }
            } else {
                this.set(key, [...values, value])
            }
        }
    }

    /**
     * @description Overrides Map.set to make it possible to enter one value as instead of 
     * encapsulating the value in square brackets.
     * @param key 
     * @param value 
     * @returns this
     */
    // 
    set(key: string, value: string | string[]) {
        if (Array.isArray(value)) {
            super.set(key, value)
        } else {
            super.set(key, [value])
        }

        return this
    }
}