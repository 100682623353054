import React, {useContext, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ReactComponent as ArrowDownLink} from '../../assets/icons/arrow_down_links.svg';
import {CityContext} from '../../contexts/CityContext';


interface OwnProps {
    props: RouteComponentProps
}

type Props = OwnProps;

const CityList: React.FC<Props> = (props) => {
    const {sortedCities} = useContext(CityContext);
    const [foldOut, setFoldOut] = useState<boolean>(false);

    const redirectToFilter = (cityName: string) => props.props.history.push(`/stad-overzicht?sorteer_op=afstand&sorteer_richting=oplopend&stad=${cityName}&page=1`);

    return (
        <div className="city-list">

            {
                sortedCities.map((city, i) => {
                    if (foldOut === false && i > 15) {
                        return;
                    }

                    return (
                        <p key={i} onClick={() => redirectToFilter(city.name)}
                           className="city-list-item">{city.name} {city.number_of_real_stores > 0 ? `(${city.number_of_real_stores})` : ''}</p>
                    )
                })
            }

            {
                foldOut === false ?
                    <p onClick={() => setFoldOut(true)}
                       className={sortedCities.length > 15 ? "show-more" : "hidden"}>Laat meer steden
                        zien <ArrowDownLink/></p>
                    :
                    <p onClick={() => setFoldOut(false)}
                       className={sortedCities.length > 15 ? "show-more fold-out" : "hidden"}>Laat minder steden
                        zien <ArrowDownLink/></p>

            }

        </div>
    )
}

export default CityList;
