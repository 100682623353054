import React from 'react';
import Star from "../../../assets/icons/favorite_store.svg";

interface OwnProps {
    title: string,
    subtitle: string,
    type: string
}

type Props = OwnProps;

const CouponItem: React.FC<Props> = (props) => {
    return (
        <svg
            width="300"
            height="180"
            className={props.type}
        >
            <g>
                <polyline
                    className="coupon-border"
                    points="300,0 300,180"
                    strokeWidth="4"
                />
                <polyline
                    className="coupon-border"
                    points="300,180 0,180"
                    strokeWidth="4"
                />
                <polyline
                    className="coupon-border"
                    points="0,180 0,0"
                    strokeWidth="4"
                />
                <circle
                    className="coupon-border"
                    cx="0"
                    cy="90"
                    r="20"
                    strokeWidth="2"
                    fill="white"
                />
            </g>
            <g>
                <rect
                    className="coupon-header-background"
                    x="0"
                    y="0"
                    width="300"
                    height="30"
                />
                <text
                    className="coupon-header-text"
                    x="50%"
                    y="15"
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {props.type.toUpperCase()}
                </text>
            </g>
            <g>
                <rect
                    className="coupon-body"
                    x="0"
                    y="0"
                    width="300"
                    height="180"
                    fill="transparent"
                />

                {
                    props.type === "coupon" &&
                    <image href={Star} width="30" height="30" x="260" y="40" />
                }

                <text textAnchor="middle" x="50%" y="50%" className="coupon-title">
                    {props.title}
                </text>

                <foreignObject
                    x="30"
                    y="115"
                    width="240"
                    height="45"
                    textAnchor="middle"
                >
                    <p className="coupon-subtitle">{props.subtitle}</p>
                </foreignObject>
            </g>
        </svg>
    )
}

export default CouponItem