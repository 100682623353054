import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHTML from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import React, { ChangeEvent, createRef, useContext, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ReactComponent as Check } from '../../../../assets/icons/check_register.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { AdminFAQContext } from '../../../../contexts/AdminFAQContext';
import { ToastContext } from '../../../../contexts/ToastContext';
import Popup from '../../../utils/Popup';


interface OwnProps {
    question: Question,
    subCat: SubCategory,
    updateSelf: Function,
    deleteSelf: Function
}

class SubCategory {
    created_at?: string = "";
    f_a_q_category_id?: number = 0;
    header_url?: string = "";
    id?: number = 0;
    logo_url?: string = "";
    name?: string = "";
    updated_at?: string = "";
    questions?: Question[] = [];
}

export class Question {
    created_at: string = "";
    updated_at: string = "";
    question: string = "";
    answer: string = "";
    id: number = 0;
    f_a_q_sub_category_id: number = 0;
}

type Props = OwnProps;

const QandA: React.FC<Props> = (props) => {
    const [question, setQuestion] = useState(new Question());
    const [edit, setEdit] = useState<boolean>(false);

    const { notify, errorNotification } = useContext(ToastContext);

    const { updateQuestion, getQuestion, deleteQuestion } = useContext(AdminFAQContext);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const textAreaRef = createRef<HTMLTextAreaElement>();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        setQuestion(props.question);
        const blocksFromHTML = htmlToDraft(props.question.answer);
        const { contentBlocks, entityMap } = blocksFromHTML;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));

    }, [])

    const update = async () => {
        const answer = draftToHTML(convertToRaw(editorState.getCurrentContent()));
        const newQuestion = { ...question, ...{ answer } };
        setQuestion(newQuestion);

        const requestError = await updateQuestion(props.subCat, newQuestion);

        if (requestError) {
            errorNotification(requestError);
        } else {
            notify("Wijzingen opgeslagen.")
            setEdit(false);
        }
    }

    const editQuestion = (e: ChangeEvent<HTMLInputElement>) => {
        setQuestion({ ...question, question: e.target.value });
        props.updateSelf(question)
    }

    const deleteSelf = async () => {
        const requestError = await deleteQuestion(props.subCat, question);

        if (requestError) {
            errorNotification(requestError);
        } else {
            notify(`${question.question} verwijderd.`);
            props.deleteSelf(question.id);
        }
    }

    const clickEdit = () => setEdit(true);

    const cancelUpdate = async () => {
        setEdit(false);

        try {
            let originalQuestion = await getQuestion(props.subCat, question);

            setQuestion(originalQuestion);

            notify(`Wijzigingen ongedaan gemaakt.`);
        } catch (e) {
            errorNotification(e);
        }
    }

    useEffect(() => {
        if (textAreaRef.current) textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }, [edit]);

    return (
        <div className="q-a">
            <div className="q-a-text">
                {
                    edit ?
                        <input
                            onChange={(e) => editQuestion(e)}
                            placeholder={question?.question}
                            value={question?.question}
                            className={"q-input"}
                            id={`question-input-${question?.id}`}
                        />
                        :
                        <p className="q">{question?.question}</p>
                }
                {
                    edit ?
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            localization={{ locale: "nl" }}
                            wrapperClassName="news-wrapper"
                            editorClassName="news-editor"
                            toolbar={{
                                options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "link",
                                    "emoji",
                                    "remove",
                                    "history"
                                ]
                            }}
                        />
                        :
                        <div className="a" dangerouslySetInnerHTML={{ __html: question.answer }}></div>
                }
            </div>

            <div className="edit-buttons">
                {
                    edit ?
                        <div>
                            <Check onClick={update} />
                            <Close onClick={cancelUpdate} />
                        </div>
                        :
                        <Edit onClick={clickEdit} />
                }

                <Delete onClick={() => setConfirmation(true)} />
            </div>

            <Popup
                title={`"${question.question}" verwijderen?`}
                buttonText="Verwijderen"
                buttonFunction={deleteSelf}
                setShow={setConfirmation}
                show={confirmation}
            />
        </div>
    )
}

export default QandA
