import Store from "../../../models/Store";
import React, { FunctionComponent } from "react";
import StoreOfTheMonth from "../../../assets/icons/store_favourites.svg";
import Star from "../../../assets/icons/favorite_store.svg";

const StoreListItem: FunctionComponent<{
  store: Store;
  currentSotm: number;
  setSotm: (storeId: number) => Promise<void>;
}> = (props) => {
  const isSotm = props.currentSotm === props.store.id;
  return (
    <div className="store-item">
      <div className="image">
        <img src={props.store.main_image_url} alt="" className="store-image" />
        <img
          src={isSotm ? StoreOfTheMonth : Star}
          alt=""
          className="icon"
          onClick={() => {
            if (!isSotm) {
              props.setSotm(props.store.id);
            }
          }}
        />
      </div>
      <p>{props.store.name}</p>
    </div>
  );
};

export default StoreListItem;
