import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import City from "../models/City";

interface OwnProps {

}

interface ICityContext {
    cities: City[],
    sortedCities: City[],
    getAllCities: () => Promise<void>
}

const cityEndPoint = new RestEndpoint('cities');

export const CityContext = createContext({} as ICityContext);

type Props = OwnProps;

const CityContextProvider: FunctionComponent<Props> = (props) => {
    const [cities, setCities] = useState<City[]>([]);
    const [sortedCities, setSortedCities] = useState<City[]>([]);

    useEffect(() => {
        getAllCities().then()
    }, []);

    useEffect(() => {
        setSortedCities([...cities].sort(compareCitiesDesc))
    }, [cities]);

    const getAllCities = async (): Promise<void> => {
        // @ts-ignore
        const allCities: City[] = await cityEndPoint.all();
        setCities(allCities);
    }

    const compareCitiesDesc = (a: City, b: City) => {
        if (a.number_of_real_stores > b.number_of_real_stores) {
            return -1;
        }
        if (a.number_of_real_stores < b.number_of_real_stores) {
            return 1;
        }
        return 0;
    }

    return (
        <CityContext.Provider
            value={{cities, sortedCities, getAllCities}}>
                {props.children}
        </CityContext.Provider>
    )
}

export default CityContextProvider;