import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import Store from '../models/Store';
import RestEndpoint from "../requests/RestEndpoint";
import { AuthContext } from './AuthContext';

interface OwnProps {
}

type Props = OwnProps;

interface IStoreContext {
    stores: Store[]
    favoriteStores: Store[]
    currentStore: Store
    getStores: () => Promise<void>
    getStore: (store_name: string) => Promise<void>
    setFavoriteStores: React.Dispatch<React.SetStateAction<Store[]>>
}

const storeEndpoint = new RestEndpoint('stores');

export const StoreContext = createContext({} as IStoreContext);

const StoreContextProvider: FunctionComponent<Props> = (props) => {
    const [stores, setStores] = useState<Store[]>([]);
    const [favoriteStores, setFavoriteStores] = useState<Store[]>([]);

    const { user, isShopper } = useContext(AuthContext);

    const [currentStore, setStore] = useState<Store>(new Store());

    useEffect(() => {
        getStores().then()
        getFavoriteStores()
    }, [])

    const getStores = async (): Promise<void> => {
        // @ts-ignore
        const allStores: Store[] = await storeEndpoint.all();
        setStores(allStores);
    }
    
    const getStore = async (store_name: string): Promise<void> => {
        // @ts-ignore
        const newStore: Store = await storeEndpoint.find(store_name);
        setStore(newStore);
    }

    const getFavoriteStores = async (): Promise<void> => {
        if(!user) return;
        if(!isShopper()) return;

        const favoritesEndpoint = new RestEndpoint(`shoppers/${user.id}/favorite-stores`);

        const allFavoriteStores = await favoritesEndpoint.all();

        // @ts-ignore
        setFavoriteStores(allFavoriteStores)
    }

    return (
        <StoreContext.Provider value = {{ stores, favoriteStores, currentStore, getStores, getStore, setFavoriteStores }}>
            {props.children}
        </StoreContext.Provider>
    );
}

export default StoreContextProvider;
