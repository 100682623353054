import React, {useContext, useEffect, useState} from 'react'
import {RouteComponentProps, useLocation, useParams} from 'react-router-dom';
import { removeSpecialChars, stringToUrl } from '../utils/UrlHelper';

// Contexts
import {NewsContext} from '../../contexts/NewsContext';
import NewsItem from '../../models/NewsItem';
import Breadcrumb from "../utils/Breadcrumb";
import RestEndpoint from "../../requests/RestEndpoint";
import {Helmet} from 'react-helmet';

interface OwnProps extends RouteComponentProps {

}

type Props = OwnProps;


const newsEndPoint = new RestEndpoint('news_items');
const pressEndPoint = new RestEndpoint('press_releases');

const NewsItemDetail: React.FC<Props> = (props) => {
    const {getNewsItem} = useContext(NewsContext);
    const {title} = useParams<{ title: string }>();
    const [currentNews, setNews] = useState<NewsItem>();
    const location = useLocation().pathname;
    const {newsItems, pressItems} = useContext(NewsContext);

    useEffect(() => {

        const getItems = async () => {
            if (title) {
                let itemId = undefined;

                if (newsItems === undefined || pressItems === undefined) {
                    return;
                }

                if (isNews()) {
                    itemId = newsItems.find(newsItem => removeSpecialChars(newsItem.title.toLowerCase()) ===
                        title.replace(/-/g, " "))?.id;
                } else {
                    itemId = pressItems.find(newsItem => removeSpecialChars(newsItem.title.toLowerCase()) ===
                        title.replace(/-/g, " "))?.id;
                }

                if (!itemId) {
                    return;
                }

                const item = isNews() ? await newsEndPoint.find(itemId) : await pressEndPoint.find(itemId);
                // @ts-ignore
                setNews(item);
            }
        };
        getItems();

    }, [newsItems]);

    const isNews = () => {
        return location.includes('nieuws');
    };


    const date = () => {
        if (!currentNews) return;

        const date = new Date((currentNews.created_at) ? currentNews.created_at.replace(/\s/, 'T') : 0);
        let months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "July", "Augustus", "September", "October", "November", "December"];

        return (
            <div className="info">
                <p className="date">{`${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`}</p>
                <p>&middot;</p>
                <p className="label">{currentNews.label}</p>
            </div>
        )
    }

    return (
        <div>
            <Helmet>
                <title>FH - {currentNews?.title || ""}</title>
            </Helmet>
            <Breadcrumb tiers={[{url: '/', name: 'Home'},
                isNews() ? {url: '/nieuws', name: 'Nieuws'} : {url: '/pers', name: 'Pers'},
                isNews() ? {
                    url: '/nieuws/' + stringToUrl(removeSpecialChars(currentNews?.title)),
                    name: currentNews?.title!
                } : {url: '/pers/' + currentNews?.id, name: stringToUrl(removeSpecialChars(currentNews?.title!))}
            ]}/>
            <div className="news-detail">
                <p className="news-title">{currentNews?.title}</p>

                {date()}
                <img src={currentNews?.image_url} alt={currentNews?.title} className="news-image"/>

                <div dangerouslySetInnerHTML={{__html: currentNews?.content!}}></div>
            </div>
        </div>
    )
}

export default NewsItemDetail
