import React, { FunctionComponent, useState, useContext } from "react";
import OpeningTimeRow from "./OpeningTimeRow";
import SpecialTimesRow from "./SpecialTimesRow";
import SubmitChanges from "./SubmitChanges";
import Store from "../../../models/Store";
import OpeningHoursForDay from "../../../models/storemodels/OpeningHoursForDay";
import OpeningHoursForHoliday from "../../../models/storemodels/OpeningHoursForHoliday";
import RestEndpoint from "../../../requests/RestEndpoint";
import RequestError from "../../../models/RequestError";
import StoreError from "./StoreError";
import { ToastContext } from "../../../contexts/ToastContext";

const OpeningTimes: FunctionComponent<{
    formData: Store;
}> = (props) => {
    const [openingTimes, setOpeningTimes] = useState<OpeningHoursForDay[]>(
        props.formData.opening_hours_for_days
    );

    const [specialOpeningTimes, setSpecialOpeningTimes] = useState<
        OpeningHoursForHoliday[]
    >(props.formData.opening_hours_for_holidays);
    const [error, setError] = useState<RequestError | null>(null);
    const { notify } = useContext(ToastContext);

    const handleOpeningTimesChange = (
        e: any,
        id: number,
        isSpecialOpeningTimes: boolean
    ) => {
        let field = '';
        if (isSpecialOpeningTimes) {
            field = e.target.id.replace(id + "_special_", "");
        } else {
            field = e.target.id.replace(id + "_", "");
        }
        let value = e.target.value;

        if (field === "closed") {
            value = e.target.checked ? 1 : 0;
        }

        if (isSpecialOpeningTimes) {
            const specialOpeningTimesCopy: OpeningHoursForHoliday[] = [
                ...specialOpeningTimes,
            ];

            const index: number = specialOpeningTimesCopy.findIndex(
                (openingTime) => openingTime.id === id
            );

            //@ts-ignore
            specialOpeningTimesCopy[index][field] = value;
            setSpecialOpeningTimes(specialOpeningTimesCopy);
        } else {
            const openingTimesCopy: OpeningHoursForDay[] = [...openingTimes];
            const index: number = openingTimesCopy.findIndex(
                (openingTime) => openingTime.id === id
            );

            //@ts-ignore
            openingTimesCopy[index][field] = value;

            setOpeningTimes(openingTimesCopy);
        }
    };

    const removeSpecialOpeningTime = (id: number) => {
        const specialOpeningTimesCopy = [...specialOpeningTimes];

        const index = specialOpeningTimesCopy.findIndex((openingTime) => {
            if (openingTime) {
                return openingTime.id === id;
            } 
            
            return undefined;
        });

        if (index > -1) {
            specialOpeningTimesCopy.splice(index, 1);
        }

        setSpecialOpeningTimes(specialOpeningTimesCopy);
    };

    const addSpecialOpeningTime = () => {
        const specialOpeningTimesCopy = [...specialOpeningTimes];
        specialOpeningTimesCopy.push(new OpeningHoursForHoliday());

        setSpecialOpeningTimes(specialOpeningTimesCopy);
    };

    const handleSubmit = async () => {
        const regularHoursUpdate = {
            opening_hours_for_days: openingTimes.map((openingTime) => ({
                day_of_the_week: openingTime.day_of_the_week,
                start_time: openingTime.start_time,
                end_time: openingTime.end_time,
                closed_start_time: openingTime.closed_start_time,
                closed_end_time: openingTime.closed_end_time,
                closed: openingTime.closed === 1,
            })),
        };

        const specialHoursUpdate = {
            opening_hours_for_holidays: specialOpeningTimes.map((openingTime) => ({
                date: openingTime.date || Date.now(),
                start_time: openingTime.start_time,
                end_time: openingTime.end_time,
                closed_start_time: openingTime.closed_start_time,
                closed_end_time: openingTime.closed_end_time,
                closed: openingTime.closed === 1,
            })),
        };

        const regularHoursEndpoint = new RestEndpoint(
            `stores/${props.formData.id}/opening-hours-for-days`
        );
        const specialHoursEndpoint = new RestEndpoint(
            `stores/${props.formData.id}/opening-hours-for-holidays`
        );

        try {
            await regularHoursEndpoint.store(regularHoursUpdate);
            if (specialHoursUpdate.opening_hours_for_holidays.length > 0) {
                await specialHoursEndpoint.store(specialHoursUpdate);
            }
            notify("Wijzigingen openingstijden opgeslagen.");

        } catch (e) {
            setError(e);
            notify(e.message, "error");
        }
    };

    const daysOfWeek: string[] = [
        "Maandag",
        "Dinsdag",
        "Woensdag",
        "Donderdag",
        "Vrijdag",
        "Zaterdag",
        "Zondag",
    ];

    return (
        <div className="section-body" key="opening-times-table">
            {error ? <StoreError error={error} /> : ""}

            <table className="opening-times-table">
                <thead>
                    <tr>
                        <th className="spacer-day">Vaste Openingstijden</th>
                        <th></th>
                        <th></th>
                        <th className="spacer"></th>
                        <th>Gesloten Tussen</th>
                        <th></th>
                        <th className="spacer"></th>
                        <th>Gesloten</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="spacer-day"></td>
                        <td>Van</td>
                        <td>Tot</td>
                        <td className="spacer"></td>
                        <td>Van</td>
                        <td>Tot</td>
                        <td className="spacer"></td>
                        <td></td>
                    </tr>

                    {openingTimes.map((openingTime) => (
                        <OpeningTimeRow
                            key={"opening_time_row_" + openingTime.day_of_the_week}
                            day={daysOfWeek[openingTime.day_of_the_week - 1]}
                            data={openingTime}
                            handleOpeningTimesChange={handleOpeningTimesChange}
                            isSpecialOpeningTime={false}
                        />
                    ))}
                </tbody>
            </table>
            <table className="opening-times-table special-times">
                <thead>
                    <tr>
                        <th>Aangepaste Openingstijden</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td className="wider special-times">Datum (+ naam dag)</td>
                        <td></td>
                        <td className="spacer"></td>
                        <td></td>
                        <td></td>
                        <td className="spacer"></td>
                        <td></td>
                    </tr>
                    {specialOpeningTimes.map((specialOpeningTime) => {
                        if (specialOpeningTime) {
                            return (
                                <SpecialTimesRow
                                    key={"special_openingtime_row" + specialOpeningTime.id}
                                    data={specialOpeningTime}
                                    handleOpeningTimesChange={handleOpeningTimesChange}
                                    removeSpecialOpeningTime={removeSpecialOpeningTime}
                                />
                            );
                        }
                    })}
                    <tr>
                        <td className="wider add-date">
                            <div onClick={addSpecialOpeningTime}>
                                <button className="add-special-time"></button>{" "}
                                <p>Voeg datum toe</p>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td className="spacer"></td>
                        <td></td>
                        <td></td>
                        <td className="spacer"></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <SubmitChanges submitChanges={handleSubmit} />
        </div>
    );
};

export default OpeningTimes;
