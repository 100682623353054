import React from 'react'
import { NavLink } from 'react-router-dom';

// Icons
import { ReactComponent as FavouriteShops } from '../../../../assets/icons/store_favourites.svg';
import { ReactComponent as Coupons } from '../../../../assets/icons/coupons.svg';
import { ReactComponent as Ratings } from '../../../../assets/icons/ratings.svg';
import {ReactComponent as IDCard} from "../../../../assets/icons/id_card.svg";


const ShopperLinks = () => {
    return (
        <div>
            <NavLink to={'/mijn-gegevens'}><IDCard /> Mijn gegevens</NavLink>
            <NavLink to={'/favoriete-winkels'}><FavouriteShops /> Mijn favoriete winkels</NavLink>
            <NavLink to={'/mijn-recensies'}><Ratings /> Mijn recensies</NavLink>
            <NavLink to={'/mijn-coupons'}><Coupons /> Mijn coupons</NavLink>
        </div>
    )
}

export default ShopperLinks;
