import React, {
    FunctionComponent,
    useState,
    useEffect,
    useContext,
} from "react";
import Category from "../../../models/Category";
import ClothingCategory from "../../../models/storemodels/ClothingCategory";
import RestEndpoint from "../../../requests/RestEndpoint";
import CategoryItem from "./CategoryItem";
import Add from "../../../assets/icons/add-review.svg";
import { BrandContext } from "../../../contexts/BrandContext";
import { ItemContext } from "../../../contexts/ItemContext";
import { AgeGenderContext } from "../../../contexts/AgeGenderContext";
import { SpecialistContext } from "../../../contexts/SpecialistsContext";
import { CityContext } from "../../../contexts/CityContext";
import { StyleContext } from "../../../contexts/StyleContext";
import {ToastContext} from "../../../contexts/ToastContext";

const CategorySection: FunctionComponent<{ parent: Category }> = (props) => {
    const { getAllBrands } = useContext(BrandContext);
    const { getAllItems } = useContext(ItemContext);
    const { getAllAgeGenders } = useContext(AgeGenderContext);
    const { getAllSpecialists } = useContext(SpecialistContext);
    const { getAllCities } = useContext(CityContext);
    const { getAllStyles } = useContext(StyleContext);
    const [clothingCategories, setClothingCategories] = useState<
        ClothingCategory[]
    >([]);
    const { notify } = useContext(ToastContext)

    const clothingCategoryEndpoint = new RestEndpoint(
        `categories/${props.parent.id}/clothing-categories`
    );
    useEffect(() => {
        const fetchAllCategories = async () => {
            //@ts-ignore
            const allClothingCategories: ClothingCategory[] = await clothingCategoryEndpoint.all();
            allClothingCategories.sort(applyOrder);
            setClothingCategories(allClothingCategories);
        };
        fetchAllCategories();
    }, [setClothingCategories, props.parent.id]);

    const refreshCategories = async () => {
        await getAllBrands();
        await getAllItems();
        await getAllAgeGenders();
        await getAllSpecialists();
        await getAllCities();
        await getAllStyles();
    }

    const applyOrder = (a: ClothingCategory, b: ClothingCategory) => {
        if (a.order_id && b.order_id) {
            if (a.order_id < b.order_id) {
                return -1;
            }
            if (a.order_id > b.order_id) {
                return 1;
            }
        }
        return 0;
    };

    const deleteCategory = async (id: number) => {
        await clothingCategoryEndpoint.destroy({ id });
        await refreshCategories();
        const categoryCopy = [...clothingCategories];
        const index = categoryCopy.findIndex((category) => category.id === id);

        if (index > -1) {
            categoryCopy.splice(index, 1);
            categoryCopy.sort(applyOrder);
            setClothingCategories(categoryCopy);
        }

        notify("Categorie verwijderd.");
    };

    const createCategory = async () => {
        let highestOrder = 0;
        clothingCategories.forEach((cat) => {
            if (cat.order_id && cat.order_id > highestOrder) {
                highestOrder = cat.order_id;
            }
        });

        //@ts-ignore
        const newCategory: ClothingCategory = await clothingCategoryEndpoint.store({
            name: "Nieuwe Categorie",
            order_id: props.parent.name == "Wie" ? highestOrder + 1 : null,
        });
        await refreshCategories();

        const catCopy = [...clothingCategories];
        if (highestOrder > 0) {
            catCopy.unshift(newCategory);
            catCopy.sort(applyOrder);
        } else {
            catCopy.push(newCategory);
        }
        setClothingCategories(catCopy);

        const menu = document.getElementById(`${props.parent.category}_menu`);
        if (menu) {
            menu.scrollTop = menu?.scrollHeight;
        }
        notify("Categorie aangemaakt.");
    };

    const updateCategoryOrder = async (categoryId: number, update: string) => {
        const categoryIndex = clothingCategories.findIndex(
            (cat) => cat.id === categoryId
        );
        if (categoryIndex > -1) {
            const categoryCopy = [...clothingCategories];
            const categoryItem = categoryCopy[categoryIndex];
            if (categoryItem.order_id) {
                const updatedOrder =
                    update === "up"
                        ? categoryItem.order_id + 1
                        : categoryItem.order_id - 1;

                const duplicateOrderIndex = clothingCategories.findIndex(
                    (cat) => cat.order_id === updatedOrder
                );
                if (duplicateOrderIndex > -1) {
                    const targetCopyItem = categoryCopy[duplicateOrderIndex];
                    if (targetCopyItem.order_id) {
                        const newValueForDuplicate =
                            update === "up"
                                ? targetCopyItem.order_id - 1
                                : targetCopyItem.order_id + 1;

                        categoryCopy[duplicateOrderIndex].order_id = newValueForDuplicate;
                        await clothingCategoryEndpoint.update({
                            id: targetCopyItem.id,
                            name: targetCopyItem.name,
                            order_id: newValueForDuplicate,
                        });
                    }
                }

                categoryCopy[categoryIndex].order_id = updatedOrder;
                await clothingCategoryEndpoint.update({
                    id: categoryCopy[categoryIndex].id,
                    name: categoryCopy[categoryIndex].name,
                    order_id: updatedOrder,
                });
            }
            await refreshCategories();
            setClothingCategories(categoryCopy.sort(applyOrder));
        }
    };

    return (
        <div className="category-section">
            <div className="section-header">
                <h3>{props.parent.name}</h3>
                <img src={Add} alt="" className="add" onClick={createCategory} />
            </div>
            <div className="section-body">
                <ul id={`${props.parent.category}_menu`}>
                    {clothingCategories.map((category) => (
                        <CategoryItem
                            parent={props.parent}
                            currentCategory={category}
                            deleteCategory={deleteCategory}
                            key={props.parent.id + "_" + category.id}
                            amountOfSubcategories={clothingCategories.length}
                            firstSubCategory={clothingCategories[0].order_id}
                            updateCategoryOrder={updateCategoryOrder}
                            refreshCategories={refreshCategories}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CategorySection;
