import React from 'react'

interface OwnProps {

}

type Props = OwnProps;

const SuperUser: React.FC<Props> = (props) => {
    return (
        <div>
            
        </div>
    )
}

export default SuperUser