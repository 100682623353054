import React, { useState } from 'react'

import { ReactComponent as Close } from "../../../../assets/icons/close.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/edit.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check_register.svg";

interface Review {
    id: number,
    rating: number,
    store_id: number,
    store: string,
    city: string,
    title: string,
    content: string,
    user_name: string,
    user_id: number,
    user_number_of_reviews: number,
    created_at: string,
    updated_at: string
}

interface OwnProps {
    review: Review,
    handleChange: Function,
    deleteReview: Function,
    updateReview: Function,
    cancelUpdate: Function
}

type Props = OwnProps;

const ReviewComponent: React.FC<Props> = (props) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

    const cancelUpdate = async () => {
        try {
            await props.cancelUpdate(props.review.id);

            setEdit(false);
        } catch (e) {

        }
    }

    const updateReview = async () => {
        try {
            await props.updateReview(props.review)

            setEdit(false)
        } catch (e) {

        }
    }

    return (
        <div key={props.review.id} className="review">
            <div className="review-header">
                <h3>{props.review.store}</h3>
                <p>Score: {props.review.rating}</p>
            </div>

            <div className="review-content">
                {
                    edit ?
                        <input value={props.review.content} onChange={(e) => props.handleChange(e)} id={props.review.id.toString()} />
                        :
                        <p>{props.review.content}</p>
                }

                <p>Voor het laatst geupdate op: {new Date(props.review.updated_at).getDate()}-{new Date(props.review.updated_at).getMonth()}-{new Date(props.review.updated_at).getFullYear()}</p>
            </div>

            <div className="edit-buttons">
                <div>
                    {
                        edit ?
                            <Check onClick={updateReview} />
                            :
                            <Edit onClick={() => setEdit(true)} />
                    }
                    {
                        edit && <p onClick={cancelUpdate}>cancel</p>
                    }
                </div>
                <Close onClick={() => setDeleteConfirmation(true)} />
            </div>

            {
                deleteConfirmation &&
                <div className="popup-div">
                    <div className="popup">
                        <Close className="close" onClick={() => setDeleteConfirmation(false)} />
                        <h2>Review verwijderen?</h2>
                        <button className="button" onClick={() => {props.deleteReview(props.review.id); setDeleteConfirmation(false)}}>Verwijderen</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default ReviewComponent;
