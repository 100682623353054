import React, { FunctionComponent } from 'react';
import Store from "../../../models/Store";
import About from "./store-info-components/About";
import ExtraInfo from "./store-info-components/ExtraInfo";
import Hours from "./store-info-components/Hours";
import Reviews from "./store-info-components/Reviews";
import WhatWeSell from "./store-info-components/WhatWeSell";

interface OwnProps {
    store: Store
    handleNewTab: (buttonId: any) => void;
}

type Props = OwnProps;

const StoreInfo: FunctionComponent<Props> = (props) => {
    const { store } = props;

    return (
        <div className="store-info">
            <div className="tabs">
                <a className="tab active" id="about-button" onClick={e => props.handleNewTab('about-button')}>
                    <span className="hide-on-desktop">Over {store.name}</span>
                    <span className="show-on-desktop">Informatie</span>
                </a>

                <a className="tab hide-on-desktop" id="hours-button" onClick={e => props.handleNewTab('hours-button')}>Openingstijden</a>
                <a className="tab" id="what-we-sell-button" onClick={e => props.handleNewTab('what-we-sell-button')}>Wat verkopen wij</a>
                <a className="tab reviews" id="reviews-button" onClick={e => props.handleNewTab('reviews-button')}>Recensies</a>
                <a className="tab hide-on-desktop" id="extra-info-button" onClick={e => props.handleNewTab('extra-info-button')}>Extra informatie</a>
            </div>

            <div className="store-info-content">
                <div id="about" className="detail-section active">
                    <div className="hide-on-desktop">
                        <About store={store} />
                    </div>
                    <div className="show-on-desktop">
                        <About store={store} />
                        <Hours store={store} />
                        <ExtraInfo store={store} />
                    </div>
                </div>
                <div id="hours" className="detail-section">
                    <Hours store={store} />
                </div>
                <div id="what-we-sell" className="detail-section">
                    <WhatWeSell store={store} />
                </div>
                <div id="reviews" className="detail-section">
                    <Reviews store={store} />
                </div>
                <div id="extra-info" className="detail-section">
                    <ExtraInfo store={store} />
                </div>
            </div>

        </div>
    );
}

export default StoreInfo;
