import React, {
  FunctionComponent,
  useState,
  useEffect,
  FormEvent,
  useContext,
} from "react";
import RestEndpoint from "../../../../requests/RestEndpoint";
import SocialMediaRow from "./SocialMediaRow";
import { ToastContext } from "../../../../contexts/ToastContext";

export interface ISocialMediaAccount {
  name: string;
  image_url: string;
  pivot: {
    fashion_hotspot_id: number;
    social_media_account_id: number;
    url: string;
  };
}

const SocialMedia: FunctionComponent = (props) => {
  const [accounts, setAccounts] = useState<ISocialMediaAccount[]>([]);
  const endpoint = new RestEndpoint("fashion-hotspots/1/social-media-accounts");
  const { notify, errorNotification } = useContext(ToastContext);

  useEffect(() => {
    const getAllAccounts = async () => {
      //@ts-ignore
      const allAccounts: ISocialMediaAccount[] = await endpoint.all();

      setAccounts(allAccounts);
    };
    getAllAccounts();
  }, []);

  const changeUrl = (id: number, newUrl: string) => {
    const accountsCopy = [...accounts];
    const index = accountsCopy.findIndex(
      (account) => account.pivot.social_media_account_id === id
    );

    if (index > -1) {
      accountsCopy[index].pivot.url = newUrl;

      setAccounts(accountsCopy);
    }
  };

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();

    const updateData = accounts.map((account) => ({
      id: account.pivot.social_media_account_id,
      url: account.pivot.url,
    }));
    try {
      await endpoint.store({ social_media_accounts: updateData });
      notify("De wijzigingen zijn succesvol opgeslagen.");
    } catch (e) {
      errorNotification(e);
    }
  };

  return (
    <div className="section">
      <h2>Social Media</h2>
      <form onSubmit={submitForm}>
        {accounts.map((account) => (
          <SocialMediaRow
            account={account}
            changeParent={changeUrl}
            key={account.name}
          />
        ))}
        <button className="button">Opslaan</button>
      </form>
    </div>
  );
};

export default SocialMedia;
