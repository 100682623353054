import React, { FunctionComponent, useState, useContext } from "react";
import Account from "../../../assets/icons/account.svg";
import Store from "../../../models/Store";
import Arrow from "./../../../assets/icons/arrow_right_white_buttons.svg";
import RestEndpoint from "../../../requests/RestEndpoint";
import { AuthContext } from "../../../contexts/AuthContext";
import { ToastContext } from "../../../contexts/ToastContext";

const ReviewResponse: FunctionComponent<{
  store: Store | null;
  id: string;
  reviewId: number;
  updateReviews: any;
}> = (props) => {
  const [reviewText, setReviewText] = useState<string>("");
  const { user } = useContext(AuthContext);
  const { notify } = useContext(ToastContext);

  const submitReview = async () => {
    const endpoint = new RestEndpoint(`stores/${props.store?.id}/reviews`);
    const data = {
      userId: user?.id,
      content: reviewText,
      parent_id: props.reviewId,
    };

    await endpoint.store(data);
    props.updateReviews();
    notify("De reactie is succesvol toegevoegd.")
  };

  return (
    <div className="review-response hidden" id={props.id}>
      <div className="response-image">
        <img src={props.store?.logo_url || Account} alt="" />
        <h5 className="my-review-response-title mobile-hidden">
          {props.store?.name}
        </h5>
      </div>
      <div className="response">
        <div className="response-title">
          <h5 className="my-review-response-title web-hidden">
            {props.store?.name}
          </h5>
        </div>

        <div className="response-body">
          <textarea
            name=""
            id=""
            cols={70}
            rows={5}
            placeholder="Plaats hier je reactie"
            onChange={(e) => {
              setReviewText(e.target.value);
            }}
          ></textarea>
        </div>
        <button className="submit" onClick={submitReview}>
          Plaats je reactie <img src={Arrow} alt="" />
        </button>
      </div>
    </div>
  );
};

export default ReviewResponse;
