import React, {FormEvent, ChangeEvent, useState, useEffect, useContext} from 'react'
import ResetPasswordEndpoint from '../../requests/ResetPasswordEndpoint';
import RequestError from '../../models/RequestError';
import ValidationError from '../utils/ValidationError';
import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {ToastContext} from "../../contexts/ToastContext";

interface OwnProps extends RouteComponentProps {

}

type Props = OwnProps;

class ResetCredentials {
    email: string = "";
    token: string = "";
    password: string = "";
    password_confirmation: string = "";
}

const endpoint = new ResetPasswordEndpoint;

const ResetPassword: React.FC<Props> = (props) => {
    const [credentials, setCredentials] = useState(new ResetCredentials);
    const [requestError, setRequestError] = useState<RequestError>();
    const [token, setToken] = useState<string>();
    const {notify} = useContext(ToastContext);

    useEffect(() => {
        let token = window.location.search.split("=");

        if (!token[1]) return;

        setCredentials({ ...credentials, token: token[1] });
    }, [])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCredentials({ ...credentials, [e.target.id]: e.target.value })
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!credentials) return;
            
        const requestError = await endpoint.resetPassword(credentials);
        
        if(requestError) {
            setRequestError(requestError);
        } else {
            notify('Wachtwoord is succesvol aangepast.');
            props.history.push('/login');
        }
    }

    return (
        <div id="reset-password-page">
            <Helmet>
                <title>FH - Wachtwoord opnieuw instellen</title>
            </Helmet>

            <h1>WACHTWOORD OPNIEUW INSTELLEN</h1>

            {requestError && <ValidationError requestError={requestError} />}

            <form onSubmit={handleSubmit}>
                <h2>E-MAILADRES</h2>
                <input id="email" onChange={handleChange} value={credentials.email} placeholder="E-mailadres"/>
                <h2>NIEUW WACHTWOORD</h2>
                <input id="password" type="password" onChange={handleChange} value={credentials.password} placeholder="Wachtwoord" />
                <input id="password_confirmation" type="password" onChange={handleChange} value={credentials.password_confirmation} placeholder="Wachtwoord bevestigen" />

                <button>Wachtwoord opnieuw instellen</button>
            </form>
        </div>
    )
}

export default ResetPassword
