import React, {createContext, FunctionComponent, useEffect, useState} from 'react';
import RestEndpoint from "../requests/RestEndpoint";
import FilterCategory from "../models/FilterCategory";

interface OwnProps {}

type Props = OwnProps;

const categoriesEndPoint = new RestEndpoint('categories');

interface IFilterCategoryContext {
  filterCategories: FilterCategory[],
  getFilterCategories: () => Promise<void>
}

export const FilterCategoriesContext = createContext({} as IFilterCategoryContext);

const FilterCategoriesContextProvider: FunctionComponent<Props> = (props) => {
  const [filterCategories, setFilterCategories] = useState<FilterCategory[]>([]);

  useEffect(() => {
    getFilterCategories().then()
  }, [])

  const getFilterCategories = async (): Promise<void> => {
    // @ts-ignore
    const allFilterCategories: FilterCategory[] = await categoriesEndPoint.all();
    setFilterCategories(allFilterCategories);
  }

  return (
      <FilterCategoriesContext.Provider
          value={{filterCategories, getFilterCategories}}>
        {props.children}
      </FilterCategoriesContext.Provider>
  )
}

export default FilterCategoriesContextProvider;
