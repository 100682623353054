import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
  useContext,
} from "react";
import TravelInfoItem from "./CheckboxItem";
import SubmitChanges from "./SubmitChanges";
import Store from "../../../models/Store";
import RestEndpoint from "../../../requests/RestEndpoint";
import RequestError from "../../../models/RequestError";
import StoreError from "./StoreError";
import { ToastContext } from "../../../contexts/ToastContext";
import TravelOption from "../../../models/storemodels/TravelOption";

const TravelInfo: FunctionComponent<{
  formData: Store;
}> = (props) => {
  const [travelOptions, setTravelOptions] = useState<TravelOption[]>([]);
  const [storeOptions, setStoreOptions] = useState<number[]>(
    props.formData.travel_options.map((option) => option.id || -1)
  );
  const [error, setError] = useState<RequestError | null>(null);
  const { notify } = useContext(ToastContext);

  const submitChanges = async () => {
    const endpoint = new RestEndpoint(
      `stores/${props.formData.id}/travel-options`
    );

    try {
      await endpoint.store({ travel_option_ids: storeOptions });
      notify("Wijzigingen opgeslagen.");
    } catch (e) {
      setError(e);
      notify(e.message, "error");
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const optionsCopy = [...storeOptions];
    const index = optionsCopy.findIndex((travelOption) => travelOption === id);

    const value = e.target.checked;

    if (index > -1) {
      if (!value) {
        optionsCopy.splice(index, 1);
      }
    } else {
      if (value) {
        optionsCopy.push(id);
      }
    }
    setStoreOptions(optionsCopy);
  };

  useEffect(() => {
    const travelOptionsEndpoint = new RestEndpoint("travel-options");
    const getAllOptions = async (): Promise<void> => {
      //@ts-ignore
      const travelOptions: TravelOption[] = await travelOptionsEndpoint.all();
      setTravelOptions(travelOptions);
    };

    getAllOptions().then();
  }, [setTravelOptions]);

  const travelOptionList = travelOptions.map((travelOptionItem) => (
    <TravelInfoItem
      image={travelOptionItem.image_url}
      key={"travel_option_" + travelOptionItem.id}
      checked={storeOptions.includes(travelOptionItem.id || -1)}
      id={travelOptionItem.id || -1}
      name={travelOptionItem.name}
      handleFieldChange={handleChange}
    />
  ));
  return (
    <div className="section-body">
      {error ? <StoreError error={error} /> : ""}
      <div className="section-field travel-options">{travelOptionList}</div>
      <SubmitChanges submitChanges={submitChanges} />
    </div>
  );
};

export default TravelInfo;
