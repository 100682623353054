import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
  useContext,
} from "react";
import ExtraServiceItem from "./CheckboxItem";
import Services from "./../../../assets/icons/services.svg";
import SubmitChanges from "./SubmitChanges";
import Store from "../../../models/Store";
import RestEndpoint from "../../../requests/RestEndpoint";
import RequestError from "../../../models/RequestError";
import StoreError from "./StoreError";
import ExtraService from "../../../models/storemodels/ExtraService";
import { ToastContext } from "../../../contexts/ToastContext";

const ExtraServices: FunctionComponent<{
  formData: Store;
}> = (props) => {
  const [extraServices, setExtraServices] = useState<ExtraService[]>([]);
  const [storeServices, setStoreServices] = useState<number[]>(
    props.formData.extra_services.map((service) => service.id || -1)
  );
  const [error, setError] = useState<RequestError | null>(null);
  const { notify } = useContext(ToastContext);

  const submitChanges = async () => {
    const endpoint = new RestEndpoint(
      `stores/${props.formData.id}/extra-services`
    );

    try {
      await endpoint.store({ extra_services_ids: storeServices });
      notify("Wijzigingen opgeslagen.")
    } catch (e) {
      setError(e);
      notify(e.message, "error");
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const servicesCopy = [...storeServices];
    const index = servicesCopy.findIndex((storeService) => storeService === id);

    const value = e.target.checked;

    if (index > -1) {
      if (!value) {
        servicesCopy.splice(index, 1);
      }
    } else {
      if (value) {
        servicesCopy.push(id);
      }
    }
    setStoreServices(servicesCopy);
  };

  useEffect(() => {
    const extraServiceEndpoint = new RestEndpoint("extra-services");
    const getAllServices = async (): Promise<void> => {
      //@ts-ignore
      const services: ExtraService[] = await extraServiceEndpoint.all();
      setExtraServices(services);
    };

    getAllServices().then();
  }, [setExtraServices]);

  const extraServiceList = extraServices.map((extraServiceItem) => (
    <ExtraServiceItem
      image={Services}
      key={"extra_service_" + extraServiceItem.id}
      checked={storeServices.includes(extraServiceItem.id || -1)}
      id={extraServiceItem.id || -1}
      name={extraServiceItem.name}
      handleFieldChange={handleChange}
    />
  ));
  return (
    <div className="section-body">
      {error ? <StoreError error={error} /> : ""}
      <div className="section-field extra-services">{extraServiceList}</div>
      <SubmitChanges submitChanges={submitChanges} />
    </div>
  );
};

export default ExtraServices;
