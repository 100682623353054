import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import Brand from "../models/Brand";
import Category from '../models/Category';

interface OwnProps {

}

interface IBrandContext {
    brands: Brand[],
    getAllBrands: () => Promise<void>
}

const brandEndPoint = new RestEndpoint('categories/2');

export const BrandContext = createContext({} as IBrandContext);

type Props = OwnProps;

const BrandContextProvider: FunctionComponent<Props> = (props) => {
    const [brands, setBrands] = useState<Brand[]>([]);

    useEffect(() => {
        getAllBrands().then()
    }, [])

    const getAllBrands = async (): Promise<void> => {
        // @ts-ignore
        const category: Category = await brandEndPoint.all();
        setBrands(category.clothing_categories);
    }

    return (
        <BrandContext.Provider
            value={{brands, getAllBrands}}>
                {props.children}
        </BrandContext.Provider>
    )
}

export default BrandContextProvider;