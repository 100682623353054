import React, {FormEvent, FunctionComponent, useContext, useEffect, useState,} from "react";
import SellingPoint from "../../../../models/SellingPoint";
import RestEndpoint from "../../../../requests/RestEndpoint";
import SellingPointRow from "./SellingPointRow";
import {ToastContext} from "../../../../contexts/ToastContext";
import RequestHelper from "../../../../requests/RequestHelper";
import RequestError from "../../../../models/RequestError";

export interface ISellingPoint {
    id: number;
    title: string;
    content: string;
    image_url: string;
    image?: HTMLImageElement;
}

const SellingPoints: FunctionComponent = (props) => {
    const [sellingPoints, setSellingPoints] = useState<ISellingPoint[]>([]);
    const [updatedSellingPoints, setUpdatedSellingPoints] = useState<ISellingPoint[]>([]);
    const endpoint = new RestEndpoint("selling-points");
    const {notify, errorNotification} = useContext(ToastContext);

    useEffect(() => {
        const getAllSellingPoints = async () => {
            //@ts-ignore
            const allSellingPoints: SellingPoint[] = await endpoint.all();
            setSellingPoints(allSellingPoints);
            setUpdatedSellingPoints(allSellingPoints);
        };
        getAllSellingPoints();
    }, []);

    const updateSellingpoint = (id: number, field: string, value: any) => {
        const sellingPointCopy = [...updatedSellingPoints];
        const index = sellingPointCopy.findIndex((point) => point.id === id);

        if (index > -1) {
            //@ts-ignore
            sellingPointCopy[index][field] = value;
            setUpdatedSellingPoints(sellingPointCopy);
            if (field === "image") {
                const originalSellingpointCopy = [...sellingPoints];
                const originalIndex = originalSellingpointCopy.findIndex(
                    (point) => point.id === id
                );

                if (originalIndex > -1) {
                    originalSellingpointCopy[originalIndex].image = value;
                    setSellingPoints(originalSellingpointCopy);
                }
            }
        }
    };

    const addSellingPoint = () => {
        const sellingPointCopy = [...updatedSellingPoints];
        sellingPointCopy.push({
            id: -1,
            title: "",
            content: "",
            image_url: "",
        });

        setSellingPoints(sellingPointCopy);
        setUpdatedSellingPoints(sellingPointCopy);
    };

    const removeSellingPoint = async (id: number) => {
        setSellingPoints(
            [...sellingPoints].filter((sellingPoint) => sellingPoint.id !== id)
        );
        setUpdatedSellingPoints(
            [...updatedSellingPoints].filter((sellingPoint) => sellingPoint.id !== id)
        );

        await endpoint.destroy({id});
    };

    const submitChanges = async (e?: FormEvent) => {
        e?.preventDefault();

        updatedSellingPoints.forEach(async (updatedPoint: ISellingPoint) => {
            try {
                if (updatedPoint.id === -1) {
                    if (!updatedPoint.image) {
                        errorNotification(
                            new RequestError(422, "Een afbeelding is verplicht.")
                        );
                        return;
                    }
                    await endpoint.store(RequestHelper.convertToFormData(updatedPoint));
                    return;
                }
                await endpoint.update(
                    updatedPoint.image
                        ? RequestHelper.convertToFormData(updatedPoint)
                        : updatedPoint
                );
            } catch (e) {
                errorNotification(e);
            }
        });
        notify("De wijzigingen zijn succesvol opgeslagen.");
    };

    return (
        <div className="section">
            <h2>Selling Points</h2>
            <form onSubmit={submitChanges}>
                {sellingPoints.map((sellingPoint) => (
                    <SellingPointRow
                        sellingPoint={sellingPoint}
                        updateParent={updateSellingpoint}
                        removeSellingPoint={removeSellingPoint}
                        key={`${sellingPoint.title}_${sellingPoint.id}`}
                    />
                ))}
                <button className="button" type="button" onClick={addSellingPoint}>
                    Nieuw Sellingpoint toevoegen
                </button>
                <br/>
                <br/>
                <button className="button">Opslaan</button>
            </form>
        </div>
    );
};

export default SellingPoints;
