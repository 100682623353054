import React, {FunctionComponent, useContext} from 'react';
import { Helmet } from 'react-helmet';
import { PageContentContext } from '../../contexts/PageContentContext';

interface OwnProps {
}

type Props = OwnProps;

const AboutUsPage: FunctionComponent<Props> = (props) => {
    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'about-us')?.content;

    return (
        <div>
            <Helmet>
                <title>Over FashionHotspots - Ontdek jouw ideale fashionwinkels in Nederland</title>
                <meta name="description" content="FashionHotspots verbindt shoppers met de leukste lokale winkels. Zoek op stad, voor wie, merk, stijl, specialisten en/of item en ontdek de meest ideale fashionwinkels"/>
                <meta name="keywords" content="fashion hotspots, team, over ons"/>
            </Helmet>

            <div className="text-content" dangerouslySetInnerHTML={{ __html: content! }} />
        </div>
    );
};

export default AboutUsPage;
