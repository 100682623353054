import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../../contexts/AuthContext';
import { ToastContext } from '../../../contexts/ToastContext';
import Coupon from "../../../models/storemodels/Coupon";
import RequestHelper from '../../../requests/RequestHelper';
import RestEndpoint from '../../../requests/RestEndpoint';
import CouponComponent, { CouponModel } from "../../utils/Coupon";
import ImageUpload from '../../utils/ImageUpload';
import Close from "./../../../assets/icons/close.svg";
import Star from "./../../../assets/icons/favorite_store.svg";
import Swipe from "./../../../assets/icons/swipe.svg";
import CouponExpand from "../../utils/Coupon";
import ExpandedCoupon from '../my-coupons-page/ExpandedCoupon';


interface OwnProps { }

type Props = OwnProps;

export class CouponCreation {
    title: string = "";
    description: string = "";
    conditions: string = "";
    code: string = "";
    expiry_date: Date = new Date(new Date().setDate(new Date().getDate() + 7));
    image?: HTMLImageElement;
    image_url?: string;
    store_id: number = 0;
    count: number = 0;
    times_used?: number;
    created_at: Date = new Date();
    updated_at: Date = new Date();
}

const endpoint = new RestEndpoint('stores');

const CreateCoupon: React.FC<Props> = (props) => {
    const [coupon, setCoupon] = useState<CouponCreation>(new CouponCreation());
    const { user } = useContext(AuthContext);
    const { notify, errorNotification } = useContext(ToastContext);
    const { id } = useParams<{ id: string }>();
    endpoint.resource = `stores/${user?.store?.id}/coupons`;

    useEffect(() => {
        const getCurrentCoupon = async () => {
            // @ts-ignore
            const couponToUpdate: Coupon = await endpoint.find(+id!);
            // @ts-ignore
            setCoupon({ ...couponToUpdate, expiry_date: new Date(couponToUpdate.expiry_date) })
        };

        if (id) {
            getCurrentCoupon();
        }
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCoupon({ ...coupon, [e.target.id]: e.target.value })
    }

    const handleDateChange = (date: Date) => {
        setCoupon({ ...coupon, expiry_date: date })
    }

    const addImage = (image: HTMLImageElement) => {
        setCoupon({ ...coupon, image })
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        endpoint.resource = `stores/${user?.store?.id}/coupons`;

        let expiryDate = coupon.expiry_date.getFullYear() + '-'
            + ('0' + (coupon.expiry_date.getMonth() + 1)).slice(-2) + '-'
            + ('0' + coupon.expiry_date.getDate()).slice(-2);

        try {
            const couponToStoreOrUpdate = { ...coupon };

            if (!couponToStoreOrUpdate.image) {
                delete couponToStoreOrUpdate.image;
            }

            const data = RequestHelper.convertToFormData({ ...couponToStoreOrUpdate, expiry_date: expiryDate });

            if (id) {
                await endpoint.update(data);
            } else {
                await endpoint.store(data);
            }

            notify("Coupon opgeslagen")
            window.location.assign('/winkel-admin/mijn-acties');

        } catch (e) {
            errorNotification(e);
        }
    }

    const setImage64 = (image64: string) => {
        setCoupon({ ...coupon, image_url: image64 });
    }

    return (
        <div className="create-coupon">
            <Helmet>
                <title>FH - Coupon aanmaken.</title>
                <meta name="description" content="Maak een coupon aan." />
            </Helmet>
            
            <h1>FASHION HOTSPOTS COUPON {id ? 'WIJZIGEN' : 'AANMAKEN'}</h1>
            <p className="create-coupon__subtitle">{id && `${coupon.times_used} ${coupon.times_used === 1 ? 'keren' : 'keer'} gebruikt`}</p>

            <div className="create-coupon__form">
                <form onSubmit={handleSubmit}>
                    <h2>Titel</h2>

                    <input className="input" id="title" value={coupon.title} onChange={handleChange}></input>

                    <h2>Omschrijving</h2>

                    <input className="input" id="description" value={coupon.description}
                        onChange={handleChange}></input>

                    <h2>Voorwaarden</h2>

                    <input className="input" id="conditions" value={coupon.conditions} onChange={handleChange}></input>

                    <h2>Afbeelding</h2>
                    <ImageUpload setImage={addImage} currentImageUrl={coupon.image_url} setImage64={setImage64} />

                    <h2>Code</h2>

                    <input className="input" id="code" value={coupon.code} onChange={handleChange}></input>

                    <h2>GELDIG T/M</h2>

                    <DatePicker selected={coupon.expiry_date} onChange={handleDateChange} dateFormat="dd/MM/yyyy" />

                    <button className="create-coupon__button button" type="submit">Coupon Opslaan</button>
                </form>
            </div>

            <div className="create-coupon__preview">
                <h2>Preview coupon</h2>
                <div className="create-coupon__preview-wrapper">
                    <ExpandedCoupon
                        isOpen={true}
                        closeModal={true}
                        coupon={
                            {
                                ...coupon,
                                image_url: coupon.image_url ? coupon.image_url : "",
                                expiry_date: coupon.expiry_date.toDateString(),
                                created_at: coupon.expiry_date.toDateString(),
                                updated_at: coupon.expiry_date.toDateString()
                            }
                        }
                        expiryDate={coupon.expiry_date.toDateString()}
                        inPage={true}
                    />
                </div>
                <h2>PREVIEW WINKEL DETAILPAGINA</h2>
                <div className="create-coupon__preview-wrapper">
                    <CouponComponent
                        disabled={false}
                        title={coupon.title}
                        subtitle={coupon.description}
                        type="FASHION HOTSPOTS AANBIEDING"
                        blueBackground={false}
                    />
                </div>
            </div>

        </div>
    );
}

export default CreateCoupon
