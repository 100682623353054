import React, { FunctionComponent, useState, ChangeEvent } from "react";
import { ISocialMediaAccount } from "./SocialMedia";
import StandardInput from "../../../utils/StandardInput";

const SocialMediaRow: FunctionComponent<{
  account: ISocialMediaAccount;
  changeParent: (id: number, newUrl: string) => void;
}> = (props) => {
  const [value, setValue] = useState(props.account.pivot.url);
  return (
    <div className="row social-media-row">
      <img src={props.account.image_url} alt="" />
      <div className="input">
        <label htmlFor={props.account.name}>{props.account.name}</label>
        <StandardInput
          type="text"
          id={props.account.name}
          value={value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            props.changeParent(
              props.account.pivot.social_media_account_id,
              e.target.value
            );
          }}
        />
      </div>
    </div>
  );
};

export default SocialMediaRow;
