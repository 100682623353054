import React, { FormEvent, ChangeEvent, useState, useContext } from 'react'

// Icons
import { ReactComponent as Arrow } from "../../assets/icons/arrow_right_links.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import ResetPasswordEndpoint from '../../requests/ResetPasswordEndpoint';
import ValidationError from '../utils/ValidationError';
import RequestError from '../../models/RequestError';
import { ToastContext } from '../../contexts/ToastContext';

interface OwnProps {
    showResetPassword: boolean | undefined,
    toggleResetPassword: Function,
    email: string,
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}

type Props = OwnProps;

const endpoint = new ResetPasswordEndpoint();

const ResetPassword: React.FC<Props> = (props) => {
    const [requestError, setRequestError] = useState<RequestError>();
    const { notify, errorNotification } = useContext(ToastContext);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const requestError = await endpoint.sendResetLink(props.email);

        if (requestError) {
            setRequestError(requestError);
            errorNotification(requestError)
        } else {
            props.toggleResetPassword();
            notify("De e-mail is verzonden.")
        }
    }

    return (
        <div id="reset-password" className={props.showResetPassword ? "" : "hidden"}>
            <div className="form-content">
                <div className="form-top">
                    <h2>JE WACHTWOORD VERGETEN?</h2>
                    <Close onClick={() => props.toggleResetPassword()} />
                </div>

                <p>Vul je e-maildres in en we sturen je een e-mail met instructies om je wachtwoord opnieuw in te stellen.</p>

                {requestError && <ValidationError requestError={requestError} />}

                <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="E-mailadres" value={props.email} onChange={props.handleChange} id="email"></input>

                    <button>Verstuur <Arrow /></button>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;