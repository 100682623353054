import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import {Link, useHistory} from "react-router-dom";
import CancelAccount from "../../assets/icons/account_trash.svg";
import Actions from "../../assets/icons/actions.svg";
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow_right_links.svg";
import Contact from "../../assets/icons/contact.svg";
import Coupons from "../../assets/icons/coupons.svg";
import DashboardIcon from "../../assets/icons/dashboard_profile.svg";
import FAQ from "../../assets/icons/faqs.svg";
import {default as PersonalDetails, default as Users} from "../../assets/icons/id_card.svg";
import Store from "../../assets/icons/my_homepage.svg";
import Photos from "../../assets/icons/photos.svg";
import Ratings from "../../assets/icons/ratings.svg";
import Speaker from "../../assets/icons/speaker.svg";
import FavoriteStores from "../../assets/icons/store_favourites.svg";
import Stores from "../../assets/icons/store_green_menu.svg";
import Tags from "../../assets/icons/tags.svg";
import {AuthContext} from "../../contexts/AuthContext";
import {NewsContext} from "../../contexts/NewsContext";
import {PageContentContext} from "../../contexts/PageContentContext";
import DashboardCard from "../utils/DashboardCard";
import {storeToUrl} from "../utils/StoreHelper";
import {removeSpecialChars} from "../utils/UrlHelper";

const Dashboard = () => {
    const history = useHistory();
    const {newsItems} = useContext(NewsContext);
    const {user, isShopper, isStoreOwner} = useContext(AuthContext);

    const redirectToNews = (title: string) => history.push(`/nieuws/${removeSpecialChars(title)}`);

    const {pageContents} = useContext(PageContentContext);
    const showNewsItems = () => {
        if (!newsItems) return;

        let sortedByDate = newsItems.sort((d1, d2) => d1?.created_at > d2?.created_at ? -1 : d1?.created_at < d2?.created_at ? 1 : 0);

        sortedByDate = [sortedByDate[0], sortedByDate[1], sortedByDate[2]];

        return sortedByDate.map((newsItem) => {
            if (!newsItem) return undefined;

            const date = new Date(newsItem.created_at);

            return (<div
                className="news-card-container"
                    onClick={() => redirectToNews(newsItem.title)}
                    key={newsItem.title}
                >
                    <div className="news-card">
                        <div
                            className="image"
                            style={{backgroundImage: `url(${newsItem.image_url})`}}
                        ></div>
                        <div className="text">
                            <p className="item-title">{newsItem.title}</p>
                            <p className="date">{date.toLocaleDateString('nl', {day: 'numeric', month: 'long', year: 'numeric'})}</p>
                        </div>
                    </div>
                </div>);
        });
    };

    const dashboardHTML = (location: string) => {
        const content = pageContents.find(content => content.location === location)?.content;

        if (content) return content;

        return "";
    }

    const introductionForRole = () => {
        if (isShopper()) {
            return dashboardHTML('shopper-admin/introduction');
        }
        if (isStoreOwner()) {
            return dashboardHTML('store-admin/introduction');
        }

        return dashboardHTML('admin/introduction');
    }

    const generateCarsForRole = () => {
        switch (user?.role_id) {
            case 1:
                return (<>
                        <DashboardCard
                            title="Filteropties en Tags"
                            icon={Tags}
                            path="/admin/categorie-beheer"
                        >
                            <p>{dashboardHTML("admin/manage-categories")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Nieuws- en Persberichten"
                            icon={Speaker}
                            path="/admin/beheer-nieuws"
                        >
                            <p>{dashboardHTML("admin/manage-news")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="FAQ Vragenbeheer"
                            icon={FAQ}
                            path="/admin/veelgestelde-vragen"
                        >
                            <p>{dashboardHTML("admin/manage-faq")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Afbeelding- en Bannerbeheer"
                            icon={Contact}
                            path="/admin/beheer-banners"
                        >
                            <p>{dashboardHTML("admin/manage-banners")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Fashion Hotspots Beheer"
                            icon={Contact}
                            path="/admin/fashion-hotspots"
                        >
                            <p>{dashboardHTML("admin/manage-fashion-hotspots")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Beheer Winkel van de Week"
                            icon={FavoriteStores}
                            path="/admin/beheer-winkel-van-de-week"
                        >
                            <p>{dashboardHTML("admin/manage-store-of-the-week")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Winkelbeheer"
                            icon={Stores}
                            path="/admin/beheer-winkels"
                        >
                            <p>{dashboardHTML("admin/manage-stores")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Horecabeheer"
                            icon={Stores}
                            path="/admin/beheer-horecas"
                        >
                            <p>{dashboardHTML("admin/manage-horecas")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Shopperbeheer"
                            icon={Users}
                            path="/admin/shoppers"
                        >
                            <p>{dashboardHTML("admin/manage-shoppers")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Adminbeheer"
                            icon={Users}
                            path="/admin/admins"
                        >
                            <p>{dashboardHTML("admin/manage-admins")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Stadsbeheer"
                            icon={Users}
                            path="/admin/beheer-steden"
                        >
                            <p>{dashboardHTML("admin/manage-cities")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Page content"
                            icon={Users}
                            path="/admin/pagina-inhoud"
                        >
                            <p>{dashboardHTML("admin/manage-page-content")}</p>
                        </DashboardCard>
                    </>);
            case 2:

                const isHoreca = user?.store?.is_horeca || false;

                return (<>
                        <DashboardCard
                            title="Mijn account gegevens"
                            icon={PersonalDetails}
                            path="/winkel-eigenaar-details"
                        >
                            <p>{dashboardHTML("store-admin/store-owner-details")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title={isHoreca ? "Mijn horecagegevens" : "Mijn winkelgegevens"}
                            icon={PersonalDetails}
                            path={`/winkel-details/${isHoreca ? 'horeca' : user?.store?.id ? '' : 'nieuw'}`}
                        >
                            <p>{dashboardHTML(`store-admin/store-details`)}</p>
                        </DashboardCard>

                        {!isHoreca && <DashboardCard
                            title="Mijn foto's"
                            icon={Photos}
                            path="/winkel-admin/afbeeldingen"
                        >
                            <p>{dashboardHTML("store-admin/images")}</p>
                        </DashboardCard>}

                        <DashboardCard
                            title="Mijn acties"
                            icon={Actions}
                            path="/winkel-admin/mijn-acties"
                        >
                            <p>{dashboardHTML("store-admin/coupons-discounts")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Mijn tags"
                            icon={Tags}
                            path="/winkel-admin/kleding-categorieen"
                        >
                            <p>{dashboardHTML("store-admin/clothing-categories")}</p>
                        </DashboardCard>

                        {!isHoreca && <DashboardCard
                            title="Mijn recensies"
                            icon={Ratings}
                            path="/winkel-admin/mijn-recensies"
                        >
                            <p>{dashboardHTML("store-admin/store-reviews")}</p>
                        </DashboardCard>}

                        {!isHoreca && <DashboardCard
                            title="Mijn winkelpagina"
                            icon={Store}
                            path={`/winkels/${storeToUrl(user.store)}`}
                        >
                            <p>{dashboardHTML("store-admin/store-page")}</p>
                        </DashboardCard>}

                        <DashboardCard
                            title="Veelgestelde vragen"
                            icon={FAQ}
                            path="/veelgestelde-vragen">
                            <p>{dashboardHTML("store-admin/faq")}</p>
                        </DashboardCard>

                        <DashboardCard
                            title="Contact Fashion Hotspots"
                            icon={Contact}
                            path="/contact"
                        >
                            <p>{dashboardHTML("store-admin/contact")}</p>
                        </DashboardCard>
                    </>);
            case 3:
            default:
                return (<>
                        <DashboardCard
                            title="Persoonlijke gegevens"
                            icon={PersonalDetails}
                            path="/mijn-gegevens"
                        >
                            <p>{dashboardHTML("shopper-admin/personal-details")}</p>
                        </DashboardCard>
                        <DashboardCard
                            title="Mijn favoriete winkels"
                            icon={FavoriteStores}
                            path="/favoriete-winkels"
                        >
                            <p>{dashboardHTML("shopper-admin/favorite-stores")}</p>
                        </DashboardCard>
                        <DashboardCard
                            title="Mijn recensies"
                            icon={Ratings}
                            path="/mijn-recensies"
                        >
                            <p>{dashboardHTML("shopper-admin/my-reviews")}</p>
                        </DashboardCard>
                        <DashboardCard
                            title="Mijn coupons"
                            icon={Coupons}
                            path="/mijn-coupons"
                        >
                            <p>{dashboardHTML("shopper-admin/my-coupons")}</p>
                        </DashboardCard>
                        <DashboardCard title="Veelgestelde vragen" icon={FAQ} path="/veelgestelde-vragen">
                            <p>{dashboardHTML("shopper-admin/faq")}</p>
                        </DashboardCard>
                        <DashboardCard
                            title="Mijn account opzeggen"
                            icon={CancelAccount}
                            path="/account-opzeggen"
                        >
                            <p>{dashboardHTML("shopper-admin/cancel-account")}</p>
                        </DashboardCard>
                        <DashboardCard title="Contact" icon={Contact} path="/contact">
                            <p>{dashboardHTML("shopper-admin/contact")}</p>
                        </DashboardCard>
                    </>);
        }
    };

    const returnName = () => {
        switch (user?.role_id) {
            case 1:
                return "Admin"
            case 2:
                return user?.store?.name
            case 3:
                return user?.first_name
        }
    }

    return (<div className="dashboard">
            <Helmet>
                <title>FH - Dashboard</title>
            </Helmet>

            <div className="dashboard-header">
                <img src={DashboardIcon} alt="Mijn Dashboard" className="header-icon"/>
                <div className="header-text">
                    <h2>
                        Hallo {returnName()},
                    </h2>
                    <p className="subtitle">welkom in je dashboard!</p>
                    <div className="dashboard-info" dangerouslySetInnerHTML={{__html: introductionForRole()!}}></div>

                </div>
            </div>
            <div className="dashboard-cards">{generateCarsForRole()}</div>
            <div className="home-news">
                <div className="news-items">{showNewsItems()}</div>

                <Link to="/nieuws" className="more-news">
                    Meer fashion news <ArrowRight/>
                </Link>
            </div>
        </div>);
};

export default Dashboard;
