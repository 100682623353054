import React, { FunctionComponent, useContext } from 'react';
import { AuthContext } from "../../contexts/AuthContext";
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface OwnProps extends RouteProps {
    isRestricted: boolean;
}

type Props = OwnProps;

const PublicRoute: FunctionComponent<Props> = (props) => {

    const { isAuthenticated, user } = useContext(AuthContext);

    if (isAuthenticated() && props.isRestricted) {
        return user?.role_id == 2 ? <Redirect to="/dashboard" /> : <Redirect to="/" />
    }
    return <Route {...props} />;
};

export default PublicRoute;
