import React, { useEffect, useState } from 'react'
import RestEndpoint from '../../../requests/RestEndpoint';
import RequestError from '../../../models/RequestError';
import PageContent from '../../../models/PageContent';
import ValidationError from '../../utils/ValidationError';
import 'draft-js/dist/Draft.css';
import { Helmet } from 'react-helmet';
import PageContentList from './page-content/PageContentList';

interface OwnProps {

}

type Props = OwnProps;

const endpoint = new RestEndpoint('page-contents');

const AdminPageContent: React.FC<Props> = (props) => {
    const [content, setContent] = useState<PageContent[]>([]);
    const [requestError, setRequestError] = useState<RequestError>();

    const getContent = async () => {
        try {
            const content = await endpoint.all();

            // @ts-ignore
            setContent(content);
        } catch (e) {
            setRequestError(e);
        }
    }

    useEffect(() => {
        getContent();
    }, [])

    if (!content) return <div></div>;

    return (
        <div id="admin-page-content">
            <Helmet>
                <title>Superuser - Content beheer</title>
            </Helmet>

            {requestError && <ValidationError requestError={requestError} />}

            <PageContentList
                content={content}
                group={null}
                pageTitle="Algemeen"
                endpoint={endpoint}
            />

            <PageContentList
                content={content}
                group="dashboard-shopper"
                pageTitle="Dashboard Shopper"
                endpoint={endpoint}
            />

            <PageContentList
                content={content}
                group="dashboard-store"
                pageTitle="Dashboard Store"
                endpoint={endpoint}
            />

            <PageContentList
                content={content}
                group="dashboard-admin"
                pageTitle="Dashboard Admin"
                endpoint={endpoint}
            />
        </div>
    )
}

export default AdminPageContent
