import Model from "./Model";

class NewsItemUpdate extends Model {
  title: string = "";
  label: string = "";
  content: string = "";
  image?: HTMLImageElement = undefined;

  constructor(newsItem?: NewsItemUpdate) {
    super();
    Object.assign(this, newsItem);
  }
}

export default NewsItemUpdate;
