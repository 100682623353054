import React, { FunctionComponent } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { createContext } from 'react';
import RequestError from '../models/RequestError';

interface IToastContext {
    notify: (msg: string, type?: "warning" | "error") => void,
    errorNotification: (requestError: RequestError) => void
}

export const ToastContext = createContext({} as IToastContext);

interface OwnProps {

}

type Props = OwnProps;

const ToastContextProvider: FunctionComponent<Props> = (props) => {
    const notify = (msg: string, type?: string) => {
        switch (type) {
            case "warning":
                toast.warning(msg);
                break;
            case "error":
                toast.error(msg);
                break;
            default:
                toast(msg);
        }
    }

    const errorNotification = (requestError: RequestError) => {
        requestError.errors && Object.values(requestError.errors).map((error: any) => console.log(error));
        requestError.errors ?
            Object.values(requestError.errors).map((error: any) => notify(error[0], "error"))
            :
            requestError.message && notify(requestError.message, "error")
    }

    return (
        <ToastContext.Provider value={{ notify: notify, errorNotification }}>
            {props.children}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </ToastContext.Provider>
    )
}

export default ToastContextProvider;