import RestEndpoint from "./RestEndpoint";

class ShopperEndpoint extends RestEndpoint {
    constructor() {
        super('shoppers')
    }

    getShopperReview(id: number, reviewId: number) {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}/${id}/reviews/${reviewId}`)
    }

    getShopperReviews(id: number) {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}/${id}/reviews`)
    }

    getShopperInfo(id: number) {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}/${id}`)
    }

    deleteShopperReview(id: number, reviewId: number) {
        return this.requestHelper.destroy(`${this.endpoint}/${this.resource}/${id}/reviews/${reviewId}`)
    }

    updateShopperReview(id: number, reviewId: number, data: any) {
        return this.requestHelper.put(`${this.endpoint}/${this.resource}/${id}/reviews/${reviewId}`, data)
    }
}

export default ShopperEndpoint;