import React, {ChangeEvent, FormEvent, FunctionComponent, useContext, useEffect, useRef, useState} from 'react';

import {ReactComponent as ArrowRight} from "../../assets/icons/arrow_right_links.svg";
import ContactEndpoint from '../../requests/ContactEndpoint';
import ContactData from '../../models/ContactData';
import RequestError from '../../models/RequestError';
import ValidationError from '../utils/ValidationError';
import {Helmet} from 'react-helmet';
import {AuthContext} from '../../contexts/AuthContext';
import Select from "react-select";
import {ValueType} from "react-select/src/types";
import {PageContentContext} from "../../contexts/PageContentContext";

interface OwnProps {
}

type Props = OwnProps;

const endpoint = new ContactEndpoint();

const contactDataInitial = {
    name: "",
    email: "",
    subject: "Account",
    question: ""
}

const ContactPage: FunctionComponent<Props> = (props) => {
    const {user} = useContext(AuthContext);
    const { pageContents } = useContext(PageContentContext);

    const [contactData, setContactData] = useState(new ContactData());
    const [requestError, setRequestError] = useState<RequestError>();
    const [showNotification, setNotification] = useState<boolean>();

    const content = pageContents.find(pageContent => pageContent.location === 'contact')?.content;

    const form = useRef<HTMLFormElement>(null);
    const questionRef = useRef<HTMLTextAreaElement>(null);
    const contactOptions = [
        {value: 'Account', label: 'Account'},
        {value: 'Klacht', label: 'Klacht'},
        {value: 'Winkel', label: 'Winkel'},
        {value: 'Samenwerking', label: 'Samenwerking'},
        {value: 'Reclame', label: 'Reclame'},
        {value: 'Vraag', label: 'Vraag'},
        {value: 'Tip', label: 'Tip'},
        {value: 'Opmerking', label: 'Opmerking'},
        {value: 'Overig', label: 'Overig'},
    ];

    const submitHandler = async (e: FormEvent) => {
        e.preventDefault();

        const requestError = await endpoint.sendContactData(contactData)

        if (requestError) {
            setRequestError(requestError);
        } else {
            setContactData(contactDataInitial)
            setNotification(true);
            setRequestError(undefined);
        }
    }

    const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
        setContactData({...contactData, [e.target.id]: e.target.value});
    }

    const handleSelectChange = (option: ValueType<any>) => {
        setContactData({...contactData, subject: option.value});
    };

    useEffect(() => {
        if (user) {
            setContactData({...contactData, name: user.first_name, email: user.email, subject: "Account"});
        } else {
            setContactData({...contactData, subject: "Account"});
        }

        if (questionRef.current) questionRef.current.style.height = `${questionRef.current.scrollHeight}px`;

        setNotification(false);
    }, []);

    useEffect(() => {
        if (questionRef.current) questionRef.current.style.height = `${questionRef.current.scrollHeight - 6.5}px`;
    }, [questionRef.current])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showNotification])


    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
    }

    return (
        <div id="contact">
            <Helmet>
                <title>Neem contact op met FashionHotspots: Contactformulier</title>
                <meta name="description" content="Log in op je FashionHotspots account en neem contact met ons op via het contactformulier of vind je antwoord bij veelgestelde vragen"/>
                <meta name="keywords" content="fashion hotspots, contact"/>
            </Helmet>
            {
                showNotification &&
                <div className="notification-container">
                    <div className="sent-notification">
                        <h2>JE BERICHT IS VERZONDEN</h2>
                        <p>
                            Bedankt voor het invullen van ons formulier.
                            We hebben een bevestiging naar je e-mailadres gestuurd.
                        </p>
                        <p>
                            We proberen je vraag zo snel mogelijk te behandelen.
                        </p>
                        <button className="close" onClick={() => setNotification(false)}>Sluiten <ArrowRight/></button>
                    </div>
                </div>
            }

            <form onSubmit={(e) => submitHandler(e)} ref={form}>

                <div dangerouslySetInnerHTML={{ __html: content! }} />

                {requestError && <ValidationError requestError={requestError}/>}

                <h3 className="required-field">Persoonlijke gegevens</h3>

                <input
                    placeholder="Naam"
                    id="name"
                    name="name"
                    value={contactData?.name}
                    onChange={(e) => handleChange(e)}
                    required
                />

                <input
                    placeholder="E-mailadres"
                    id="email"
                    name="email"
                    value={contactData?.email}
                    onChange={(e) => handleChange(e)}
                    required
                />

                <h3 className="required-field">Onderwerp</h3>

                <Select
                    options={contactOptions}
                    onChange={handleSelectChange}
                    placeholder="Selecteer een onderwerp"
                    value={contactOptions.find(option => option.value === contactData?.subject)}
                    className={'contact__select-container'}
                    classNamePrefix ={'contact__select'}
                    id="subject"
                    name="subject"
                />

                <h3 className="required-field">Vraag</h3>
                <textarea
                    placeholder="Vraag"
                    rows={6}
                    id="question"
                    name="question"
                    ref={questionRef}
                    value={contactData?.question}
                    onChange={(e) => handleChange(e)}
                    required
                />

                <button type="submit">Verstuur <ArrowRight/></button>
            </form>
        </div>
    );
};

export default ContactPage;
