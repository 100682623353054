import React, { useContext, useEffect, useRef } from 'react';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down_links.svg';
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { CityContext } from '../../../contexts/CityContext';
import { FilterCategoriesContext } from '../../../contexts/FilterCategoriesContext';
import FilterCategory from '../../../models/FilterCategory';
import { FiltersMap } from '../../utils/Filters';
import { Sort } from '../CityOverview';
import SortListMobile from './filter-menu-components/filter-menu-category-components/SortListMobile';
import FilterMenuCategory from './filter-menu-components/FilterMenuCategory';

/**
 * The interface for FilterMenu.tsx
 */
interface IProps {
    filters: FiltersMap;
    toggleFilter: (key: string, value: string) => void;
    setCurrentPage: (pageNumber: number) => void;
    getSort: () => Sort;
    setSort: (sort: Sort) => void;
    hideMenu?: () => void;
    reference?: React.RefObject<HTMLDivElement>;
    areFiltersEmpty: () => boolean;
    clearAll: () => void;
    clearFilter: (key: string) => void;
}

/**
 * This component contains all the filtercategories with their items, either checked or unchecked,.
 * @component
 * @author Sem van Koutrik
 * @param filters all the filters with their items. 
 * @param toggleFilters setStateAction for filters.
 * @param getSort unique filter for sorting. It is set ouside of FilterMenu.tsx, because it has two values instead of one which need to be set at the same time.
 * @param setSort setStateAction for sort.
 */
const FilterMenu: React.FC<IProps> = (
    {
        filters,
        toggleFilter,
        getSort,
        setSort,
        hideMenu,
        reference,
        areFiltersEmpty,
        clearAll,
        clearFilter
    }
) => {
    const { filterCategories } = useContext(FilterCategoriesContext);
    const { cities } = useContext(CityContext);

    const filterMenuRef = useRef<HTMLDivElement>(null);
    const sortCategoryRef = useRef<HTMLDivElement>(null);

    const mqDesktop = window.matchMedia('(min-width: 960px)');

    /**
     * Converts the array of cities to an, by FilterMenuCategory, usable filter.
     */
    const citiesAsFilterCategory: FilterCategory = {
        id: 0,
        name: "Steden",
        category: "stad",
        clothing_categories: cities.map(city => {
            return { name: city.name, order_id: city.id, filter_category_id: city.id, }
        })
    };

    const toggleSortFold = () => {
        if (sortCategoryRef.current) sortCategoryRef.current.classList.toggle("filter-menu-category--close");
    }

    /**
     * The filtermenu will close itself if the user did not click inside the filter-menu.
     * @returns Removes the eventlistener if the menu is gone.
     */
    useEffect(() => {
        var documentListener = (e: MouseEvent) => {
            if (filterMenuRef.current && !filterMenuRef.current.contains(e.target as Node) && hideMenu) {
                hideMenu();
            }
        };

        document.addEventListener("click", documentListener)

        return () => {
            document.removeEventListener("click", documentListener);
        }
    }, []);

    return (
        <div className="filter-menu" ref={reference}>
            {
                mqDesktop.matches ?
                    <div className="filter-menu__desktop-header">
                        <p className="filter-menu__desktop-header-title">Verfijn</p>

                        {areFiltersEmpty() && <button onClick={() => clearAll()} className="filter-menu__restore-button">Herstel alles <Close /></button>}
                    </div>
                    :
                    <div className={mqDesktop.matches ? "filter-menu-category" : "filter-menu-category filter-menu-category--close"}
                        id="sortOutput"
                        ref={sortCategoryRef}
                    >
                        <div className="filter-menu-category__top" onClick={toggleSortFold}>
                            <p className="filter-menu-category__title">Sorteer</p>
                            {!mqDesktop.matches && <ArrowDown className="filter-menu-category__arrow" />}
                        </div>

                        <SortListMobile getSort={getSort} setSort={setSort} />
                    </div>
            }

            {
                filterCategories.map((category, i) => {
                    return (
                        <FilterMenuCategory
                            key={i}
                            category={category}
                            filters={filters}
                            searchable={category.category === "merken"}
                            listByChar={!mqDesktop.matches && category.category === "merken"}
                            orderByChar={category.category === "merken" || category.category === "items"}
                            clearFilter={clearFilter}
                            toggleFilter={toggleFilter}
                        />
                    )
                })
            }


            <FilterMenuCategory
                category={citiesAsFilterCategory}
                filters={filters}
                searchable={false}
                listByChar={false}
                orderByChar={true}
                clearFilter={clearFilter}
                toggleFilter={toggleFilter}
            />
        </div>

    )
}

export default FilterMenu;
