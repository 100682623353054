import Model from "./Model";
import ClothingCategory from "./storemodels/ClothingCategory";
import Coupon from "./storemodels/Coupon";
import ExtraService from "./storemodels/ExtraService";
import Image from "./storemodels/Image";
import OpeningHoursForDay from "./storemodels/OpeningHoursForDay";
import OpeningHoursForHoliday from "./storemodels/OpeningHoursForHoliday";
import PaymentMethod from "./storemodels/PaymentMethod";
import SocialMediaAccount from "./storemodels/SocialMediaAccount";
import TravelOption from "./storemodels/TravelOption";

class Store extends Model {
    id: number = 0;
    user_id: number = -1;
    city_id: number = -1;
    name: string = "";
    email: string = "";
    information: string = "";
    short_description: string = "";
    street: string = "";
    house_number: string = "";
    zip_code: string = "";
    city: string = "";
    country: string = "";
    phone: string = "";
    website: string = "";
    dummy: boolean = false;
    is_horeca: boolean = false;

    latitude!: number;
    longitude!: number;

    rating: number = -1;
    logo_url: string = "";
    coupons: Coupon[] = [];
    created_at: string = '';
    updated_at: string = '';

    main_image_url: string = '';
    images: Image[] = [];
    number_of_reviews: number = 0;
    payment_methods: PaymentMethod[] = [];
    clothing_categories: ClothingCategory[] = [];
    social_media_accounts: SocialMediaAccount[] = [];
    opening_hours_for_days: OpeningHoursForDay[] = [];
    opening_hours_for_holidays: OpeningHoursForHoliday[] = [];
    travel_options: TravelOption[] = [];
    extra_services: ExtraService[] = [];

    constructor(store?: Store) {
        super();
        Object.assign(this, store);
    }
}

export default Store;
