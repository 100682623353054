import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import Banner from "../models/Banner";

interface OwnProps {

}

interface IBannerContext {
    banners: Banner[],
    getAllBanners: () => Promise<void>
}

const bannerEndPoint = new RestEndpoint('banners');

export const BannerContext = createContext({} as IBannerContext);

type Props = OwnProps;

const BannerContextProvider: FunctionComponent<Props> = (props) => {
    const [banners, setBanners] = useState<Banner[]>([]);

    useEffect(() => {
        getAllBanners().then()
    }, [])

    const getAllBanners = async (): Promise<void> => {
        // @ts-ignore
        const banners: Banner[] = await bannerEndPoint.all();
        // @ts-ignore
        setBanners(banners);
    }

    return (
        <BannerContext.Provider
            value={{banners: banners, getAllBanners: getAllBanners}}>
                {props.children}
        </BannerContext.Provider>
    )
}

export default BannerContextProvider;
