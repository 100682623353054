export function stringToUrl(name: string) {
    return name.toLowerCase().replace(/ /g, "-") || "";
}

export function stringToUrlKeepCase(name: string) {
    return name.replace(/ /g, "-") || "";
}

export function removeSpecialChars(name: string | undefined) {
    if(name == undefined) return "";

    return name.replace(/[^\w\s]/gi, '');
}
