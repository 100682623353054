import RestEndpoint from "./../RestEndpoint";
import Image from "../../models/storemodels/Image";
import FAQSubCategory from "../../models/FAQSubCategory";
import FAQ from "../../models/FAQ";
import RequestHelper from "../RequestHelper";
import FAQCategory from "../../models/FAQCategory";

interface URLNewQuestion {
    faq_category: number,
    faq_subcategory: number
}

interface Question {
    question: string,
    answer: string
}

interface URLQuestion {
    faq_category: number,
    faq_subcategory: number,
    faq: number
}

interface SubCategory {
    name: string,
    header?: string,
    logo?: string
}

interface URLSubCategory {
    faq_category: number,
    faq_subcategory: number
}

class AdminFAQEndpoint extends RestEndpoint {
    constructor() {
        super('faq-categories');
    }

    // Question requests

    getQuestion = (subCat: FAQSubCategory, q: FAQ) => {
        try {
            return this.requestHelper.get(`${this.endpoint}/${this.resource}/${subCat.f_a_q_category_id}/faq-subcategories/${q.f_a_q_sub_category_id}/faqs/${q.id}`);
        } catch (e) {
            throw new Error(e);
        }
    }

    getQuestions = (urlParam: URLSubCategory) => {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}/${urlParam.faq_category}/faq-subcategories/${urlParam.faq_subcategory}/faqs`);
    }

    addQuestion = async (urlParam: URLNewQuestion, question: Question) => {
        try {
            return await this.requestHelper.post(`${this.endpoint}/${this.resource}/${urlParam.faq_category}/faq-subcategories/${urlParam.faq_subcategory}/faqs`, question);
        } catch (e) {
            throw new Error(e);
        }
    }

    updateQuestion = (urlParam: URLQuestion, question: Question) => {
        try {
            return this.requestHelper.put(`${this.endpoint}/${this.resource}/${urlParam.faq_category}/faq-subcategories/${urlParam.faq_subcategory}/faqs/${urlParam.faq}`, question);
        } catch (e) {
            throw new Error(e);
        }
    }

    deleteQuestion = (urlParam: URLQuestion) => {
        try {
            return this.requestHelper.destroy(`${this.endpoint}/${this.resource}/${urlParam.faq_category}/faq-subcategories/${urlParam.faq_subcategory}/faqs/${urlParam.faq}`);
        } catch (e) {
            throw new Error(e);
        }
    }

    // Subcategory requests

    newSubCategory = (category: URLNewQuestion, subCategory: SubCategory) => {
        try {
            return this.requestHelper.post(`${this.endpoint}/${this.resource}/${category}/faq-subcategories`, subCategory);
        } catch (e) {
            throw new Error(e);
        }
    }

    getSubCat = (subCat: FAQSubCategory) => {
        try {
            return this.requestHelper.get(`${this.endpoint}/${this.resource}/${subCat.f_a_q_category_id}/faq-subcategories/${subCat.id}}`);
        } catch (e) {
            throw new Error(e);
        }
    }

    updateSubCategory = (subCat: FAQSubCategory, subCatProps: { name?: string, header?: Image, logo?: Image }) => {
        const subCatFormData = RequestHelper.convertToFormDataExUndefined(subCatProps)
        try {
            return this.requestHelper.put(`${this.endpoint}/${this.resource}/${subCat.f_a_q_category_id}/faq-subcategories/${subCat.id}`, subCatFormData);
        } catch (e) {
            throw new Error(e);
        }
    }

    deleteSubCategory = (subCat: FAQSubCategory) => {
        try {
            return this.requestHelper.destroy(`${this.endpoint}/${this.resource}/${subCat.f_a_q_category_id}/faq-subcategories/${subCat.id}`);
        } catch (e) {
            throw new Error(e);
        }
    }

    getSubCategories = (urlParam: URLSubCategory) => {
        try {
            return this.requestHelper.get(`${this.endpoint}/${this.resource}/${urlParam.faq_category}/faq-subcategories/${urlParam.faq_subcategory}`);
        } catch (e) {
            throw new Error(e);
        }
    }

    storeSubCategory = (subCat: { faq_category: number, name: string, header?: Image, logo?: Image }) => {
        const subCatFormData = RequestHelper.convertToFormDataExUndefined({ name: subCat.name, header: subCat.header, logo: subCat.logo })

        try {
            return this.requestHelper.post(`${this.endpoint}/${this.resource}/${subCat.faq_category}/faq-subcategories`, subCatFormData);
        } catch (e) {
            throw new Error(e);
        }
    }

    getMainCat = (mainCat: FAQCategory) => {
        try {
            return this.requestHelper.get(`${this.endpoint}/${this.resource}/${mainCat.id})`);
        } catch (e) {
            throw new Error(e);
        }
    }
}

export default AdminFAQEndpoint;