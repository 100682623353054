import React, {FunctionComponent, useContext} from 'react';
import {PageContentContext} from "../../contexts/PageContentContext";
import { Helmet } from 'react-helmet';

interface OwnProps {}

type Props = OwnProps;

const TermsAndConditionsPage: FunctionComponent<Props> = (props) => {
    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'terms-and-conditions')?.content;

    return (
        <div>
            <Helmet>
                <title>Algemene voorwaarden - FashionHotspots</title>
                <meta name="description" content="Alle algemene voorwaarden van FashionHotspots."/>
                <meta name="keywords" content="fashion hotspots, terms and conditions, algemene voorwaarden" />
            </Helmet>
            <div className="text-content" dangerouslySetInnerHTML={{ __html: content! }} />
        </div>
    );
};

export default TermsAndConditionsPage;
