import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import Account from "../../../assets/icons/account.svg";
import Close from "../../../assets/icons/close.svg";
import Review from "../../../models/storemodels/Review";
import Store from "../../../models/Store";
import ReviewResponse from "./ReviewResponse";
import AddReview from "./../../../assets/icons/add-review.svg";
import {StoreContext} from "../../../contexts/StoreContext";
import RestEndpoint from "../../../requests/RestEndpoint";
import {generateDate} from "../../utils/DateTimeHelper";
import {ToastContext} from "../../../contexts/ToastContext";
import Popup from "../../utils/Popup";

const StoreReviewItem: FunctionComponent<{
    review: Review;
    store: Store | undefined;
    deleteReview: any;
    ownStore: Store | null;
    updateReviews: any;
}> = (props) => {
    const {stores} = useContext(StoreContext);
    const reviewDate = generateDate(props.review.created_at || "");
    const {notify} = useContext(ToastContext);
    const [showDelete, setShowDelete] = useState(false);

    const [responseStore, setResponseStore] = useState<{
        id?: number;
        name?: string;
        logo?: string;
    } | null>(null);
    let responseDate = null;

    if (props.review.child_review) {
        responseDate = generateDate(props.review.child_review.created_at || "");
    }

    useEffect(() => {
        if (props.review.child_review) {
            const targetStore = stores.find(
                (store) => store.id === props.review.child_review?.store_id
            );
            setResponseStore({
                id: props.review.child_review.store_id,
                name: targetStore?.name,
                logo: targetStore?.logo_url,
            });
        }
    }, [setResponseStore]);

    const deleteReview = async (id?: number) => {
        const endpoint = new RestEndpoint(
            `stores/${props.review.store_id}/reviews`
        );
        await endpoint.destroy({id});

        props.updateReviews();
        notify("Reactie verwijderd.");


    };

    const newResponse = (id: string) => {
        const element = document.getElementById(id);

        element?.classList.toggle("hidden");

        const button = document.getElementById(id + "_button");
        button?.classList.add("hidden");
    };

    const dateOptions = {month: "long", day: "numeric", year: "numeric"};

    return (
        <div className="review-shop" key={`review_${props.review.title}`}>
            <Popup
                title={`Reactie verwijderen?`}
                buttonText="Verwijderen"
                buttonFunction={() => {
                    deleteReview(props.review?.child_review?.id);
                    setShowDelete(false);
                }}
                show={showDelete}
                setShow={setShowDelete}
            />
            <div className="review-image">
                <img src={Account} alt=""/>
                <h5 className="my-review-response-title mobile-hidden">
                    {props.review.user_name}
                </h5>
                <p className="subtitle">
                    {props.review.user_number_of_reviews}{" "}
                    {props.review.user_number_of_reviews === 1 ? "recensie" : "recensies"}
                </p>
            </div>
            <div className="shop-body">
                <div className="review">
                    <div className="review-header">
                        <div className="review-header-row">
                            <h5 className="my-review-title">{props.review.title}</h5>
                            <p className="my-review-grade">
                                <span className="my-review-rating">{props.review.rating}</span>
                                /10
                            </p>
                        </div>

                        <p className="my-review-date">
                            {reviewDate.toLocaleDateString("nl-NL", dateOptions)}
                        </p>
                    </div>
                    <div className="review-body">
                        <i className="my-review-text">{props.review.content}</i>
                    </div>
                    {props.review.child_review ? (
                        <div className="review-response">
                            <div className="response-image">
                                <img src={responseStore?.logo || Account} alt=""/>
                                <h5 className="my-review-response-title mobile-hidden">
                                    {responseStore?.name}
                                </h5>
                            </div>
                            <div className="response">
                                <div className="response-title">
                                    <h5 className="my-review-response-title web-hidden">
                                        {responseStore?.name}
                                    </h5>
                                    <p className="my-review-response-date">
                                        {responseDate?.toLocaleDateString("nl-NL", dateOptions)}
                                    </p>
                                    <img
                                        src={Close}
                                        alt="Verwijder review"
                                        className="my-review-remove"
                                        onClick={() => {
                                            setShowDelete(true);
                                        }}
                                    />
                                </div>

                                <div className="response-body">
                                    <i className="my-review-response-text">
                                        {props.review.child_review.content}
                                    </i>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            id={`review_response_area_${props.review.id}`}
                            className="review-response-area"
                        >
                            <ReviewResponse
                                store={props.ownStore}
                                id={`review_response_input_${props.review.id}`}
                                reviewId={props.review.id || -1}
                                updateReviews={props.updateReviews}
                            />
                            <button
                                id={`review_response_input_${props.review.id}_button`}
                                onClick={() => {
                                    newResponse(`review_response_input_${props.review.id}`);
                                }}
                            >
                                <img src={AddReview} alt=""/>
                                Plaats Reactie
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StoreReviewItem;
