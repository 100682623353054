import React from 'react';
import { SearchResults } from '../../../contexts/SearchContext';
import Store from '../../../models/Store';
import CityOverviewStore from './CityOverviewStore';

interface IProps {
    searchResults: SearchResults | undefined,
    store?: Store
}

/**
 * Component for the store of the month above the search results and FilterMenu in CityOverview.
 * @component
 * @author Sem van Koutrik
 * @param {SearchResults} searchResults - Used to check if there is a store of the month and if there is, it then will be used to present this store and create a embedded google maps.
 */
const StoreOfTheMonth: React.FC<IProps> = ({ searchResults, store }) => {
    const mqDesktop = window.matchMedia('(min-width: 960px)');

    if (!store) return <></>; 

    return (
        <>
            {
                mqDesktop.matches &&
                <p className="desktop-title">Winkel van de week</p>
            }

            {
                <div className="store-of-the-month-cont">
                    {mqDesktop.matches && <div className="maps-placeholder">
                        <iframe
                            width="443"
                            height="341"
                            frameBorder="0" style={{ border: 0 }}
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC3P_S4bDe0-0OeBKxqivvHBR4WzwxksXU&q=${store.street}+${store.house_number},${store.city}`}
                            allowFullScreen
                            title="Google Maps"    
                        >
                        </iframe>
                    </div>}
                    <div className="store-of-the-month">
                        {!mqDesktop.matches && <p className="title">Winkel van de week</p>}
                        <CityOverviewStore store={store} dummy={false} />
                    </div>
                </div>
            }
        </>
    )
}

export default StoreOfTheMonth;