import React, {FunctionComponent, useContext, useState} from "react";
import { Link } from "react-router-dom";
import Popup from "../../utils/Popup";
import Star from "./../../../assets/icons/favorite_store.svg";
import {ToastContext} from "../../../contexts/ToastContext";

const FavoriteStoreItem: FunctionComponent<{
    link: string;
    storeId: number;
    title: string;
    removeFavorite: any;
    main_image_url: string;
}> = (props) => {
    const [showDelete, setShowDelete] = useState(false);
    const { notify } = useContext(ToastContext)

    return (
        <div className="favorite-stores-item" key={"favorite_store_" + props.title}>
            <Link to={props.link} key={"favorite_store_" + props.title + "_link"}>
                <img
                    src={props.main_image_url}
                    alt=""
                    key={"favorite_store_" + props.title + "_image"}
                    className="store-image"
                />
            </Link>
            
            <img
                src={Star}
                alt="Verwijderen"
                className="delete"
                onClick={() => {
                    setShowDelete(true);
                }}
            />

            <Link
                className="favorite-stores-name"
                to={props.link}
                key={"favorite_store_" + props.title + "_title"}
            >
                {props.title}
            </Link>

            <Popup
                title={`${props.title} als favoriete winkel verwijderen?`}
                buttonText="Verwijderen"
                buttonFunction={() => {
                    props.removeFavorite(props.storeId);
                    notify(`${props.title} als favoriete winkel verwijderd.`);
                }}
                show={showDelete}
                setShow={setShowDelete}
            />
        </div>
    );
};

export default FavoriteStoreItem;
