import React, {FunctionComponent, useCallback, useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {Link, useLocation, useParams} from "react-router-dom";
import {AuthContext} from "../../../contexts/AuthContext";
import {CityContext} from "../../../contexts/CityContext";
import {PageContentContext} from "../../../contexts/PageContentContext";
import {StoreContext} from "../../../contexts/StoreContext";
import {ToastContext} from "../../../contexts/ToastContext";
import RequestError from "../../../models/RequestError";
import Store from "../../../models/Store";
import RequestHelper from "../../../requests/RequestHelper";
import RestEndpoint from "../../../requests/RestEndpoint";
import ExtraServices from "./ExtraServices";
import GeneralInfo from "./GeneralInfo";
import OpeningTimes from "./OpeningTimes";
import PaymentMethods from "./PaymentMethods";
import SocialMedia from "./SocialMedia";
import StoreError from "./StoreError";
import TravelInfo from "./TravelInfo";
import {storeToUrl} from "../../utils/StoreHelper";

interface StoreUpdate {
    id: number;
    city_id: number;
    user_id: number;
    name: string;
    information: string;
    short_description: string;
    street: string;
    house_number: string;
    zip_code: string;
    latitude: number;
    longitude: number;
    phone: string | null;
    website: string | null;
    logo: HTMLImageElement | null;
    is_horeca: number;
}

const storeEndpoint = new RestEndpoint("stores");
const horecaEndpoint = new RestEndpoint("horecas");

const StoreDetails: FunctionComponent = () => {
    const [formData, setFormData] = useState<Store>(new Store());
    const [image, setImage] = useState<HTMLImageElement | null>(null);
    const [error, setError] = useState<RequestError | null>(null);

    const {errorNotification} = useContext(ToastContext);
    const {cities} = useContext(CityContext);
    const {pageContents} = useContext(PageContentContext);
    const {stores, getStores} = useContext(StoreContext);
    const {user, isStoreOwner, isAdmin} = useContext(AuthContext);

    const {id} = useParams<{ id: string }>();

    const location = useLocation();

    const newStore = location.pathname.includes("/nieuw");
    const isHoreca = location.pathname.includes("/horeca");

    const content = pageContents.find(pageContent => pageContent.location === 'store-details')?.content;

    const handleFormChange = (e: any) => {
        const value = e.target.value;
        const field = e.target.id;
        const dataCopy: Store = {...formData};

        setFormData(Object.assign({}, dataCopy, {[field]: value}));
    };

    const handleCitySelect = (e: any) => {
        setFormData({...formData, ...{city_id: e.value}});
    };

    const handleImageUpload = (image: HTMLImageElement) => {
        setImage(image);
    };

    const getGeolocation = async (): Promise<any> => {
        if (cities.length > 0 && formData.city_id > 0) {
            const city = cities.find((city) => city.id === formData.city_id);

            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json` +
                `?address=${formData.street}+${formData.house_number},+${city?.name}&key=AIzaSyCH8Cm7J5Mviekf5AgqgaA6wOmFhL3lYvE`,
                {
                    mode: "cors",
                    credentials: "same-origin",
                }
            );

            const json = await response.json();

            if (json.status === 'REQUEST_DENIED') {
                setError({code: 400, message: 'Kan niet verbinden met google maps'});
                return;
            }

            if (json.results[0].length < 1) {
                setError({code: 400, message: 'Adres is niet bekend'});
                return;
            }

            const {location} = json.results[0].geometry;

            return location;
        }

        console.error("Could not get geo location");
    };

    const submitChanges = async () => {
        const endpoint = new RestEndpoint(`stores`);

        const location = await getGeolocation();

        const updateStore: StoreUpdate = {
            id: formData.id || -1,
            user_id: formData.user_id,
            city_id: formData.city_id,
            name: formData.name,
            information: formData.information,
            short_description: formData.short_description,
            street: formData.street,
            house_number: formData.house_number,
            zip_code: formData.zip_code,
            latitude: location?.lat,
            longitude: location?.lng,
            phone: formData.phone,
            website: formData.website,
            logo: image,
            is_horeca: isHoreca ? 1 : 0,
        };

        const updatedFormData = RequestHelper.convertToFormData(updateStore);
        console.log(updatedFormData);
        try {
            let data: any;

            if (newStore) {
                data = await endpoint.store(updatedFormData);
            } else {
                data = await endpoint.update(updatedFormData);
            }

            if (isStoreOwner()) {
                isHoreca ?
                    window.location.href = '' :
                    window.location.href = '/winkel-details/';
            }

            if (isAdmin()) {
                getStores().then();
                isHoreca ?
                    window.location.href = '/admin/beheer-horecas' :
                    window.location.href = '/winkels/' + storeToUrl(data)
            }
        } catch (e) {
            setError(e);
            errorNotification(e);
        }
    };

    const InitializeFormData = useCallback(async () => {
        if (user) {
            let storeOfUser: Store | undefined;

            if (isHoreca) {
                //@ts-ignore
                const horecas: Store[] = await horecaEndpoint.all();

                storeOfUser = horecas.find((horecaItem) => horecaItem.user_id === user?.id);

            } else {
                if (id) {
                    storeOfUser = stores.find((storeItem) => storeItem.id === +id);
                } else {
                    storeOfUser = stores.find((storeItem) => storeItem.user_id === user?.id);
                }
            }

            if (storeOfUser && storeOfUser.id) {
                //@ts-ignore
                const userStore: Store = await storeEndpoint.find(storeOfUser.id);

                setFormData(userStore);
            }
        }

        if (newStore) {
            let user_id = -1;

            if (isStoreOwner() && user?.id) {
                user_id = user.id;
            } else {
                user_id = location.state.storeOwnerId;
            }

            setFormData({...formData, user_id});
        }
    }, [user, id, stores, storeEndpoint, setFormData])

    useEffect(() => {
        InitializeFormData();
    }, [user, stores, InitializeFormData]);

    if ((!formData.id || formData.id === -1) && !newStore) {
        return <p className="">Loading</p>;
    }

    return (
        <div className="store-details">
            <Helmet>
                <title>FH - {isHoreca ? "Horeca" : "Winkel"}gegevens</title>
                <meta name="description" content={`${isHoreca ? "Horeca" : "Winkel"}gegevens`}/>
            </Helmet>

            <div className="store-details-header">

                <div className="subtitle">
                    <div dangerouslySetInnerHTML={{__html: content!}}/>
                </div>
                {isAdmin() && (
                    <p className="notice"><b>Let op:</b> Je bent ingelogd als beheerder. Op deze pagina kun je de
                        geselecteerde {isHoreca ? "horeca" : "winkel"} beheren. <br/> Klik <Link
                            to={isHoreca ? "/admin/beheer-horecas" : "/admin/beheer-winkels"}>hier</Link> om terug te
                        gaan naar het {isHoreca ? "horeca" : "winkel"}overzicht.</p>
                )}
            </div>

            <div className="store-details-body">
                <div className="store-details-section">
                    <div className="section-header">
                        <h3>
                            Algemene Gegevens
                            <div className="section-icon">
                                <p>|</p>
                            </div>
                        </h3>
                    </div>
                    {error ? <StoreError error={error}/> : ""}

                    <GeneralInfo
                        formData={formData}
                        handleFormChange={handleFormChange}
                        submitChanges={submitChanges}
                        handleCitySelect={handleCitySelect}
                        handleImageUpload={handleImageUpload}
                        isHoreca={isHoreca}
                    />
                </div>
                {!id && (
                    <>
                        <div className="store-details-section">
                            <div className="section-header">
                                <h3>
                                    Social Media
                                    <div className="section-icon">
                                        <p>|</p>
                                    </div>
                                </h3>
                            </div>
                            <SocialMedia formData={formData}/>
                        </div>
                        <div className="store-details-section">
                            <div className="section-header">
                                <h3>
                                    Openingstijden
                                    <div className="section-icon">
                                        <p>|</p>
                                    </div>
                                </h3>
                            </div>
                            <OpeningTimes formData={formData}/>
                        </div>
                        <div className="store-details-section">
                            <div className="section-header">
                                <h3>
                                    Betaalwijzen
                                    <div className="section-icon">
                                        <p>|</p>
                                    </div>
                                </h3>
                            </div>
                            <PaymentMethods formData={formData}/>
                        </div>
                        {
                            !isHoreca && (
                                <div className="store-details-section">
                                    <div className="section-header">
                                        <h3>
                                            Extra services
                                            <div className="section-icon">
                                                <p>|</p>
                                            </div>
                                        </h3>
                                    </div>
                                    <ExtraServices formData={formData}/>
                                </div>
                            )
                        }
                        <div className="store-details-section">
                            <div className="section-header">
                                <h3>
                                    Reisinformatie
                                    <div className="section-icon">
                                        <p>|</p>
                                    </div>
                                </h3>
                            </div>
                            <TravelInfo formData={formData}/>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default StoreDetails;
