import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ReactComponent as DashboardMenu } from "../../../assets/icons/dashboard_menu.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";
import User from '../../../models/User';
import ShopperLinks from './user-menu-components/ShopperLinks';
import StoreLinks from './user-menu-components/StoreLinks';

interface OwnProps {
    user: User;
    logout: Function;
}

type Props = OwnProps;

const UserMenu: React.FC<Props> = (props) => {
    const { user } = props;

    const mqDekstop = window.matchMedia('(min-width: 960px)');

    return (
        <div className="user-menu">
            <Link to="/dashboard">
            {mqDekstop.matches && user.role_id === 1 && <p className="greeting">Hallo {user.first_name}</p>}
            {mqDekstop.matches && user.role_id === 2 && <p className="greeting">Hallo {user.store?.name}</p>}
            {mqDekstop.matches && user.role_id === 3 && <p className="greeting">Hallo {user.first_name}</p>}
            </Link>
            <ul className="user-menu-list">
                <div className="menu-arrow"><span></span></div>

                {user.role_id === 3 && <ShopperLinks />}
                {user.role_id === 2 && <StoreLinks user={user} />}

                <NavLink to={'/dashboard'}><DashboardMenu /> Dashboard</NavLink>
                <Link to={'/'} onClick={() => props.logout()}><Logout /> Uitloggen</Link>
            </ul>
        </div>
    )
}

export default UserMenu;
