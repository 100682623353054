import React, { FunctionComponent, useState, useContext } from "react";
import Category from "../../../models/Category";
import Close from "../../../assets/icons/close.svg";
import Save from "../../../assets/icons/check_register.svg";
import Edit from "../../../assets/icons/edit.svg";
import Up from "../../../assets/icons/arrow_up.svg";
import Down from "../../../assets/icons/arrow_down_links.svg";
import ClothingCategory from "../../../models/storemodels/ClothingCategory";
import RestEndpoint from "../../../requests/RestEndpoint";
import { FilterCategoriesContext } from "../../../contexts/FilterCategoriesContext";
import {ToastContext} from "../../../contexts/ToastContext";

const CategoryItem: FunctionComponent<{
  parent: Category;
  currentCategory: ClothingCategory;
  deleteCategory: (id: number) => void;
  amountOfSubcategories: number;
  firstSubCategory?: number;
  updateCategoryOrder: (categoryId: number, update: string) => Promise<void>;
  refreshCategories: () => Promise<void>;
}> = (props) => {
  const { getFilterCategories } = useContext(FilterCategoriesContext);
  const [value, setValue] = useState(props.currentCategory.name);
  const [isEditing, setIsEditing] = useState(false);
  const { notify } = useContext(ToastContext)

  const submit = async () => {
    const inputValue = (document.getElementById(
      `category_${props.currentCategory.id}_name`
    ) as HTMLInputElement)?.value;
    setValue(inputValue || value);

    const endpoint = new RestEndpoint(
      `categories/${props.parent.id}/clothing-categories`
    );

    const update = {
      id: props.currentCategory.id,
      name: inputValue,
      order: props.currentCategory.order_id,
    };

    await endpoint.update(update);
    await props.refreshCategories();
    setIsEditing(!isEditing);
    notify("Categorie aangepast.");

  };

  const generateOrderArrow = () => {
    if (props.currentCategory.order_id === props.firstSubCategory) {
      return (
        <img
          className="order-arrow"
          src={Down}
          alt=""
          onClick={() => {
            // @ts-ignore
            props.updateCategoryOrder(props.currentCategory.id, "up");
          }}
        />
      );
    }
    if (props.currentCategory.order_id === props.amountOfSubcategories + 1) {
      return (
        <img
          className="order-arrow"
          src={Up}
          alt=""
          onClick={() => {
            // @ts-ignore
            props.updateCategoryOrder(props.currentCategory.id, "down");
          }}
        />
      );
    }
    return (
      <>
        <img
          className="order-arrow"
          src={Up}
          alt=""
          onClick={() => {
            // @ts-ignore
            props.updateCategoryOrder(props.currentCategory.id, "down");
          }}
        />
        <img
          className="order-arrow"
          src={Down}
          alt=""
          onClick={() => {
            // @ts-ignore
            props.updateCategoryOrder(props.currentCategory.id, "up");
          }}
        />
      </>
    );
  };
  return (
    <li key={`${props.parent.name}_subcategory${props.currentCategory.id}`}>
      {props.parent.name === "Wie" ? generateOrderArrow() : <></>}
      {isEditing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <input
            id={`category_${props.currentCategory.id}_name`}
            defaultValue={value}
          ></input>
        </form>
      ) : (
        <p>{value}</p>
      )}
      {isEditing ? (
        <img src={Save} alt="" onClick={submit} />
      ) : (
        <div className="images">
          <img
            src={Edit}
            alt=""
            onClick={() => {
              setIsEditing(true);
            }}
          />
          <img
            src={Close}
            alt=""
            onClick={() => {
              props.deleteCategory(props.currentCategory.id!!);
            }}
          />
        </div>
      )}
    </li>
  );
};

export default CategoryItem;
