import React, {
    FunctionComponent,
    useState,
    useEffect,
    useContext,
} from "react";
import CouponItem from "./my-coupons-page/CouponItem";
import CouponModel from "../../models/storemodels/Coupon";
import RestEndpoint from "../../requests/RestEndpoint";
import { AuthContext } from "../../contexts/AuthContext";
import { Helmet } from "react-helmet";
import { PageContentContext } from "../../contexts/PageContentContext";

const MyCoupons: FunctionComponent = (props) => {
    const { user } = useContext(AuthContext);
    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'my-coupons')?.content;

    const [unusedCoupons, setUnusedCoupons] = useState<CouponModel[]>([]);
    const [usedCoupons, setUsedCoupons] = useState<CouponModel[]>([]);

    const getAllCoupons = async (): Promise<void> => {
        const allCouponsEndpoint = new RestEndpoint(
            `shoppers/${user?.id}/coupons`
        );

        const allCoupons = await allCouponsEndpoint.all();

        //@ts-ignore
        const allUsedCoupons: CouponModel[] = allCoupons.used;
        //@ts-ignore
        const allUnusedCoupons: CouponModel[] = allCoupons.unused;

        setUsedCoupons(allUsedCoupons);
        setUnusedCoupons(allUnusedCoupons);
    };

    useEffect(() => {
        getAllCoupons();
    }, [user]);

    const unusedCouponList =
        unusedCoupons.length > 0 ? (
            unusedCoupons.map((coupon) => (
                <CouponItem
                    coupon={coupon}
                    disabled={false}
                    key={"coupon_" + coupon.id}
                    refreshCoupons={getAllCoupons}
                />
            ))
        ) : (
                <p>Geen geldige coupons gevonden</p>
            );

    const usedCouponList =
        usedCoupons.length > 0 ?
            usedCoupons.map((coupon) => (
                <CouponItem coupon={coupon} disabled key={"coupon_" + coupon.id} refreshCoupons={getAllCoupons} />
            ))
            :
            <p>Geen gebruikte coupons gevonden</p>;

    return (
        <div className="coupons">
            <Helmet>
                <title>FH - Mijn coupons</title>
                <meta name="description" content="Vind hier jouw coupons." />
            </Helmet>

            <div className="coupons-header">
                <div className="subtitle">
                    <div dangerouslySetInnerHTML={{ __html: content! }} />
                </div>
            </div>

            <div className="coupons-body">
                <h3>Opgeslagen coupons</h3>
                <div className="coupons-list">{unusedCouponList}</div>
                <h3>Ingewisselde coupons</h3>
                <div className="coupons-list">{usedCouponList}</div>
            </div>
        </div>
    );
};

export default MyCoupons;
