import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import RestEndpoint from '../../requests/RestEndpoint';
import { AuthContext } from '../../contexts/AuthContext';
import StandardInput from '../utils/StandardInput';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down_links.svg';
import ValidationError from '../utils/ValidationError';
import RequestError from '../../models/RequestError';
import { ToastContext } from '../../contexts/ToastContext';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface OwnProps {

}

type Props = OwnProps;

const endpoint = new RestEndpoint('users');

class StoreOwner {
    id?: number = 0;
    first_name: string = "";
    last_name: string = "";
    email: string = "";
    password?: string = "";
    password_confirmation?: string = "";
}

const StoreOwnerDetails: React.FC<Props> = (props) => {
    const { user, isStoreOwner, isAdmin } = useContext(AuthContext);
    const userEndpoint = new RestEndpoint('users');

    const { notify } = useContext(ToastContext);

    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const history = useHistory();


    const [storeOwner, setStoreOwner] = useState<StoreOwner>(isStoreOwner() ? user as StoreOwner : new StoreOwner());
    const [editPassword, setEditPassword] = useState<boolean>(isAdmin());
    const [requestError, setRequestError] = useState<RequestError>();


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStoreOwner({ ...storeOwner, [e.target.id]: e.target.value })
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        try {
            if (location.pathname.includes("/nieuw")) {

                if (location.pathname.includes("/winkel-eigenaar")) {
                    //@ts-ignore
                    const result: StoreOwner = await endpoint.store({ ...storeOwner, ...{ role: "storeOwner" } })
                    localStorage.setItem("newOwner", result.id!.toString())
                    history.push({
                        pathname: '/winkel-details/nieuw/',
                        state: { storeOwnerId: result.id }
                    });
                } else if (location.pathname.includes("/horeca-eigenaar")) {
                    //@ts-ignore
                    const result: StoreOwner = await endpoint.store({ ...storeOwner, ...{ role: "storeOwner" } })
                    localStorage.setItem("newOwner", result.id!.toString())
                    history.push({
                        pathname: '/horeca-details/nieuw/',
                        state: { storeOwnerId: result.id }
                    });
                } else {
                    await endpoint.store({ ...storeOwner, ...{ role: "admin" } })
                    history.goBack();
                }
            } else {
                await endpoint.update(storeOwner);
                history.goBack();
            }

            notify("Wijzigingen opgeslagen");
        } catch (e) {
            setRequestError(e);
        }
    }

    const togglePassword = () => {
        setEditPassword(!editPassword)
        setStoreOwner({ ...storeOwner, password: undefined, password_confirmation: undefined })
    }

    const getStoreOwner = async () => {
        if (id) {
            // @ts-ignore
            setStoreOwner(await userEndpoint.find(+id));
        }
    }

    useEffect(() => {
        if (location.pathname.includes("/nieuw")) {
            let owner = new StoreOwner();
            setStoreOwner(owner)
            return
        }

        getStoreOwner().then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="store-owner-details">
            <Helmet>
                <title>FH - Winkeleigenaar</title>
            </Helmet>
            <h1>{id ? "Gegevens Gebruiker" : "Mijn gegevens"}</h1>
            {id ? <p className="notice"><b>Let op:</b> Je bent ingelogd als beheerder. Op deze pagina kun je
                de geselecteerde gebruiker beheren.</p> : null}

            {requestError && <ValidationError requestError={requestError} />}

            <form onSubmit={handleSubmit}>
                <h2>{id ? "Naam" : "Jouw naam"}</h2>
                <StandardInput
                    onChange={handleChange}
                    value={storeOwner.first_name}
                    id="first_name"
                    placeholder="Voornaam"
                    type="text"
                    required
                    name="first_name"
                />

                <StandardInput
                    onChange={handleChange}
                    value={storeOwner.last_name}
                    id="last_name"
                    placeholder="Achternaam"
                    type="text"
                    required
                    name="last_name"
                />

                <h2>E-mailadres</h2>

                <StandardInput
                    onChange={handleChange}
                    value={storeOwner.email}
                    id="email"
                    placeholder="E-mailadres"
                    type="text"
                    required
                    name="email"
                />

                {isStoreOwner() &&
                    <div className={editPassword ? "open edit-password" : "edit-password"} onClick={togglePassword}>
                        <h2>Wachtwoord {location.pathname.includes("/nieuw") ? "" : "Wijzigen"}</h2>
                        <ArrowDown />
                    </div>
                }


                <div className={editPassword ? "" : "close"}>
                    <StandardInput
                        onChange={handleChange}
                        value={storeOwner.password ? storeOwner.password : ""}
                        id="password"
                        placeholder="Wachtwoord"
                        type="password"
                        name="password"
                        required={location.pathname.includes("/nieuw")}
                    />

                    <StandardInput
                        onChange={handleChange}
                        value={storeOwner.password_confirmation ? storeOwner.password_confirmation : ""}
                        id="password_confirmation"
                        placeholder="Wachtwoord bevestigen"
                        type="password"
                        name="password_confirmation"
                        required={location.pathname.includes("/nieuw")}
                    />
                </div>

                <button type="submit">Opslaan</button>
            </form>
        </div>
    )
}

export default StoreOwnerDetails
