import Model from "./Model";
import Store from "./Store";

interface StoreDetails {
    city_id: number;
    country: string;
    created_at: string;
    house_number: string;
    id: number;
    information: string;
    short_description: string;
    latitude: number;
    logo_url: string;
    longitude: number;
    name: string;
    phone: string;
    rating: number;
    street: string;
    updated_at: string;
    user_id: number;
    website: string;
    zip_code: string;
}

class User extends Model {
    first_name: string = '';
    last_name: string = '';
    email: string = '';
    role_id: number = 0;
    date: string = '';
    is_sso?: boolean;
    password?: string = '';
    password_confirmation?: string = '';
    store?: Store;
    gender?: string = '';
    address?: string = '';
    postcode?: string = '';
    city?: string = '';
    phone_number?: string = '';
    shopper_details: any;
    date_of_birth?: string = '';
    favorite_coupon_ids?: number[];
    redirect_url: string = process.env.REACT_APP_ENDPOINT + "/login";
    email_verified_at?: string;

    constructor(user?: User) {
        super();
        Object.assign(this, user);
    }
}

export default User;
