import React, { createRef, useEffect, useState } from 'react';
import { ReactComponent as Arrows } from "../../assets/icons/Arrows.svg";
import { Sort } from '../pages/CityOverview';

interface IProps {
    setSort: (sort: Sort) => void
}

type Props = IProps;

const SortDropdown: React.FC<Props> = ({ setSort }) => {
    const dropdownSort = createRef<HTMLUListElement>();
    const dropdownButton = createRef<HTMLButtonElement>();
    const dropdownCont = createRef<HTMLDivElement>();
    const [locationAvailable, setLocationAvailable] = useState<boolean>(false);

    const dropdown = () => {
        const dropdownRef = dropdownSort.current;
        const dropdownButtonRef = dropdownButton.current;

        dropdownRef?.classList.toggle("close");
        dropdownButtonRef?.classList.toggle("open");
    }

    const clickSort = (sorteer_op: string, sorteer_richting: string) => {
        const dropdownRef = dropdownSort.current;
        const dropdownButtonRef = dropdownButton.current;

        dropdownRef?.classList.toggle("close");
        dropdownButtonRef?.classList.toggle("open");

        setSort({ sorteer_op: sorteer_op, sorteer_richting: sorteer_richting })
    }

    useEffect(() => {

        window.navigator.permissions.query({name: "geolocation"}).then((result) => {
            if (result.state === 'granted') {
                setLocationAvailable(true)
            } else if (result.state === 'denied') {
                setLocationAvailable(false)
            }
        })
    }, [])

    return (
        <div className="sort-dropdown" ref={dropdownCont}>
            <button onClick={() => dropdown()} ref={dropdownButton}>sorteren <Arrows /></button>

            <ul ref={dropdownSort} className="close">
                <li onClick={() => clickSort("naam", "oplopend")}>A-Z</li>
                {locationAvailable && <li onClick={() => clickSort("afstand", "oplopend")}>Afstand</li>}
                <li onClick={() => clickSort("beoordeling", "aflopend")}>Beoordeling</li>
            </ul>
        </div>
    )
}

export default SortDropdown
