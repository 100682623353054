import React, {FunctionComponent} from "react";
import PersonalInfo from "../../../models/PersonalInfo";
import CheckMark from "../../../assets/icons/check_register.svg";

export const ContactDetailsWeb: FunctionComponent<{
    details: PersonalInfo;
    handleFieldChange: any;
    checks: boolean;
}> = (props) => {
    return (
        <div className="section-body mobile-hidden">
            <div className="input-group">
                <div className="input-group-body">
                    <div className="input-field-wrapper">
                        <div className="input-group-header">
                            <h4>E-Mail</h4>
                        </div>
                        <div className={"input-field" + (props.checks ? " validated" : "")} key="email_input">
                            <input
                                type="email"
                                name="email"
                                onChange={props.handleFieldChange}
                                defaultValue={props.details.email}
                                placeholder="E-mail adres"
                                disabled={props.details.is_sso}
                                required
                            />
                            <img src={CheckMark} alt="Check"/>
                        </div>
                    </div>
                    <br/>
                    <div className="input-field-wrapper">
                        <div className="input-group-header">
                            <h4>Telefoonnummer (Optioneel)</h4>
                        </div>
                        <div className={"input-field" + (props.checks ? " validated" : "")} key="zip_input">
                            <input
                                type="phone"
                                name="phone_number"
                                onChange={props.handleFieldChange}
                                placeholder="Telefoonnummer"
                                defaultValue={props.details.phone_number}
                            />
                            <img src={CheckMark} alt="Check"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-group">
                <div className="input-group-header">
                    <h4>Straatnaam en huisnummer</h4>
                </div>
                <div className="input-group-body">
                    <div className={"input-field" + (props.checks ? " validated" : "")} key="adress_input">
                        <input
                            type="text"
                            name="address"
                            defaultValue={props.details.address}
                            placeholder="Straatnaam en Huisnummer"
                            onChange={props.handleFieldChange}
                            required
                        />
                        <img src={CheckMark} alt="Check"/>
                    </div>
                </div>
            </div>
            <div className="input-group">
                <div className="input-group-header">
                    <h4>Postcode</h4>
                </div>
                <div className="input-group-body">
                    <div className={"input-field" + (props.checks ? " validated" : "")} key="zip_input">
                        <input
                            type="text"
                            name="postcode"
                            defaultValue={props.details.postcode}
                            placeholder="Postcode"
                            onChange={props.handleFieldChange}
                            required
                        />
                        <img src={CheckMark} alt="Check"/>
                    </div>
                </div>
            </div>
            <div className="input-group">
                <div className="input-group-header">
                    <h4>Plaats</h4>
                </div>
                <div className="input-group-body">
                    <div className={"input-field" + (props.checks ? " validated" : "")} key="city_input">
                        <input
                            type="text"
                            name="city"
                            placeholder="Plaats"
                            onChange={props.handleFieldChange}
                            defaultValue={props.details.city}
                            required
                        />
                        <img src={CheckMark} alt="Check"/>
                    </div>
                </div>
            </div>
        </div>
    );
};
