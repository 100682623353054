import React, { FunctionComponent } from "react";

const SubmitChanges: FunctionComponent<{
  submitChanges: any;
}> = (props) => {
  return (
    <button onClick={props.submitChanges} className="submit-changes">Opslaan</button>
  );
};

export default SubmitChanges;
