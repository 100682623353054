import React, { FunctionComponent, useState } from "react";
import CheckMark from "../../../assets/icons/check_register.svg";

const togglePasswordField = () => {
  const classList = document.getElementById("password-change-group")?.classList;
  if (classList && classList.contains("hidden")) {
    classList.remove("hidden");
  } else {
    classList?.add("hidden");
  }
};

export const ChangePassword: FunctionComponent<{
  handleFieldChange: any;
  checks: boolean;
}> = (props) => {
  const [passwordChanged, setPasswordChanged] = useState(0);

  return (
    <div className="section-body">
      <p className="change-password" onClick={() => togglePasswordField()}>
        Wachtwoord wijzigen
      </p>
      <div
        className="input-group password-change hidden"
        id="password-change-group"
      >
        <div className="input-group-body">
          <div
            className={"input-field" + (props.checks ? " validated" : "")}
            key="current_password_input"
          >
            <input
              type="password"
              name="current_password"
              onChange={(e) => {
                props.handleFieldChange(e);
                setPasswordChanged(passwordChanged + 1);
              }}
              placeholder="Oud wachtwoord"
              className="required"
              id="current_password"
            />
            <img src={CheckMark} alt="Check" />
          </div>
          <br />
          <div
            className={"input-field" + (props.checks ? " validated" : "")}
            key="new_password_input"
          >
            <input
              type="password"
              name="new_password"
              onChange={(e) => {
                props.handleFieldChange(e);
                setPasswordChanged(passwordChanged + 1);
              }}
              placeholder="Nieuw wachtwoord"
              className="required"
              id="new_password"
            />
            <img src={CheckMark} alt="Check" />
          </div>
          <div
            className={"input-field" + (props.checks ? " validated" : "")}
            key="new_password_input_repeat"
          >
            <input
              type="password"
              name="new_password_confirmation"
              onChange={(e) => {
                props.handleFieldChange(e);
                setPasswordChanged(passwordChanged + 1);
              }}
              placeholder="Nieuw wachtwoord (Herhalen)"
              className="repeat-password required"
              id="new_password_confirmation"
            />
            <img src={CheckMark} alt="Check" />
          </div>
        </div>
      </div>
    </div>
  );
};
