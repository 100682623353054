import React, {
    FunctionComponent,
    useState,
    useEffect,
    useContext,
} from "react";
import CouponModel from "../../../models/storemodels/Coupon";
import Store from "../../../models/Store";
import { StoreContext } from "../../../contexts/StoreContext";
import Coupon from "../../utils/Coupon";
import ExpandedCoupon from "./ExpandedCoupon";
import { generateDate } from "../../utils/DateTimeHelper";
import {AuthContext} from "../../../contexts/AuthContext";

const CouponItem: FunctionComponent<{
    coupon: CouponModel;
    disabled: boolean;
    managementPage?: boolean;
    refreshCoupons: () => Promise<void>;
}> = (props) => {
    const { stores } = useContext(StoreContext);
    const { user } = useContext(AuthContext);


    const [store, setStore] = useState<Store>();
    const [modalOpen, setModalOpen] = useState(false);

    const expiryDate = generateDate(props.coupon.expiry_date)
        .toLocaleDateString()
        .replace(/\//g, "-");

    useEffect(() => {
        const targetStore = stores.find(
            (store) => store.id === props.coupon.store_id
        );

        if (targetStore) {
            setStore(targetStore);
        }
    }, [props.coupon.store_id, stores]);

    return (
        <div className={`coupon-item ${props.disabled ? "disabled" : ""}`}>
            <Coupon
                disabled={props.disabled}
                title={props.coupon.title}
                subtitle={props.coupon.description}
                onClick={() => {
                    if (!props.disabled) {
                        setModalOpen(true);
                    }
                }}
                isFavorite={!!user?.favorite_coupon_ids?.find(couponId => couponId === props.coupon.id)}
            />
            <div className="coupon-text">
                {
                    !props.managementPage &&
                    <div>
                        <h2 className="coupon-title">{store?.name}</h2>
                        <p className="coupon-city">{store?.city}</p>
                        <p className="coupon-valid">Geldig t/m {expiryDate}</p>
                    </div>
                }

            </div>
            <ExpandedCoupon
                isOpen={modalOpen}
                closeModal={() => {
                    setModalOpen(false);
                }}
                storeImage={store?.logo_url}
                coupon={props.coupon}
                expiryDate={expiryDate}
                refreshCoupons={props.refreshCoupons}
            />
        </div>
    );
};

export default CouponItem;
