import Store from "../../models/Store";
import {StoreDetails} from "../../models/StoreDetails";

export function storeToUrl(store?: Store) {
    return `${store?.city?.toLowerCase()?.replace(/ /g, "-")}/${store?.name?.toLowerCase()?.replace(/ /g, "-")}` || "";
}

export function storeDetailsToUrl(store?: StoreDetails) {
    return `${store?.city?.toLowerCase()?.replace(/ /g, "-")}/${store?.store?.toLowerCase()?.replace(/ /g, "-")}` || "";
}
