import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom';

import {ReactComponent as Arrow} from "../../../assets/icons/arrow_right_links.svg";
import CouponEndpoint from '../../../requests/CouponEndpoint';
import Coupon from '../../../models/storemodels/Coupon';
import {AuthContext} from '../../../contexts/AuthContext';
import CouponItem from './CouponItem';
import {ToastContext} from '../../../contexts/ToastContext';
import {Helmet} from 'react-helmet';
import {PageContentContext} from "../../../contexts/PageContentContext";

interface OwnProps {

}

type Props = OwnProps;

const couponEndpoint = new CouponEndpoint();

interface ActiveExpired {
    active: Coupon[],
    expired: Coupon[]
}

const CouponManagement: React.FC<Props> = (props) => {
    const {user} = useContext(AuthContext);
    const {notify, errorNotification} = useContext(ToastContext);
    const { pageContents } = useContext(PageContentContext);

    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [expiredCoupons, setExpiredCoupons] = useState<Coupon[]>([]);

    const pageContent = pageContents.find(pageContent => pageContent.location === 'my-actions')?.content;

    const getAll = async () => {
        if (!user?.store?.id) return;

        // @ts-ignore
        let couponsData: { active: Coupon[], expired: Coupon[] } = await couponEndpoint.getCoupons(user?.store?.id);

        setCoupons(couponsData.active);
        setExpiredCoupons(couponsData.expired);
    }

    const expireCoupon = async (coupon: Coupon) => {
        couponEndpoint.resource = `stores/${user?.store?.id}/coupons`;
        // @ts-ignore
        try {
            coupon.expiry_date = createExpireDate();
            await couponEndpoint.update(coupon)
            setExpiredCoupons([...expiredCoupons, coupon]);
            setCoupons(coupons?.filter(c => c.id !== coupon.id));
            notify("Coupon actie is stopgezet.")
        } catch (e) {
            errorNotification(e);
        }
    }

    const createExpireDate = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        return yesterday.getFullYear() + '-'
            + ('0' + (yesterday.getMonth() + 1)).slice(-2) + '-'
            + ('0' + yesterday.getDate()).slice(-2);
    }

    useEffect(() => {
        getAll();
    }, [])


    return (
        <div id="coupon-management">
            <Helmet>
                <title>FH - Mijn coupons</title>
                <meta name="description" content="Mijn coupons."/>
            </Helmet>

            <div dangerouslySetInnerHTML={{ __html: pageContent! }} />

            <h2>Geldige Fashion Hotspots coupons (Maximaal 1)</h2>

            <div className="coupon-cont coupons">
                {
                    coupons?.map(coupon => {
                        return (
                            <div key={`coupon-${coupon.id}`}>
                                <CouponItem title={coupon.title} subtitle={coupon.description} type="coupon"/>

                                <p className="coupon-cont__times-used">{coupon.times_used} {coupon.times_used === 1 ? "keer" : "keren"} gebruikt.</p>

                                <div className="edit-links">
                                    <p onClick={() => expireCoupon(coupon)}>Stopzetten</p>
                                    <Link to={`/winkel-admin/coupon-bewerken/${coupon.id}`}>Wijzigen</Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {
                (coupons && coupons.length >= 3)
                    ?
                    <button type="button" className={'button-link'} disabled={true}>
                        Maximum aantal geldige coupons
                    </button>
                    :
                    <Link to="/winkel-admin/maak-coupon" className={`button-link`}>
                        Maak een Fashion Hotspots coupon <Arrow/>
                    </Link>
            }

            <div className="split-line"></div>

            <h2>Verlopen Fashion Hotspots coupons</h2>

            <div className="coupon-cont coupons">
                {expiredCoupons?.length === 0 && <p>Geen resultaten</p>}
                {
                    expiredCoupons?.map(coupon => {
                        return (
                            <div>
                                <CouponItem title={coupon.title} subtitle={coupon.description} type="coupon"
                                            key={`coupon-${coupon.id}`}/>

                                <p className="coupon-cont__times-used">{coupon.times_used} {coupon.times_used === 1 ? "keer" : "keren"} gebruikt.</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CouponManagement
