import React, { ChangeEvent, useEffect, useState } from 'react'

import { ReactComponent as SearchIcon } from '../../assets/icons/magnifier_searchfield.svg';

interface OwnProps {
    placeholder: string,
    objAttr: string,
    array: any[],
    setArray: (array: any[]) => void,
    onFocus?: () => void,
    onBlur?: () => void,
    characterMinimum?: number
}

type Props = OwnProps;

const SearchComponent: React.FC<Props> = (props) => {
    const [originalArray, setOriginalArray] = useState<any>([]);
    const [array, setArray] = useState<any[]>([]);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if(props.characterMinimum) if(e.target.value.length < props.characterMinimum) return;

        const searchInput = e.target.value.replace(/[!@#$%^&*()+=\-[\]\\';,./{}|":<>?~_]/g, "\\$&");

        let patt = new RegExp(`${searchInput}`, "i");

        setArray(originalArray?.filter((item: any) => patt.test(item[props.objAttr])));
    }

    useEffect(() => {
        setArray(props.array);
        setOriginalArray(props.array);
    }, []);

    useEffect(() => {
        props.setArray(array)
    }, [array]);

    useEffect(() => {
        setOriginalArray(props.array)
    }, [props.array])

    return (
        <div className="search-container">
            <SearchIcon id="search-icon" />
            <input 
                className="search-input" 
                onChange={(e) => handleSearch(e)} 
                placeholder={props.placeholder}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
        </div>
    )
}

export default SearchComponent
