import React, {FunctionComponent, useContext} from 'react';
import {PageContentContext} from "../../contexts/PageContentContext";
import { Helmet } from 'react-helmet';

interface OwnProps {}

type Props = OwnProps;

const HowDoesItWorkStorePage: FunctionComponent<Props> = (props) => {
    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'how-does-it-work-store')?.content;

    return (
        <div>
            <Helmet>
                <title>Hoe werkt het voor winkels - Fashion Hotspots</title>
                <meta name="description" content="Meld jouw winkel aan, richt je winkelpagina in en word beter vindbaar. Fashion Hotspots; Samen naar meer winkelbezoeken"/>
                <meta name="keywords" content="fashion hotspots, hoe werkt het" />
            </Helmet>
            <div className="text-content" dangerouslySetInnerHTML={{ __html: content! }} />
        </div>
    );
};

export default HowDoesItWorkStorePage;
