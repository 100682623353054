import React, {FunctionComponent, useContext} from "react";
import Modal from "react-modal";
import {AuthContext} from "../../../contexts/AuthContext";
import {StoreContext} from "../../../contexts/StoreContext";
import {ToastContext} from "../../../contexts/ToastContext";
import Coupon from "../../../models/storemodels/Coupon";
import RestEndpoint from "../../../requests/RestEndpoint";
import Close from "./../../../assets/icons/close.svg";
import Swipe from "./../../../assets/icons/swipe.svg";
import FavoriteStore from "./../../../assets/icons/favorite_store.svg";
import FavoriteStoreRemove from "./../../../assets/icons/favorite_store_remove.svg";
import {getDateAsDayMonthYear} from "../../utils/DateTimeHelper";
import {useSwipeable} from "react-swipeable";


const ExpandedCoupon: FunctionComponent<{
    isOpen: boolean;
    closeModal: any;
    storeImage?: string;
    coupon: Coupon;
    expiryDate: string;
    refreshCoupons?: () => Promise<void>;
    inPage?: boolean;
}> = (props) => {
    const {user, fetchUser, isShopper, isStoreOwner} = useContext(AuthContext);
    const {notify, errorNotification} = useContext(ToastContext);
    const {stores} = useContext(StoreContext);
    const store = stores.find(store => store.id === props.coupon.store_id);

    const swipeableConfig = {
        delta: 10,                            // min distance(px) before a swipe starts
        preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
        trackTouch: true,                     // track touch input
        trackMouse: false,                    // track mouse input
        rotationAngle: 0,                     // set a rotation angle
    };

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => submitCoupon(),
        ...swipeableConfig,
    });

    const toggleIsFavorite = async () => {
        if (!isShopper()) {
            notify('Alleen shoppers kunnen coupons opslaan.', 'error');
            return;
        }

        const endpoint = new RestEndpoint(`shoppers/${user?.id}/toggle-favorite-coupon`);

        try {
            await endpoint.store({
                store_coupon_id: props.coupon.id,
            });

            await fetchUser();

            props.closeModal();


            if (isFavorite()) {
                notify(`De coupon is van "Mijn coupons" verwijderd.`);
            } else {
                notify(`De coupon is aan "Mijn coupons" toegevoegd.`);
            }

        } catch (e) {
            errorNotification(e);
        }
    };

    const submitCoupon = async () => {
        const enpoint = new RestEndpoint(`shoppers/${user?.id}/use-coupon`);


        if (!isShopper()) {
            notify('Alleen shoppers kunnen coupons verzilveren', 'error');
            return;
        }

        try {
            await enpoint.store({
                code: props.coupon.code,
                store_id: props.coupon.store_id,
            });

            await fetchUser();

            props.closeModal();

            if (props.refreshCoupons) {
                props.refreshCoupons().then();
            }

            notify("De coupon is verzilverd.");
        } catch (e) {
            errorNotification(e);
        }
    };

    const isFavorite = () => {
        return !!user?.favorite_coupon_ids?.find(couponId => couponId === props.coupon.id);
    }

    return (
        props.inPage ?
            <div className="expanded-coupon expanded-coupon--in-page">
                <div className="header">
                    <p>
                        <span className="emphasis">Fashion Hotspots</span> aanbieding
                    </p>
                </div>
                <div className="body">
                    <div className="body-row body-top">
                        <img src={FavoriteStoreRemove} className={`star-image`}/>
                        <div className="coupon-store">
                            <p>TE BESTEDEN BIJ</p>
                            <img
                                src={user?.store?.logo_url}
                                alt=""
                                className="store-image"
                            />
                        </div>
                        <img
                            src={Close}
                            alt=""
                            className="close-image"
                        />
                    </div>
                    <div className="body-row coupon-details">
                        <div className="details-wrapper">
                            <h6>{props.coupon.title}</h6>
                            <p>{props.coupon.description}</p>
                        </div>
                    </div>
                    <div className="body-row qr">
                        <div>
                            <img src={props.coupon.image_url} alt=""/>
                        </div>
                        <div className="qr-text">
                            <p>Toon je coupon bij de kassa</p>
                        </div>
                    </div>
                    {
                        props.coupon.code &&
                        <div className="body-row">
                            <p className="expanded-coupon__conditions">
                                <strong>Code: </strong> {props.coupon.code}
                            </p>
                        </div>
                    }
                    <div className="body-row">
                        <p className="expanded-coupon__conditions">
                            <strong>Voorwaarden: </strong> {props.coupon.conditions}
                        </p>
                    </div>
                    <div className="body-row">
                        <p className="expanded-coupon__expiry-date">
                            <strong>Coupon is geldig t/m {getDateAsDayMonthYear(props.expiryDate.replace(/ /g, "T"))}</strong>
                        </p>
                    </div>
                </div>
                <div className="footer">
                    <div className="mobile-hidden">
                        <button>Verzilveren</button>
                    </div>
                    <div className="web-hidden">
                        <img src={Swipe} alt=""/>
                        <p>Swipe naar links om je coupon te verzilveren</p>
                    </div>
                </div>
            </div>
            :
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.closeModal}
                className="coupon-modal"
                overlayClassName="coupon-modal-overlay"
            >
                <div className="expanded-coupon">
                    <div className="header">
                        <p>
                            <span className="emphasis">Fashion Hotspots</span> aanbieding
                        </p>
                    </div>
                    <div className="body">
                        <div className="body-row body-top">
                            <img src={isFavorite() ? FavoriteStore : FavoriteStoreRemove}
                                 className={`star-image ${!isShopper() ? 'disabled' : ''}`}
                                 onClick={toggleIsFavorite}/>
                            <div className="coupon-store">
                                <p>TE BESTEDEN BIJ</p>
                                <img
                                    src={store?.logo_url}
                                    alt=""
                                    className="store-image"
                                />
                            </div>
                            <img
                                src={Close}
                                alt=""
                                className="close-image"
                                onClick={props.closeModal}
                            />
                        </div>
                        <div className="body-row coupon-details">
                            <div className="details-wrapper">
                                <h6>{props.coupon.title}</h6>
                                <p>{props.coupon.description}</p>
                            </div>
                        </div>
                        <div className="body-row qr">
                            <div>
                                <img src={props.coupon.image_url} alt=""/>
                            </div>
                            <div className="qr-text">
                                <p>Toon je coupon bij de kassa</p>
                            </div>
                        </div>
                        {
                            props.coupon.code &&
                            <div className="body-row">
                                <p className="expanded-coupon__conditions">
                                    <strong>Code: </strong> {props.coupon.code}
                                </p>
                            </div>
                        }
                        <div className="body-row">
                            <p className="expanded-coupon__conditions">
                                <strong>Voorwaarden: </strong> {props.coupon.conditions}
                            </p>
                        </div>
                        <div className="body-row">
                            <p className="expanded-coupon__expiry-date">
                                <strong>Coupon is geldig t/m {getDateAsDayMonthYear(props.expiryDate.replace(/ /g, "T"))}</strong>
                            </p>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="mobile-hidden">
                            <button onClick={submitCoupon}>Verzilveren</button>
                        </div>
                        <div {...handlers} className="web-hidden">
                            <img src={Swipe} alt=""/>
                            <p>Swipe naar links om je coupon te verzilveren</p>
                        </div>
                    </div>
                </div>
            </Modal>
    );
};

export default ExpandedCoupon;
