import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from "react-transition-group/TransitionGroup";
import AdminLogin from "./components/auth/AdminLogin";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ResetPassword from './components/auth/ResetPassword';
import SSOLoginHandler from './components/auth/SSOLoginHandler';
import Footer from "./components/layout/Footer";
import NavBar from "./components/layout/NavBar";
import AboutUsPage from "./components/pages/AboutUsPage";
import AdminFAQ from './components/pages/admin-pages/AdminFAQ';
import AdminPageContent from './components/pages/admin-pages/AdminPageContent';
import FashionHotspotsManagement from './components/pages/admin-pages/FashionHotspotsMangement';
import ShopperReview from './components/pages/admin-pages/ShopperMgmt/ShopperReview';
import SuperUser from './components/pages/admin-pages/SuperUser';
import UserManagement from './components/pages/admin-pages/UserManagement';
import ManageStores from './components/pages/admin/stores/ManageStores';
import AllCities from './components/pages/AllCities';
import CancelAccount from './components/pages/CancelAccount';
import CityOverview from "./components/pages/CityOverview";
import ContactPage from "./components/pages/ContactPage";
import CookiePolicyPage from "./components/pages/CookiePolicyPage";
import Dashboard from "./components/pages/Dashboard";
import DetailPage from './components/pages/DetailPage';
import EditNewsItem from './components/pages/EditNewsItem';
import FAQCategoryPage from "./components/pages/FAQCategoryPage";
import FAQPage from "./components/pages/FAQPage";
import FAQSubcategoryPage from "./components/pages/FAQSubcategoryPage";
import FavoriteStores from './components/pages/FavoriteStores';
import Home from "./components/pages/Home";
import HowDoesItWork from './components/pages/HowDoesItWork';
import HowDoesItWorkStorePage from "./components/pages/HowDoesItWorkStorePage";
import EditCity from './components/pages/manage-cities-page/EditCity';
import ManageBanners from './components/pages/ManageBanners';
import ManageCategoriesPage from './components/pages/ManageCategoriesPage';
import ManageCities from './components/pages/ManageCities';
import ManageNews from './components/pages/ManageNews';
import ManageStoreOfTheWeek from './components/pages/ManageStoreOfTheWeek';
import MyReviews from './components/pages/my-reviews-page/MyReviews';
import MyCoupons from './components/pages/MyCoupons';
import News from './components/pages/News';
import NewsItemDetail from './components/pages/NewsItemDetail';
import ShopperDetails from './components/pages/personal-details-components/ShopperDetails';
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import RegisterStorePage from "./components/pages/RegisterStorePage";
import ReviewFormShopperPage from "./components/pages/ReviewFormShopperPage";
import CouponManagement from './components/pages/store-admin-components/CouponManagement';
import CreateCoupon from './components/pages/store-admin-components/CreateCoupon';
import StoreClothingCategoriesPage from "./components/pages/store-admin-components/StoreClothingCategoriesPage";
import StoreImagesPage from "./components/pages/store-admin-components/StoreImagesPage";
import StoreDetails from './components/pages/store-details-components/StoreDetails';
import StoreReviews from './components/pages/store-reviews/StoreReviews';
import StoreOwnerDetails from './components/pages/StoreOwnerDetails';
import StorePromotionsPage from "./components/pages/StorePromotionsPage";
import TermsAndConditionsPage from "./components/pages/TermsAndConditionsPage";
import NotFoundPage from "./components/utils/NotFoundPage";
import PrivateRoute from "./components/utils/PrivateRoute";
import PublicRoute from "./components/utils/PublicRoute";
import AdminFAQContextProvider from './contexts/AdminFAQContext';
import AgeGenderContextProvider from './contexts/AgeGenderContext';
import AuthContextProvider from "./contexts/AuthContext";
import BannerContextProvider from './contexts/BannerContext';
import BrandContextProvider from './contexts/BrandContext';
import CityContextProvider from "./contexts/CityContext";
import FilterCategoriesContextProvider from "./contexts/FilterCategoriesContext";
import ItemContextProvider from './contexts/ItemContext';
import NewsContextProvider from './contexts/NewsContext';
import PageContentProvider from './contexts/PageContentContext';
import SearchContextProvider from './contexts/SearchContext';
import SellingPointContextProvider from './contexts/SellingPointsProvider';
import SpecialistContextProvider from './contexts/SpecialistsContext';
import StoreContextProvider from './contexts/StoreContext';
import StyleContextProvider from './contexts/StyleContext';
import ToastContextProvider from './contexts/ToastContext';
import Cookie from './components/utils/Cookie';
import User from "./models/User";
import ManageStoreClothingCategories from "./components/pages/admin/stores/ManageStoreClothingCategories";
import ManageHorecas from "./components/pages/admin/horecas/ManageHorecas";

interface OwnProps {
    user?: User
}

type Props = OwnProps;

const ItemProviders = ({ children }: any) => {
    return (
        <ItemContextProvider>
            <BrandContextProvider>
                <StoreContextProvider>
                    <CityContextProvider>
                        <StyleContextProvider>
                            <NewsContextProvider>
                                {children}
                            </NewsContextProvider>
                        </StyleContextProvider>
                    </CityContextProvider>
                </StoreContextProvider>
            </BrandContextProvider>
        </ItemContextProvider>
    )
}

const FilterProviders = ({ children }: any) => {
    return (
        <FilterCategoriesContextProvider>
            <SpecialistContextProvider>
                <AgeGenderContextProvider>
                    {children}
                </AgeGenderContextProvider>
            </SpecialistContextProvider>
        </FilterCategoriesContextProvider>
    )
}

const ContentProvider = ({ children }: any) => {
    return (
        <BannerContextProvider>
            <PageContentProvider>
                <SellingPointContextProvider>
                    <ToastContextProvider>
                        {children}
                    </ToastContextProvider>
                </SellingPointContextProvider>
            </PageContentProvider>
        </BannerContextProvider>
    )
}

const SuperUserProvider = ({ children }: any) => {
    return (
        <AdminFAQContextProvider>
            {children}
        </AdminFAQContextProvider>
    )
}

const App: FunctionComponent<Props> = (props) => {
    return (
        <BrowserRouter>
            <AuthContextProvider user={props.user}>
                <FilterProviders>
                    <ContentProvider>
                        <ItemProviders>
                            <SearchContextProvider>
                                <SuperUserProvider>
                                    <NavBar />
                                    <Route render={({ location }) => {
                                        return (
                                            <div className="content">
                                                <Helmet>
                                                    <title>Fashion Hotspots</title>
                                                    <meta name="theme-color" content="#00a09b" />
                                                </Helmet>
                                                <main>
                                                    <TransitionGroup component={null}>
                                                        <CSSTransition
                                                            key={location.pathname}
                                                            timeout={250}
                                                            classNames={'fade'}
                                                            onExit={
                                                                () => window && window.scroll({ top: 0 })
                                                            }
                                                        >
                                                            <Switch location={location}>
                                                                <PublicRoute isRestricted={false} exact={true} path={'/'}
                                                                    component={Home} />

                                                                <PublicRoute isRestricted={true} exact={true}
                                                                    path={'/login'}
                                                                    component={Login} />

                                                                <PublicRoute isRestricted={true} exact={true}
                                                                    path={'/admin-login'}
                                                                    component={AdminLogin} />

                                                                <PublicRoute isRestricted={true} exact={true}
                                                                    path={'/herstel-wachtwoord'}
                                                                    component={ResetPassword} />

                                                                <PublicRoute isRestricted={true} exact={true}
                                                                    path={'/domain/login/sso'}
                                                                    component={SSOLoginHandler} />

                                                                <PublicRoute isRestricted={true} exact={true}
                                                                    path={'/registreer'}
                                                                    component={Register} />

                                                                <PrivateRoute exact={true} path={'/dashboard'}
                                                                    component={Dashboard} />

                                                                <PublicRoute isRestricted={false} exact={true}
                                                                    path={'/winkels/:city_name/:store_name'}
                                                                    component={DetailPage} />

                                                                <PublicRoute isRestricted={false} exact={true}
                                                                    path={'/steden'}
                                                                    component={AllCities} />

                                                                <PublicRoute isRestricted={false} exact={true}
                                                                    path={'/nieuws'}
                                                                    component={News} />

                                                                <PublicRoute isRestricted={false} exact={true}
                                                                    path={'/nieuws/:title'}
                                                                    component={NewsItemDetail} />
                                                                <PublicRoute isRestricted={false} exact={true}
                                                                    path={'/pers'}
                                                                    component={News} />

                                                                <PublicRoute isRestricted={false} exact={true}
                                                                    path={'/pers/:title'}
                                                                    component={NewsItemDetail} />

                                                                <PublicRoute isRestricted={false} exact={true}
                                                                    path={'/privacy-verklaring'}
                                                                    component={PrivacyPolicyPage} />

                                                                <PrivateRoute exact={true} path={'/favoriete-winkels'}
                                                                    component={FavoriteStores} />

                                                                <PrivateRoute exact={true} path={'/mijn-recensies'}
                                                                    component={MyReviews} />

                                                                <PrivateRoute exact={true} path={'/mijn-coupons'}
                                                                    component={MyCoupons} />

                                                                <PrivateRoute exact={true} path={'/shopper/maak-review'}
                                                                    component={ReviewFormShopperPage} />

                                                                <PrivateRoute exact={true} path={'/shopper/maak-review/:store_name'}
                                                                    component={ReviewFormShopperPage} />

                                                                <PrivateRoute exact={true} path='/mijn-gegevens'
                                                                    component={ShopperDetails} />

                                                                <PrivateRoute exact={true} path='/account-opzeggen'
                                                                    component={CancelAccount} />

                                                                <PrivateRoute exact={true} path='/winkel-details'
                                                                    component={StoreDetails} />

                                                                <PrivateRoute exact={true} path='/winkel-details/:id'
                                                                    component={StoreDetails} />

                                                                <PrivateRoute exact={true} path='/winkel-details/nieuw'
                                                                    component={StoreDetails} />

                                                               <PrivateRoute exact={true} path='/winkel-details/horeca'
                                                                    component={StoreDetails} />

                                                                <PrivateRoute exact={true} path='/horeca-details/nieuw'
                                                                              component={StoreDetails} />
                                                                <PrivateRoute exact={true}
                                                                    path='/winkel-admin/kleding-categorieen'
                                                                    component={StoreClothingCategoriesPage} />
                                                                <PrivateRoute exact={true} path='/winkel-admin/afbeeldingen'
                                                                    component={StoreImagesPage} />

                                                                <PrivateRoute exact={true} path='/winkel-eigenaar-details'
                                                                    component={StoreOwnerDetails} />
                                                                <PrivateRoute exact={true} path='/winkel-eigenaar-details/:id'
                                                                    component={StoreOwnerDetails} />

                                                                <PrivateRoute exact={true} path='/horeca-eigenaar-details'
                                                                              component={StoreOwnerDetails} />
                                                                <PrivateRoute exact={true} path='/horeca-eigenaar-details/:id'
                                                                              component={StoreOwnerDetails} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/store-admin/promoties'
                                                                    component={StorePromotionsPage} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/winkel-admin/maak-coupon'
                                                                    component={CreateCoupon} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/winkel-admin/coupon-bewerken/:id'
                                                                    component={CreateCoupon} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/winkel-admin/mijn-acties'
                                                                    component={CouponManagement} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/winkel-admin/mijn-recensies'}
                                                                    component={StoreReviews} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin/shoppers/:id'
                                                                    component={ShopperReview} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin/shoppers'
                                                                    render={() => <UserManagement type="shopper" />} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/admins'}
                                                                    render={() => <UserManagement type="admin" />} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin/fashion-hotspots'
                                                                    component={FashionHotspotsManagement} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin/pagina-inhoud'
                                                                    component={AdminPageContent} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin/'
                                                                    component={SuperUser} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin/veelgestelde-vragen'
                                                                    component={AdminFAQ} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/categorie-beheer'}
                                                                    component={ManageCategoriesPage} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-nieuws'}
                                                                    component={ManageNews} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-nieuws/:type/:id'}
                                                                    component={EditNewsItem} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-nieuws/nieuw'}
                                                                    component={EditNewsItem} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-steden'}
                                                                    component={ManageCities}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-steden/:id'}
                                                                    component={EditCity}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-steden/nieuw'}
                                                                    component={EditCity}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-banners'}
                                                                    component={ManageBanners}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-winkel-van-de-week'}
                                                                    component={ManageStoreOfTheWeek}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-winkels'}
                                                                    component={ManageStores}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-winkels/:id/kleding-categorieen'}
                                                                    component={ManageStoreClothingCategories}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path={'/admin/beheer-horecas'}
                                                                    component={ManageHorecas}
                                                                />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/winkel-eigenaar-details/nieuw'
                                                                    component={StoreOwnerDetails} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin-details/:id'
                                                                    component={StoreOwnerDetails} />

                                                                <PrivateRoute
                                                                    exact={true}
                                                                    path='/admin-details/nieuw'
                                                                    component={StoreOwnerDetails} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/stad-overzicht'}
                                                                    component={CityOverview} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/veelgestelde-vragen'}
                                                                    component={FAQCategoryPage} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/veelgestelde-vragen/:sub_category_id'}
                                                                    component={FAQSubcategoryPage} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/veelgestelde-vragen/:sub_category_id/:faq_id'}
                                                                    component={FAQPage} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/hoe-werkt-het'}
                                                                    component={HowDoesItWork} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/over-ons'}
                                                                    component={AboutUsPage}
                                                                />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/contact'}
                                                                    component={ContactPage}
                                                                />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/algemene-voorwaarden'}
                                                                    component={TermsAndConditionsPage} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/cookie-verklaring'}
                                                                    component={CookiePolicyPage} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/hoe-werkt-het-winkel'}
                                                                    component={HowDoesItWorkStorePage} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={true}
                                                                    path={'/registreer-winkel'}
                                                                    component={RegisterStorePage} />

                                                                <PublicRoute
                                                                    isRestricted={false}
                                                                    exact={false}
                                                                    component={NotFoundPage} />

                                                            </Switch>
                                                        </CSSTransition>
                                                    </TransitionGroup>
                                                </main>
                                            </div>
                                        )
                                    }}
                                    />
                                    <Footer />
                                    <Cookie />

                                </SuperUserProvider>
                            </SearchContextProvider>
                        </ItemProviders>
                    </ContentProvider>
                </FilterProviders>
            </AuthContextProvider>
        </BrowserRouter>
    );
};

export default App;
