import React, { FunctionComponent, useState } from "react";
import City from "../../../models/City";
import Close from "../../../assets/icons/close.svg";
import Edit from "../../../assets/icons/edit.svg";
import { useHistory } from "react-router-dom";
import Popup from "../../utils/Popup";

const ManageCityListItem: FunctionComponent<{
  city: City;
  deleteItem: (id: number) => Promise<void>;
}> = (props) => {
  const history = useHistory();
  const navigateToEdit = () => {
    history.push(`/admin/beheer-steden/${props.city.id}`);
  };
  
  const [show, setShow] = useState<boolean>(false);

  return (
    <tr className="city-list-item">
      <Popup
        title={`${props.city.name} verwijderen?`}
        buttonText="Verwijderen"
        buttonFunction={() => props.deleteItem(props.city.id)}
        setShow={setShow}
        show={show}
      />

      <td className="name">{props.city.name}</td>
      <td className="image">
        <img src={Edit} alt="" onClick={navigateToEdit} />
      </td>
      <td className="image">
        <img
          src={Close}
          alt=""
          onClick={() => setShow(true)}
        />
      </td>
    </tr>
  );
};

export default ManageCityListItem;
