import React, {FunctionComponent, useEffect, useState} from "react";
import Banner from "../../models/Banner";
import RestEndpoint from "../../requests/RestEndpoint";
import BannerItem from "./manage-banners-components/BannerItem";
import {Helmet} from "react-helmet";

const ManageBanners: FunctionComponent = (props) => {
    const [banners, setBanners] = useState<Banner[]>([]);

    useEffect(() => {
        const getAllBanners = async () => {
            const endpoint = new RestEndpoint("banners");
            //@ts-ignore
            const allBanners: Banner[] = await endpoint.all();
            setBanners(allBanners);
        };
        getAllBanners();
    }, []);

    return (
        <div className="manage-banners">
            <Helmet>
                <title>FH - Afbeelding- en Bannerbeheer</title>
            </Helmet>
            
            <div className="header">
                <h1>Afbeelding- en Bannerbeheer</h1>
                <p className="subtitle">
                    Hier kun je de afbeeldingen en banners bekijken en updaten. Klik op
                    een item om een nieuwe foto te uploaden.
                </p>
            </div>
            <div className="manage-banners-content">
                <h2>Afbeeldingen en Banners</h2>
                <div className="images">
                    {banners.map((banner) => (
                        <BannerItem banner={banner} key={banner.id}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ManageBanners;
