import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import NewsItem from "../models/NewsItem";

interface OwnProps {

}

interface INewsContext {
    newsItems: NewsItem[],
    pressItems: NewsItem[],
    getAllNewsItems: () => Promise<void>,
    getNewsItem: (id: number) => NewsItem | undefined
    getPressItem: (id: number) => NewsItem | undefined
}

const newsEndPoint = new RestEndpoint('news_items');
const pressEndPoint = new RestEndpoint('press_releases');

export const NewsContext = createContext({} as INewsContext);

type Props = OwnProps;

const NewsContextProvider: FunctionComponent<Props> = (props) => {
    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
    const [pressItems, setPressItems] = useState<NewsItem[]>([]);

    useEffect(() => {
        getAllNewsItems().then()
    }, [])

    const getAllNewsItems = async (): Promise<void> => {
        // @ts-ignore
        const allNewsItems: NewsItem[] = await newsEndPoint.all();
        // @ts-ignore
        const allPressItems: NewsItem[] = await pressEndPoint.all();

        setNewsItems(allNewsItems.reverse());
        setPressItems(allPressItems.reverse());
    }

    const getNewsItem = (id: number) => {
        const item = newsItems.find(item => item.id === id);

        return item ? item : undefined;
    }

    const getPressItem  = (id: number) => {
        const item = pressItems.find(item => item.id === id);

        return item ? item : undefined;
    }

    return (
        <NewsContext.Provider
            value={{newsItems, pressItems, getAllNewsItems, getNewsItem, getPressItem}}>
                {props.children}
        </NewsContext.Provider>
    )
}

export default NewsContextProvider;
