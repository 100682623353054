import React, {createRef, Dispatch, SetStateAction} from 'react'

import {ReactComponent as Arrows} from "../../../../../assets/icons/Arrows.svg";

interface IProps {
    setFilter: Dispatch<SetStateAction<number | null>>
}

const ManageStoresDropdown: React.FC<IProps> = ({setFilter}) => {
    const dropdownSort = createRef<HTMLUListElement>();
    const dropdownButton = createRef<HTMLButtonElement>();
    const dropdownCont = createRef<HTMLDivElement>();

    const dropdown = () => {
        const dropdownRef = dropdownSort.current;
        const dropdownButtonRef = dropdownButton.current;

        dropdownRef?.classList.toggle("close");
        dropdownButtonRef?.classList.toggle("open");
    }

    const toggleFilter = (name: number | null) => {
        const dropdownRef = dropdownSort.current;
        const dropdownButtonRef = dropdownButton.current;

        setFilter(name);

        dropdownRef?.classList.toggle("close");
        dropdownButtonRef?.classList.toggle("open");
    }

    return (
        <div className="sort-dropdown" ref={dropdownCont}>
            <button onClick={() => dropdown()} ref={dropdownButton}>Laat zien <Arrows/></button>

            <ul ref={dropdownSort} className="close">
                <li onClick={() => toggleFilter(2)}>Gearchiveerd</li>
                <li onClick={() => toggleFilter(1)}>Dummy's</li>
                <li onClick={() => toggleFilter(0)}>Winkels</li>
                <li onClick={() => toggleFilter(null)}>Alles</li>
            </ul>
        </div>
    )
}

export default ManageStoresDropdown