import React, {useContext, useEffect, useState} from 'react'
import RestEndpoint from '../../../../requests/RestEndpoint';
import {IUser} from '../UserManagement';

import {ReactComponent as ArrowRight} from "../../../../assets/icons/arrow_right_links.svg";
import {ReactComponent as Close} from "../../../../assets/icons/close.svg";
import {Link} from 'react-router-dom';
import {ReactComponent as Edit} from "../../../../assets/icons/edit.svg";
import {generateDate} from '../../../utils/DateTimeHelper';
import {ToastContext} from "../../../../contexts/ToastContext";

interface OwnProps {
    user: IUser,
    endpoint: RestEndpoint,
    errorNotification: Function
    type: string;
    handleUserDelete: (userId: number) => void;
}

type Props = OwnProps;

const User: React.FC<Props> = (props) => {
    const [date, setDate] = useState<Date>();
    const [confirmation, setConfirmation] = useState<boolean>();
    const {notify} = useContext(ToastContext);

    useEffect(() => {
        setDate(generateDate(props.user.created_at))
    }, [])

    const deleteUser = async () => {
        try {
            await props.endpoint.destroy(props.user);
            props.handleUserDelete(props.user.id);
            setConfirmation(false);
            notify(props.type === 'admin' ? `Admin "${props.user.first_name + " " + props.user.last_name}" is verwijderd`
                : `Shopper "${props.user.first_name + " " + props.user.last_name}" is verwijderd`);
        } catch (e) {
            props.errorNotification(e);
        }
    }

    return (
        <div className="user">
            {props.type === 'admin' ?
                <Link to={`/admin-details/${props.user.id}`}><Edit className="edit"/></Link>
                : null}
            <Close className="close" onClick={() => setConfirmation(true)}/>

            <h3>{props.user.first_name} {props.user.last_name}</h3>
            <p>Actief sinds: <b>{date?.getDate()}-{date?.getMonth()! + 1}-{date?.getFullYear()}</b></p>
            <p>Email: <b>{props.user.email_verified_at ? "geverifieerd" : "niet geverifieerd"}</b></p>
            <p>E-mailadres: <b>{props.user.email}</b></p>

            {props.type !== "admin" &&
                <Link className="link-reviews" to={`/admin/shoppers/${props.user.id}`}>Reviews <ArrowRight/></Link>}


            {
                confirmation &&
                <div className="popup-div">
                    <div className="popup">
                        <Close className="close" onClick={() => setConfirmation(false)}/>
                        <h2>{props.user.first_name} {props.user.last_name} verwijderen?</h2>
                        <button className="button" onClick={deleteUser}>Verwijderen</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default User
