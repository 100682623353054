import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useEffect,
  useContext,
} from "react";
import PaymentMethodItem from "./CheckboxItem";
import SubmitChanges from "./SubmitChanges";
import Store from "../../../models/Store";
import RestEndpoint from "../../../requests/RestEndpoint";
import RequestError from "../../../models/RequestError";
import StoreError from "./StoreError";
import PaymentMethod from "../../../models/storemodels/PaymentMethod";
import { ToastContext } from "../../../contexts/ToastContext";

const PaymentMethods: FunctionComponent<{
  formData: Store;
}> = (props) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [error, setError] = useState<RequestError | null>(null);
  const [storePaymentMethods, setStorePaymentMethods] = useState<number[]>(
    props.formData.payment_methods.map((method) => method.id)
  );
  const { notify } = useContext(ToastContext);

  const submitChanges = async () => {
    const endpoint = new RestEndpoint(
      `stores/${props.formData.id}/payment-methods`
    );

    try {
      await endpoint.store({ payment_method_ids: storePaymentMethods });
      notify("Wijzigingen opgeslagen.");
    } catch (e) {
      setError(e);
      notify(e.message, "error");
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const paymentCopy = [...storePaymentMethods];
    const index = paymentCopy.findIndex(
      (paymentMethod) => paymentMethod === id
    );

    const value = e.target.checked;

    if (index > -1) {
      if (!value) {
        paymentCopy.splice(index, 1);
      }
    } else {
      if (value) {
        paymentCopy.push(id);
      }
    }
    setStorePaymentMethods(paymentCopy);
  };

  const paymentMethodList = paymentMethods.map((paymentMethod) => (
    <PaymentMethodItem
      key={"payment_method_" + paymentMethod.id}
      image={paymentMethod.image_url}
      checked={storePaymentMethods.includes(paymentMethod.id)}
      id={paymentMethod.id || -1}
      name={paymentMethod.name}
      handleFieldChange={handleChange}
    />
  ));

  useEffect(() => {
    const paymentMethodEndpoint = new RestEndpoint("payment-methods");
    const getAllMethods = async (): Promise<void> => {
      //@ts-ignore
      const methods: PaymentMethod[] = await paymentMethodEndpoint.all();
      setPaymentMethods(methods);
    };
    getAllMethods().then();
  }, [setPaymentMethods]);

  return (
    <div className="section-body">
      {error ? <StoreError error={error} /> : ""}
      <div className="section-field payment-methods">{paymentMethodList}</div>
      <SubmitChanges submitChanges={submitChanges} />
    </div>
  );
};

export default PaymentMethods;
