import React, {useContext, useEffect, useState} from 'react'
import striptags from "striptags";

// Icons
import {ReactComponent as Navigate} from "../../../assets/icons/navigate.svg";
import {ReactComponent as CouponIcon} from "../../../assets/icons/coupons.svg";
import {ReactComponent as Login} from "../../../assets/icons/login.svg";
import {ReactComponent as FavoriteIcon} from "../../../assets/icons/favorite_store.svg";
import {ReactComponent as FavoriteRemoveIcon} from "../../../assets/icons/favorite_store_remove.svg";
import {ReactComponent as MapSearchResultIcon} from "../../../assets/icons/map_search_result.svg";

// Context
import Store from '../../../models/Store';
import {AuthContext} from '../../../contexts/AuthContext';
import {Link, RouteComponentProps, useHistory} from 'react-router-dom';

// Utils
import {getDistance} from "../../utils/DistanceHelper";
import Navigator from '../../utils/Navigator';
import {StoreContext} from '../../../contexts/StoreContext';
import Popup from '../../utils/Popup';
import RestEndpoint from '../../../requests/RestEndpoint';
import {ToastContext} from '../../../contexts/ToastContext';
import {storeToUrl} from "../../utils/StoreHelper";
import ExpandedCoupon from "../my-coupons-page/ExpandedCoupon";
import {generateDate} from "../../utils/DateTimeHelper";

interface OwnProps {
    store: Store,
    props?: RouteComponentProps,
    dummy?: boolean
}

type Props = OwnProps;

const CityOverviewStore: React.FC<Props> = ({store, props, dummy}) => {
    const {user} = useContext(AuthContext);
    const {favoriteStores, setFavoriteStores} = useContext(StoreContext);
    const {notify} = useContext(ToastContext);

    const [favorite, setFavorite] = useState<boolean>();
    const [showPopup, setShowPopup] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [distance, setDistance] = useState<number>();

    const history = useHistory();

    const checkFavorite = () => {
        return !!favoriteStores.find(favoriteStore => favoriteStore.id === store.id);
    }

    useEffect(() => {
        if (checkFavorite()) {
            setFavorite(true);
        } else {
            setFavorite(false);
        }
    }, [favoriteStores])

    useEffect(() => {
        getDistance(store.latitude, store.longitude)
            .then(distance => setDistance(Math.round(distance * 10) / 10));
    }, [])

    if (store === undefined || store === null) return <div></div>;

    const mqDesktop = window.matchMedia('(min-width: 960px)');

    const toMaps = () => window.location.href = `https://www.google.com/maps/search/${store.city}+${store.house_number}+${store.street}`;

    const redirectStoreDetail = () => history.push(`/winkels/${storeToUrl(store)}`);

    const submitFavoriteStore = async () => {
        if (!user) return;

        if (favorite) {
            const endpoint = new RestEndpoint(`shoppers/${user.id}/favorite-stores`);
            await endpoint.destroy({id: store.id});

            notify("De winkel is uit je favorieten verwijderd.");
            setFavoriteStores(favoriteStores.filter(favoriteStore => favoriteStore.id !== store.id))
            setShowPopup(false);

            return;
        }

        const endpoint = new RestEndpoint(`shoppers/${user.id}/favorite-stores/${store.id}`);

        await endpoint.store({});

        notify("De winkel is aan je favorieten toegevoegd.")
        setFavoriteStores(favoriteStores.concat(store))

        setShowPopup(false);
    }

    const favoriteIcon = () => {
        if (!user) return;
        if (user.role_id === 1 || user.role_id === 2) return;
        if (store.dummy) return;

        if (checkFavorite()) return <FavoriteIcon className="favorite-store-icon" onClick={() => {
            setShowPopup(true)
        }}/>

        return <FavoriteRemoveIcon className="favorite-store-icon-grey" onClick={() => {
            setShowPopup(true)
        }}/>
    }

    return (
        <>
            <Popup
                title={`${store.name} als favoriete winkel ${favorite ? 'verwijderen' : 'toevoegen'}?`}
                buttonText={`${favorite ? 'Verwijderen' : 'Toevoegen'}`}
                buttonFunction={submitFavoriteStore}
                show={showPopup}
                setShow={setShowPopup}
            />

            <div className={dummy ? "city-overview-store city-overview-store--dummy" : "city-overview-store"}
                 style={store.is_horeca ? {cursor: 'default'} : {}}
                 key={store.id}>
                <div
                    style={store.main_image_url !== undefined ? store.is_horeca ? {cursor: 'default', backgroundImage: `url(${store.main_image_url})`} : {backgroundImage: `url(${store.main_image_url})`} : {}}
                    className="city-overview-store__image"
                    onClick={store.is_horeca ? undefined : dummy ? undefined : redirectStoreDetail}>
                </div>

                <div className="city-overview-store__info">
                    {
                        !mqDesktop.matches &&
                        <div className="city-overview-store__info-header">
                            <div className="city-overview-store__name-location-container">
                                <div className="city-overview-store__name-container">
                                    <p className="city-overview-store__name"
                                       style={store.is_horeca ? {cursor: 'default'} : {}}
                                       onClick={dummy ? undefined : store.is_horeca ? undefined : redirectStoreDetail}>{store.name}</p>
                                </div>

                                {distance ?
                                    <div className="city-overview-store__location-container">
                                        <div className="city-overview-store__location">
                                            <MapSearchResultIcon/>
                                            <p>{distance} km</p>
                                        </div>
                                    </div>
                                    :
                                    <>
                                    </>
                                }
                            </div>

                            <div className="city-overview-store__rating-container">
                                {
                                    store?.is_horeca ?
                                        <div></div>
                                        :
                                        dummy ?
                                            <></>
                                            :
                                            <Link
                                                to={{
                                                    pathname: `/winkels/${storeToUrl(store)}`,
                                                    hash: "#reviews",
                                                    state: {goToRatings: true}
                                                }}
                                                className="city-overview-store__rating"
                                            >
                                                {
                                                    <div className="city-overview-store__rating-number">
                                                        <p className="city-overview-store__actual-rating">{
                                                            store.number_of_reviews > 0 ?
                                                                (Math.round(store?.rating * 10) / 10).toString().replace(".", ",")
                                                                :
                                                                '-'
                                                        }</p>
                                                        <p className="city-overview-store__max-rating">/10</p>
                                                    </div>
                                                }                                                
                                                <p className="city-overview-store__number-of-ratings">{store.number_of_reviews} {store.number_of_reviews === 1 ? "recensie" : "recensies"}</p>
                                            </Link>
                                }
                            </div>
                        </div>
                    }

                    {
                        mqDesktop.matches && <p className="city-overview-store__name"
                        style={store.is_horeca ?  {cursor: 'default'} : {}}>
                            {
                                store.is_horeca ?
                                    <p style={{cursor: 'default'}}>{store.name}</p>
                                    :
                                    <a onClick={dummy ? undefined : redirectStoreDetail}>{store.name}</a>
                            }
                            {
                                favoriteIcon()
                            }
                        </p>
                    }

                    {
                        (!user && !store.dummy) && (
                            <div>
                                <p className="information">
                                    {striptags(store.short_description)}
                                </p>
                            </div>
                        )
                    }

                    {
                        !user &&
                        <div
                            className={dummy ? "city-overview-store__login city-overview-store__login--dummy" : "city-overview-store__login"}>
                            <div className={"login"}>
                                <Login className="city-overview-store__login-icon"/>
                                <Link to={`/login`}><b>Log in</b> voor exclusieve aanbiedingen</Link>
                            </div>
                        </div>
                    }

                    {
                        (user && !store.dummy) && (
                            <div>
                                <p className="information">
                                    {striptags(store.short_description)}
                                </p>
                            </div>
                        )
                    }

                    {
                        (user && !store.dummy) &&
                        store.coupons &&
                        store.coupons.length !== 0 && (
                            <div>
                                <p className="title-coupons">Exclusieve Fashion Hotspots aanbiedingen</p>
                            </div>
                        )

                    }

                    {
                        (user && !store.dummy) &&
                        store.coupons &&
                        <div className="coupons">
                            {
                                store.coupons && store.coupons.map((coupon, i) => {
                                    if (!user) return;
                                    if (i > 2) return;

                                    return (
                                        <div key={store.id}>
                                            <div className="coupon" style={{cursor:'pointer'}} key={coupon.id} onClick={() => {setModalOpen(true);}}>
                                                <CouponIcon/>
                                                <p key={coupon.id}>{coupon.description}</p>
                                            </div>
                                            <ExpandedCoupon
                                                isOpen={modalOpen}
                                                storeImage={store?.logo_url}
                                                coupon={coupon}
                                                expiryDate={generateDate(coupon.expiry_date)
                                                    .toLocaleDateString()
                                                    .replace(/\//g, "-")}
                                                closeModal={() => {
                                                    setModalOpen(false);
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        !mqDesktop.matches &&
                        <button className="navigate" onClick={toMaps}>Navigeer <Navigate/></button>
                    }
                </div>

                {
                    mqDesktop.matches &&
                    <div className="city-overview-store__side-info">
                        {dummy == false && store?.is_horeca == false &&
                            <div className="city-overview-store__rating">
                                <div>
                                    {
                                        <Link to={{
                                            pathname: `/winkels/${storeToUrl(store)}`,
                                            hash: "#reviews"
                                        }}>
                                            <p className="city-overview-store__actual-rating">
                                                {
                                                    store.number_of_reviews > 0 ?
                                                        (Math.round(store?.rating * 10) / 10).toString().replace(".", ",")
                                                        :
                                                        '-'
                                                }</p>
                                            <p className="city-overview-store__max-rating">/10</p>
                                        </Link>
                                    }
                                    <p className="city-overview-store__number-of-ratings">{store.number_of_reviews} {store.number_of_reviews === 1 ? "recensie" : "recensies"}</p>
                                </div>
                            </div>
                        }

                        <Navigator store={store}/>
                    </div>
                }
            </div>
        </>
    )
}

export default CityOverviewStore;
