import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import Account from "../../../assets/icons/account.svg";
import Close from "../../../assets/icons/close.svg";
import Review from "../../../models/storemodels/Review";
import {generateDate} from "../../utils/DateTimeHelper";
import {StoreContext} from "../../../contexts/StoreContext";
import Popup from "../../utils/Popup";
import {ToastContext} from "../../../contexts/ToastContext";
import {Link} from "react-router-dom";

const ReviewItem: FunctionComponent<{
    store: { id:number; name: string; city: string };
    review: Review;
    deleteReview: any;
}> = (props) => {
    const dateOptions = {month: "long", day: "numeric", year: "numeric"};
    const { stores } = useContext(StoreContext);

    const {review} = props;
    const reviewDate = generateDate(review.created_at || "");

    const [showDelete, setShowDelete] = useState(false);

    const { notify } = useContext(ToastContext);

    let responseDate = null;
    if (review.child_review) {
        responseDate = generateDate(review.child_review.created_at || "");
    }

    const [responseStore, setResponseStore] = useState<{
        id?: number;
        name?: string;
        logo?: string;
    } | null>(null);
    useEffect(() => {
        if (props.review.child_review) {
            const targetStore = stores.find(
                (store) => store.id === props.review.child_review?.store_id
            );
            setResponseStore({
                id: props.review.child_review.store_id,
                name: targetStore?.name,
                logo: targetStore?.logo_url,
            });
        }
    }, [setResponseStore]);

    return (
        <div
            className="review-shop"
            key={`${props.store.name}_review_${props.review.title}`}
        >
            <Popup
                title={`Recensie verwijderen?`}
                buttonText="Verwijderen"
                buttonFunction={() => {
                    props.deleteReview(props.review.id);
                    notify("De recensie is verwijderd.");
                }}
                show={showDelete}
                setShow={setShowDelete}
            />

            <div className="shop-header">
                <div className="shop-info">
                    <Link to={`/winkels/${props.store.name.replace(/ /g, "-")}-${props.store.city}`} key={`review-store-${props.store.id}`}><h2 className="shop-title">{props.store.name}</h2></Link>
                    <p className="subtitle">{props.store.city}</p>
                </div>
                <img
                    src={Close}
                    alt="Verwijder review"
                    className="my-review-remove"
                    onClick={() => {
                        setShowDelete(true)
                    }}
                />
            </div>
            <div className="shop-body">
                <div className="review">
                    <div className="review-header">
                        <div className="review-header-row">
                            <h5 className="my-review-title">{props.review.title}</h5>
                            <p className="my-review-grade">
                                <span className="my-review-rating">{props.review.rating}</span>
                                /10
                            </p>
                        </div>

                        <p className="my-review-date">
                            {reviewDate.toLocaleDateString("nl-NL", dateOptions)}
                        </p>
                    </div>
                    <div className="review-body">
                        <i className="my-review-text">{props.review.content}</i>
                    </div>
                    {review.child_review && (
                        <div className="review-response">
                            <div className="response-image">
                                <img src={responseStore?.logo || Account} alt="" />
                                <h5 className="my-review-response-title mobile-hidden">
                                    {responseStore?.name}
                                </h5>
                            </div>
                            <div className="response">
                                <div className="response-title">
                                    <h5 className="my-review-response-title web-hidden">
                                        {responseStore?.name}
                                    </h5>
                                    <p className="my-review-response-date">
                                        {responseDate?.toLocaleDateString("nl-NL", dateOptions)}
                                    </p>
                                </div>

                                <div className="response-body">
                                    <i className="my-review-response-text">
                                        {review.child_review.content}
                                    </i>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReviewItem;
