import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import SellingPoint from "../models/SellingPoint";

interface OwnProps {

}

interface ISellingPointContext {
    sellingPoints: SellingPoint[],
    getAllSellingPoints: () => Promise<void>
}

const sellingPointEndPoint = new RestEndpoint('selling-points');

export const SellingPointContext = createContext({} as ISellingPointContext);

type Props = OwnProps;

const SellingPointContextProvider: FunctionComponent<Props> = (props) => {
    const [sellingPoints, setSellingPoints] = useState<SellingPoint[]>([]);

    useEffect(() => {
        getAllSellingPoint().then()
    }, [])

    const getAllSellingPoint = async (): Promise<void> => {
        // @ts-ignore
        const sellingPoints: SellingPoint[] = await sellingPointEndPoint.all();
        // @ts-ignore
        setSellingPoints(sellingPoints);
    }

    return (
        <SellingPointContext.Provider
            value={{sellingPoints: sellingPoints, getAllSellingPoints: getAllSellingPoint}}>
                {props.children}
        </SellingPointContext.Provider>
    )
}

export default SellingPointContextProvider;
