import React, { useEffect, useState } from 'react'
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

interface OwnProps {
    content: string,
    onChange: Function
}

type Props = OwnProps;

const HTMLEditor: React.FC<Props> = (props) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                htmlToDraft(props.content).contentBlocks,
                htmlToDraft(props.content).entityMap
            )
        ))
    }, [])

    useEffect(() => {
        props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }, [editorState])

    return (
        <div className="page-content-editor">
            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                localization={{ locale: "nl" }}
                wrapperClassName="news-wrapper"
                editorClassName="news-editor"
                toolbar={{
                    options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                    ],
                }}
            />
        </div>
    )
}

export default HTMLEditor;