import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import Store from "../../../models/Store";
import SubmitChanges from "./SubmitChanges";
import ImageUpload from "../../utils/ImageUpload";
import { CityContext } from "../../../contexts/CityContext";
import Select from "react-select";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHTML from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";

interface Props {
    formData: Store;
    handleFormChange: any;
    submitChanges: any;
    handleCitySelect: any;
    handleImageUpload: any;
    isHoreca?: boolean;
}

const GeneralInfo: FunctionComponent<Props> = (props) => {
    const { cities } = useContext(CityContext);
    const cityOptions = cities.map((city) => ({
        value: city.id,
        label: city.name,
    }));

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleEditorChange = (state: EditorState) => {
        setEditorState(state);

        const editorHTML = draftToHTML(convertToRaw(state.getCurrentContent()));

        props.handleFormChange({ target: { value: editorHTML, id: "information" } })
    }

    useEffect(() => {
        const blocksFromHTML = htmlToDraft(props.formData.information ? props.formData.information : "");
        const { contentBlocks, entityMap } = blocksFromHTML;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
    }, [])

    return (
        <div className="section-body">
            <div className="section-row">
                <div className="section-field shop-logo">
                    <h4>Logo {props.isHoreca ? "Horeca" : "Winkel"}</h4>
                    <ImageUpload
                        setImage={props.handleImageUpload}
                        currentImageUrl={props.formData.logo_url}
                    />
                    <div className="image-size">300 x 300px</div>
                </div>
            </div>
            <div className="section-row">
                <div className="section-field">
                    <label htmlFor="name">Naam {props.isHoreca ? "horeca" : "winkel"}</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder={`Naam ${props.isHoreca ? "horeca" : "winkel"}`}
                        defaultValue={props.formData.name || ""}
                        onChange={props.handleFormChange}
                    />
                </div>
            </div>
            <div className="section-row">
                <div className="section-field">
                    <label htmlFor="website">Website</label>
                    <p>
                        De link naar de website moet van het volgende formaat zijn:
                        http://www.google.com/. Vergeet http (of https) en een slash (/) op
                        het einde niet.
          </p>
                    <input
                        type="text"
                        name="website"
                        id="website"
                        placeholder="Link naar website"
                        defaultValue={props.formData.website || ""}
                        onChange={props.handleFormChange}
                    />
                </div>
            </div>
            <div className="section-row">
                <div className="section-field">
                    <label htmlFor="street">Straatnaam</label>
                    <input
                        type="text"
                        name="street"
                        id="street"
                        placeholder="Straatnaam"
                        defaultValue={props.formData.street || ""}
                        onChange={props.handleFormChange}
                    />
                </div>
                <div className="section-field">
                    <div className="house-number-zip-code">
                        <div className="house-number">
                            <label htmlFor="house_number">Huisnr.</label>
                            <input
                                type="text"
                                name="house_number"
                                id="house_number"
                                placeholder="Huisnr."
                                defaultValue={props.formData.house_number || ""}
                                onChange={props.handleFormChange}
                            />
                        </div>
                        <div className="zip-code">
                            <label htmlFor="zip_code">Postcode</label>
                            <input
                                type="text"
                                name="zip_code"
                                id="zip_code"
                                placeholder="Postcode"
                                defaultValue={props.formData.zip_code || ""}
                                onChange={props.handleFormChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-row">
                <div className="section-field">
                    <label htmlFor="city">Plaats</label>
                    <Select
                        options={cityOptions}
                        onChange={props.handleCitySelect}
                        noOptionsMessage={() => "Geen steden gevonden"}
                        value={cityOptions.find(
                            (option) => option.value === props.formData.city_id
                        )}
                        placeholder="Selecteer een stad"
                    />
                </div>
                <div className="section-field">
                    <label htmlFor="phone">Telefoonnummer</label>
                    <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Telefoonnummer"
                        defaultValue={props.formData.phone || ""}
                        onChange={props.handleFormChange}
                    />
                </div>
            </div>
            <div className="section-row">
                <div className="section-field about-store">
                    <label htmlFor="information">Over deze {props.isHoreca ? "horeca" : "winkel"}</label>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        localization={{ locale: "nl" }}
                        wrapperClassName="news-wrapper"
                        editorClassName="news-editor"
                        toolbar={{
                            options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                "list",
                                "textAlign",
                                "link",
                                "emoji",
                                "remove",
                                "history",
                            ],
                        }}
                    />
                </div>
            </div>
            <div className="section-row">
                <div className="section-field short-about-store">
                    <label htmlFor="information">Kleine beschrijving over deze {props.isHoreca ? "horeca" : "winkel"}</label>
                    <input
                        type="text"
                        name="short_description"
                        id="short_description"
                        placeholder="Geef een kleine beschrijving voor op de overzichtpagina's"
                        defaultValue={props.formData.short_description || ""}
                        onChange={props.handleFormChange}
                    />
                </div>
            </div>

            <SubmitChanges submitChanges={props.submitChanges} />
        </div>
    );
};

export default GeneralInfo;
