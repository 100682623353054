import Model from "../models/Model";

class PageContent extends Model {
    id: number = 0;
    location: string = '';
    content: string = "";
    created_at: string = "";
    group: string | null = null;
    is_html: boolean = false;

    constructor(pageContent?: PageContent) {
        super();
        Object.assign(this, pageContent);
    }
}

export default PageContent;
