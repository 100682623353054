import City from "../../../models/City";
import React, { FunctionComponent, useContext } from "react";
import { StoreContext } from "../../../contexts/StoreContext";
import StoreListItem from "./StoreListItem";
import RestEndpoint from "../../../requests/RestEndpoint";

const CityListItem: FunctionComponent<{
  city: City;
}> = (props) => {
  const { stores } = useContext(StoreContext);
  const storesForCity = stores.filter(
    (store) => store.city_id === props.city.id
  );

  const setSotm = async (storeId: number) => {
    const endpoint = new RestEndpoint('cities');
    const data = { ...props.city };
    data.store_id = storeId;
    await endpoint.update(data);
    window.location.reload();
  };

  return (
    <div className="city-row">
      <h2>{props.city.name}</h2>
      <div className="city-row-content">
        {storesForCity.map((store) => (
          <StoreListItem
            store={store}
            currentSotm={props.city.store_id}
            setSotm={setSotm}
          />
        ))}
      </div>
    </div>
  );
};

export default CityListItem;
