import React from 'react';
import { ReactComponent as Check } from "../../assets/icons/check_white_checkbox.svg";

interface IProps {
    checked: boolean;
    onClick: () => void;
    required?: boolean;
}

const CustomCheck: React.FC<IProps> = ({ checked, onClick, required }) => {
    return (
        <div className="checkbox-container">
            <input type="checkbox" onClick={onClick}
                checked={checked}
                onChange={() => ""}
                required={required}></input>

            <div className="checkmark" >
                <Check />
            </div>
        </div>
    )
}

export default CustomCheck;