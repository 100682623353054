import React, {FunctionComponent, useContext, useState} from "react";
import RestEndpoint from "../../../../../requests/RestEndpoint";
import {Link} from "react-router-dom";
import {ReactComponent as IDCard} from "../../../../../assets/icons/id_card.svg";
import {ReactComponent as Delete} from "../../../../../assets/icons/delete.svg";
import {ReactComponent as Tags} from "../../../../../assets/icons/tags.svg";
import {StoreDetails} from "../../../../../models/StoreDetails";
import Popup from "../../../../utils/Popup";
import {ToastContext} from "../../../../../contexts/ToastContext";

interface IProps {
    horeca: StoreDetails,
    setHorecaReset: React.Dispatch<React.SetStateAction<boolean>>
}

const HorecaItem: FunctionComponent<IProps> = (props) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const {notify, errorNotification} = useContext(ToastContext);

    const deleteStore = async () => {
        const endpoint = new RestEndpoint("users");
        try {
            await endpoint.destroy({id: props.horeca.id});
            props.setHorecaReset(true);
            notify("Horeca verwijderd.", "warning");
        } catch (e) {
            errorNotification(e);
        }
    };

    const date = new Date(props.horeca.created_at).toLocaleDateString();

    return (
        <div className="store-item user">
            <div className="store-item-buttons">
                {
                    !props.horeca.dummy &&
                    <Link to={`/admin/beheer-winkels/${props.horeca.store_id}/kleding-categorieen`}>
                        <Tags/>
                    </Link>
                }
                {
                    !props.horeca.dummy &&
                    <Link to={`/winkel-eigenaar-details/${props.horeca.id}`}>
                        <IDCard/>
                    </Link>
                }

                <Delete onClick={() => setShowConfirmation(true)}/>
            </div>

            <Popup
                title={`${props.horeca.store} verwijderen?`}
                buttonText="Verwijderen"
                buttonFunction={deleteStore}
                show={showConfirmation}
                setShow={setShowConfirmation}
            />

            <h3 className="store-name">{`${props.horeca.store} (${props.horeca.city})` || "Onbekend"}</h3>
            <p>Actief sinds: <b>{date || "Onbekend"}</b></p>
            <p>Naam eigenaar: <b>{props.horeca.first_name} {props.horeca.last_name}</b></p>
            <p>Aantal reviews: <b>{props.horeca.number_of_reviews} (gemiddeld {props.horeca.rating})</b></p>
        </div>
    );
};

export default HorecaItem;
