import React, {FunctionComponent, useContext} from 'react';
import { Helmet } from 'react-helmet';
import {PageContentContext} from "../../contexts/PageContentContext";

interface OwnProps {
}

type Props = OwnProps;

const RegisterStorePage: FunctionComponent<Props> = (props) => {
    const {pageContents} = useContext(PageContentContext);
    const registrationStoreContent = pageContents.find(pageContent => pageContent.location === 'registration-store')?.content;

    return (
        <div className="text-content">
            <Helmet>
                <title>Meld je winkel aan en we zorgen samen voor meer winkelbezoeken - Fashion Hotspots</title>
                <meta name="description" content={`Is jouw fashionwinkel een echte hotspot van jouw stad? Wil je (nog) meer winkelbezoeken? Meld jouw winkel dan aan als Fashion Hotspot van jouw stad.`} />

            </Helmet>
            <div dangerouslySetInnerHTML={{__html: registrationStoreContent!}}></div>
        </div>
    );
};

export default RegisterStorePage;
