import React, { FunctionComponent, useContext } from "react";
import { ReactComponent as ArrowWhite } from "../../assets/icons/arrow_right_white_buttons.svg";
import RestEndpoint from "../../requests/RestEndpoint";
import { AuthContext } from "../../contexts/AuthContext";
import { Helmet } from "react-helmet";
import { PageContentContext } from "../../contexts/PageContentContext";

const CancelAccount: FunctionComponent = (props) => {
    const { user } = useContext(AuthContext);
    const deleteAccount = async () => {
        const endpoint = new RestEndpoint(`shoppers`);
        await endpoint.destroy({ id: user?.id });
        window.location.reload();
    };

    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'cancel-account')?.content;

    return (
        <div className="cancel-account">
            <Helmet>
                <title>FH - Account opzeggen</title>
                <meta name="description" content="Zeg hier je account op." />
            </Helmet>

            <div className="cancel-account__content">
                    <div dangerouslySetInnerHTML={{ __html: content! }} />
                <button className="cancel-account__button" onClick={deleteAccount}>
                    <p>Account opzeggen <ArrowWhite /></p>
                </button>
            </div>
        </div>
    );
};

export default CancelAccount;
