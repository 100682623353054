import React, { useEffect, useState } from 'react'
import { Sort } from '../../../CityOverview';

/**
 * @desc The interface for the function component SortListMobile below.
 */
interface IProps {
    getSort: () => Sort;
    setSort: (sort: Sort) => void;
}

/**
 * This component will be included in the mobile FilterMenu in comparison to the desktop version which will be presented above the results in CityOverview.
 * @component 
 * @author Sem van Koutrik
 * @param {() => Sort} getSort - Contains the properties by which the stores in CityOverview the sorted including the direction these will be sorted in.
 * @param {(sort: Sort) => void} setSort - The setStateAction for the state sort.
 */
const SortListMobile: React.FC<IProps> = ({ getSort, setSort  }) => {

    const [locationAvailable, setLocationAvailable] = useState<boolean>(false)

    useEffect(() => {
        window.navigator.permissions.query({name: "geolocation"}).then((result) => {
            if (result.state === 'granted') {
                setLocationAvailable(true)
            } else if (result.state === 'denied') {
                setLocationAvailable(false)
            }
        })
    }, [])

    return (
        <div className="filter-menu-category-items">
            <div className="sort-option">
                <div className="radio-container">
                    <input 
                        type="radio" 
                        value="rating"
                        name="sort" 
                        onClick={() => setSort({sorteer_op: "beoordeling", sorteer_richting: "aflopend"})}
                        checked={getSort().sorteer_op === "beoordeling"}
                    />

                    <div className="radio-outline">
                        <div className="radio-ellipse"></div>
                    </div>
                </div>

                <p>Beoordeling</p>
            </div>

            {locationAvailable && <div className="sort-option">
                <div className="radio-container">
                    <input 
                        type="radio" 
                        value="rating"
                        name="sort" 
                        onClick={() => setSort({sorteer_op: "afstand", sorteer_richting: "oplopend"})}
                        checked={getSort().sorteer_op === "afstand"}
                    />

                    <div className="radio-outline">
                        <div className="radio-ellipse"></div>
                    </div>
                </div>

                <p>Afstand</p>
            </div>}

            <div className="sort-option">
                <div className="radio-container">
                    <input 
                        type="radio" 
                        value="A-Z"
                        name="sort" 
                        onClick={() => setSort({sorteer_op: "naam", sorteer_richting: "oplopend"})} 
                        checked={getSort().sorteer_op === "naam"} 
                    />

                    <div className="radio-outline">
                        <div className="radio-ellipse"></div>
                    </div>
                </div>

                <p>A-Z</p>
            </div>
        </div>
    )
}

export default SortListMobile;
