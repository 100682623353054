import React, { createContext, FunctionComponent, useState } from 'react';
import FAQ from '../models/FAQ';
import FAQCategory from '../models/FAQCategory';
import FAQSubCategory from '../models/FAQSubCategory';
import Image from '../models/storemodels/Image';
import AdminFAQEndpoint from '../requests/admin/AdminFAQEndpoint';
import RequestHelper from '../requests/RequestHelper';

interface IAdminFAQContext {
    getAllCategories: Function,
    getSubCatQuestions: Function,
    updateQuestion: Function,
    faqCategories?: FAQCategory[],
    setFaqCategories: Function,
    getSubCat: Function,
    updateSubCat: Function,
    getQuestion: Function,
    deleteQuestion: Function
    deleteSubCat: Function,
    addQuestion: Function,
    updateMainCat: Function,
    storeSubCat: Function,
    getMainCat: Function
}

interface MainCatUpload {
    id: number,
    name: string,
    header?: Image
}

interface OwnProps {

}

type Props = OwnProps;

const endpoint = new AdminFAQEndpoint();

class NewQuestion {
    faq_category: number = 0;
    faq_subcategory: number = 0;
    question: string = "";
    answer: string = "";
}

export const AdminFAQContext = createContext({} as IAdminFAQContext);

const AdminFAQContextProvider: FunctionComponent<Props> = (props) => {
    const [faqCategories, setFaqCategories] = useState<FAQCategory[]>();

    const getAllCategories = async () => {
        // @ts-ignore
        let mainCats: MainCategory[] = await endpoint.all();

        setFaqCategories(mainCats)

        return mainCats;
    }

    const addQuestion = async (qa: NewQuestion) => {
        try {
            return await endpoint.addQuestion({ faq_category: qa.faq_category, faq_subcategory: qa.faq_subcategory }, { question: qa.question, answer: qa.answer })
        } catch (e) {
            throw Error(e);
        }
    }

    const getQuestion = async (subCat: FAQSubCategory, question: FAQ) => {
        try {
            return await endpoint.getQuestion(subCat, question);
        } catch (e) {
            throw Error(e);
        }
    }

    const getSubCatQuestions = async (cat: FAQSubCategory) => {
        try {
            return await endpoint.getQuestions({ faq_category: cat.f_a_q_category_id, faq_subcategory: cat.id });
        } catch (e) {
            throw new Error(e);
        }
    }

    const updateQuestion = async (subCat: FAQSubCategory, question: FAQ) => {
        try {
            await endpoint.updateQuestion({ faq_category: subCat.f_a_q_category_id, faq_subcategory: subCat.id, faq: question.id }, { question: question.question, answer: question.answer });
        } catch (e) {
            return e;
        }
    }

    const deleteQuestion = async (subCat: FAQSubCategory, question: FAQ) => {
        try {
            await endpoint.deleteQuestion({ faq_category: subCat.f_a_q_category_id, faq_subcategory: question.f_a_q_sub_category_id, faq: question.id });
        } catch (e) {
            return e;
        }
    }

    const getSubCat = async (subCat: FAQSubCategory) => {
        try {
            return await endpoint.getSubCat(subCat);
        } catch (e) {
            throw Error(e);
        }
    }

    const updateSubCat = async (subCat: FAQSubCategory) => {
        try {
            await endpoint.updateSubCategory(subCat, { name: subCat.name, header: subCat.header, logo: subCat.logo })
        } catch (e) {
            throw new Error(e);
        }
    }

    const deleteSubCat = async (subCat: FAQSubCategory) => {
        try {
            await endpoint.deleteSubCategory(subCat);
        } catch (e) {
            return e;
        }
    }

    const storeSubCat = async (subCat: { faq_category: number, name: string, header?: Image, logo?: Image }) => {
        try {
            await endpoint.storeSubCategory(subCat);
        } catch (e) {
            return e;
        }
    }

    const getMainCat = async (mainCat: FAQCategory) => {
        try {
            return await endpoint.getMainCat(mainCat);
        } catch (e) {
            throw new Error(e)
        }
    }

    const updateMainCat = async (mainCat: MainCatUpload) => {
        try {
            const mainCatFormData = RequestHelper.convertToFormData(mainCat)
            return await endpoint.update(mainCatFormData);
        } catch (e) {
            throw new Error(e)
        }
    }

    return (
        <AdminFAQContext.Provider value={{ getSubCatQuestions, updateQuestion, getAllCategories, faqCategories, setFaqCategories, updateSubCat, deleteQuestion, deleteSubCat, getQuestion, getSubCat, addQuestion, updateMainCat, storeSubCat, getMainCat }} >
            {props.children}
        </AdminFAQContext.Provider>
    )
}

export default AdminFAQContextProvider
