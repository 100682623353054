import RestEndpoint from "./RestEndpoint";

class CouponEndpoint extends RestEndpoint {
    constructor() {
        super('stores');
    }

    getCoupons(storeId: number) {
        return this.requestHelper.get(`${this.endpoint}/${this.resource}/${storeId}/coupons`);
    }
}

export default CouponEndpoint;