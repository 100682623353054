import React, {FormEvent, FunctionComponent, useContext, useEffect, useState} from 'react';
import RestEndpoint from "../../../requests/RestEndpoint";
import {AuthContext} from "../../../contexts/AuthContext";
import Image from "../../../models/storemodels/Image";
import ImageUpload from "../../utils/ImageUpload";

import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import RequestHelper from "../../../requests/RequestHelper";
import {Helmet} from 'react-helmet';
import {PageContentContext} from '../../../contexts/PageContentContext';
import {ToastContext} from "../../../contexts/ToastContext";

interface OwnProps {
}

type Props = OwnProps;

const StoreImagesPage: FunctionComponent<Props> = (props) => {
    const {user} = useContext(AuthContext);
    const {pageContents} = useContext(PageContentContext);
    const {notify} = useContext(ToastContext)
    const [deleteConfirmationId, setDeleteConfirmationId] = useState<number>();

    const content = pageContents.find(pageContent => pageContent.location === 'store-images')?.content;

    const storeImagesEndpoint = new RestEndpoint(`stores/${user?.store?.id}/images`);

    const [currentImages, setCurrentImages] = useState<Image[]>([]);
    const [uploadedImage, setUploadedImage] = useState<HTMLImageElement>();

    useEffect(() => {
        getImagesOfStore().then();
    }, []);

    const getImagesOfStore = async () => {
        // @ts-ignore
        const tempImages: Image[] = await storeImagesEndpoint.all();
        setCurrentImages(tempImages);
    };

    const handleImageUpload = (image: HTMLImageElement) => {
        setUploadedImage(image);
    };

    const handleDeleteImage = async (imageId?: any) => {
        await storeImagesEndpoint.destroy({id: imageId});
        setCurrentImages(currentImages.filter(currentImage => currentImage.id !== imageId));
        setDeleteConfirmationId(undefined);
        notify("De afbeelding is verwijderd.");
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const uploadedImageFormData = RequestHelper.convertToFormData({
            image: uploadedImage
        });
        await storeImagesEndpoint.store(uploadedImageFormData);

        setUploadedImage(undefined);
        getImagesOfStore().then();
        notify("De afbeelding is opgeslagen.");
    };

    const imagesHTML = currentImages.map((image, key) => {
        return (
            <div key={image.id} className="image-item">
                <div className="image-number">{key + 1}</div>
                <div className="image-container">
                    <img src={image.url} alt=""/>
                    <div className="image-delete" onClick={() => setDeleteConfirmationId(image?.id)}>
                        <Close/>
                    </div>
                </div>
            </div>
        )
    });

    return (
        <div className="store-admin store-images">
            <Helmet>
                <title>FH - Mijn foto's</title>
                <meta name="description" content="Mijn foto's."/>
            </Helmet>

            <div dangerouslySetInnerHTML={{__html: content!}}/>
            <form onSubmit={handleSubmit}>
                <h2>VUL JE ALBUM</h2>
                <div className="image-upload-items">
                    {imagesHTML}
                    <div>
                        <div className="image-number">{currentImages.length + 1}</div>
                        <ImageUpload key={currentImages.length + 1} setImage={handleImageUpload}/>
                        <div className="image-size">Aanbevolen resolutie: 1270 x 716px</div>
                    </div>
                </div>
                <button>Opslaan</button>
            </form>


            {
                deleteConfirmationId &&
                <div className="popup-div">
                    <div className="popup">
                        <Close className="close" onClick={() => setDeleteConfirmationId(undefined)} />
                        <h2>Afbeelding verwijderen?</h2>
                        <button className="button" onClick={() => {handleDeleteImage(deleteConfirmationId); setDeleteConfirmationId(undefined)}}>Verwijderen</button>
                    </div>
                </div>
            }
        </div>


    );
};

export default StoreImagesPage;
