import React, { FunctionComponent } from "react";
import OpeningTimeRow from "./OpeningTimeRow";
import OpeningHoursForHoliday from "../../../models/storemodels/OpeningHoursForHoliday";
const SpecialTimesRow: FunctionComponent<{
  data: OpeningHoursForHoliday;
  handleOpeningTimesChange: any;
  removeSpecialOpeningTime: any;
}> = (props) => {
  if (props.data) {
    return (
      <>
        <tr>
          <td className="wider add-date">
            <input
              type="text"
              className="special-time-description"
              placeholder="Bijv. 26 december (2e Kerstdag)"
              defaultValue={props.data.date || ""}
              id={props.data.id + "_special_date"}
              onChange={(e) => {
                props.handleOpeningTimesChange(e, props.data.id, true);
              }}
            />
          </td>
          <td> </td>
          <td></td>
          <td className="spacer"></td>
          <td></td>
          <td></td>
          <td className="spacer"></td>
          <td className="remove-special-date">
            <span
              onClick={() => {
                props.removeSpecialOpeningTime(props.data.id);
              }}
            >
              &#9587;
            </span>
          </td>
        </tr>
        <OpeningTimeRow
          day={null}
          data={props.data}
          handleOpeningTimesChange={props.handleOpeningTimesChange}
          isSpecialOpeningTime
        />
      </>
    );
  } else {
    return null;
  }
};

export default SpecialTimesRow;
