import React, { useEffect, useState } from 'react'

interface OwnProps {
    id?: string,
    className?: string | string[],
    placeholder?: string,
    onChange: Function,
    value: string | number,
    type: string,
    required?: boolean,
    name?: string
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

type Props = OwnProps;

const StandardInput: React.FC<Props> = (props) => {
    const [classNames, setClassNames] = useState<string>();

    useEffect(() => {
        if(Array.isArray(props.className)) {
            setClassNames(props.className.reduce((acc, name)  => acc += ` ${name}`))
        } else {
            setClassNames(props.className)
        }
    }, [props.className])

    return (
        <input 
            onChange={(e) => props.onChange(e)}
            placeholder={props.placeholder ? props.placeholder : ""}
            value={props.value}
            required={props.required}
            id={props.id ? props.id : ""}
            type={props.type}
            className={`standard-input ${classNames}`}
            name={props.name ? props.name : undefined}
            onBlur={props.onBlur || undefined}
        />
    )
}

export default StandardInput
