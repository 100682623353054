import React, {FunctionComponent, useEffect, useState} from 'react';
import {ReactComponent as NavigateIcon} from "../../assets/icons/navigate.svg";
import {ReactComponent as MapSearchResultIcon} from "../../assets/icons/map_search_result.svg";
import Store from "../../models/Store";
import {getDistance} from "./DistanceHelper";

interface OwnProps {
    store: Store
}

type Props = OwnProps;

const Navigator: FunctionComponent<Props> = (props) => {

    const {store} = props;
    const [distance, setDistance] = useState(0);

    useEffect(() => {
        showDistance().then();
    }, [store.id]);

    const showDistance = async () => {
        if (!store.longitude || !store.latitude) {
            return;
        }

        const distanceBetween = await getDistance(store.latitude, store.longitude);
        setDistance(distanceBetween);
    }

    return (

        <div className={"navigator"}>
            <a href={`https://www.google.com/maps/search/${store.street} ${store.house_number}, ${store.city}`}
               target="_blank" className="navigate-title" rel="noopener noreferrer">
                <span className="navigate-title">Navigeer<NavigateIcon/></span>
            </a>
            {distance ?
                <span className="navigate-distance"><MapSearchResultIcon/>
                    {(Math.round(distance * 10) / 10).toFixed(1)}km</span>
                :
                <span className="navigate-distance"></span>
            }
        </div>
    );
};

export default Navigator;
