import React, { FunctionComponent } from "react";
import CityList from "./manage-store-of-the-month-components/CityList";
import { Helmet } from "react-helmet";

const ManageStoreOfTheWeek: FunctionComponent = (props) => {
  return (
    <div className="manage-store-of-the-week">
      <Helmet>
        <title>FH - Winkel van de week beheer</title>
      </Helmet>
      <div className="header">
        <h1>Winkel van de Week Beheren</h1>
        <p className="subtitle">
          Op deze pagina kun je per stad een winkel van de week selecteren. De
          huidige winkel van de week is aangegeven met een winkel met een ster
          boven in de hoek. Klik op het sterretje in de hoek van een winkel om
          deze als winkel van de week te selecteren.
        </p>
      </div>
      <div className="body">
        <CityList />
      </div>
    </div>
  );
};

export default ManageStoreOfTheWeek;
