import RestEndpoint from "./RestEndpoint";
import ContactData from "../models/ContactData";

class ContactEndpoint extends RestEndpoint {
    constructor() {
        super('contact');
    }

    async sendContactData(data: ContactData) {
        try {
            await this.requestHelper.post(`${this.endpoint}/${this.resource}`, data);
        } catch (requestError) {
            return requestError;
        }
    }
}

export default ContactEndpoint;