import { convertToRaw, EditorState } from "draft-js";
import draftToHTML from "draftjs-to-html";
import React, { ChangeEvent, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

interface Props {
    submit: (question: NewQuestion) => void;
}

class NewQuestion {
    faq_category: number = 0;
    faq_subcategory: number = 0;
    question: string = "";
    answer: string = "";
}

const NewQandA: React.FC<Props> = (props) => {
    const [question, setQuestion] = useState(new NewQuestion());
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const editQuestion = (e: ChangeEvent<HTMLInputElement>) => {
        setQuestion({ ...question, question: e.target.value });
    }

    const handleSubmitQandA = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        try {
            await props.submit({ ...question, answer: draftToHTML(convertToRaw(editorState.getCurrentContent())) });
            setQuestion(new NewQuestion());
        } catch (e) {

        }
    }

    return (
        <form onSubmit={e => handleSubmitQandA(e)}>
            <h2>Nieuwe vraag</h2>

            <input
                onChange={(e) => editQuestion(e)}
                placeholder={question?.question}
                value={question?.question}
                className={"q-input"}
            />

            <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                localization={{ locale: "nl" }}
                wrapperClassName="news-wrapper"
                editorClassName="news-editor"
                toolbar={{
                    options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "textAlign",
                        "link",
                        "emoji",
                        "remove",
                        "history",
                    ],
                }}
            />

            <div className="buttons">
                <button type="submit">Toevoegen</button>
            </div>
        </form>
    )
}

export default NewQandA;