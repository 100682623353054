import React, { ChangeEvent, FunctionComponent, useState } from "react";
import Close from "../../../../assets/icons/close.svg";
import ImageUpload from "../../../utils/ImageUpload";
import StandardInput from "../../../utils/StandardInput";
import { ISellingPoint } from "./SellingPoints";

const SellingPointRow: FunctionComponent<{
    sellingPoint: ISellingPoint;
    updateParent: (id: number, field: string, value: any) => void;
    removeSellingPoint: (id: number) => Promise<void>;
}> = (props) => {
    const [title, setTitle] = useState(props.sellingPoint.title);
    const [content, setContent] = useState(props.sellingPoint.content);

    return (
        <div className="selling-point-row row">
            <img
                src={Close}
                alt=""
                className="delete"
                onClick={() => {
                    props.removeSellingPoint(props.sellingPoint.id);
                }}
            />

            <ImageUpload
                setImage={(image) => {
                    const pointCopy = { ...props.sellingPoint };
                    pointCopy.image = image;
                    props.updateParent(props.sellingPoint.id, "image", image);
                }}
                currentImageUrl={props.sellingPoint.image_url}
            />

            <div className="selling-point-input">
                <div className="input">
                    <label htmlFor={`${props.sellingPoint.id}_title`}>Titel</label>

                    <StandardInput
                        type="text"
                        required
                        id={`${props.sellingPoint.id}_title`}
                        value={title}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setTitle(e.target.value);
                        }}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                            props.updateParent(
                                props.sellingPoint.id,
                                "title",
                                event.target.value
                            );
                        }}
                    />
                </div>

                <div className="input">
                    <label htmlFor={`${props.sellingPoint.id}_content`}>Inhoud</label>

                    <StandardInput
                        type="text"
                        required
                        id={`${props.sellingPoint.id}_content`}
                        value={content}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setContent(e.target.value);
                        }}
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                            props.updateParent(
                                props.sellingPoint.id,
                                "content",
                                event.target.value
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SellingPointRow;
