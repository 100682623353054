import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {ReactComponent as ArrowDownLink} from '../../assets/icons/arrow_down_links.svg';
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow_right_links.svg';
import {AuthContext} from '../../contexts/AuthContext';
import {BannerContext} from '../../contexts/BannerContext';
import {BrandContext} from '../../contexts/BrandContext';
import {CityContext} from '../../contexts/CityContext';
import {NewsContext} from '../../contexts/NewsContext';
import {PageContentContext} from '../../contexts/PageContentContext';
import {StoreContext} from '../../contexts/StoreContext';
import CityList from '../utils/CityList';
import CityPictures from './home-page-components/CityPictures';
import Search from './home-page-components/Search';
import SellingPoints from './home-page-components/SellingPoints';
import {storeToUrl} from "../utils/StoreHelper";
import {removeSpecialChars, stringToUrl} from "../utils/UrlHelper";
import {Helmet} from 'react-helmet';

const Home: FunctionComponent<RouteComponentProps> = (props) => {
    const {stores} = useContext(StoreContext);
    const {cities} = useContext(CityContext);
    const {brands} = useContext(BrandContext);
    const {newsItems} = useContext(NewsContext);
    const {user} = useContext(AuthContext);
    const {banners} = useContext(BannerContext);
    const {pageContents} = useContext(PageContentContext);

    const [locationAvailable, setLocationAvailable] = useState<boolean>(false)

    const newestStores = () => {
        let sortedByDate = stores.sort((d1, d2) => d1?.created_at > d2?.created_at ? -1 : d1?.created_at < d2?.created_at ? 1 : 0);

        sortedByDate = [sortedByDate[0], sortedByDate[1], sortedByDate[2]];

        return sortedByDate.map(store => {
            if (!store) return;

            return (
                <div className="store-card-container" onClick={() => redirectToStore(storeToUrl(store))} key={store.id}>
                    <div className="store-card">
                        <div className="image" style={{backgroundImage: `url(${store.main_image_url})`}}></div>
                        <div className="text">
                            <p className="name">{store.name}</p>
                            <p className="city">{store.city}</p>
                        </div>
                    </div>
                </div>
            )
        })
    }

    useEffect(() => {
        window.navigator.permissions.query({name: "geolocation"}).then((result) => {
            if (result.state === 'granted') {
                setLocationAvailable(true)
            } else if (result.state === 'denied') {
                setLocationAvailable(false)
            }
        })
    }, [])

    const showNewsItems = () => {
        if (!newsItems) return <></>;

        let sortedByDate = newsItems.sort((d1, d2) => d1?.created_at > d2?.created_at ? -1 : d1?.created_at < d2?.created_at ? 1 : 0);

        sortedByDate = [sortedByDate[0], sortedByDate[1], sortedByDate[2]];

        return sortedByDate.map(newsItem => {
            if (!newsItem) return;

            const date = new Date(newsItem.created_at.replace(/ /g, "T"));

            let months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "July", "Augustus", "September", "October", "November", "December"];

            return (
                <div className="news-card-container"
                     onClick={() => redirectToNews(stringToUrl(removeSpecialChars(newsItem.title)))}
                     key={newsItem.title}>
                    <div className="news-card">
                        <div className="image" style={{backgroundImage: `url(${newsItem.image_url})`}}></div>
                        <div className="text">
                            <p className="item-title">{newsItem.title}</p>
                            <p className="date">{`${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`}</p>
                        </div>
                    </div>
                </div>
            )
        })
    }

    const UserBanner = (id: number) => {
        const banner = banners.find(banner => banner.id === id);

        return <img src={`${banner?.image_url}`} className="user-banner" alt="Banner"/>;
    }

    const redirectToFilter = (catName: string, itemName: string) => props.history.push(`/stad-overzicht?sorteer_op=afstand&sorteer_richting=oplopend&${catName}=${itemName.charAt(0).toUpperCase() + itemName.slice(1)}&page=1`);

    const redirectToNameFilter = (catName: string, itemName: string) => props.history.push(`/stad-overzicht?sorteer_op=naam&sorteer_richting=oplopend&${catName}=${itemName.charAt(0).toUpperCase() + itemName.slice(1)}&page=1`);

    const redirectToStore = (name: string) => props.history.push(`/winkels/${name}`);

    const redirectToNews = (title: string) => props.history.push(`/nieuws/${title}`);

    const homeHeader = () => {
        const headerDesktop = banners.find(banner => banner.location === 'home-header-desktop');
        const headerMobile = banners.find(banner => banner.location === 'home-header-mobile');

        const mqDesktop = window.matchMedia("(min-width: 960px)");

        if (mqDesktop.matches) {
            if (!headerDesktop) return;
            return (
                <a href={'https://fashionhotspotsroutes.nl/'}>
                    <img src={`${headerDesktop.image_url}`} className="home-header" alt="home-header-desktop"/>
                </a>
            )
        } else {
            if (!headerMobile) return;
            return (
                <a href={'https://fashionhotspotsroutes.nl/'}>
                    <img src={`${headerMobile.image_url}`} className="home-header" alt="home-header-mobile"/>
                </a>
            )
        }
    }

    const introduction = pageContents.find(content => content.location === 'home-fashion-hotspots-info')?.content;

    const mqDesktop = window.matchMedia("(min-width: 960px)");

    return (
        <>
            <Helmet>
                <title>Winkelen in Nederland, ontdek jouw ideale fashionwinkels in de leukste winkelstraten - Fashion
                    Hotspots</title>
                <meta name="description"
                      content="Vul je persoonlijke voorkeuren in en ontdek jouw ideale fashionwinkels en winkelstraten. Profiteer van exclusieve aanbiedingen in de winkel met je gratis Fashion Hotspots account."/>
                <meta name="keywords" content="fashion hotspots, home, kledingwinkels in de buurt, kledingwinkels"/>
            </Helmet>

            <div id="home">

                <div id="intro-banner">
                    <div id="intro-banner-cont">
                        {homeHeader()}
                    </div>
                    <Search stores={stores} cities={cities} brands={brands} routeProps={props}
                            locationAvailable={locationAvailable}/>
                </div>

                <div id="home-city-pictures">
                    {
                        locationAvailable ?
                            <CityPictures redirectFilter={redirectToFilter} slice={6}/>
                            :
                            <CityPictures redirectFilter={redirectToNameFilter} slice={6}/>
                    }

                    <Link to="/steden" className="show-all-cities">Toon alle steden<ArrowDownLink/></Link>
                </div>

                <div id="participating-cities">
                    <div className="container">
                        <a href={'https://fashionhotspotsroutes.nl/'}>
                            {UserBanner(3)}
                        </a>
                        <p className="title">Of maak je eigen route (aantal hotspots)</p>
                        <CityList props={props}/>
                    </div>
                </div>

                <div id="new-hotspots">
                    <div className="container">
                        <p className="title">Nieuwe hotspots</p>
                        <div className="hotspots">
                            {newestStores()}
                        </div>
                        <Link to="/login">
                            {UserBanner(4)}
                        </Link>
                    </div>
                </div>

                <div className="home-news">
                    <p className="title">Fashion News</p>

                    <div className="news-items">
                        {showNewsItems()}
                    </div>

                    <Link to="/nieuws" className="more-news">Meer fashion news <ArrowRight/></Link>
                </div>

                <div id="about">
                    <div className="container">
                        <div className="about-text">
                            <h1 className="about-title"><b>Fashion</b><br></br>Hotspots</h1>
                            <div dangerouslySetInnerHTML={{__html: introduction!}} className="about-content"></div>
                        </div>

                        <SellingPoints props={props}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
