import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import PageContent from "../models/PageContent";

interface OwnProps {

}

interface IPageContentContext {
    pageContents: PageContent[],
    getAllPageContents: () => Promise<void>,
    getPageContentForLocation: (location: string) => PageContent
}

const pageContentEndPoint = new RestEndpoint('page-contents');

export const PageContentContext = createContext({} as IPageContentContext);

type Props = OwnProps;

const PageContentProvider: FunctionComponent<Props> = (props) => {
    const [pageContents, setPageContents] = useState<PageContent[]>([]);

    useEffect(() => {
        getAllPageContents().then()
    }, [])

    const getAllPageContents = async (): Promise<void> => {
        // @ts-ignore
        const allPageContents: PageContent[] = await pageContentEndPoint.all();
        setPageContents(allPageContents);
    }

    const getPageContentForLocation = (location: string) : PageContent=> {
        return pageContents.find(pageContent => pageContent.location === location)!;
    }

    return (
        <PageContentContext.Provider
            value={{pageContents: pageContents, getPageContentForLocation, getAllPageContents: getAllPageContents}}>
                {props.children}
        </PageContentContext.Provider>
    )
}

export default PageContentProvider;
