import React, {FunctionComponent, useEffect, useState} from "react";

interface OwnProps {
    setImage: (image: HTMLImageElement) => void;
    currentImageUrl?: string;
    setImage64?: (image64: string) => void;
    title?: string;
}

type Props = OwnProps;

interface IForm {
    image64: string | ArrayBuffer | null;
}

const ImageUpload: FunctionComponent<Props> = (props) => {
    const [form, setForm] = useState<IForm>({
        image64: "",
    });


    useEffect(() => {
        if (props.currentImageUrl) {
            setForm({image64: props.currentImageUrl});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentImageUrl]);

    const imageInputRef = React.createRef<HTMLInputElement>();

    const handleImageUpload = (event: any) => {
        const selectedImage = event.target.files[0];

        if (!selectedImage) {
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsDataURL(selectedImage);

        fileReader.onload = (e) => {
            setForm({...form, image64: fileReader.result});
        };

        props.setImage(selectedImage);

    };

    useEffect(() => {
        if (props.setImage64) {
            props.setImage64('' + form.image64);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.image64])


    return (
        <div className="image-select">
            <input
                style={{display: "none"}}
                type="file"
                onChange={handleImageUpload}
                ref={imageInputRef}
            />
            <button
                type="button"
                className="image-upload-button"
                style={{backgroundImage: `url(${form.image64})`}}
                onClick={() => imageInputRef.current && imageInputRef.current.click()}
            >
                {!form.image64 && (
                    <div>
                        <span>{props.title || "Upload foto"}</span>
                    </div>
                )}
            </button>
        </div>
    );
};

export default ImageUpload;
