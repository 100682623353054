import React, { FunctionComponent, useContext } from 'react';
import { FilterCategoriesContext } from "../../../../contexts/FilterCategoriesContext";
import FilterCategory from "../../../../models/FilterCategory";
import Store from "../../../../models/Store";

interface OwnProps {
    store: Store
}

type Props = OwnProps;

const WhatWeSell: FunctionComponent<Props> = (props) => {

    const {filterCategories} = useContext(FilterCategoriesContext);
    const {store} = props;

    const filterCategoriesOfStore = filterCategories.reduce((acc: any, filterCategory) => {
        if (store.clothing_categories.find(clothingCategory => clothingCategory.filter_category_id === filterCategory.id)) {
            return [...acc, {
                ...filterCategory,
                clothing_categories: store.clothing_categories.filter(clothingCategory => clothingCategory.filter_category_id === filterCategory.id)
            }];
        }
        return [...acc];
    }, []);


    const filterCategoriesOfStoreWithHTML = filterCategoriesOfStore.map((filterCategory: FilterCategory) => {
        return (
            <div key={filterCategory.id}>
                <h3>{filterCategory.name}</h3>
                <div className="clothing-categories">
                    {filterCategory.clothing_categories.map(clothingCategory =>
                        <div key={clothingCategory.id} className="clothing-category">{clothingCategory.name}</div>)}
                </div>
            </div>
        )
    })


    return (
        <div className="detail-section-component what-we-sell">
            <h2 className="hide-on-tablet">Wat verkopen wij</h2>
            {filterCategoriesOfStoreWithHTML}
        </div>
    );
}

export default WhatWeSell;
