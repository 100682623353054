import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import Style from "../models/Style";
import Category from '../models/Category';

interface OwnProps {

}

interface IStyleContext {
    styles: Style[],
    getAllStyles: () => Promise<void>
}

const styleEndPoint = new RestEndpoint('categories/5');

export const StyleContext = createContext({} as IStyleContext);

type Props = OwnProps;

const StyleContextProvider: FunctionComponent<Props> = (props) => {
    const [styles, setStyles] = useState<Style[]>([]);

    useEffect(() => {
        getAllStyles().then()
    }, [])

    const getAllStyles = async (): Promise<void> => {
        // @ts-ignore
        const category: Category = await styleEndPoint.all();
        // @ts-ignore
        setStyles(category.clothing_categories);
    }

    return (
        <StyleContext.Provider
            value={{styles: styles, getAllStyles: getAllStyles}}>
                {props.children}
        </StyleContext.Provider>
    )
}

export default StyleContextProvider;
