import React, {useContext} from 'react'
import {PageContentContext} from "../../contexts/PageContentContext";
import { Helmet } from 'react-helmet';

interface OwnProps {

}

type Props = OwnProps;

const HowDoesItWork: React.FC<Props> = (props) => {
    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'how-does-it-work')?.content;

    return (
        <div>
            <Helmet>
                <title>Hoe werkt het voor shoppers - Fashion Hotspots</title>
                <meta name="description" content="Vul je persoonlijke voorkeuren in en ontdek de leukste fashionwinkels en winkelstraten. Profiteer van exclusieve kortingsacties in de winkel met je Fashion Hotspots account."/>
                <meta name="keywords" content="fashion hotspots, hoe werkt het" />
            </Helmet>
            <div className="text-content" dangerouslySetInnerHTML={{ __html: content! }} />
        </div>
    )
}

export default HowDoesItWork;
