import React, {
    FunctionComponent,
    useState,
    useContext,
    useEffect,
} from "react";
import FavoriteStoreItem from "./favorite-store-components/FavoriteStoreItem";
import { AuthContext } from "../../contexts/AuthContext";
import RestEndpoint from "../../requests/RestEndpoint";
import { CityContext } from "../../contexts/CityContext";
import Store from "../../models/Store";
import { Helmet } from "react-helmet";
import { PageContentContext } from "../../contexts/PageContentContext";
import {storeToUrl} from "../utils/StoreHelper";

const FavoriteStores: FunctionComponent = () => {
    const [favoriteStores, setFavoriteStores] = useState<Store[]>([]);
    const { cities } = useContext(CityContext);
    const { user } = useContext(AuthContext);
    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'favorite-stores')?.content;

    const removeFavoriteStore = async (id: number) => {
        const index = favoriteStores.findIndex((store) => store.id === id);

        if (index > -1) {
            const favoriteStoreCopy = [...favoriteStores];
            favoriteStoreCopy.splice(index, 1);

            const syncStores = favoriteStoreCopy.map((store) => store.id);

            const endpoint = new RestEndpoint(
                `shoppers/${user?.id}/favorite-stores`
            );

            //@ts-ignore
            await endpoint.store({
                stores: syncStores,
            });

            setFavoriteStores(favoriteStoreCopy);
        }
    };

    let cityIds = favoriteStores.map((store) => store.city_id);

    cityIds = cityIds.filter(
        (storeId, index, self) => self.indexOf(storeId) === index
    );

    const myFavoriteStores = cityIds.map((cityId) => (
        <div key={cityId + "_store"}>
            <h3 className="city-name">
                {cities.find((city) => city.id === cityId)?.name}
            </h3>

            <div className="favorite-stores-city-content">
                {favoriteStores.map((favoriteStore) => {
                    return favoriteStore.city_id === cityId ? (
                        <FavoriteStoreItem
                            link={"/winkels/" + storeToUrl(favoriteStore)}
                            storeId={favoriteStore.id}
                            title={favoriteStore.name}
                            main_image_url={favoriteStore.main_image_url}
                            key={favoriteStore.name + "_" + favoriteStore.id}
                            removeFavorite={removeFavoriteStore}
                        />
                    ) : null;
                })}
            </div>
        </div>
    ));

    useEffect(() => {
        const getFavoriteStores = async (): Promise<void> => {
            const favoriteStoresEndpoint = new RestEndpoint(
                `shoppers/${user?.id}/favorite-stores`
            );

            try {
                //@ts-ignore
                const allStores: Store[] = await favoriteStoresEndpoint.all();

                setFavoriteStores(allStores);
            } catch (e) {
                console.log(e);
            }
        };

        getFavoriteStores();
    }, [user]);

    if (!cities || !favoriteStores) {
        return <p>Loading...</p>;
    }

    return (
        <div className="favorite-stores">
            <Helmet>
                <title>FH - Favoriete winkels</title>
                <meta name="description" content="Vind hier jouw favoriete winkels." />
            </Helmet>

            <div className="favorite-stores-header">
                <div dangerouslySetInnerHTML={{ __html: content! }} className="subtitle" />
            </div>
            <div className="favorite-stores-content">{myFavoriteStores}</div>
        </div>
    );
};

export default FavoriteStores;
