import React, {FunctionComponent, useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {StoreDetails} from "../../../../../models/StoreDetails";
import RestEndpoint from "../../../../../requests/RestEndpoint";
import HorecaItem from "./HorecaItem";

interface IProps {
    horecaReset: boolean,
    setHorecaReset: React.Dispatch<React.SetStateAction<boolean>>
}

const HorecaList: FunctionComponent<IProps> = ({horecaReset, setHorecaReset}) => {
    const [horecas, setHorecas] = useState<StoreDetails[]>([]);
    const [page, setPage] = useState<number>(0);
    const [amountOfPages, setAmountOfPages] = useState<number>();
    const mqDesktop = window.matchMedia("(min-width: 960px)");

    const getAllHorecas = async () => {
        const userEndpoint = new RestEndpoint("users");

        const response = await userEndpoint.search([
            {type: "type", values: ["horeca-owner"]},
            {type: "page", values: [page]}
        ]);

        //@ts-ignore
        const allHorecas: StoreDetails[] = response.data;

        //@ts-ignore
        setAmountOfPages(response.meta.last_page);
        setHorecas(allHorecas);
    };

    useEffect(() => {
        getAllHorecas();
        horecaReset && setHorecaReset(false);
    }, [horecaReset]);

    return (
        <>
            {
                horecas.map((horeca) => {
                    return <HorecaItem key={horeca.id} horeca={horeca} setHorecaReset={setHorecaReset}/>;
                })
            }

            <ReactPaginate
                previousLabel={mqDesktop.matches ? "vorige" : "<"}
                nextLabel={mqDesktop.matches ? "volgende" : ">"}
                breakLabel={"..."}
                disableInitialCallback={true}
                initialPage={page}
                breakClassName={"break-me"}
                pageCount={amountOfPages || 0}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                onPageChange={(data) => {
                    setPage(data.selected + 1);
                }}
                containerClassName={"pagination"}
                activeClassName={"active"}
            />
        </>
    );
};

export default HorecaList;
