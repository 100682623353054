import React, { FunctionComponent } from "react";
import ContactInfo from "./FashionHotspots/ContactInfo";
import SocialMedia from "./FashionHotspots/SocialMedia";
import SellingPoints from "./FashionHotspots/SellingPoints";
import { Helmet } from "react-helmet";

const FashionHotspotsManagement: FunctionComponent = () => {
  return (
    <div className="fashion-hotspots-management">
      <Helmet>
        <title>Superuser - Fashion Hotspots beheer</title>
      </Helmet>

      <div className="header">
        <h1>Fashion Hotspots Beheer</h1>
        <p className="subtitle">
          Op deze pagina kun je de contactinformatie, socialmedia accounts en de
          selling-points van de website beheren.
        </p>
      </div>
      <div className="body">
        <ContactInfo />
        <SocialMedia />
        <SellingPoints />
      </div>
    </div>
  );
};

export default FashionHotspotsManagement;
