import React, { FunctionComponent, useState, useEffect } from "react";
import NewsItem from "../../../models/NewsItem";
import RestEndpoint from "../../../requests/RestEndpoint";
import ManageNewsListItem from "./ManageNewsListItem";
import { useHistory } from "react-router-dom";

const ManageNewsItems: FunctionComponent = (props) => {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [filteredNews, setFilteredNews] = useState<NewsItem[]>([]);
  const history = useHistory();

    const fetchAllNews = async () => {
        const newsEndpoint = new RestEndpoint("news_items");
        const pressEndpoint = new RestEndpoint("press_releases");

        const allNews: NewsItem[] = (await newsEndpoint.all())
            // @ts-ignore
            .map((item) => ({
                id: item.id,
                title: item.title,
                image_url: item.image_url,
                label: item.label,
                created_at: item.created_at,
                updated_at: item.updated_at,
                type: "news",
            }));

        const allPressReleases: NewsItem[] = (await pressEndpoint.all())
            // @ts-ignore
            .map((item) => ({
                id: item.id,
                title: item.title,
                image_url: item.image_url,
                label: item.label,
                created_at: item.created_at,
                updated_at: item.updated_at,
                type: "press",
            }));

        setNews([...allNews, ...allPressReleases]);
    };
  useEffect(() => {
    fetchAllNews();
  }, [setNews]);

  const filterItems = (type: string) => {
    let copy = [...news];
    copy = copy.filter((newsItem) => newsItem.type === type);
    setFilteredNews(copy);
  };

  const filterOnKeywords = (keywords: string) => {
    if (keywords.length === 0) {
      resetFilter();
    }
    let copy = [...news];
    copy = copy.filter((newsItem) =>
      newsItem.title.toUpperCase().includes(keywords.toUpperCase())
    );
    setFilteredNews(copy);
  };

  const resetFilter = () => {
    setFilteredNews([]);
    const searchField = document.getElementById(
      "manage-news-search"
    ) as HTMLInputElement;
    if (searchField) {
      searchField.value = "";
    }
  };

  return (
    <div className="news-list">
      <div className="news-list-header">
        <input
          type="text"
          id="manage-news-search"
          onChange={(e) => {
            filterOnKeywords(e.target.value);
          }}
          placeholder="Zoek op titel"
        />
        <div className="filters">
          <p onClick={resetFilter}>Alle Items</p>
          <p
            onClick={() => {
              filterItems("press");
            }}
          >
            Alleen persberichten
          </p>
          <p
            onClick={() => {
              filterItems("news");
            }}
          >
            Alleen nieuwsberichten
          </p>
        </div>
      </div>
      <div className="news-list-content">
        <strong>
          {filteredNews.length === 0
            ? `${news.length} ${news.length === 1 ? `item` : `items`} gevonden`
            : `${filteredNews.length} ${
                filteredNews.length === 1 ? `item` : `items`
              } gevonden`}
        </strong>

        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Titel</th>
                <th>Datum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredNews.length === 0
                ? news.map((newsItem) => (
                    <ManageNewsListItem
                      newsItem={newsItem}
                      key={`${newsItem.type}_${newsItem.title}`}
                      refreshItems={fetchAllNews}
                    />
                  ))
                : filteredNews.map((newsItem) => (
                    <ManageNewsListItem
                      newsItem={newsItem}
                      key={`${newsItem.type}_${newsItem.title}`}
                      refreshItems={fetchAllNews}
                    />
                  ))}
            </tbody>
          </table>
        </div>
        <button
          onClick={() => {
            history.push("/admin/beheer-nieuws/nieuw");
          }}
        >
          Nieuw bericht
        </button>
      </div>
    </div>
  );
};

export default ManageNewsItems;
