import React, {FunctionComponent, useContext, useState} from "react";
import RestEndpoint from "../../../../../requests/RestEndpoint";
import {Link} from "react-router-dom";
import {ReactComponent as Contact} from "../../../../../assets/icons/contact.svg";
import {ReactComponent as IDCard} from "../../../../../assets/icons/id_card.svg";
import {ReactComponent as Delete} from "../../../../../assets/icons/delete.svg";
import {ReactComponent as Tags} from "../../../../../assets/icons/tags.svg";
import {ReactComponent as Restore} from "../../../../../assets/icons/store_green_menu.svg";
import {StoreDetails} from "../../../../../models/StoreDetails";
import Popup from "../../../../utils/Popup";
import {ToastContext} from "../../../../../contexts/ToastContext";
import {storeDetailsToUrl} from "../../../../utils/StoreHelper";

interface IProps {
    store: StoreDetails,
    updateDummy?: (storeId: number) => void,
    setStoresReset: React.Dispatch<React.SetStateAction<boolean>>
    filter: number | null
}

const StoreItem: FunctionComponent<IProps> = (props) => {
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showConfirmConvert, setShowConfirmConvert] = useState<boolean>(false);
    const [showConfirmRestore, setShowConfirmRestore] = useState<boolean>(false);


    const {notify, errorNotification} = useContext(ToastContext);

    const deleteStore = async () => {
        const endpoint = new RestEndpoint("stores");
        try {
            await endpoint.destroy({id: props.store.store_id});
            props.setStoresReset(true);
            notify("Store gearchiveerd.", "warning");
        } catch (e) {
            errorNotification(e);
        }
    };

    const date = new Date(props.store.created_at).toLocaleDateString();

    const update = () => {
        if (props.updateDummy === undefined) return;

        props.updateDummy(props.store.store_id)
    }

    const convertStore = async () => {
        const endpoint = new RestEndpoint(`dummy-stores/${props.store.store_id}/switch`);


        try {
            await endpoint.requestHelper.postWithoutBody(endpoint.endpoint + '/' + endpoint.resource);
            setShowConfirmConvert(false);

            if (props.store.dummy) {
                notify('Dummy winkel is omgezet in normale winkel');
                return;
            }


            notify('Winkel is omgezet in dummy winkel');
            return;

        } catch (e) {
            errorNotification(e);
        }
    }

    const restoreStore = async () => {
        const endpoint = new RestEndpoint(`stores/${props.store.store_id}`);

        try {
            endpoint.restore({id: props.store.store_id});
            props.setStoresReset(true);
            notify("Store hersteld.");
        } catch (e) {
            errorNotification(e);
        }
    }

    return (
        <div className="store-item user">
            <div className="store-item-buttons">
                {
                    !props.store.dummy ?
                        <Link to={{
                            pathname: `/winkel-details/${props.store.store_id ? props.store.store_id : 'new'}`,
                            state: props.store.store_id ? {} : {storeOwnerId: props.store.id}
                        }}>
                            <Contact/>
                        </Link>
                        :
                        <Contact onClick={update}/>
                }

                {
                    !props.store.dummy &&
                    <Link to={`/admin/beheer-winkels/${props.store.store_id}/kleding-categorieen`}>
                        <Tags/>
                    </Link>
                }

                {
                    !props.store.dummy &&
                    <Link to={`/winkel-eigenaar-details/${props.store.id}`}>
                        <IDCard/>
                    </Link>
                }

                {
                    props.filter !== 2 &&
                    <Delete onClick={() => setShowConfirmation(true)}/>
                }

                {
                    props.filter === 2 &&
                    <Restore onClick={() => setShowConfirmRestore(true)}/>
                }

            </div>

            <Popup
                title={`${props.store.store} archiveren?`}
                buttonText="Archiveren"
                buttonFunction={deleteStore}
                show={showConfirmation}
                setShow={setShowConfirmation}
            />

            <Popup
                title={`${props.store.store} herstellen?`}
                buttonText="Herstellen"
                buttonFunction={restoreStore}
                show={showConfirmRestore}
                setShow={setShowConfirmRestore}
            />

            <Popup
                title={`${props.store.store} omzetten naar ${props.store.dummy ? "winkel" : "dummy"}?`}
                buttonText="Omzetten"
                buttonFunction={convertStore}
                show={showConfirmConvert}
                setShow={setShowConfirmConvert}
            />

            <h3 className="store-name">
                <Link to={`/winkels/${storeDetailsToUrl(props.store)}`}>
                    {
                        props.store.store_id ?
                            `${props.store.dummy ? "(Dummy)" : ""} ${props.store.store} (${props.store.city}) `
                            :
                            <span className={'warning'}>Deze winkeleigenaar heeft nog geen winkel</span>
                    }

                </Link>
            </h3>
            <p>
                Actief sinds: <b>{date || "Onbekend"}</b>
            </p>
            <p>
                Naam eigenaar:{" "}
                <b>
                    {props.store.first_name} {props.store.last_name}
                </b>
            </p>
            {
                !props.store.dummy &&
                <p>
                    Aantal reviews:{" "}
                    <b>
                        {props.store.number_of_reviews} (gemiddeld {props.store.rating})
                    </b>
                </p>
            }

            {
                props.store.dummy ?
                    <button className="button" onClick={() => setShowConfirmConvert(true)}>Omzetten naar winkel</button>
                    :
                    <button className="button" onClick={() => setShowConfirmConvert(true)}>Omzetten naar dummy</button>
            }

        </div>
    );
};

export default StoreItem;
