import React, { FunctionComponent } from "react";
import PersonalInfo from "../../../models/PersonalInfo";
import CheckMark from "../../../assets/icons/check_register.svg";

const generateDOBOptions = (type: string) => {
    switch (type) {
        case "year":
            const startYear = 1930;
            const year = new Date().getFullYear();
            let yearArray = new Array(year - 1930);
            yearArray.fill(0);
            yearArray = yearArray.map((year, index) => index + startYear);
            return yearArray
                .sort((a, b) => a + b)
                .map((year) => (
                    <option defaultValue={year} key={"dob_" + year}>
                        {year}
                    </option>
                ));
        case "month":
            let monthArray = new Array(12);
            monthArray.fill(0);
            monthArray = monthArray.map((month, index) => index + 1);
            return monthArray.map((month) => (
                <option defaultValue={month} key={"dob_" + month}>
                    {month}
                </option>
            ));
        case "day":
            let dayArray = new Array(31);
            dayArray.fill(0);
            dayArray = dayArray.map((day, index) => index + 1);
            return dayArray.map((day) => (
                <option defaultValue={day} key={"dob_" + day}>
                    {day}
                </option>
            ));
    }
};

export const PersonalDetails: FunctionComponent<{
    details: PersonalInfo;
    dateOfBirth: Date;
    handleFieldChange: any;
    handleDOBChange: any;
    checks: boolean;
}> = (props) => {
    return (
        <div className="section-body">
            <div className="input-group">
                <div className="input-group-header">
                    <h4>Jouw Naam</h4>
                </div>

                <div className="input-group-body">
                    <div className={"input-field" + (props.checks ? " validated" : "")} key="firstName_input">
                        <input
                            type="text"
                            name="first_name"
                            defaultValue={props.details?.first_name}
                            placeholder="Voornaam"
                            onChange={props.handleFieldChange}
                            required
                        />
                        <img src={CheckMark} alt="Check" />
                    </div>

                    <br />

                    <div className={"input-field" + (props.checks ? " validated" : "")} key="lastName_input">
                        <input
                            type="text"
                            name="last_name"
                            defaultValue={props.details?.last_name}
                            onChange={props.handleFieldChange}
                            placeholder="Achternaam"
                            required
                        />
                        <img src={CheckMark} alt="Check" />
                    </div>
                </div>
            </div>

            <div className="input-group">
                <div className="input-group-header">
                    <h4>Geboortedatum</h4>
                </div>

                <div className="input-group-body dob">
                    <div className={"input-field" + (props.checks ? " validated" : "")} key="dob_input_day">
                        <select
                            name="dob_day"
                            id="dob_day"
                            onChange={props.handleDOBChange}
                            defaultValue={props.dateOfBirth.getDate()}
                        >
                            {generateDOBOptions("day")}
                        </select>
                        <img src={CheckMark} alt="Check" />
                    </div>

                    <div className={"input-field" + (props.checks ? " validated" : "")} key="dob_input_month">
                        <select
                            name="dob_month"
                            id="dob_month"
                            onChange={props.handleDOBChange}
                            defaultValue={props.dateOfBirth.getMonth() + 1}
                        >
                            {generateDOBOptions("month")}
                        </select>
                        <img src={CheckMark} alt="Check" />
                    </div>

                    <div className={"input-field" + (props.checks ? " validated" : "")} key="dob_input_year">
                        <select
                            name="dob_year"
                            id="dob_year"
                            onChange={props.handleDOBChange}
                            defaultValue={props.dateOfBirth.getFullYear()}
                        >
                            {generateDOBOptions("year")}
                        </select>
                        <img src={CheckMark} alt="Check" />
                    </div>
                </div>
            </div>

            <div className="input-group">
                <div className="input-group-header">
                    <h4>Geslacht</h4>
                </div>

                <div className="input-group-body gender">
                    <div className="input-field" key="gender_input_male">
                        <input
                            type="radio"
                            name="gender"
                            defaultValue="male"
                            onChange={props.handleFieldChange}
                            id="gender_male"
                            defaultChecked={props.details?.gender === "male" ? true : false}
                            required
                        />
                        <label htmlFor="gender_male">
                            <p>Man</p>
                        </label>
                    </div>

                    <div className="input-field" key="gender_input_female">
                        <input
                            type="radio"
                            name="gender"
                            defaultValue="female"
                            onChange={props.handleFieldChange}
                            id="gender_female"
                            defaultChecked={props.details?.gender === "female" ? true : false}
                        />
                        <label htmlFor="gender_female">
                            <p>Vrouw</p>
                        </label>
                    </div>

                    <div className="input-field" key="gender_input_other">
                        <input
                            type="radio"
                            name="gender"
                            defaultValue="other"
                            onChange={props.handleFieldChange}
                            id="gender_other"
                            defaultChecked={props.details?.gender === "other" ? true : false}
                        />
                        <label htmlFor="gender_other">
                            <p>Overige</p>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
