import React, {FunctionComponent, useContext, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory, useParams} from 'react-router-dom';
import {BannerContext} from "../../contexts/BannerContext";
import {StoreContext} from '../../contexts/StoreContext';
import Breadcrumb from "../utils/Breadcrumb";
import DetailHeader from "./detail-page-components/DetailHeader";
import StoreInfo from './detail-page-components/StoreInfo';
import {stringToUrlKeepCase} from "../utils/UrlHelper";
import {storeToUrl} from '../utils/StoreHelper';
import {CityContext} from "../../contexts/CityContext";

interface OwnProps {
    location: any;
}

type Props = OwnProps;

const DetailPage: FunctionComponent<Props> = (props) => {
    const {currentStore, getStore, stores} = useContext(StoreContext);
    const {cities} = useContext(CityContext);
    const {banners} = useContext(BannerContext);
    const hash = props.location.hash;

    const history = useHistory();

    const store_name = useParams<{ store_name: string }>().store_name.split("#")[0];
    const store_city = useParams<{ city_name: string }>().city_name;

    const tabs = ['about', 'what-we-sell', 'reviews', 'hours', 'extra-info'];

    const handleNewTab = (buttonId: any, noHistoryPush?: boolean) => {
        const activeTab = buttonId.replace('-button', '');

        !noHistoryPush && history.push(
            {
                pathname: `/winkels/${store_city}/${store_name}`,
                hash: `#${activeTab}`,
            }
        );

        for (let tab of tabs) {
            const tabHeader = document.getElementById(tab + '-button');
            const tabContent = document.getElementById(tab);

            if (!tabHeader || !tabContent) return;

            if (tab === activeTab) {
                tabHeader.classList.add('active');
                tabContent.classList.add('active');
            } else {
                tabHeader.classList.remove('active');
                tabContent.classList.remove('active');
            }
        }
    }

    useEffect(() => {
        if (currentStore.dummy === true) {
            history.push('/')
        }
    }, [currentStore])

    useEffect(() => {
        const currentStoreNameStripped = store_name.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
        const cityId = cities.find(city => city.name.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase() === store_city)?.id;

        const storeId = stores.find(store => {
            const storeNameStripped = store.name.replace(/[^a-zA-Z0-9]+/g, "-").toLowerCase();
            return storeNameStripped === currentStoreNameStripped && store.city_id === cityId
        })?.id;

        if (storeId) {
            // @ts-ignore
            getStore(storeId);
        }
    }, [stores, cities]);

    useEffect(() => {
        if (hash) {
            handleNewTab(`${hash.replace("#", "")}-button`, true)
        } else {
            handleNewTab("about-button", true);
        }
    }, [hash, currentStore])

    useEffect(() => {
        if (props.location.hash == "#reviews") {
            handleNewTab('reviews-button', true);

            const element = document.getElementsByClassName('tabs')?.[0];

            if (element) {
                element.scrollIntoView(true);
            }
        }
    }, [currentStore])

    if (!currentStore.id) {
        return (
            <div></div>
        );
    }

    const getPromotionsBanner = (): string | undefined => {
        return banners.find(banner => banner.location === 'store-detail-promotions')?.image_url;
    }

    return (
        <div>
            <Helmet>
                <title>{currentStore.name}. Fashion Hotspot in {currentStore.city} - Fashion Hotspots</title>
                <meta name="description"
                      content={`${currentStore.name} in ${currentStore.city}. Deze hotspot wil je bezoeken! Profiteer van exclusieve aanbiedingen met je Fashion Hotspots account.`}/>
                <meta name="keywords" content="winkel, winkeldetail, beschrijving"/>
            </Helmet>

            <Breadcrumb
                tiers={
                    [
                        {
                            url: '/',
                            name: 'Home'
                        },
                        {
                            url: `/stad-overzicht?sorteer_op=afstand&sorteer_richting=oplopend&page=1&stad=${stringToUrlKeepCase(currentStore.city)}`,
                            name: 'Winkels'
                        },
                        {
                            url: `/stad-overzicht?sorteer_op=afstand&sorteer_richting=oplopend&page=1&stad=${stringToUrlKeepCase(currentStore.city)}`,
                            name: currentStore.city
                        },
                        {
                            url: `/winkels/${currentStore.name}-${currentStore.city}`,
                            name: currentStore.name
                        }
                    ]
                }
            />

            <div id="detail-content">
                <DetailHeader store={currentStore} handleNewTab={handleNewTab}/>

                <StoreInfo store={currentStore} handleNewTab={handleNewTab}/>

                <div className="promotions-banner">
                    <img src={getPromotionsBanner()} alt="Promotie-banner"/>
                </div>
            </div>
        </div>

    );
}

export default DetailPage;
