import React, { FunctionComponent, useContext } from 'react';
import Slider from "react-slick";
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow_down_links.svg';
import { StoreContext } from '../../../contexts/StoreContext';

interface IProps {
    imageAlt: string
}

interface PrevProps {
    classToPass?: string
}

const CustomButton: FunctionComponent<PrevProps> = ({classToPass, to, onClick}: any) => {
    return (
        <button type="button" onClick={onClick} className={classToPass}
                aria-label={to}>
            <ArrowRight/>
        </button>
    );
};

const ImageSlider: FunctionComponent<IProps> = (props) => {

    const {currentStore} = useContext(StoreContext);



    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomButton classToPass="custom-arrow arrow-left"/>,
        nextArrow: <CustomButton classToPass="custom-arrow arrow-right"/>
    };



    return (
        <div className="image-slider">
            <div className={"slider"}>
                <Slider {...settings} className="store-detail-image">
                    {
                        currentStore.images.map((image, index) => {
                                return (
                                    <div key={image.id} className="image-container">
                                        <img className="" src={image.url} alt={`${props.imageAlt}-${image.id}`}/>
                                    </div>
                                )
                            }
                        )
                    }
                </Slider>
            </div>
        </div>
    );
};

export default ImageSlider;
