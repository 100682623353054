import React, { FunctionComponent, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import ManageNewsItems from "./manage-news-components/ManageNewsItems";
import { Helmet } from "react-helmet";

const ManageNews: FunctionComponent = (props) => {
    const { user } = useContext(AuthContext);

    if (!user || user.role_id !== 1) {
        return <Redirect to="/login" />;
    }
    return (
        <div className="manage-news">
            <Helmet>
                <title>FH - Beheer nieuws- en persberichten</title>
            </Helmet>
            <div className="header">
                <h1>Beheer nieuws- en persberichten</h1>
                <p className="subtitle">
                    Op deze pagina vind je alle nieuws- en persberichten in een overzicht.
                    Je kunt deze berichten bekijken, aanpassen en verwijderen. Ook kun je
                    hier nieuwe berichten aanmaken.
        </p>
            </div>
            <div className="body">
                <ManageNewsItems />
            </div>
        </div>
    );
};

export default ManageNews;
