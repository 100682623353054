import React, { FunctionComponent, useEffect, useState, useContext } from 'react';
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import { ReactComponent as LinkedIn } from '../../assets/icons/linkedin.svg';
import { Link } from 'react-router-dom';
import RestEndpoint from "../../requests/RestEndpoint";
import SocialMediaAccount from "../../models/storemodels/SocialMediaAccount";
import { AuthContext } from '../../contexts/AuthContext';

interface OwnProps {
}

type Props = OwnProps;

const Footer: FunctionComponent<Props> = (props) => {
    const mq = window.matchMedia('(min-width: 960px)');
    const { user } = useContext(AuthContext);
    const [socialMediaAccounts, setSocialMediaAccounts] = useState<SocialMediaAccount[]>([]);

    const fhSocialMediaAccountsEndpoint = new RestEndpoint('fashion-hotspots/1/social-media-accounts');

    useEffect(() => {
        const fetchFHSocialMediaAccounts = async () => {
            // @ts-ignore
            setSocialMediaAccounts(await fhSocialMediaAccountsEndpoint.all());
        };

        fetchFHSocialMediaAccounts();

    }, []);


    const socialMediaAccountsHTML = socialMediaAccounts.map(socialMediaAccount => {
        return (
            <div key={socialMediaAccount.id}>
                <a href={socialMediaAccount.pivot.url} target="_blank" rel="noopener noreferrer">
                    <img src={socialMediaAccount.image_url} alt={socialMediaAccount.name} />
                </a>
            </div>
        )
    });
 
    const currentDate = new Date();

    return (
        <footer>
            <div id="footer-section">
                <div>
                    <h3>Fashion Hotspots</h3>
                    <Link to="/over-ons">Wie zijn wij?</Link>
                    <Link to="/nieuws">Nieuws</Link>
                    <Link to="/pers">Pers</Link>
                </div>

                <div>
                    <h3>Hulp nodig?</h3>
                    <Link to="/contact">Neem contact op</Link>
                    <Link to="/algemene-voorwaarden">Algemene voorwaarden</Link>
                    <Link to="/privacy-verklaring">Privacy</Link>
                    <Link to="/cookie-verklaring">Cookiebeleid</Link>
                    <Link to="/registreer">Registreren</Link>
                    <Link to="/login">Inloggen</Link>
                    <Link to="/hoe-werkt-het">Hoe werkt het?</Link>
                    <Link to="/veelgestelde-vragen">Veelgestelde vragen</Link>
                </div>

                {
                    mq.matches &&
                    <div>
                        <h3>Voor winkels</h3>
                        <Link to="/hoe-werkt-het-winkel">Hoe werkt het?</Link>
                        <Link to="/registreer-winkel">Registreren</Link>
                        <Link to="/login">Inloggen</Link>
                    </div>
                }
            </div>

            {
                !mq.matches &&
                <div id="footer-section">
                    <div>
                        <h3>Voor winkels</h3>
                        <Link to="/hoe-werkt-het-winkel">Hoe werkt het?</Link>
                        <Link to="/registreer-winkel">Registreren</Link>
                        <Link to="/login">Inloggen</Link>
                    </div>
                </div>
            }

            <div id="footer-social">
                {mq.matches && <p className="follow">VOLG ONS</p>}

                <div>
                    {socialMediaAccountsHTML}
                </div>
            </div>

            <p className="copyright">&copy; Copyright - Fashion
                Hotspots {currentDate.getFullYear()}{mq.matches ? " - " : <br></br>}Gerealiseerd door rmic en B302</p>

        </footer>
    );
};

export default Footer;
