import React, {FunctionComponent, useContext} from 'react';
import {PageContentContext} from "../../contexts/PageContentContext";
import { Helmet } from 'react-helmet';

interface OwnProps {}

type Props = OwnProps;

const CookiePolicyPage: FunctionComponent<Props> = (props) => {

    const { pageContents } = useContext(PageContentContext);

    const content = pageContents.find(pageContent => pageContent.location === 'cookie-policy')?.content;

    return (
        <div>
            <Helmet>
                <title>Cookiebeleid - Fashion Hotspots</title>
                <meta name="description" content="Het cookiebeleid van Fashion Hotspots"/>
            </Helmet>
            <div className="text-content" dangerouslySetInnerHTML={{ __html: content! }} />
        </div>
    );
};

export default CookiePolicyPage;
