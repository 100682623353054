import RestEndpoint from "./RestEndpoint";

interface UserPasswordReset {
    email: string,
    token: string,
    password: string,
    password_confirmation: string
}

class ResetPasswordEndpoint extends RestEndpoint {
    constructor() {
        super('forgot-password');
    }

    sendResetLink = async (email: string) => {
        try {
            await this.requestHelper.post(`${this.endpoint}/${this.resource}/email`, { email: email, client_url: process.env.REACT_APP_ENDPOINT + "/herstel-wachtwoord" });
        } catch (e) {
            return e;
        }
    }

    resetPassword = async (user: UserPasswordReset) => {
        try {
            await this.requestHelper.post(`${this.endpoint}/${this.resource}/reset`, user);
        } catch (e) {
            return e;
        }

    }
}

export default ResetPasswordEndpoint;
