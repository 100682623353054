import React, { useContext, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { AdminFAQContext } from '../../../contexts/AdminFAQContext';
import FAQCategory from '../../../models/FAQCategory';
import MainCat from './faq-components/MainCat';

interface OwnProps {

}

type Props = OwnProps;

const AdminFAQ: React.FC<Props> = (props) => {
    const { getAllCategories } = useContext(AdminFAQContext);
    const [mainCats, setMainCats] = useState<FAQCategory[]>();

    const getMainCats = useCallback(async () => {
        setMainCats(await getAllCategories());
    }, [getAllCategories])

    const updateMainCat = (cat: FAQCategory) => {
        setMainCats(mainCats?.map(mainCat => mainCat.id === cat.id ? cat : mainCat))
    }

    useEffect(() => {
        getMainCats();
    }, [])

    return (
        <div id="admin-faq">
            <Helmet>
                <title>Superuser - FAQ Beheer</title>
            </Helmet>

            <h1>Beheer FAQ</h1>

            {
                mainCats?.map(mainCat => {
                    return (
                        <MainCat
                            mainCat={mainCat}
                            updateSelf={updateMainCat}
                            key={mainCat.id}
                        />
                    )
                })
            }
        </div>
    )
}

export default AdminFAQ
