import React, { FunctionComponent } from "react";

const SocialMediaRow: FunctionComponent<{
    name: string;
    id: number | null;
    icon: string;
    handleFormChange: any;
    url: string | null;
}> = (props) => {
    const handleCheckboxTick = (e: any) => {
        const element: HTMLInputElement | null = document.getElementById(
            props.id?.toString() || ""
        ) as HTMLInputElement;

        if (element) {
            e.target.checked
                ? element.classList.remove("hidden")
                : element.classList.add("hidden");

            if (!e.target.checked) {
                props.handleFormChange(props.id, null);
            }
        }
    };

    const uppercaseName =
        props.name.charAt(0).toUpperCase() + props.name.slice(1);

    return (
        <tr className="social-media-row" key={"social_media_" + props.id}>
            <td
                className="store-details-checkbox"
                key={"social_media_" + props.id + "_checkbox"}
            >
                <label htmlFor={props.name + "_checkbox"} className="checkbox">
                    <input
                        type="checkbox"
                        id={props.name + "_checkbox"}
                        onChange={handleCheckboxTick}
                        defaultChecked={props.url !== null && props.url !== ""}
                    />
                    <span className="checkmark"></span>
                </label>
            </td>
            
            <td
                className="store-details-icon"
                key={"social_media_" + props.id + "_icon"}
            >
                <img src={props.icon} alt={uppercaseName} />
            </td>

            <td
                className={"social-media-input"}
                key={"social_media_" + props.id + "_url"}
            >
                <input
                    type="text"
                    name={props.name}
                    id={props.id?.toString()}
                    className={props.url ? "" : "hidden"}
                    defaultValue={props.url || ""}
                    placeholder={"URL " + uppercaseName}
                    onChange={(e) => {
                        props.handleFormChange(e.target.id, e.target.value);
                    }}
                />
            </td>
        </tr>
    );
};

export default SocialMediaRow;
