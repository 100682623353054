import { FunctionComponent, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import TokenService from "../../requests/TokenService";

const SSOLoginHandler: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();

  const params = location.search.replace("?token=", "");

  useEffect(() => {
    if (params) {
      TokenService.setToken(params);
      window.location.reload();
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  }, [params]);
  return null;
};

export default SSOLoginHandler;
