import React, {ChangeEvent, FunctionComponent, useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {RouteComponentProps, useParams} from 'react-router-dom';
import {ReactComponent as ArrowRight} from "../../assets/icons/arrow_right_links.svg";
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import {ReactComponent as StoreIcon} from "../../assets/icons/store_green_menu.svg";
import {StoreContext} from '../../contexts/StoreContext';
import {ToastContext} from '../../contexts/ToastContext';
import Store from "../../models/Store";
import Review from "../../models/storemodels/Review";
import RestEndpoint from "../../requests/RestEndpoint";
import StoreReviewShopperEndpoint from "../../requests/StoreReviewsEndpoint";
import SearchComponent from '../utils/SearchComponent';
import {storeToUrl} from "../utils/StoreHelper";

interface OwnProps extends RouteComponentProps {
}

type Props = OwnProps;

interface IReviewForm {
    id?: number,
    rating: number | string,
    title: string,
    content: string
}

const ReviewFormShopperPage: FunctionComponent<Props> = (props) => {
    const {store_name} = useParams<{ store_name: string }>();

    const {stores} = useContext(StoreContext);
    const {notify, errorNotification} = useContext(ToastContext);

    const [filteredStores, setFilteredStores] = useState<Store[]>([]);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [store, setStore] = useState<Store>();
    const [form, setForm] = useState<IReviewForm>({
        rating: -1,
        title: '',
        content: ''
    });
    const [hoverResults, setHoverResults] = useState<boolean>();

    const storesEndpoints = new RestEndpoint(`stores`);
    const storeReviewsEndpoint = new RestEndpoint(`stores/${store_name}/reviews`);
    // @ts-ignore
    const storeReviewShopperEndpoint = new StoreReviewShopperEndpoint(store_name);

    useEffect(() => {
        if (store_name) {
            const currentStore = stores.find(store => store.id === parseInt(store_name));

            if (currentStore) setStore(currentStore);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store_name])

    const getCurrentReview = async () => {
        // @ts-ignore
        const currentReview: Review = await storeReviewShopperEndpoint.getReviewShopper();
        if (currentReview && currentReview.id) {
            setForm({
                id: currentReview.id,
                title: currentReview.title,
                content: currentReview.content,
                rating: currentReview.rating
            });
        }
    }

    const getStoreInfo = async () => {
        // @ts-ignore
        setStore(await storesEndpoints.find(store_name));
    };

    useEffect(() => {
        if (store_name) {
            getCurrentReview();
            getStoreInfo().then();
        }

        setHoverResults(false);
    }, []);


    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    const handleSubmit = async () => {
        if (form.id) {
            try {
                await storeReviewsEndpoint.update(form);
                notify("De review is succesvol bijgewerkt.");
                props.history.push(`/winkels/${storeToUrl(store)}`);
            } catch (e) {
                errorNotification(e);
            }
        } else {
            try {
                await storeReviewsEndpoint.store(form);
                notify("De review is succesvol geplaatst.");
                props.history.push(`/winkels/${storeToUrl(store)}`);
            } catch (e) {
                errorNotification(e);
            }
        }
    };

    const selectStore = (id: number) => {
        const store = stores.find(store => store.id === id);

        setShowResults(false);
        setHoverResults(false);
        setStore(store);

        props.history.push(`/shopper/maak-review/${id}`)
    }

    const unselectStore = () => {
        setStore(undefined);
        props.history.push('/shopper/maak-review')
    }

    const changeSearchResults = (array: Store[]) => {
        setFilteredStores(array.slice(0, 6))
    }

    const searchOnBlur = () => {
        if (!hoverResults) {
            setShowResults(false)
        }
    }

    const toggleHoverResults = () => {
        setHoverResults(!hoverResults);
    }

    return (
        <div className="shopper-review-form">
            <Helmet>
                <title>FH - Schrijf een recensie.</title>
                <meta name="description" content="Schrijf een recensie."/>
                <meta name="keywords" content="recensie"/>
            </Helmet>

            <h1>Schrijf een recensie</h1>

            <form>
                <h3>Winkel</h3>

                {
                    store &&
                    <div className="review-store-info">
                        <StoreIcon className="store-icon"/>
                        <div className="info-div">
                            <div>
                                <div className="store-name">
                                    {store?.name}
                                </div>
                                <div className="store-address">
                                    {store?.street} {store?.house_number}, {store.city}
                                </div>
                            </div>

                            <Close className="close-icon" onClick={unselectStore}/>
                        </div>
                    </div>
                }

                {
                    !store &&
                    <div className="search-results">
                        <SearchComponent
                            placeholder="Zoek een winkel"
                            objAttr="name"
                            array={stores}
                            setArray={changeSearchResults}
                            onFocus={() => setShowResults(true)}
                            onBlur={searchOnBlur}
                        />

                        {
                            showResults &&
                            <div className="store-list" onMouseEnter={toggleHoverResults}
                                 onMouseLeave={toggleHoverResults}>
                                {
                                    filteredStores.map(store => {
                                        return (
                                            <div className="store-search-result" key={store.id}
                                                 onClick={() => selectStore(store.id)}
                                            >
                                                <p className="store-name">{store.name}</p>
                                                <p className="store-address">{store?.street} {store?.house_number}, {store.city}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                }

                <h3>Algemene score <span className="required">*</span></h3>
                <div className="store-rating">
                    <div className="store-rating__mobile">
                        <select value={form.rating} name="rating" required onChange={handleChange}>
                            <option hidden value={-1}>Kies een score</option>
                            {
                                [...Array(10)].map((e, i) => {
                                    const next = i + 1;
                                    return (
                                        <option key={next} value={next}>{next}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="store-rating__desktop">
                        {
                            [...Array(10)].map((e, i) => {
                                const next = i + 1;
                                return (
                                    <label key={next}>{next}
                                        <input type="radio" name="rating" value={next}
                                               checked={form.rating === next.toString()}
                                               onChange={handleChange}/>
                                    </label>
                                )
                            })
                        }
                    </div>
                </div>
                <h3>Jouw mening in één zin <span className="required">*</span></h3>
                <input onChange={handleChange} name="title" type="text" placeholder="Voorbeeld: Wat een goede service"
                       value={form.title}
                />
                <h3>Recensie</h3>
                <textarea value={form.content} onChange={handleChange} name="content" rows={6}
                          placeholder="Schrijf hier je recensie..."></textarea>
                <button type="button" onClick={handleSubmit}>Plaats je recensie <ArrowRight/></button>
                <p>
                    Door een recensie te plaatsen ga je akkoord met onze gebruikersvoorwaarden en bevestig je dat je ons
                    privacybeleid hebt gelezen.
                </p>
            </form>
        </div>
    );
};

export default ReviewFormShopperPage;
