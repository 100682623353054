import React, { FunctionComponent } from "react";
import City from "../../../models/City";
import ManageCityListItem from "./ManageCityListItem";

const ManageCityList: FunctionComponent<{
  cities: City[];
  deleteItem: (id: number) => Promise<void>;
}> = (props) => {
  return (
    <table className="city-list">
      <tbody>
        {props.cities.map((city) => (
          <ManageCityListItem
            city={city}
            deleteItem={props.deleteItem}
            key={city.id}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ManageCityList;
