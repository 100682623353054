import React, {FunctionComponent, useContext, useEffect, useState,} from "react";
import NewsItem from "../../models/NewsItem";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useHistory, useParams} from "react-router-dom";
import RestEndpoint from "../../requests/RestEndpoint";
import htmlToDraft from "html-to-draftjs";
import draftToHTML from "draftjs-to-html";
import NewsItemUpdate from "../../models/NewsItemUpdate";
import RequestHelper from "../../requests/RequestHelper";
import {AuthContext} from "../../contexts/AuthContext";
import {Helmet} from "react-helmet";
import {removeSpecialChars, stringToUrl} from "../utils/UrlHelper";
import {NewsContext} from "../../contexts/NewsContext";

interface ImageDimensions {
    width: number;
    height: number;
}

const EditNewsItem: FunctionComponent = () => {
    const [newsItem, setNewsItem] = useState<NewsItem>();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [selectedType, setSelectedType] = useState<string>("");
    const {type, id} = useParams<{ type: string, id: string }>();
    const [headerImagePreview, setHeaderImagePreview] = useState<string>("");
    const [headerImage, setHeaderImage] = useState<HTMLImageElement>();
    const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({
        width: 0, height: 0
    });
    const history = useHistory();
    const [error, setError] = useState<string>("");
    const {user} = useContext(AuthContext);
    const {getAllNewsItems} = useContext(NewsContext);

    if (!user || user.role_id !== 1) {
        history.push("/login");
    }

    useEffect(() => {
        if (type === 'news' || 'press') {
            setSelectedType(type);
        }
    }, []);

    useEffect(() => {
        const getItemDetails = async () => {

            const endpoint =
                type === "news"
                    ? new RestEndpoint(`news_items/${id}`)
                    : new RestEndpoint(`press_releases/${id}`);

            //@ts-ignore
            const item: NewsItem = await endpoint.all();

            item.type = type;
            setNewsItem(item);

            const blocksFromHTML = htmlToDraft(item.content);
            const {contentBlocks, entityMap} = blocksFromHTML;
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));

            setHeaderImagePreview(item.image_url);
        };
        if (type && type !== "new") {
            getItemDetails();
        }
    }, [type, id]);

    const handlePicturePreview = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                setImageDimensions({
                    width: img.width,
                    height: img.height,
                });
            };

            const reader = new FileReader();

            reader.onload = (e) => {
                setHeaderImagePreview(e.target?.result as string);
            };

            reader.readAsDataURL(file);
            setHeaderImage(file);
        }
    };

    const handleSubmit = async () => {
        setError("");
        const title = (document.getElementById("item_title") as HTMLInputElement)
            ?.value;
        if (!title) {
            window.scroll(0, 0);
            setError("Het titel-veld is verplicht");
            return;
        }

        if (title.includes('%')) {
            window.scroll(0, 0);
            setError("Het % teken is niet toegestaan in de titel van een bericht");
            return;
        }

        const label = (document.getElementById("item_label") as HTMLInputElement)
            ?.value;
        if (!label) {
            window.scroll(0, 0);
            setError("Het label-veld is verplicht");
            return;
        }

        const image = headerImage;
        if (!image && !(type === "news" || type === "press")) {
            window.scroll(0, 0);
            setError("Een header afbeelding is verplicht");
            return;
        }

        if (image && (imageDimensions.width !== 1270 || imageDimensions.height !== 716)) {
            window.scroll(0, 0);
            setError("De header afbeelding moet een breedte van 1270px en een hoogte van 716px hebben");
            return;
        }

        const content = draftToHTML(convertToRaw(editorState.getCurrentContent()));
        if (!content) {
            window.scroll(0, 0);
            setError("Een bericht moet een inhoud hebben.");
            return;
        }

        const newsItemUpdate = new NewsItemUpdate({
            title,
            label,
            image,
            content,
        });

        let updatedItem;

        if (type && type !== "new") {
            const endpoint =
                type === "news"
                    ? new RestEndpoint(`news_items/${id}`)
                    : new RestEndpoint(`press_releases/${id}`);

            if (newsItemUpdate.image) {
                updatedItem = endpoint.update(RequestHelper.convertToFormData(newsItemUpdate));
            } else {
                updatedItem = endpoint.update(newsItemUpdate);
            }
        } else {
            const endpoint =
                selectedType === "news"
                    ? new RestEndpoint(`news_items`)
                    : new RestEndpoint(`press_releases`);

            updatedItem = endpoint.store(RequestHelper.convertToFormData(newsItemUpdate));
        }
        setImageDimensions({width: 0, height: 0});
        //@ts-ignore
        history.push(`/${selectedType === "news" ? "nieuws" : "pers"}/${stringToUrl(removeSpecialChars(updatedItem.title))}`);
        await getAllNewsItems();
    };

    return (
        <div className="edit-news-item">
            <Helmet>
                <title>FH - Beheer nieuws- en persberichten</title>
            </Helmet>
            <div className="header">
                <h1>Beheer nieuws- en persberichten</h1>
                <p className="subtitle">
                    Op deze pagina kun je een persbericht aanpassen.
                </p>
            </div>
            {error.length > 0 ? (
                <p className="error">{`De volgende fout is opgetreden: ${error}`}</p>
            ) : (
                ""
            )}
            <div className="body">
                {!type || type === "new" ? (
                    <div className="row">
                        <label>Type Bericht</label>
                        <div className="radio-row">
                            <label htmlFor="item_type_news">Nieuwsbericht</label>
                            <input
                                type="radio"
                                name="item_type"
                                id="item_type_news"
                                onClick={() => {
                                    setSelectedType("news");
                                }}
                            />
                        </div>
                        <div className="radio-row">
                            <label htmlFor="item_type_press">Persbericht</label>
                            <input
                                type="radio"
                                name="item_type"
                                id="item_type_press"
                                onClick={() => {
                                    setSelectedType("press");
                                }}
                            />
                        </div>
                    </div>
                ) : null}
                <div className="row">
                    <label htmlFor="item_title">Titel</label>
                    <input
                        type="text"
                        name="title"
                        id="item_title"
                        defaultValue={newsItem?.title}
                    />
                </div>
                <div className="row">
                    <label htmlFor="item_label">Label</label>
                    <input
                        type="text"
                        name="label"
                        id="item_label"
                        defaultValue={newsItem?.label}
                    />
                </div>
                <div className="row">
                    <label htmlFor="item_image">Header Afbeelding (1270 x 716px)</label>
                    <img src={headerImagePreview} alt="" id="header-preview"/>
                    <input
                        type="file"
                        id="item_image"
                        className="file-input"
                        onChange={handlePicturePreview}
                    />
                </div>
                <div className="row content-row">
                    <label>Inhoud</label>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        localization={{locale: "nl"}}
                        wrapperClassName="news-wrapper"
                        editorClassName="news-editor"
                        toolbar={{
                            options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                "list",
                                "textAlign",
                                "link",
                                "emoji",
                                "remove",
                                "history",
                            ],
                        }}
                    />
                </div>
                <div className="row">
                    <button onClick={handleSubmit}>Opslaan</button>
                </div>
            </div>
        </div>
    );
};

export default EditNewsItem;
