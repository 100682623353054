import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right2.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { PageContentContext } from "../../contexts/PageContentContext";
import FAQCategory from "../../models/FAQCategory";
import RestEndpoint from "../../requests/RestEndpoint";
import Breadcrumb from "../utils/Breadcrumb";
import FAQHelper from "../utils/FAQHelper";
import FAQContact from "./FAQContact";



interface OwnProps {
}

type Props = OwnProps;

const faqCategoryEndpoint = new RestEndpoint('faq-categories');

const FAQCategoryPage: FunctionComponent<Props> = () => {

    // const [faqId, setFaqId] = useState(1);
    const [faqCategory, setFaqCategory] = useState<FAQCategory>();
    const {user, isStoreOwner, isShopper} = useContext(AuthContext);
    const {getPageContentForLocation} = useContext(PageContentContext);

    useEffect(() => {
        const fetchFaqCategory = async () => {
            let faqCategoryId = 1;
            if (user) {
                faqCategoryId = FAQHelper.getFAQCategoryId(user.role_id);
            }

            // @ts-ignore
            setFaqCategory(await faqCategoryEndpoint.find(faqCategoryId));
        };

        fetchFaqCategory();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const faqSubCategories = faqCategory?.sub_categories.map(subCategory => {
        return (
            <Link key={subCategory.id} className='item' to={`/veelgestelde-vragen/${subCategory.id}`}>
                <div className='subcategory-logo-name'>
                    <span className={'subcategory-logo'}>
                        <img src={subCategory.logo_url} alt="Subcategorie logo" />
                    </span>
                    <span className={'subcategory-name'}>
                        {subCategory.name}
                    </span>
                </div>

                <span className='subcategory-arrow-right'>
                    <ArrowRight/>
                </span>
            </Link>
        )
    });

    const getPageContentLocation = () => {
        if (isStoreOwner()) {
            return 'faq-store';
        }
        if (isShopper()) {
            return 'faq-shopper';
        }
        return 'faq';
    }

    return (
        <div>
            <Helmet>
                <title>Veelgestelde vragen - Fashion Hotspots FAQ</title>
                <meta name="description" content="Hier vind je de veelgestelde vragen met antwoorden van Fashion Hotspots. Jouw vraag en antwoord niet kunnen vinden? Log in en stel je vraag via het contactformulier."/>
                <meta name="keywords" content="vragen, faq, veelgesteld" />
            </Helmet>

            <Breadcrumb tiers={[{url: '/', name: 'Home'}, {url: '/veelgestelde-vragen', name: 'Veelgestelde vragen'}]}/>
            <div className="faq-all faq-category">
                <h1 className="hide-on-tablet">Veelgestelde vragen</h1>
                <div className='faq-header' style={{backgroundImage: 'url(' + faqCategory?.header_url + ')'}}>
                    <div className='faq-category-title' dangerouslySetInnerHTML={{__html:
                        getPageContentForLocation(getPageContentLocation())?.content}}>
                    </div>
                </div>
                <h1 className="show-on-tablet">Veelgestelde vragen</h1>

                <div className="faq-content">
                    <h3>Zoek op onderwerp</h3>
                    <div>
                        {faqSubCategories}
                    </div>
                    <h3>Contact</h3>
                    <FAQContact/>
                </div>
            </div>
        </div>

    );
};

export default FAQCategoryPage;
