import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {ReactComponent as FavoriteIcon} from "../../../assets/icons/favorite_store.svg";
import {ReactComponent as FavoriteRemoveIcon} from "../../../assets/icons/favorite_store_remove.svg";
import {AuthContext} from "../../../contexts/AuthContext";
import {ToastContext} from '../../../contexts/ToastContext';
import Store from "../../../models/Store";
import RestEndpoint from '../../../requests/RestEndpoint';
import Coupon from "../../utils/Coupon";
import Navigator from "../../utils/Navigator";
import Popup from '../../utils/Popup';
import ImageSlider from "./ImageSlider";

interface OwnProps {
    store: Store
    handleNewTab: (buttonId: any) => void;
}

type Props = OwnProps;

const DetailHeader: FunctionComponent<Props> = (props) => {
    const {store} = props;
    const [favoriteStore, setFavoriteStore] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const {user} = useContext(AuthContext);
    const {notify} = useContext(ToastContext);

    const mqDesktop = window.matchMedia('(max-width: 765px)');

    const coupons = store.coupons?.map(coupon => {

        return (
            <div className="store-action-item" key={coupon.id}>
                {
                    !user &&
                    <div className="coupon-cont">
                        <div className="coupon-overlay">
                            <p>Log in voor deze exclusieve aanbieding</p>
                        </div>
                    </div>
                }

                <Coupon
                    disabled={!user}
                    title={coupon.title}
                    subtitle={coupon.description}
                    type="FASHION HOTSPOTS AANBIEDING"
                    blueBackground={mqDesktop.matches ? true : false}
                    coupon={coupon}
                    expandCoupon={true}
                    isFavorite={!!user?.favorite_coupon_ids?.find(couponId => couponId === coupon.id)}
                />
            </div>
        )
    });

    const handleRatingsClick = () => {
        props.handleNewTab('reviews-button')
        const element = document.getElementById('reviews');
        if (element) {
            element.scrollIntoView(true)
        }
    }

    useEffect(() => {
        const getFavoriteStore = async () => {
            if (user && user.role_id === 3) {
                const endpoint = new RestEndpoint(`shoppers/${user.id}/favorite-stores`);
                const stores = await endpoint.all();

                //@ts-ignore
                if (stores.filter((favoriteStore) => (favoriteStore.id === store.id)).length > 0) {
                    setFavoriteStore(true);
                }
            }
        }
        getFavoriteStore().then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitFavoriteStore = async () => {
        if (user) {
            if (favoriteStore) {
                const endpoint = new RestEndpoint(`shoppers/${user.id}/favorite-stores`);
                await endpoint.destroy({id: store.id});
                notify("De winkel is uit je favorieten verwijderd.");
                setFavoriteStore(false);
            } else {
                const endpoint = new RestEndpoint(`shoppers/${user.id}/favorite-stores/${store.id}`);
                await endpoint.store({});
                notify("De winkel is aan je favorieten toegevoegd.")
                setFavoriteStore(true);
            }
        }
        setShowPopup(false);
    }

    const favIcon = () => {
        if (!user) return;
        if (user.role_id === 1 || user.role_id === 2) return;

        return favoriteStore ? <FavoriteIcon className="favorite-store-icon" onClick={() => {
            setShowPopup(true);
        }}/> : <FavoriteRemoveIcon className="favorite-store-icon" onClick={() => {
            setShowPopup(true);
        }}/>
    }

    const contact = () => {
        return (
            <>
                <p className="address">{store.street} {store.house_number}, {store.zip_code}, {store.city}, {store.country}</p>
                <div className="contact">
                    {
                        store.phone &&
                        <a href={"tel:" + store.phone}>T. {store.phone}</a>
                    }
                    {
                        store.website &&
                        <a href={store.website} target="_blank" rel="noopener noreferrer">BEZOEK WEBSITE</a>
                    }
                </div>
            </>
        )
    }

    return (
        <div className="detail-header">
            <div className="title-image-address">
                <div className="inner-title-row">
                    <h2>{store.name}
                        {favIcon()}
                    </h2>
                    <div className='number-reviews-rating' onClick={handleRatingsClick}>
                        <div className="rating">
                            <span
                                className="average">{
                                store.number_of_reviews > 0 ?
                                    (Math.round(store?.rating * 10) / 10).toString().replace(".", ",")
                                    :
                                    '-'
                            }</span>
                            <span className="max-score">/10</span>
                        </div>
                        <div
                            className="number-of-reviews">{store.number_of_reviews} {store.number_of_reviews === 1 ? "recensie" : "recensies"}</div>
                    </div>
                </div>

                <ImageSlider imageAlt={`${store.name}-${store.city}`}/>
                <div className="inner-logo-address">
                    <div className="logo">
                        <img src={store.logo_url} alt={`${store.name}-${store.city}-logo`}/>
                    </div>
                    <div className="divider">
                        <Popup
                            title={`${store.name} als favoriete winkel ${favoriteStore ? 'verwijderen' : 'toevoegen'}?`}
                            buttonText={`${favoriteStore ? 'Verwijderen' : 'Toevoegen'}`}
                            buttonFunction={submitFavoriteStore}
                            show={showPopup}
                            setShow={setShowPopup}
                        />

                    </div>
                    <div className="address-contact hide-mobile">
                        {contact()}
                    </div>
                    <div className="divider hide-mobile">

                    </div>
                    <Navigator store={store}/>
                    <div className="address-contact hide-tablet">
                        {contact()}
                    </div>
                </div>


            </div>
            {
                store.coupons.length > 0 &&
                <div className="offers">
                    {
                        store.coupons.length > 0 &&
                        <div className="coupons">
                            {coupons}
                            <div className="hide-on-tablet">&nbsp;&nbsp;&nbsp;</div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default DetailHeader;
