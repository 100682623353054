import React, {createContext, FunctionComponent, useEffect, useState } from 'react'
import RestEndpoint from "../requests/RestEndpoint";
import Item from "../models/Item";
import Category from '../models/Category';

interface OwnProps {

}

interface IItemContext {
    items: Item[],
    getAllItems: () => Promise<void>
}

const itemEndPoint = new RestEndpoint('categories/3');

export const ItemContext = createContext({} as IItemContext);

type Props = OwnProps;

const ItemContextProvider: FunctionComponent<Props> = (props) => {
    const [items, setItems] = useState<Item[]>([]);

    useEffect(() => {
        getAllItems().then()
    }, [])

    const getAllItems = async (): Promise<void> => {
        // @ts-ignore
        const category: Category = await itemEndPoint.all();
        // @ts-ignore
        setItems(category.clothing_categories);
    }

    return (
        <ItemContext.Provider
            value={{items: items, getAllItems: getAllItems}}>
                {props.children}
        </ItemContext.Provider>
    )
}

export default ItemContextProvider;
